import SendResetPasswordContainer from 'src/components/LoginForm/SendResetPasswordContainer';
import { UnauthenticatedTemplate } from 'src/templates/UnauthenticatedTemplate';

export function SendResetPasswordEmailPage() {
  return (
    <UnauthenticatedTemplate>
      <SendResetPasswordContainer />
    </UnauthenticatedTemplate>
  );
}
