import { css, styled } from '@mui/material';

type SpinnerProps = {
  size?: string;
  color?: string;
  [x: string]: any;
};

export function SimpleSpinner({ size = '2em', color, ...props }: SpinnerProps) {
  return <Loader width={size} height={size} {...props} />;
}

type LoaderProps = {
  width: string;
  height: string;
  color?: string;
};

const Loader = styled('div')<LoaderProps>`
  ${({ theme, width, height, color }) => css`
    display: inline-block;
    width: ${width};
    height: ${width};
    &:after {
      content: ' ';
      display: block;
      width: ${width};
      height: ${height};
      border-radius: 50%;
      border: 3px solid #fff;
      border-color: ${color || theme.palette.secondary.main} transparent ${color || theme.palette.secondary.main}
        ${color || theme.palette.secondary.main};
      animation: spinner 1.2s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`;
