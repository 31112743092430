import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIListItem from '@mui/material/ListItem';
import MUITypography from '@mui/material/Typography';
import { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import useFormattedPrice, { ISO4217 } from 'src/services/formatCurrency';

interface CheckoutCartItemProps {
  partName: string;
  partNumber: string;
  quantity: number;
  price: DiscountedPartPrice;
  currency: ISO4217;
}

export function DesktopMyOrderItem({ partName, partNumber, quantity, price, currency }: CheckoutCartItemProps) {
  return (
    <ReceivedOrderItemWrapper>
      <ItemTitle>
        <PartName>{partName}</PartName> <span>•</span> <PartNumber>{partNumber}</PartNumber>
      </ItemTitle>
      <ItemInformation>
        <ItemQuantity>
          <MUITypography variant="body2">Quantity:</MUITypography>
          <ItemQuantityNumber variant="body1">{quantity}</ItemQuantityNumber>
        </ItemQuantity>
        <ItemPrice data-testid="received-order-item-price">{useFormattedPrice({ price, currency })}</ItemPrice>
      </ItemInformation>
    </ReceivedOrderItemWrapper>
  );
}

const ReceivedOrderItemWrapper = styled(MUIListItem)`
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
`;

const ItemTitle = styled('div')`
  display: flex;
  width: 90%;
  margin-left: 2rem;
`;

const PartName = styled(MUITypography)`
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

PartName.defaultProps = { fontWeight: 'bold', variant: 'body1' };

const PartNumber = styled(MUITypography)`
  max-width: 50%;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ItemInformation = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
`;

const ItemQuantity = styled(MUIBox)`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

const ItemQuantityNumber = styled(MUITypography)`
  margin-left: 0.5rem;
`;

const ItemPrice = styled(MUITypography)`
  font-weight: bold;
  margin-right: 2rem;
`;
