/* eslint-disable no-console */
import { isProduction } from 'src/utils/isProduction';
import { TrackJS } from 'trackjs';

if (isProduction()) {
  TrackJS.install({
    token: process.env.REACT_APP_TRACK_TOKEN || '',
    application: process.env.REACT_APP_TRACK_APPLICATION,
  });
}

export const error = (error: Error, message?: string) => {
  if (isProduction()) {
    TrackJS.track(`${error} \n ${message}`);
  } else {
    console.error(error);
    console.log(message);
  }
};

export const info = (tag: string, message: string) => {
  if (!isProduction()) {
    console.info(`${tag}: ${message}`);
  }
};
