import { faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export function MobileHeaderShoppingCartButton({ openPanel }: { openPanel: () => void }) {
  return (
    <Box component="div" sx={{ flexGrow: 0 }}>
      <Tooltip title="Shopping Cart">
        <MobileShoppingCartIcon onClick={openPanel} data-testid="shopping-cart">
          <FontAwesomeIcon icon={faCartShopping} />
        </MobileShoppingCartIcon>
      </Tooltip>
    </Box>
  );
}

const MobileShoppingCartIcon = styled(IconButton)`
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;
