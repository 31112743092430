import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { AutocompleteRenderInputParams, InputAdornment } from '@mui/material';
import type { Supersession } from '@samsonvt/shared-types/partsTable';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import filterOptionsFunction, {
  uniqueKey,
} from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/filterOptions';
import SearchOption from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/SearchOption';
import {
  AutocompleteTextField,
  StyledPopper,
  StyledProductSearch,
  WhiteIcon,
} from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/styles';
import { useProduct } from 'src/providers/Product';
import { PartPathWithHash } from 'src/services/partPath';
import { makePartUrl } from 'src/services/partUrl';

const minimumSearchTermLength = 3;
const initialSearchTerm = '';

export interface SearchablePart extends PartPathWithHash, Supersession {} // Typing is slightly off. Some options do not have name or id, partNumber is considered required but in fact its not (only supersessions have part number, this should be merged with id in EPC).

export const charactersToEscapeRegex = /[.*+?^${}()|[\]\\]/g;

export function Autocomplete(): JSX.Element {
  const [query, setQuery] = useState(initialSearchTerm);
  const history = useHistory();
  const { productId, partPaths } = useProduct();

  const enabled = query.length >= minimumSearchTermLength;
  const escapedQuery = query.replace(charactersToEscapeRegex, '\\$&');
  const regex = new RegExp(escapedQuery, 'gi');

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const options = filterOptionsFunction(regex, enabled)(flattenSupersessionsAsParts(partPaths));
    return (
      <AutocompleteTextField
        {...params}
        variant="outlined"
        label="Search"
        focused
        margin="normal"
        placeholder="Search for a part name or number"
        InputProps={{
          ...params.InputProps,
          className: 'override-class',
          startAdornment: <SearchIcon />,
          endAdornment: options.length ? params.InputProps.endAdornment : undefined,
        }}
      />
    );
  };

  return (
    <StyledProductSearch<SearchablePart, false, true, false> // <T (Type of options), Multiple, DisableClearable, FreeSolo>
      data-testid="bom-filter-input"
      disableClearable
      autoHighlight
      getOptionLabel={(option) => option.part.name}
      isOptionEqualToValue={(option: SearchablePart, value: SearchablePart) => uniqueKey(option) === uniqueKey(value)} // Fixes console warings, probably some reference field breaks === comparison
      filterOptions={filterOptionsFunction(regex, enabled)} // Filter function closes over regex
      options={flattenSupersessionsAsParts(partPaths)} // Adding supersessions here to parts taken from product provider
      noOptionsText={enabled ? 'Nothing found' : `Please enter more than ${minimumSearchTermLength} characters`}
      onChange={(_, { part: { id }, pathHash, partNumber }) =>
        history.push(
          makePartUrl(productId, id, partNumber ? { group: pathHash, supersession: partNumber } : { group: pathHash })
        )
      }
      onInputChange={(_, value) => setQuery(value)} // Connect Autocomplete value with react state
      renderInput={renderInput}
      PopperComponent={StyledPopper} // Popper required to target the list item
      renderOption={(props, option) => (
        <SearchOption key={uniqueKey(option)} props={props} searchablePart={option} regex={regex} />
      )}
    />
  );
}

const flattenSupersessionsAsParts = (parts: PartPathWithHash[] = []) =>
  parts
    .map((p) => {
      const partPathWithHash = p as SearchablePart;
      const supersessionEntries = partPathWithHash.part.supersessions?.map((s) => ({ ...p, ...s }));
      return [partPathWithHash, ...(supersessionEntries || [])];
    })
    .flat();

function SearchIcon() {
  return (
    <InputAdornment position="start">
      <WhiteIcon icon={faMagnifyingGlass} />
    </InputAdornment>
  );
}
