import { useEffect, useState } from 'react';
import { Vector3Tuple } from 'three';

export function useTransformControls() {
  const [pointCoords, setPointCoords] = useState<Vector3Tuple>([0, 0, 0]);
  const [cameraCoords, setCameraCoords] = useState<Vector3Tuple>([0, 0, 0]);
  const [inputCoordinates, setInputCoordinates] = useState<Vector3Tuple>([0, 0, 0]);
  const [transformControlsEnabled, setTransformControlsEnabled] = useState(false);

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      if (event.shiftKey && event.ctrlKey && event.altKey && event.code === 'KeyI') {
        setTransformControlsEnabled(!transformControlsEnabled);
      }
    };
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [transformControlsEnabled]);

  return {
    transformControlsEnabled,
    pointCoords,
    setPointCoords,
    cameraCoords,
    setCameraCoords,
    inputCoordinates,
    setInputCoordinates,
  };
}
