import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { isWithinIframe } from 'src/services/checkForIframe';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import type { IRepairStepView, IRepairView } from 'src/providers/repairs/types';
import Description from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/Description';
import StepButton from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/StepButton';
import { BottomBar } from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/styles';
import { workInstructionsLink } from 'src/utils/urls';

interface ProductParams {
  productId: string;
  section: string;
}

export function RepairText() {
  const repairDetails = useContext(RepairDetailContext);
  const { productId, section } = useParams<ProductParams>();
  const { search: tagUrlSection } = useLocation();

  if (!repairDetails) {
    return null;
  }

  const { step, repair } = repairDetails;

  const { nextStepUrl } = stepUrls(repair, step);

  const repairsHome = workInstructionsLink(productId);
  const currentRepairStart = workInstructionsLink(productId, section);

  const nextStepURL = `${
    isWithinIframe ? nextStepUrl || currentRepairStart : nextStepUrl || repairsHome
  }${tagUrlSection}`;

  return (
    <BottomBar data-testid="repair-bar">
      <Description description={step.description} />
      <StepButton icon={faChevronRight} to={nextStepURL} data-testid="next-step-button">
        {nextStepUrl ? 'Next Step' : 'Complete'}
      </StepButton>
    </BottomBar>
  );
}

const stepUrls = (repairDetails: IRepairView, step: IRepairStepView) => {
  const flattenedSteps = repairDetails.sections.map((section) => section.steps).flat();
  const currentStepIndex = flattenedSteps.findIndex((s) => s.url === step.url);
  const nextStep = flattenedSteps[currentStepIndex + 1];
  return {
    nextStepUrl: nextStep ? nextStep.url : undefined,
  };
};
