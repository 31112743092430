import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUITypography from '@mui/material/Typography';
import { spacing } from 'src/constants/spacing';

interface BasicListHeaderProps {
  productName: string | undefined;
  tagListActive: boolean | undefined;
}

export default function BasicListHeader({ productName, tagListActive }: BasicListHeaderProps) {
  return (
    <HeaderContainer>
      <HeaderWithMargin>{productName}</HeaderWithMargin>
      <PrimaryDivider />
      <TextWithMargin>
        {tagListActive ? 'Select from the below:' : 'Select from the below working instructions:'}
      </TextWithMargin>
    </HeaderContainer>
  );
}

const HeaderContainer = styled('div')`
  padding: ${spacing.mediumSpacing};
`;

const HeaderWithMargin = styled(MUITypography)`
  margin-top: 0.65rem;
  margin-bottom: 1.45rem;
`;
HeaderWithMargin.defaultProps = { variant: 'h1' };

const TextWithMargin = styled(MUITypography)`
  margin-top: 0.45rem;
`;
TextWithMargin.defaultProps = { variant: 'body1' };

const PrimaryDivider = styled(MUIDivider)`
  border-color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  border-bottom-width: 2px;
  margin: 0;
`;
PrimaryDivider.defaultProps = { variant: 'middle' };
