import Chip from 'src/components/Chip';
import { Highlight } from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/Highlight';
import { SearchablePart } from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/index';
import {
  BlockLi,
  FlexSpaceBetween,
  SmallCapitalized
} from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/styles';

interface SearchOptionProps {
  searchablePart: SearchablePart;
  regex: RegExp;
  props: React.HTMLAttributes<HTMLLIElement>;
}

export default function SearchOption({ searchablePart, regex, props }: SearchOptionProps) {
  const {
    part: { name, id, partNumber },
    group,
    active,
  } = searchablePart;

  return (
    <BlockLi data-testid="bom-filter-result" {...props}>
      <Highlight text={name} regex={regex} />
      {searchablePart.partNumber &&
        !active && ( // Display chip only for outdated supersessions (For some reason active part is also on the list)
          <Chip size="small" label="Outdated" style={{ marginLeft: 10 }} />
        )}
      <FlexSpaceBetween>
        <Highlight text={partNumber || id} regex={regex} />
        {group?.name && <SmallCapitalized>In {group.name.toLowerCase()}</SmallCapitalized>}
      </FlexSpaceBetween>
    </BlockLi>
  );
}
