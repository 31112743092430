import { styled } from '@mui/material';
import { spacing } from 'src/constants/spacing';

export const BottomBar = styled('div')`
  height: 8.5rem;
  display: flex;
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

export const RepairText = styled('div')`
  font-size: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  padding: ${spacing.mediumSpacing};
`;

export const ScrollContainer = styled('div')`
  overflow: auto;
  height: 100%;
  position: relative;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;
