import MUIToolbar from '@mui/material/Toolbar';
import { faBars, faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIIconButton from '@mui/material/IconButton';
import MUIButton from '@mui/material/Button';

import { device } from 'src/constants/mediaQueries';

interface MobileMenuProps {
  openPanel: () => void;
  triggerMobileProductMenu: () => void;
}

export function MobileCheckoutHeader({ openPanel, triggerMobileProductMenu }: MobileMenuProps) {
  return (
    <MobileCheckoutBar>
      <MobileCheckoutMenuContainer>
        <MUIIconButton size="large" onClick={triggerMobileProductMenu} color="inherit" data-testid="nav-hamburger-icon">
          <FontAwesomeIcon icon={faBars} />
        </MUIIconButton>
        <Divider orientation="vertical" />
        <MobileCheckoutCartButton onClick={openPanel} endIcon={<FontAwesomeIcon icon={faCartShopping} />}>
          View your shopping cart
        </MobileCheckoutCartButton>
      </MobileCheckoutMenuContainer>
    </MobileCheckoutBar>
  );
}

const MobileCheckoutBar = styled(MUIToolbar)`
  height: 5.5rem;

  @media ${device.mobileOrTablet} {
    min-height: 0px;
  }
`;

const MobileCheckoutMenuContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const MobileCheckoutCartButton = styled(MUIButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
  font-size: 0.85rem;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.whiteGlass};
  text-transform: none;
  margin-left: auto;
`;

const Divider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  height: 40px;
  width: 1px;
  margin: 1rem;
`;
