import { styled } from '@mui/material';
import { Autocomplete } from 'src/components/Product/Mobile/Panels/PartsCatalogue/Autocomplete';
import { StyledHeader, headerZ } from './StyledHeader';

export function Search({ closeSearch, fullscreen }: { closeSearch: any; fullscreen: boolean }) {
  return (
    <>
      <StyledHeader>
        <Autocomplete fullscreen={fullscreen} closeSearch={closeSearch} data-testid="drawer-autocomplete" />
      </StyledHeader>
      <SearchResults onClick={preventNavDrawerTrigger} data-testid="drawer-searchresults" />
      <SearchOverlay data-testid="search-overlay" onClick={closeSearch} />
    </>
  );
}

// Stops the click bubbling up to the parent and triggering the whole
// drawer motion.
const preventNavDrawerTrigger = (e: React.MouseEvent<any>) => {
  e.stopPropagation();
};

const SearchResults = styled('div')`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
  z-index: ${headerZ + 1};
`;

const SearchOverlay = styled('div')`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${headerZ - 1};
  top: 0;
  height: 100%;
  width: 100%;
`;
