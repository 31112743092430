/* eslint-disable no-restricted-syntax */
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { computeHash, PartContext, PartPath, PartPathWithHash } from './partPath';

const flatten = (node: PartNode, context?: PartContext): PartPath[] => {
  // Using recursion here
  // eslint-disable-next-line no-param-reassign
  context = context || { parents: [] };

  const parts = [{ part: node, ...context }];

  const nextContext: PartContext = {
    group: node.focal ? node : context.group,
    parents: [...context.parents, node],
  };

  for (const child of node.children) {
    const nested = flatten(child, nextContext);
    parts.push(...nested);
  }

  return parts;
};

export const flattenAndHash = (node: PartNode): PartPathWithHash[] =>
  flatten(node).map((path) => ({
    ...path,
    pathHash: computeHash(path),
  }));

export const findRelatedPartNumber = (object3DName: string, part: PartNode): string | undefined => {
  const isRelatedPart = part.glbIds?.includes(object3DName);

  if (isRelatedPart) {
    return part.id; // In EPC partNumber is now called `id`, this is legacy and should be renamed in EPC.
  }

  for (const child of part.children) {
    const foundPartNumber = findRelatedPartNumber(object3DName, child);

    if (foundPartNumber) {
      return foundPartNumber;
    }
  }
};
