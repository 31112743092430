import { useState } from 'react';

import { MainTemplate } from 'src/templates/MainTemplate';
import { ContentPadding } from 'src/components/ContentPane';
import { ProductList } from 'src/components/ProductsList';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { ProductLibraryBackground } from 'src/components/ProductLibraryBackground';
import { Toast } from 'src/components/Toast';
import { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';
import { Footer } from '../../components/Footer';
import { ProductListHeader } from './ProductListHeader';
import EmptyProductList from './EmptyProductList';

export type MessageType = 'error' | 'success';
export type DisplayMessageHandler = (title: string, message: string, messageType: MessageType) => void;

interface ProductListPageProps {
  showAddProductTile: boolean;
  isLoading: boolean;
  isError: boolean;
  products: ProductListAPIResponse;
}

export function ProductListPage({ showAddProductTile, products, isLoading, isError }: ProductListPageProps) {
  const [searchFieldState, setSearchFieldState] = useState('');

  const filteredProducts = products.filter(
    (product) =>
      (searchFieldState.length >= 3 && product.name.toLowerCase().includes(searchFieldState.toLowerCase())) ||
      searchFieldState.length < 3
  );

  if (isLoading) {
    return <FullscreenLoadingSpinner />;
  }

  return (
    <>
      <MainTemplate>
        <ProductLibraryBackground>
          <ContentPadding>
            <ProductListHeader searchFieldState={searchFieldState} setSearchFieldState={setSearchFieldState} />
            {!filteredProducts.length ? (
              <EmptyProductList areThereAnyFetchedProducts={!!products.length} searchFieldState={searchFieldState} />
            ) : (
              <ProductList products={filteredProducts} showAddProductTile={showAddProductTile} />
            )}
          </ContentPadding>
        </ProductLibraryBackground>
        <Footer />
      </MainTemplate>
      <Toast
        dependency={isError}
        severity="error"
        title="Sorry there has been a problem"
        message="Please try again later."
      />
    </>
  );
}
