import { CheckoutStepper } from 'src/components/Stepper';
import { BackButton } from 'src/components/Button';
import { DesktopCheckoutCart } from './DesktopCheckoutCart';
import { DesktopCheckoutForm } from './DesktopCheckoutForm';
import { DesktopCheckoutFormContainer, DesktopCheckoutPageContainer } from '../styles';
import type { CheckoutProps } from '../Checkout';

export default function DesktopCheckout({
  cart,
  orderNumber,
  isOrderPOR,
  discountPercentage,
  preferredCurrency,
  sendOrder,
  cartTotal,
  isFetching,
  goBack,
  countryList,
  defaultAccountDetails,
}: CheckoutProps) {
  return (
    <DesktopCheckoutPageContainer>
      <BackButton onClick={goBack} />
      <CheckoutStepper currentStep={!orderNumber ? 1 : 3} />
      <DesktopCheckoutFormContainer>
        <>
          <DesktopCheckoutForm
            cart={cart}
            isOrderPOR={isOrderPOR}
            discountPercentage={discountPercentage}
            preferredCurrency={preferredCurrency}
            sendOrder={sendOrder}
            isFetching={isFetching}
            countryList={countryList}
            defaultAccountDetails={defaultAccountDetails}
          />
          <DesktopCheckoutCart
            cart={cart}
            cartTotal={cartTotal}
            preferredCurrency={preferredCurrency}
            isFetching={isFetching}
          />
        </>
      </DesktopCheckoutFormContainer>
    </DesktopCheckoutPageContainer>
  );
}
