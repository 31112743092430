import { styled } from '@mui/material';
import MUICard from '@mui/material/Card';
import MUICardContent from '@mui/material/CardContent';
import MUIList from '@mui/material/List';
import MUIDrawer from '@mui/material/SwipeableDrawer';
import MUITypography from '@mui/material/Typography';
import { StyledMUIButton } from 'src/components/Button';
import { ListFade } from 'src/components/ListFade';

export const MobileDrawer = styled(MUIDrawer)`
  z-index: ${({ theme }) => theme.zIndex.modal};
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.palette.modalBackdrop};
  }
`;

MobileDrawer.defaultProps = {
  anchor: 'right',
  color: 'secondary',
  PaperProps: {
    sx: {
      background: ({ palette }) => palette.primary.dark,
      boxSizing: 'border-box',
      overflow: 'visible',
      width: 320,
    },
  },
  swipeAreaWidth: 0,
};

export const Header = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding-top: 36px;
`;

export const Title = styled(MUITypography)`
  margin: 1rem;
  display: block;
  justify-self: center;
`;
Title.defaultProps = { variant: 'h6', fontWeight: 'bold' };

export const ShoppingCartWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileShoppingCartPanelListFade = styled(ListFade)`
  &::after {
    margin-left: 5px;
  }
`;

export const PositionedCard = styled(MUICard)`
  max-width: 345;
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  position: relative;
  padding: 0;
  box-shadow: none;
`;

export const PriceTag = styled('strong')`
  float: right;
  font-size: 1rem;
  margin-left: auto;
  margin-right: 1rem;
  text-align: right;
`;

export const PaddingTop = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const ContentContainer = styled(MUICardContent)`
  padding: 0 0 0 1rem;
`;

export const TwoLineEllipsis = styled('div')`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OneLineEllipsis = styled('div')`
  display: block;
  margin-right: auto;
`;

export const ThumbnailStyles = styled('div')`
  margin-left: 1rem;
`;

export const DeleteButton = styled(StyledMUIButton)`
  margin: 0rem 1rem 0rem 0rem;
  height: 30px;
  width: 60px;
  min-width: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: ${({ theme }) => theme.shadows[2]};

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const StyledCardHeader = styled('div')`
  display: flex;
`;

export const ProductInfo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ListHeightContainer = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
  height: 0px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  overflow-y: scroll;

  ${({ theme }) => theme.mixins.hideScrollbar}
`;

export const StyledList = styled(MUIList)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};

  .MuiPaper-root:nth-of-type(even) {
    background-color: transparent;
  }
  .MuiPaper-root:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.action.focus};
  }
`;

export const ListBackground = styled('div')`
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  height: 100%;
  margin-left: 5px;
`;
