import { styled, Popper } from '@mui/material';

export const BlockLi = styled('li')`
  padding: 16px !important;
  display: block !important; // overriding Mui default flex display
`;

export const SmallCapitalized = styled('div')`
  text-transform: capitalize;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

export const FlexSpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
`;

// Slight rework on material-ui to achieve dotted border on top and remove general padding around entire list

export const StyledPopper = styled(Popper)`
  top: 8px !important;

  .MuiAutocomplete-paper {
    background-color: transparent !important;
    box-shadow: none;
    color: white;
  }

  // Option part name
  .MuiAutocomplete-option > span:first-of-type {
    font-weight: bold !important;
  }

  // Option part number
  .MuiAutocomplete-option > div > span {
    font-size: 14px;
  }

  .MuiAutocomplete-noOptions {
    color: white;
  }

  .MuiAutocomplete-listbox {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-right: 16px;
    max-height: calc(50vh - 60px);

    li {
      border-bottom: 1px solid ${({ theme }) => theme.palette.whiteGlass} !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    li:last-of-type {
      border-bottom: none !important;
    }

    li:hover {
      background: ${({ theme }) => `${theme.palette.secondary.dark} !important`};
    }
  }
`;

export const FullScreenPopper = styled(StyledPopper)`
  .MuiAutocomplete-listbox {
    max-height: calc(90vh - 60px) !important;
  }
`;
