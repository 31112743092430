import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import MUIIconButton from '@mui/material/IconButton';
import MUITableContainer from '@mui/material/TableContainer';
import MUILink from '@mui/material/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { faCircleCheck, faSquareCheck as faSquareCheckLight } from '@fortawesome/pro-light-svg-icons';
import type { OrderStatus } from '@samsonvt/shared-types/orderLambda';
import { LoadingSpinner } from 'src/components/Loading';
import { device } from 'src/constants/mediaQueries';

const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Background = styled(FlexColumn)`
  overflow-y: auto;
  overflow-x: hidden;
  background: ${({ theme }) => theme.palette.common.white};
`;

export const ContentContainer = styled(FlexColumn)`
  width: 80%;
  @media ${device.mobileOrTablet} {
    width: 95%;
  }
  margin: auto;
  margin-top: 1rem;
`;

export const TableStyles = styled(MUITableContainer)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  margin-bottom: 5rem;
`;

export const ConfirmationNumber = styled(MUILink)<LinkProps>`
  font-weight: bold;
  font-size: 1rem;
  text-decoration-thickness: 2px;
  text-decoration-color: ${({ theme }) => theme.palette.grey[900]};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    cursor: pointer;
    text-decoration: none;
  }
`;

ConfirmationNumber.defaultProps = { component: RouterLink };

const CheckButton = styled(MUIIconButton)`
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  height: 2rem;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-around;
  padding: 1.2rem;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  &.Mui-disabled {
    background: none;
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;
interface CompletedButtonProps extends React.HTMLProps<HTMLButtonElement> {
  orderStatus: OrderStatus;
  loading?: boolean;
}

export function CompletedButton({ orderStatus, onClick, loading }: CompletedButtonProps) {
  return (
    <CheckButton disabled={orderStatus !== 'ACTIVE'} onClick={onClick} data-testid="complete-button">
      {loading ? (
        <LoadingSpinner width="100px" height="100px" />
      ) : (
        <>
          <FontAwesomeIcon icon={orderStatus === 'ACTIVE' ? faSquareCheckLight : faCircleCheck} />
          <CheckedText status={orderStatus} data-testid="complete-button-text">
            {orderStatus === 'ACTIVE' ? 'Mark as complete' : 'Order completed'}
          </CheckedText>
        </>
      )}
    </CheckButton>
  );
}

const CheckedText = styled(MUITypography)<{ status: OrderStatus }>`
  font-weight: ${({ status }) => (status === 'ACTIVE' ? 'bold' : 'normal')};
  color: inherit;
  padding-left: 0.5rem;
`;
CheckedText.defaultProps = { variant: 'body1' };

export const StatusCircle = styled(FontAwesomeIcon)<{ status: OrderStatus }>`
  color: ${({ status, theme }) => (status === 'ACTIVE' ? theme.palette.success.main : theme.palette.common.black)};
  margin-right: 0.5rem;
`;

export const LoadingSpinnerStyles = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'listLength' && prop !== 'isFirstTimeLoading',
})<{
  isFirstTimeLoading: boolean;
  listLength: number;
}>`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[900]};
  background-color: ${({ theme, listLength }) =>
    listLength % 2 ? theme.palette.grey[100] : theme.palette.common.white};
  ${({ isFirstTimeLoading, theme }) =>
    isFirstTimeLoading &&
    `height: 330px; 
    width: 100%; 
    margin: auto;  
    color: ${theme.palette.primary.contrastText}; ; 
    text-align: center;
    background-color: ${theme.palette.whiteGlass};
    padding-top: 130px;`}
`;

export const AllOrdersLoaded = styled(MUITypography, { shouldForwardProp: (prop: string) => prop !== 'listLength' })<{
  listLength: number;
}>`
  text-align: center;
  line-height: 45px;
  height: 46px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[900]};
  color: ${({ theme }) => theme.palette.grey[900]};
  background-color: ${({ theme, listLength }) =>
    listLength % 2 ? theme.palette.grey[100] : theme.palette.common.white};
`;

export const EmptyTable = styled(MUITypography)`
  height: 330px;
  width: 100%;
  margin: auto;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  text-align: center;
  padding-top: 155px;
`;
export const MagnifyingGlassSearchIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey['600']};
`;
