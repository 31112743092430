import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Portal, styled } from '@mui/material';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { StyledMUIPrimaryButton } from 'src/components/Button';
import { LoadingSpinner } from 'src/components/Loading';
import { TableData, TableDataRow } from 'src/components/Table/MUITableStyles';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';
import { Toast } from 'src/components/Toast';
import { deleteDiscountScheme, putDiscountScheme } from 'src/services/api';

interface DiscountSchemeTableRowProps {
  scheme: DiscountScheme;
}

export default function DiscountSchemeTableRow({ scheme: schemeTakenFromBackend }: DiscountSchemeTableRowProps) {
  const [controlledDiscountPercentage, setControlledDiscountPercentage] = useState(
    schemeTakenFromBackend.discountPercentage
  );
  const queryClient = useQueryClient();

  const deleteDiscountSchemeMutation = useMutation(deleteDiscountScheme, {
    onSuccess: () => {
      queryClient.invalidateQueries(['discountSchemesList']);
    },
  });

  const deleteScheme = () => deleteDiscountSchemeMutation.mutate(schemeTakenFromBackend.id);

  const updateDiscountScheme = async () =>
    putDiscountScheme(schemeTakenFromBackend.id, {
      discountPercentage: controlledDiscountPercentage,
    });

  const updateDiscountSchemeMutation = useMutation(updateDiscountScheme, {
    onSuccess: () => {
      queryClient.invalidateQueries(['discountSchemesList']);
    },
  });

  const [validationError, setValidationError] = useState<{ message: string }>(); // { message: string } and no just string, So that we compare by ref in toast deps to display message even if it is the same

  const validateAndSendUpdateDiscountPercentage = () => {
    if (!controlledDiscountPercentage && controlledDiscountPercentage !== 0) {
      setValidationError({
        message: `The given discount percentage for ${schemeTakenFromBackend.name} is invalid. Please input a valid discount percentage`,
      });
      setControlledDiscountPercentage(schemeTakenFromBackend.discountPercentage);
      return;
    }
    if (controlledDiscountPercentage > 100 || controlledDiscountPercentage < 0) {
      setValidationError({
        message: `The given discount percentage for ${schemeTakenFromBackend.name} is outside the allowed range 0-100`,
      });
      setControlledDiscountPercentage(schemeTakenFromBackend.discountPercentage);
      return;
    }
    if (controlledDiscountPercentage === schemeTakenFromBackend.discountPercentage) {
      return;
    }
    setValidationError(undefined);

    updateDiscountSchemeMutation.mutate();
  };

  return (
    <TableDataRow data-testid={`discount-table-row-${schemeTakenFromBackend.id}`}>
      <TableData>{schemeTakenFromBackend.name}</TableData>
      <TableData>
        <EditPercentageInputField
          value={controlledDiscountPercentage}
          InputProps={{ endAdornment: '%' }}
          onBlur={validateAndSendUpdateDiscountPercentage}
          onChange={(e) => setControlledDiscountPercentage(parseFloat(e.target.value))}
        />
      </TableData>
      <TableData sx={{ textAlign: 'center' }}>
        <DeleteDiscountButton onClick={deleteScheme}>
          {deleteDiscountSchemeMutation.isLoading ? (
            <LoadingSpinner height="80px" width="80px" />
          ) : (
            <>
              <TrashIcon icon={faTrash} />
              Delete discount
            </>
          )}
        </DeleteDiscountButton>
      </TableData>
      <Portal>
        <Toast
          dependency={updateDiscountSchemeMutation.data}
          severity="success"
          title="Success"
          message={`Successfully updated ${updateDiscountSchemeMutation.data?.data.name} discount percentage to ${updateDiscountSchemeMutation.data?.data.discountPercentage}%`}
        />
        <Toast
          dependency={deleteDiscountSchemeMutation.isError}
          severity="error"
          title="Error"
          message="Could not delete the discount scheme"
        />
        <Toast
          dependency={updateDiscountSchemeMutation.isError}
          severity="error"
          title="Error"
          message="Could not update the discount scheme"
        />
        <Toast
          dependency={validationError}
          severity="error"
          title="Validation error"
          message={validationError?.message}
        />
      </Portal>
    </TableDataRow>
  );
}

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const DeleteDiscountButton = styled(StyledMUIPrimaryButton)`
  width: fit-content;
`;

const EditPercentageInputField = styled(WhiteTextFieldWithoutLabel)`
  width: 4rem;
  height: 3rem;

  .MuiInputBase-input {
    text-align: center;
  }

  ${({ theme }) => theme.mixins.hideArrowsInNumberTypeInput}
`;

EditPercentageInputField.defaultProps = {
  variant: 'filled',
  type: 'number',
};
