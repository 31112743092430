import { useMediaQuery } from '@mui/material';
import type { CreateOrderRequest, CreateOrderResponse } from '@samsonvt/shared-types/orderLambda';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Redirect } from 'react-router-dom';
import { device } from 'src/constants/mediaQueries';
import { AccountDetails, MutateAccountDetails } from 'src/hooks/useAccountDetails';
import { CartEntry } from 'src/providers/ShoppingCart';
import DesktopCheckout from './Desktop';
import MobileCheckout from './Mobile';

export interface CheckoutProps {
  cart: CartEntry[];
  orderNumber?: string;
  isOrderPOR: boolean;
  discountPercentage: number | undefined;
  preferredCurrency: string | undefined;
  sendOrder: UseMutateFunction<AxiosResponse<CreateOrderResponse>, unknown, CreateOrderRequest, unknown>;
  cartTotal: DiscountedPartPrice;
  currentPath: string;
  isFetching: boolean;
  goBack: () => void;
  countryList: string[];
  defaultAccountDetails: MutateAccountDetails & AccountDetails;
}

export default function Checkout(checkoutProps: CheckoutProps) {
  const isMobile = useMediaQuery(device.mobileOrTablet);

  const { orderNumber, currentPath } = checkoutProps;

  if (orderNumber) return <Redirect to={`${currentPath}/thank-you`} />; // go to confirmation screen

  return isMobile ? <MobileCheckout {...checkoutProps} /> : <DesktopCheckout {...checkoutProps} />;
}
