import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, alpha } from '@mui/material';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUIPopper from '@mui/material/Popper';

import { faMagnifyingGlass, faX } from '@fortawesome/pro-light-svg-icons';

import { ProductListResult } from '@samsonvt/shared-types/productLambda';
import { useState } from 'react';
import { device } from 'src/constants/mediaQueries';
import { useProductFeatures } from 'src/hooks/useProductFeatures';
import { useProductsListQuery } from 'src/providers/useProductsListQuery';
import ProductSearchOption from './ProductSearchOption';
import { SearchField } from '../SearchField';

export default function GlobalProductNameSearch() {
  const { data: fetchedProducts = [] } = useProductsListQuery();
  const availableProductFeatures = useProductFeatures();
  const [searchFieldState, setSearchFieldState] = useState('');
  const filteredProducts = fetchedProducts.filter(
    (product) =>
      (searchFieldState.length >= 3 && product.name.toLowerCase().includes(searchFieldState.toLowerCase())) ||
      searchFieldState.length < 3
  );

  return (
    <StyledAutoComplete
      freeSolo
      disableClearable
      options={searchFieldState.length >= 3 ? filteredProducts : []}
      getOptionLabel={(option) => (option as ProductListResult).name}
      renderInput={(params) => (
        <GlobalProductNameSearchField
          {...params}
          placeholder="Type a product name"
          InputProps={{
            ...params.InputProps,
            startAdornment: searchFieldState.length ? (
              <FontAwesomeIcon
                icon={faX}
                onClick={() => setSearchFieldState('')}
                style={{ cursor: 'pointer' }}
                data-testid="clear-button"
              />
            ) : (
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            ),
          }}
          onChange={(e: any) => setSearchFieldState(e.target.value)}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            value: searchFieldState, // need to use these props to change the value of the input
          }}
          data-testid="global-search-input"
          label="Search"
        />
      )}
      PopperComponent={StyledPopper}
      renderOption={(_, option) => (
        <ProductSearchOption
          key={(option as ProductListResult).productId}
          option={option as ProductListResult}
          searchFieldState={searchFieldState}
          availableProductFeatures={availableProductFeatures}
        />
      )}
    />
  );
}

const StyledAutoComplete = styled(MUIAutocomplete)`
  width: 50%;

  @media ${device.mobileOrTablet} {
    width: 80%;
  }

  @media ${device.smallMobile} {
    width: 100%;
  }
`;

export const StyledPopper = styled(MUIPopper)`
  top: -6px !important;

  .MuiAutocomplete-listbox {
    border-top: 2px solid white;
    padding: 0;
    background-color: ${({ theme }) => alpha(theme.palette.primary.light, 0.8)};
    a:nth-of-type(even) {
      background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.16)};
    }
  }
`;

export const GlobalProductNameSearchField = styled(SearchField)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 100%;

  & .MuiFilledInput-input {
    margin-left: 0.5rem;
    margin-top: 0.75rem;
  }

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    padding: 0;
  }

  & .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  & .MuiFilledInput-root::after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main};
  }

  & .MuiInputLabel-root {
    color: ${({ theme }) => alpha(theme.palette.primary.contrastText, 0.87)};
    top: -0.25rem;
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => alpha(theme.palette.primary.contrastText, 0.87)}; // keep it the colour even if focused
  }

  svg {
    color: ${({ theme }) => alpha(theme.palette.primary.contrastText, 0.87)};
    margin-left: 1rem;
    margin-top: 0.75rem;
  }

  @media ${device.smallMobile} {
    flex-grow: 1;
  }
`;
