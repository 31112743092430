import { TextFieldProps, styled } from '@mui/material';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';

export default function AttributeTextField(props: TextFieldProps) {
  const { label, ...rest } = props;
  return (
    <Label>
      {label}
      <TextField size="small" {...rest} />
    </Label>
  );
}

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.25 : 1)};
`;

const TextField = styled(WhiteTextFieldWithoutLabel)`
  width: 25%;
  input {
    font-weight: bold;
    text-align: right;
  }
`;
