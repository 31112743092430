import { faSquarePlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { PartDetails } from '@samsonvt/shared-types/partService';
import { v4 as uuidv4 } from 'uuid';
import type { FormsSupersession, PartDetailsForm } from '../../EditModal';
import { Button } from './Buttons';
import SupersessionRow from './SupersessionRow';

export type PartHistoryProps = {
  originalPartDetails: PartDetails | undefined;
  supersessions?: FormsSupersession[];
  setPartForm: React.Dispatch<React.SetStateAction<PartDetailsForm | undefined>>;
};

export default function PartHistoryTab({
  supersessions = [],
  originalPartDetails,

  setPartForm,
}: PartHistoryProps) {
  const addNewSupersession = () => {
    const newSupersession: FormsSupersession = {
      part: {
        number: '',
        notes: '',
      },
      tempID: uuidv4(),
    };

    setPartForm((partForm) => ({
      ...partForm!,
      supersessions: [newSupersession, ...(partForm!.supersessions || [])],
    }));
  };

  const setSupersessionFactory = (tempID: string) => (editedSupersessionData: Partial<FormsSupersession>) => {
    const newSupersessions = supersessions?.map((supersession) =>
      supersession.tempID === tempID
        ? {
            ...supersession,
            ...editedSupersessionData,
          }
        : supersession
    );

    setPartForm((partForm) => ({
      ...partForm!,
      supersessions: newSupersessions,
    }));
  };

  const deleteSupersessionFactory = (tempID: string) => () =>
    setPartForm((partForm) => ({
      ...partForm!,
      supersessions: partForm!.supersessions.filter((supersession) => supersession.tempID !== tempID),
    }));

  const isSupersessionFromPrevPart = (supersession: FormsSupersession) =>
    originalPartDetails && 'svtPartID' in supersession && supersession.svtPartID === originalPartDetails.svtPartID;

  return (
    <Column>
      <AddButton
        size="small"
        type="button"
        onClick={addNewSupersession}
        startIcon={<FontAwesomeIcon size="xs" icon={faSquarePlus} />}
        data-testid="add-supersession-button"
      >
        Add a previous part number
      </AddButton>
      <Divider />
      <NegativeMargin>
        <Table>
          <tbody>
            {supersessions.map((supersession) =>
              supersession ? (
                <SupersessionRow
                  supersession={supersession}
                  isFromPrevPart={isSupersessionFromPrevPart(supersession)}
                  key={supersession.tempID}
                  setSupersession={setSupersessionFactory(supersession.tempID)}
                  deleteSupersession={deleteSupersessionFactory(supersession.tempID)}
                />
              ) : null
            )}
          </tbody>
        </Table>
      </NegativeMargin>
    </Column>
  );
}

const Column = styled('div')`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
`;

const Divider = styled('hr')`
  width: 100%;
  border-color: ${({ theme }) => theme.palette.grey['300']};
`;

const AddButton = styled(Button)`
  width: fit-content;
`;

const NegativeMargin = styled('div')`
  margin: '-1rem';
`;

const Table = styled('table')`
  border-collapse: collapse;
  width: 100%;
`;
