/* eslint-disable no-restricted-syntax */
import { useEffect, useCallback, useMemo, useRef } from 'react';
import { Object3D } from 'three';
import { useThree } from '@react-three/fiber';
import { History } from 'history';
import { debounce } from 'lodash-es';
import { usePart } from 'src/services/part';
import { findRelatedPartNumber } from 'src/services/partUtils';
import { makePartUrl } from 'src/services/partUrl';

import { makeRaycaster } from './raycaster';

interface HoverParams {
  history: History;
  model: Object3D;
}

let isDragging = false; // SVT-2103 prevent selection on drag. Click always fires on drag end, so set on drag, unset on click. There is just one mouse so can be scoped to module.

export function HoverDetector({ history, model }: HoverParams) {
  const { camera, size, gl } = useThree();
  const { group, partPath, productId } = usePart();

  const currentHovered = useRef<[string, string] | undefined>(undefined);

  const canvasOffset = useMemo(() => {
    const { left, top } = gl.domElement.getBoundingClientRect();
    return { left, top };
  }, [gl.domElement]);

  const raycaster = useMemo(() => makeRaycaster(camera, size, canvasOffset), [camera, size, canvasOffset]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (e.buttons) {
        isDragging = true;
        return; // Performance optimisation: skip raycasting if any buttons pressed, i.e. user is dragging to rotate the model.
      }
      const intersections = raycaster.intersectObjects(model.children, e);

      if (intersections.length && group) {
        let partNumber;

        for (const { name } of intersections) {
          partNumber = findRelatedPartNumber(name, group);

          if (partNumber) {
            document.body.style.cursor = 'pointer';
            currentHovered.current = [partNumber, name];
            return;
          }
        }

        // Multiple materials fix: if intersections none of the intersections matches, then check parents.
        const parents = intersections
          .map((intersection) => intersection.parent)
          .filter((x): x is Object3D => x !== null);

        for (const { name } of parents) {
          partNumber = findRelatedPartNumber(name, group);

          if (partNumber) {
            document.body.style.cursor = 'pointer';
            currentHovered.current = [partNumber, name];
            return;
          }
        }
      }

      document.body.style.cursor = 'default';
      currentHovered.current = undefined;
    },
    [raycaster, model.children, group]
  );

  const onMouseMove = debounce(handleMouseMove, 10, { leading: true });

  const onClick = useCallback(() => {
    if (isDragging) {
      isDragging = false;
      return;
    }
    if (productId && partPath && currentHovered.current) {
      const currentLocation = history.location.pathname + history.location.search;
      const [partNumber, meshId] = currentHovered.current;
      const hoveredPartUrl = makePartUrl(productId, partNumber, {
        group: partPath.pathHash,
        mesh: meshId,
      });

      if (hoveredPartUrl !== currentLocation) {
        history.push(hoveredPartUrl);
      }
    }
  }, [history, partPath, productId, currentHovered]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('click', onClick, false);
    document.addEventListener('touchend', onClick, false);

    return () => {
      document.removeEventListener('mousemove', onMouseMove, false);
      document.removeEventListener('click', onClick, false);
      document.removeEventListener('touchend', onClick, false);
    };
  }, [onMouseMove, onClick]);

  return null;
}
