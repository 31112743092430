import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';

import { spacing } from 'src/constants/spacing';

interface StyledSwatchProps {
  swatchColour?: string;
}

const StyledInitials = styled(MUIBox)<StyledSwatchProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: ${({ swatchColour, theme }) => swatchColour || theme.palette.secondary.main};
  color: ${({ swatchColour, theme }) =>
    swatchColour ? theme.palette.common.white : theme.palette.secondary.contrastText};
  font-size: 10px;
  margin-right: ${spacing.xSmallSpacing};
`;

const generateInitials = (nameString: string | undefined) => {
  if (!nameString) {
    return '?';
  }

  if (!nameString.includes(' ')) {
    return nameString.charAt(0);
  }

  const stringArray = nameString.split(' ');
  let initials = '';

  for (let i = 0; i < 2; i += 1) {
    if (stringArray.length === 1) {
      // Rule disabled to clean console output and improve developer experience when writing new code. Should be reviewed
      // eslint-disable-next-line no-return-assign
      return (initials = stringArray[0]?.charAt(0));
    }
    // Rule disabled to clean console output and improve developer experience when writing new code. Should be reviewed
    // eslint-disable-next-line no-unsafe-optional-chaining
    initials += stringArray[i]?.charAt(0);
  }

  return initials;
};

export default function Initials({ name, swatchColour }: StyledSwatchProps & { name: string }) {
  return <StyledInitials swatchColour={swatchColour}>{generateInitials(name)}</StyledInitials>;
}
