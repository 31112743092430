import { Code, Key, AddShoppingCart, SwitchAccount } from '@mui/icons-material';
import { getToken } from 'src/services/api';
import { Toast, useTrigger } from 'src/components/Toast';
import { SpeedDial, SpeedDialAction } from '@mui/material';

import useShoppingCartMutation from 'src/providers/ShoppingCart/useShoppingCartMutation';

import { numberOfMockTenants } from 'src/utils/mockTenantDetails';
import { randomItem } from './randomCartItem';

export function DevTools() {
  const [toast, openToast] = useTrigger();
  const { mutate } = useShoppingCartMutation();

  const copyJWTToClipboard = async () => {
    const token = await getToken();
    await navigator.clipboard.writeText(token);
    openToast();
  };

  const randomItems = new Array(10).fill(null).map((el, index) => {
    const newItem = { ...randomItem, partNumber: index.toString() };
    return newItem;
  });

  const addRandomItemToTheCart = () => {
    mutate([randomItems[Math.floor(Math.random() * 10)]]);
  };

  const changeMockTenant = () => {
    const params = new URLSearchParams(window.location.search);
    const currentIndex = params.get('mockTenant');
    if (currentIndex !== null) {
      const newIndex = parseInt(currentIndex) < numberOfMockTenants - 1 ? parseInt(currentIndex) + 1 : 0;
      const newURL = window.location.href.slice(0, -1).concat(`${newIndex}`);
      // eslint-disable-next-line no-restricted-globals
      window.location.href = newURL;
    } else {
      const newURL = params.keys().next().value
        ? window.location.href.concat('&mockTenant=1')
        : window.location.href.concat('?mockTenant=1');
      window.location.href = newURL;
    }
  };

  return (
    <>
      <SpeedDial ariaLabel="SpeedDial basic example" sx={{ position: 'fixed', bottom: 16, right: 16 }} icon={<Code />}>
        <SpeedDialAction key="copy-jwt" icon={<Key />} tooltipTitle="Copy JWT" onClick={copyJWTToClipboard} />
        <SpeedDialAction
          key="switch-tenant"
          icon={<SwitchAccount />}
          tooltipTitle="Switch Mock Tenant"
          onClick={changeMockTenant}
        />
        <SpeedDialAction
          key="randomItemToCart"
          icon={<AddShoppingCart />}
          tooltipTitle="Add random item to cart"
          onClick={addRandomItemToTheCart}
        />
      </SpeedDial>
      <Toast
        dependency={toast}
        severity="success"
        title="Dev Tools"
        message="Successfully copied JWT token to clipboard"
      />
    </>
  );
}
