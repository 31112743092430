import { FeatureFlag } from '@samsonvt/shared-types/accountsTable';
import { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { useContext } from 'react';
import { isFeatureSupported } from 'src/Feature';
import { ProductContext } from 'src/providers/Product';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import { can } from 'src/services/Can';
import { productFeaturePermissions } from '../constants/features';

const allProductFeatures: ProductFeature[] = ['parts-catalogue', 'work-instructions'];

export const notSuppressedProductFeatures = (enabledFeatures: FeatureFlag[]): ProductFeature[] =>
  allProductFeatures.filter((feature) => isFeatureSupported(feature, enabledFeatures));

export const useProductFeatures = () => {
  const { enabledFeatures } = useContext(TenantContext);
  const user = useUser();

  // ↶ Might be called from outside product context, hence the default ↷
  const { enabledFeatures: productLevelEnabledFeatures = [] } = useContext(ProductContext);
  const notSupressedProductFeatures = notSuppressedProductFeatures([
    ...enabledFeatures,
    ...productLevelEnabledFeatures,
  ]);

  return notSupressedProductFeatures.filter((productFeature) =>
    can(user, 'view', productFeaturePermissions[productFeature])
  );
};
