import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Tabs, Tab } from 'src/components/Tabs';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { productFeatureNames } from 'src/constants/features';
import { TabProps } from 'src/pages/ProductDetailPage';

export function SidebarTabs({ currentTabName, availableTabs, productPath }: TabProps) {
  const { url } = useRouteMatch(productPath)!;
  const { push } = useHistory();
  const handleChange = (_: React.SyntheticEvent, newValue: ProductFeature) => push(`${url}/${newValue}`);

  return (
    <Tabs data-testid="tab-panel" value={currentTabName} onChange={handleChange}>
      {availableTabs.map((feature) => (
        <Tab
          key={feature}
          data-testid={`nav-product-tab-${feature}`}
          label={productFeatureNames[feature]}
          value={feature}
        />
      ))}
    </Tabs>
  );
}
