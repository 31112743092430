import { invalidate, useFrame, useThree } from '@react-three/fiber';
import { Clock, Object3D, Quaternion, Vector3 } from 'three';
import { useEffect, useRef } from 'react';

type Props = {
  active: boolean;
  object: Object3D;
  quantity: number;
};
const rotationTimeout = 0.7;
const rotationSpeed = Math.PI / 60;
const rotationAxis = new Vector3(0, 1, 0.2);
const quaternion = new Quaternion();

export default function Rotate({ active, object, quantity }: Props) {
  const ref = useRef<Object3D>(null!);

  const clockRef = useRef<Clock>(null!);

  const { clock } = useThree();

  useEffect(invalidate);

  useEffect(() => {
    clockRef.current = clock;
  }, [clockRef, clock]);

  useEffect(() => {
    if (clockRef.current) clockRef.current.start();
  }, [quantity]);

  useFrame(({ clock }) => {
    if (active) {
      ref.current.applyQuaternion(quaternion.setFromAxisAngle(rotationAxis.normalize(), rotationSpeed));
      invalidate();
    } else if (clock.elapsedTime < rotationTimeout) {
      ref.current.applyQuaternion(
        quaternion.setFromAxisAngle(
          rotationAxis.normalize(),
          (rotationTimeout - clock.elapsedTime) / (rotationTimeout * 10)
        )
      );
      invalidate();
    }
  });

  return (
    <group ref={ref}>
      <primitive object={object} />
    </group>
  );
}
