import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { PasswordTextField } from './PasswordTextField';

interface PasswordTextInputProps {
  label: string;
  value: string;
  isRequired?: boolean;
  onChange: any;
  error?: boolean;
  match?: boolean;
  testId?: string;
}

export function LoginPasswordInput({
  label,
  value,
  isRequired = false,
  onChange,
  error,
  match,
  testId,
}: PasswordTextInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const determineMatchText = (value: string, match: boolean | undefined) => {
    if (match !== undefined) {
      return match ? 'Passwords match' : "Passwords don't match";
    }
    return undefined;
  };

  return (
    <PasswordTextField
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      required={isRequired}
      helperText={determineMatchText(value, match)}
      match={match}
      InputProps={{
        autoComplete: 'current-password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              aria-label="toggle password visibility"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        'data-testid': testId,
      }}
      error={!!error}
    />
  );
}
