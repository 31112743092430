import { styled } from '@mui/material';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { CartEntry } from 'src/providers/ShoppingCart';
import CartItemsList from './CartItemsList';
import CheckoutSection from './CheckoutSection';
import Puller from './Puller';
import { Drawer, DrawerContent, Header } from './style';

interface DesktopShoppingCartPanelProps {
  panelOpen: boolean;
  triggerPanel: () => void;
  cart: CartEntry[];
  cartTotal: DiscountedPartPrice;
}

export function DesktopShoppingCartPanel({ panelOpen, triggerPanel, cart, cartTotal }: DesktopShoppingCartPanelProps) {
  return (
    <Drawer isOpen={panelOpen}>
      <Puller />
      <DrawerContent color="secondary" data-testid="shopping-cart-panel">
        <StyledHeader>Your shopping cart</StyledHeader>
        <CartItemsList cart={cart} />
        <CheckoutSection totalPrice={cartTotal} isCartEmpty={cart.length === 0} triggerPanel={triggerPanel} />
      </DrawerContent>
    </Drawer>
  );
}

const StyledHeader = styled(Header)`
  text-align: center;
`;
