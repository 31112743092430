import { useEffect, useMemo, useLayoutEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useThree } from '@react-three/fiber';
import { ThemeProvider } from 'src/providers/Theme';
import { Theme } from '@mui/material';

export default function HtmlStaticPseudoPortal({ children, theme }: { children: React.ReactNode; theme: Theme }) {
  const { gl } = useThree();
  const target = gl.domElement.parentNode;

  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    if (target) {
      el.style.position = 'absolute';
      el.style.bottom = '0';
      el.style.width = '100%'; // Position wrapper in such a way that it will stick to the bottom of the canvas, but not cover it so that it won't block mouse events.
      target.appendChild(el);
    }
    return () => {
      if (target) target.removeChild(el);
      ReactDOM.unmountComponentAtNode(el);
    };
  }, [target, el]);

  useLayoutEffect(() => {
    ReactDOM.render(<ThemeProvider theme={theme}>{children}</ThemeProvider>, el);
  });
  return null;
}
