import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { PaddedBlock } from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import { spacing } from 'src/constants/spacing';
import { NamedLink } from 'src/services/partNavigation';

const StyledAppearsInContainer = styled('div')`
  padding: ${spacing.mediumSpacing};

  h4 {
    margin-bottom: 0;
  }
`;

const StyledLink = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: ${spacing.smallSpacing} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.sidebarBackground.contrastText}50;
  transition: 0.4s all ease-in-out;

  a {
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  }
`;

const StyledButton = styled(Link)`
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: ${spacing.xSmallSpacing} ${spacing.smallSpacing};

  &:hover {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }
`;

export default function LocationsTab({ relatedParts }: { relatedParts: NamedLink[] }) {
  const parts = [...relatedParts].sort((a, b) => a.name.localeCompare(b.name));

  if (!parts.length) {
    return <PaddedBlock>This part only appears in this location</PaddedBlock>;
  }

  return (
    <StyledAppearsInContainer>
      <h4 data-testid="product-related-title">This part also appears in:</h4>
      {parts.map((item) => (
        <StyledLink key={item.url}>
          <Link data-testid="product-related-group-item-link" to={item.url}>
            {item.name}
          </Link>
          <StyledButton to={item.url}>View</StyledButton>
        </StyledLink>
      ))}
    </StyledAppearsInContainer>
  );
}
