import { Card, CardMedia, Menu, MenuItem, styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import { border, radius } from 'src/constants/borderSizes';
import { device } from 'src/constants/mediaQueries';
import { spacing } from 'src/constants/spacing';
import { StyledMUIButton } from '../Button';

interface ActiveProps {
  isActive: boolean;
}
interface StatusProps {
  status?: string;
}

const dropdownMenuWidth = '14.375rem';
const productCardDesktopHeight = '15.625rem';
const productCardLaptopHeight = '12.5rem';

export const ProductListStyles = styled('div')`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: ${spacing.mediumSpacing};
  grid-row-gap: ${spacing.mediumSpacing};

  @media ${device.mobileOrTablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.smallMobile} {
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${device.smallLaptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.largeLaptopOrSmallDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ProductCardStyles = styled(Card)`
  align-self: flex-end;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
`;

export const ProductCardImageContainer = styled(CardMedia)`
  img {
    margin-bottom: -${spacing.xSmallSpacing};
    width: 100%;
    aspect-ratio: 4/3;
  }

  a {
    display: block;
    overflow: auto;
  }

  @media ${device.largeLaptopOrSmallDesktop} {
    .product-card__background {
      min-height: ${productCardDesktopHeight};
    }
  }
  @media ${device.smallLaptop} {
    .product-card__background {
      min-height: ${productCardLaptopHeight};
    }
  }
`;

export const ProductCardTextContainer = styled('div')`
  display: block;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  padding: ${spacing.smallSpacing};
  text-align: center;

  span {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  }
`;

export const DropdownNavItem = styled('div')<StatusProps>`
  display: block;
  white-space: nowrap;
  background: ${({ theme }) => theme.palette.grey['700']};
  color: ${({ theme }) => theme.palette.common.white};
  transition: 0.4s all ease-in-out;
  padding: ${spacing.smallSpacing};
  text-transform: 'capitalize';

  &:not(last-of-type) {
    border-bottom: ${border.xSmall} solid ${({ theme }) => theme.palette.grey['600']};
  }

  svg {
    height: ${spacing.mediumSpacing};
    margin-right: ${spacing.smallSpacing};
    vertical-align: middle;
    background: ${({ status, theme }) =>
      status === 'COMPLETED' ? theme.palette.success.main : theme.palette.warning.main};
    border-radius: ${radius.xLarge};
    padding: ${spacing.xSmallSpacing};
  }

  img {
    height: ${spacing.mediumSpacing};
    width: auto;
  }
`;

const DropdownMenu = styled(MUIBox)`
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  min-width: ${dropdownMenuWidth};
  z-index: 200;
`;

export const DropdownHiddenContainer = styled(DropdownMenu)<ActiveProps>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  right: 0;
`;

export const DropdownContainerStyles = styled(MUIBox)<ActiveProps>`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  padding: ${spacing.xSmallSpacing} ${spacing.smallSpacing};

  .product-actions__view-more svg {
    height: ${spacing.largeSpacing};
  }

  svg {
    height: ${spacing.largeSpacing};
    color: ${({ isActive, theme }) => (isActive ? theme.palette.common.white : theme.palette.grey['700'])};
    transition: 0.4s all ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const StyledBadge = styled('span')<StatusProps>`
  background: ${({ status, theme }) =>
    status === 'COMPLETED' ? theme.palette.success.main : theme.palette.warning.main};
  border-radius: 4px;
  padding: ${spacing.xSmallSpacing} ${spacing.smallSpacing};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.75rem;
  text-transform: capitalize;
`;

export const UpdateStatusButton = styled(StyledMUIButton)`
  width: 100%;
`;

export const StatusDropdownList = styled(Menu)`
  width: 100%;
`;
export const StatusDropdownListItem = styled(MenuItem)`
  width: 100%;
`;
