import { MatchModelNodesToPartDetailsResponse, PartDetails } from '@samsonvt/shared-types/partService';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { PartDetailsForm } from './EditModal';
import type { CreateAndAssignParams, UpdateAndAssignParams } from './MutationsHost';
import { Spinner, SaveButton as StyledButton } from './styles';
import { formToPartDetails, isNew } from './utils';

interface Props {
  mutateCreateAndAssignPartDetails: UseMutateAsyncFunction<
    AxiosResponse<MatchModelNodesToPartDetailsResponse>,
    unknown,
    CreateAndAssignParams
  >;
  mutateUpdateAndAssignPartDetails: UseMutateAsyncFunction<
    AxiosResponse<MatchModelNodesToPartDetailsResponse>,
    unknown,
    UpdateAndAssignParams
  >;
  isLoadingMutations: boolean;
  editedNodes: string[];
  handleClose: () => void;
  partDetailsForm: PartDetailsForm | undefined;
  originalPartDetails: PartDetails | undefined;
}

export default function SaveButton({
  mutateCreateAndAssignPartDetails,
  mutateUpdateAndAssignPartDetails,
  isLoadingMutations,
  handleClose,
  editedNodes,
  partDetailsForm,
  originalPartDetails,
}: Props) {
  const saveHandler = async () => {
    const partDetails = formToPartDetails(partDetailsForm!, originalPartDetails); // Save button is disabled if !partDetailsForm, hence the ! assertion.

    if (isNew(partDetails)) {
      await mutateCreateAndAssignPartDetails({
        partDetails,
        editedNodes,
      });
    } else {
      await mutateUpdateAndAssignPartDetails({
        partDetails,
        editedNodes,
      });
    }
    // If it's error await has thrown and we never get here.
    handleClose();
  };

  const saveButtonDisabled = !partDetailsForm || !partDetailsForm.displayName;

  return (
    <StyledButton onClick={saveHandler} disabled={saveButtonDisabled} data-testid="save-button">
      {isLoadingMutations ? <Spinner /> : 'Save'}
    </StyledButton>
  );
}
