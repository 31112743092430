import { CartEntry } from 'src/providers/ShoppingCart';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { Box } from '@mui/material';
import { XButton } from 'src/components/Button/XButton';
import MobilePanelCartItemsList from './MobilePanelCartItemsList';
import EmptyCartMobile from './EmptyCartMobile';
import { Header, MobileDrawer, MobileShoppingCartPanelListFade, Title, ShoppingCartWrapper } from './styles';
import MobileCheckoutSection from './MobileCheckoutSection';

interface MobileShoppingCartPanelProps {
  panelOpen: boolean;
  triggerPanel: () => void;
  cart: CartEntry[];
  cartTotal: DiscountedPartPrice;
}

export default function MobileShoppingCartPanel({
  panelOpen,
  triggerPanel,
  cart,
  cartTotal,
}: MobileShoppingCartPanelProps) {
  return (
    <MobileDrawer
      ModalProps={{
        keepMounted: true,
      }}
      open={panelOpen}
      onClose={triggerPanel}
      onOpen={triggerPanel}
    >
      <ShoppingCartWrapper data-testid="mobile-shopping-cart-panel">
        <Header>
          <Title>Your shopping cart</Title>
          <Box component="div" sx={{ marginLeft: '2rem', marginRight: '1rem', fontSize: '2rem' }}>
            <XButton onClick={triggerPanel} />
          </Box>
        </Header>
        {cart.length ? (
          <>
            <MobilePanelCartItemsList cart={cart} />
            <MobileShoppingCartPanelListFade />
            <MobileCheckoutSection totalPrice={cartTotal} isCartEmpty={cart.length === 0} triggerPanel={triggerPanel} />
          </>
        ) : (
          <EmptyCartMobile />
        )}
      </ShoppingCartWrapper>
    </MobileDrawer>
  );
}
