import { useCallback } from 'react';
import { SetQuantity } from 'src/components/Product/Desktop/Panels/PartsCatalogue/SetQuantity';
import { CartEntry } from 'src/providers/ShoppingCart';
import useShoppingCartMutation, { updateQuantity, without } from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useFormattedPrice from 'src/services/formatCurrency';
import { calculateTotalWithPOR } from 'src/services/isPOR';
// import PartThumbnail from '../../PartThumbnail';
import {
  BottomSection,
  CardContent,
  DeleteButton,
  Flex,
  FlexRow,
  OneLineEllipsis,
  TitleText,
  PartNumber,
  PositionedCard,
  PriceTag,
  QtyText,
  SetQuantityContainer,
  TwoLineEllipsis,
} from './style';

interface CartItemProps {
  cartItem: CartEntry;
  cart: CartEntry[];
}

export default function CartItem({ cart, cartItem }: CartItemProps) {
  const { mutate } = useShoppingCartMutation();
  const { partName, quantity, partNumber, unitPrice } = cartItem;
  const price = calculateTotalWithPOR([{ quantity, unitPrice }]);

  const removeFromCartMutation = () => mutate(without(cart, cartItem.partNumber));
  const removeFromCart = useCallback(removeFromCartMutation, [cart, cartItem.partNumber, mutate]);

  const setQuantityMutation = (quantity: number | '') =>
    mutate(updateQuantity(cart, Number(quantity), cartItem.partNumber));
  const setQuantity = useCallback(setQuantityMutation, [cart, cartItem.partNumber, mutate]);

  return (
    <PositionedCard data-testid="shopping-cart-panel-item">
      {/* <PartThumbnail partNumber={partNumber} quantity={quantity} height={100} width={270} />  remove for now SVT-2407 */}
      <CardContent>
        <TwoLineEllipsis>
          <TitleText>{partName}</TitleText>
        </TwoLineEllipsis>
        <OneLineEllipsis>
          <PartNumber>{partNumber}</PartNumber>
        </OneLineEllipsis>
        <Flex>
          <PriceTag data-testid="cart-item-panel-price">
            {useFormattedPrice({ price, shortLabel: true, smallFont: true })}
          </PriceTag>
          <BottomSection>
            <SetQuantityContainer>
              <QtyText>Qty:</QtyText>
              <SetQuantity quantity={quantity} setQuantity={setQuantity} size="small" />
            </SetQuantityContainer>
            <FlexRow>
              <DeleteButton
                aria-label="delete"
                onClick={() => removeFromCart()}
                data-testid="shopping-cart-panel-item-delete-button"
              >
                Delete
              </DeleteButton>
            </FlexRow>
          </BottomSection>
        </Flex>
      </CardContent>
    </PositionedCard>
  );
}
