import { Suspense, useMemo, useState } from 'react';
import { Object3D, ObjectLoader } from 'three';
import { Canvas } from '@react-three/fiber';
import { styled, useTheme } from '@mui/material';

import { Environment } from 'src/components/3DControls/Environment';
import { DefaultStudioLighting } from 'src/components/3DControls/DefaultStudioLighting';
import type { MetaObject } from 'src/providers/ShoppingCart';
import scaleModel from './scaleModel';
import Rotate from './Rotate';
import type { Px } from '../ShoppingCart/styles';

type Props = {
  metaObject?: MetaObject;
  quantity: number;
  transparent?: boolean;
  width: Px;
  height: Px;
  widthResizeConstant?: number;
  heightResizeConstant?: number;
};

export default function Thumbnail3D({
  metaObject,
  quantity,
  transparent,
  width,
  height,
  widthResizeConstant,
  heightResizeConstant,
}: Props) {
  const scene = new ObjectLoader().parse(metaObject ?? new Object3D().toJSON());
  const { palette } = useTheme();
  const [hover, setHover] = useState(false);
  const scaledModel = useMemo(
    () => scene && scaleModel(scene, width, height, widthResizeConstant, heightResizeConstant),
    [height, scene, width, widthResizeConstant, heightResizeConstant]
  );

  return (
    <HeightLimit
      data-testid="shopping-cart-panel-thumbnail"
      data-chromatic="ignore"
      onPointerOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onPointerOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      width={width}
      height={height}
    >
      <Canvas
        data-chromatic="ignore"
        frameloop="demand"
        camera={
          { fov: 10, position: [0, 0, 40] } // Almost orthographic
        }
      >
        <Suspense fallback={null}>
          <DefaultStudioLighting />
          <Environment />
          {scaledModel && <Rotate active={hover} object={scaledModel} quantity={quantity} />}
          {!transparent && <color attach="background" args={[scene ? palette.grey['500'] : palette.common.black]} />}
        </Suspense>
      </Canvas>
    </HeightLimit>
  );
}

const HeightLimit = styled('div')<{ width: Px; height: Px }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;
