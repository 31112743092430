export default function IconPanModel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.373" height="18.036" viewBox="0 0 14.373 18.036">
      <g id="Group_711" data-name="Group 711" transform="translate(-11.5 -6.985)">
        <path
          id="Path_5202"
          data-name="Path 5202"
          d="M911.759,18.376l-4.631-2.315a.579.579,0,0,0-.518,0l-4.631,2.315a.581.581,0,0,0-.32.518v5.789a.579.579,0,0,0,.32.518l4.631,2.315a.589.589,0,0,0,.259.061h0a.584.584,0,0,0,.259-.061l4.631-2.315a.579.579,0,0,0,.32-.518V18.894A.58.58,0,0,0,911.759,18.376Zm-5.469,7.685-3.473-1.737V19.831l3.473,1.737Zm.579-5.5-3.336-1.668,3.336-1.668,3.337,1.668Zm4.052,3.763-3.473,1.737V21.567l3.473-1.737Z"
          transform="translate(-888.183 -8.508)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_5203"
          data-name="Path 5203"
          d="M876.618,346.6l-1.706-1.706a.5.5,0,0,0-.709.71l.813.813h-9.948l.813-.813a.5.5,0,0,0-.709-.71l-1.633,1.633a.5.5,0,0,0,0,.709l1.706,1.706a.5.5,0,0,0,.709-.709l-.813-.813h9.948l-.813.813a.5.5,0,0,0,.71.709l1.633-1.633a.5.5,0,0,0,0-.71Z"
          transform="translate(-851.392 -324.568)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          opacity="0.5"
        />
      </g>
    </svg>
  );
}
