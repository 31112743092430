import { CognitoUser } from '@aws-amplify/auth';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useUser } from 'src/providers/User';
import { patchUser } from 'src/services/api';

type RawCognitoUser = Record<'attributes', RawCognitoUserAttributes>;
type RawCognitoUserAttributes = Record<string, string>;

export interface MutateAccountDetailsInput {
  name: string;
  companyName: string;
  telephone: string;
  buildingName: string;
  streetName: string;
  city: string;
  postalCode: string;
  country: string;
}

export interface AccountDetails {
  name: string;
  telephone: string;
  email: string;
  companyName: any;
  buildingName: string;
  streetName: string;
  city: string;
  country: string;
  postalCode: string;
}

export interface MutateAccountDetails {
  applyUserDetails: UseMutateFunction<void, unknown, MutateAccountDetailsInput, unknown>;
  isApplyUserDetailsSuccess: boolean;
  isApplyUserLoading: boolean;
  isApplyUserDetailsError: boolean;
  applyUserDetailsError: unknown;
}

export function getAccountDetails(cognitoUser: CognitoUser | null): AccountDetails {
  const {
    address = '',
    name = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    phone_number = '',
    email = '',
  } = cognitoUser ? (cognitoUser as any as RawCognitoUser).attributes : {};
  const companyName = (cognitoUser as any).attributes['custom:vtaas-company-name'] || '';
  const [buildingName = '', streetName = '', city = '', postalCode = '', country = ''] = (address as string).split(',');

  return {
    name,
    telephone: phone_number,
    email,
    companyName,
    buildingName,
    streetName,
    city,
    country,
    postalCode,
  };
}

export function useMutateAccountDetails(): MutateAccountDetails {
  const { refreshSession, user: cognitoUser } = useUser();
  const { sub: userId = '' } = cognitoUser ? (cognitoUser as any as RawCognitoUser).attributes : {};

  const applyUserDetailsRequest = async (newAccountDetails: MutateAccountDetailsInput) => {
    await patchUser(userId, newAccountDetails);
    await refreshSession();
  };

  const {
    mutate: applyUserDetails,
    isSuccess: isApplyUserDetailsSuccess,
    isLoading: isApplyUserLoading,
    isError: isApplyUserDetailsError,
    error: applyUserDetailsError,
  } = useMutation(applyUserDetailsRequest);

  return {
    applyUserDetails,
    isApplyUserDetailsSuccess,
    isApplyUserLoading,
    isApplyUserDetailsError,
    applyUserDetailsError,
  };
}
