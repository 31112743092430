import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIDivider from '@mui/material/Divider';
import MUITypography from '@mui/material/Typography';
import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';

export const StyledTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledTabContentPanel = styled('div')`
  background: ${({ theme }) => theme.palette.whiteGlass};
  border-top-right-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  flex-grow: 1;
`;

export const SearchStyles = styled('div')`
  padding: ${spacing.mediumSpacing} 0 ${spacing.mediumSpacing} 0;

  h3 {
    margin-bottom: 0;
  }

  span {
    font-size: ${font.small};
  }
`;

export const PaddedBlock = styled('div')`
  padding: ${spacing.mediumSpacing};
`;

export const ThinDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  opacity: 0.12;
`;

export const EmptyPartsTab = styled('div')`
  display: flex;
  align-items: center;
  margin: 1.5rem 1rem;
`;

export const EmptyPartsText = styled(MUITypography)`
  margin-left: 0.5rem;
`;

export const AccordionItemContainer = styled(MUIBox)<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1.2rem 1rem 0.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: ${({ highlight, theme }) => highlight && theme.palette.secondary.dark};
`;
