import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material';

import { StyledMUIButton } from 'src/components/Button';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import MUITypography from '@mui/material/Typography';

interface AlternativeOptionCardProps {
  item: PartNode;
  link: string;
  onClick: () => void;
}

const StyledCard = styled('div')`
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #e3e3e3;
`;

const StyledOptionPartName = styled(MUITypography)`
  font-size: 1.5rem;
  font-weight: bold;
`;

const StyledOptionPartNumber = styled(MUITypography)`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

function AlternativeOptionCard({ item, link, onClick }: AlternativeOptionCardProps) {
  const history = useHistory();
  const { name, partNumber } = item;

  return (
    <StyledCard data-testid="part-option">
      <StyledOptionPartName>{name}</StyledOptionPartName>
      <StyledOptionPartNumber>{partNumber}</StyledOptionPartNumber>

      <StyledMUIButton
        data-testid="select-part-option"
        data-test-href={link} // Providing an attribute for test as we now navigate via onClick navigate
        onClick={() => {
          history.push(link);
          onClick();
        }}
      >
        View
      </StyledMUIButton>
    </StyledCard>
  );
}

export default AlternativeOptionCard;
