import { styled } from '@mui/material';
import MUIList from '@mui/material/List';
import type { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';
import type { History } from 'history';
import { useProductFeatures } from 'src/hooks/useProductFeatures';
import MobileProductMenuListItem from './MobileProductMenuItem';

export default function MobileProductMenuList({
  products,
  currentProductName,
  history,
  triggerMobileProductMenu,
}: {
  products: ProductListAPIResponse;
  currentProductName: string | undefined;
  history: History<unknown>;
  triggerMobileProductMenu: () => void;
}) {
  const productFeatures = useProductFeatures();

  return (
    <StyledList>
      {products.map((product) => (
        <MobileProductMenuListItem
          key={product.productId}
          productId={product.productId}
          productName={product.name}
          productFeatures={productFeatures}
          history={history}
          triggerMobileProductMenu={triggerMobileProductMenu}
          selectedProduct={product.name === currentProductName}
        />
      ))}
    </StyledList>
  );
}

const StyledList = styled(MUIList)`
  padding: 0;
  ${({ theme }) => theme.mixins.hideScrollbar}
`;
