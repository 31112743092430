import { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';
import { productCategoryPathEnumerationSeparator } from 'src/utils/urls';
import CategoryCard from './CategoryCard';
import { CategoriesListStyles } from './styles';

interface CategoriesListProps {
  categoriesToShow: string[]; // use real shared types
  products: ProductListAPIResponse;
  decodedPathEnumeration: string;
}

export function CategoriesList({ categoriesToShow, products, decodedPathEnumeration }: CategoriesListProps) {
  return (
    <CategoriesListStyles>
      {categoriesToShow.map((category) => (
        <CategoryCard
          key={category}
          categoryOfProducts={category}
          thumbnailUrl={determineThumbnailURL(products, decodedPathEnumeration, category)}
          decodedPathEnumeration={decodedPathEnumeration}
        />
      ))}
    </CategoriesListStyles>
  );
}

const determineThumbnailURL = (
  products: ProductListAPIResponse,
  decodedPathEnumeration: string,
  currentCategory: string
) => {
  const thumbnailUrl = products.find((product) =>
    product.productCategories?.find((categoryEnumeration) =>
      categoryEnumeration.includes(
        decodedPathEnumeration
          ? `${decodedPathEnumeration}${productCategoryPathEnumerationSeparator}${currentCategory}`
          : currentCategory
      )
    )
  )?.thumbnailUrl; // get first thumbnail of product category

  return thumbnailUrl || '/';
};
