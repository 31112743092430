import { styled } from '@mui/material';
import { font } from 'src/constants/fontSizes';

export const ErrorMessage = styled('div')`
  font-size: ${font.large};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
