import { faCartShoppingFast } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import useFormattedPrice from 'src/services/formatCurrency';
import { CheckoutButton, CheckoutButtonIcon, CheckoutButtonWrapper, TotalWrapper } from './style';

interface CheckoutSectionProps {
  isCartEmpty: boolean;
  totalPrice: DiscountedPartPrice;
  triggerPanel: () => void;
}

export default function CheckoutSection({ totalPrice, isCartEmpty, triggerPanel }: CheckoutSectionProps) {
  const nextPath = isCartEmpty
    ? '#'
    : {
        pathname: '/cart', // goes to review order page
        state: { from: window.location.pathname },
      };

  const totalPriceToDisplay = isCartEmpty ? undefined : totalPrice;

  return (
    <>
      <TotalWrapper>
        <TotalLabel>Total:</TotalLabel>
        <TotalPrice data-testid="shopping-cart-panel-total-price">
          {useFormattedPrice({ price: totalPriceToDisplay })}
        </TotalPrice>
      </TotalWrapper>
      <CheckoutButtonWrapper>
        <CheckoutButton
          to={nextPath}
          onClick={triggerPanel}
          disabled={isCartEmpty}
          size="large"
          data-testid="shopping-cart-panel-checkout-button"
        >
          <CheckoutButtonIcon icon={faCartShoppingFast} />
          Review your order
        </CheckoutButton>
      </CheckoutButtonWrapper>
    </>
  );
}

const TotalLabel = styled(MUITypography)`
  margin-right: 1rem;
  line-height: 1.8rem;
`;

const TotalPrice = styled(MUITypography)`
  white-space: pre;
  margin-right: 1rem;
`;
TotalPrice.defaultProps = {
  variant: 'h5',
  fontWeight: 'bold',
};
