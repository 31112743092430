import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Autocomplete, Popper, styled, TextField } from '@mui/material';
import { font } from 'src/constants/fontSizes';

export const BlockLi = styled('li')`
  padding: 16px !important;
  display: block !important; // overriding Mui default flex display
`;

export const SmallCapitalized = styled('div')`
  text-transform: capitalize;
  font-size: ${font.small};
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

export const FlexSpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
`;

// Slight rework on material-ui to achieve dotted border on top and remove general padding around entire list
export const StyledPopper = styled(Popper)`
  top: -6px !important;

  .MuiAutocomplete-listbox {
    border: 2px solid white;
    border-top-style: dashed;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    background: ${({ theme }) => theme.palette.sidebarBackground.main};

    li:hover,
    li.Mui-focused {
      background: ${({ theme }) => `${theme.palette.secondary.dark} !important`};
    }

    li:nth-of-type(even) {
      background: ${({ theme }) => theme.palette.whiteGlass};
    }
  }
`;

export const WhiteIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.3)};
`;

export const AutocompleteTextField = styled(TextField)`
  input::placeholder {
    font-style: italic;
  }
  .override-class {
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  }
  .override-class > fieldset {
    border-color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.5)} !important;
    border-width: 1px !important;
  }
`;

export const StyledProductSearch: typeof Autocomplete = styled(Autocomplete)`
  .MuiAutocomplete-root,
  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText} !important;
  }
  label {
    font-weight: bold;
    font-size: 1.15rem;
  }
  .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.5)} !important;
  }
` as any;
