import { formatDuration, intervalToDuration } from 'date-fns';

export const getFileExtension = (filename: string) => {
  if (!filename) {
    return '';
  }
  const parts = filename.split('.');

  if (parts.length > 1) {
    return parts.pop();
  }

  return '';
};

export const getFormattedRepairTime = (duration: number): string => {
  const formattedDuration = formatDuration(intervalToDuration({ start: 0, end: duration * 60000 }));
  return formattedDuration;
};
