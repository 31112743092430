import { styled, Typography } from '@mui/material';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { useEffect, useState } from 'react';
import { Feature } from 'src/Feature';
import { hasPrice } from 'src/services/isPartBuyable';
import { AddToCartButton } from '../AddToCartButton';
import { SetQuantity } from '../SetQuantity';
import { PriceSection } from './PriceSection';

interface BuyPartsProps {
  currentPart: PartNode;
  defaultBuyableQuantity?: string;
}
export function BuyPartsSection({ currentPart, defaultBuyableQuantity }: BuyPartsProps) {
  const [quantity, setQuantity] = useState<number | ''>(parseInt(defaultBuyableQuantity || '1'));
  const isBuyable = hasPrice(currentPart); // undefined price means part is not buyable

  useEffect(() => {
    setQuantity(parseInt(defaultBuyableQuantity || '1'));
  }, [currentPart, defaultBuyableQuantity]);

  const cartQuantity = quantity === '' ? 1 : quantity;
  const addToCartButtonDisabled = quantity === 0 || quantity === '';

  if (!isBuyable) return null;

  return (
    <Feature flag="shopping-cart">
      <>
        <QuantityText>Quantity:</QuantityText>
        <SetQuantityContainer>
          <SetQuantity quantity={quantity} setQuantity={setQuantity} allowZero />
        </SetQuantityContainer>
        <PriceSection price={currentPart.price} />
        <AddToCartButton quantity={cartQuantity} part={currentPart} disabled={addToCartButtonDisabled} />
      </>
    </Feature>
  );
}

const QuantityText = styled(Typography)`
  margin-bottom: 0.3em;
`;

const SetQuantityContainer = styled('div')`
  margin-bottom: 2em;
`;
