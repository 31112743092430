import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { BackButton } from 'src/components/Button';
import { Footer } from 'src/components/Footer';
import { MainTemplate } from 'src/templates/MainTemplate';
import { ContentContainer } from '../styles';
import { OrderDetails } from './OrderDetailsPage/OrderDetails';
import ReceivedOrders from './ReceivedOrders';

export default function ReceivedOrdersPage() {
  const { path } = useRouteMatch();
  const { goBack } = useHistory();
  return (
    <MainTemplate>
      <ContentContainer>
        <BackButton onClick={goBack} />
        <Route exact path={path}>
          <ReceivedOrders />
        </Route>
        <Route path={`${path}/:orderId`}>
          <OrderDetails />
        </Route>
      </ContentContainer>
      <Footer />
    </MainTemplate>
  );
}
