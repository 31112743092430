import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';

export type Px = number & {}; // & {} prevents resolving type to number, see https://github.com/microsoft/TypeScript/issues/31940

export const spacing: Px = 20;

export const CartItemsListContainer = styled(MUIBox)<{ cartHasItems: boolean }>`
  border: ${({ cartHasItems, theme }) => (cartHasItems ? `1px solid ${theme.palette.grey[900]}` : 'none')};
  color: ${({ theme }) => theme.palette.grey[900]};
  margin: ${({ cartHasItems }) => (cartHasItems ? '0' : '1rem 0 0 0')};
  min-width: 400px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
