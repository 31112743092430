import { useEffect, useState, ReactNode, PropsWithChildren } from 'react';
import { useUser } from 'src/providers/User';
import { NotFoundPageAuthorised } from 'src/pages/NotFoundPageAuthorised';
import { Permission, usePermissions } from 'src/hooks/usePermissions';
import { Redirect, useLocation } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { NotFoundPageUnAuthorised } from 'src/pages/NotFoundPageUnAuthorised';
import { LocationType } from './UnauthenticatedTemplate';
import { isAuthorised } from '../utils/cognito';

interface Props {
  children: JSX.Element;
  permissions?: Permission[];
}

export function RedirectAnonUsers({ children, permissions }: Props) {
  const userContext = useUser();
  const { userLoading } = userContext;
  const location = useLocation();
  const hasPermissions = usePermissions(...(permissions || []));
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [sessionPending, setSessionPending] = useState(true);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        setSession(session);
      })
      .catch(() => {
        setSession(null);
      })
      .finally(() => {
        setSessionPending(false);
      });
  }, []);

  const redirectState: LocationType = {
    redirectUrl: location.pathname + location.search,
  };

  if (sessionPending) {
    return <FullscreenLoadingSpinner />;
  }

  if (!isAuthorised(session)) {
    Auth.signOut();
    return (
      <Redirect
        to={{
          pathname: '/',
          state: redirectState,
        }}
      />
    );
  }

  if (userLoading) {
    return <FullscreenLoadingSpinner />;
  }

  if (!hasPermissions) {
    return <NotFoundPageAuthorised />;
  }

  return <>{children}</>;
}

export function RedirectAuthedUsers({ children }: PropsWithChildren<ReactNode>) {
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [sessionPending, setSessionPending] = useState(true);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    Auth.currentSession()
      .then((session) => {
        setSession(session);
      })
      .catch(() => setSession(null))
      .finally(() => setSessionPending(false));
  }, []);

  if (sessionPending) {
    return <FullscreenLoadingSpinner />;
  }

  if (isAuthorised(session)) {
    return (
      <Redirect
        to={{
          pathname: '/product-library',
        }}
      />
    );
  }

  return <>{children}</>;
}

export function NotFoundPage() {
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [sessionPending, setSessionPending] = useState(true);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    Auth.currentSession()
      .then((session) => {
        setSession(session);
      })
      .catch(() => setSession(null))
      .finally(() => setSessionPending(false));
  }, []);

  if (sessionPending) {
    return <FullscreenLoadingSpinner />;
  }

  if (isAuthorised(session)) {
    return <NotFoundPageAuthorised />;
  }

  return <NotFoundPageUnAuthorised />;
}
