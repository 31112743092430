import { ReactComponent as Loader } from './loader.svg';

export function LoadingSpinner({
  width = '200px',
  height = '200px',
  viewBox = '0 0 100 100',
  ...props
}: {
  width?: string;
  height?: string;
  viewBox?: string;
  [x: string]: any;
}) {
  return <Loader width={width} height={height} viewBox={viewBox} {...props} />;
}
