import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';

export default function UploadProductCard() {
  return (
    <UploadProductCardContent to="/upload" data-testid="upload-product-tile">
      <FontAwesomeIcon icon={faCloudArrowUp} size="5x" />
      <UploadProductCardText>
        Go to the upload section <br /> and upload files
      </UploadProductCardText>
    </UploadProductCardContent>
  );
}

const UploadProductCardContent = styled(NavLink)`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px ${({ theme }) => theme.palette.grey[400]} dashed;
`;

const UploadProductCardText = styled(MUITypography)`
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 1.5rem;
`;
