import { StyledMUIDialogTitle } from './styles';

interface EditPartsTitleProps {
  isEditingDetails: boolean;
  editedNodes: string[];
}

export function EditPartsTitle({ isEditingDetails, editedNodes }: EditPartsTitleProps) {
  return (
    <StyledMUIDialogTitle variant="h5">
      {isEditingDetails ? <span>Edit part details </span> : 'Edit part'}
      <small>
        {' '}
        ({editedNodes.length} part{editedNodes.length > 1 ? 's' : ''})
      </small>
    </StyledMUIDialogTitle>
  );
}
