import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import GlobalProductNameSearch from 'src/components/GlobalProductNameSearch/GlobalProductNameSearch';
import { ProductCategoriesListHeader } from 'src/pages/CategoriesListPage/ProductCategoriesBreadcrumbs';
import {
  HeaderContainer,
  HeaderContainerLeftSide,
  CategoryHeaderTitle,
  CategoryHeaderBackButton,
  HeaderContainerRightSide,
} from './styles';

interface ProductListHeaderProps {
  decodedPathEnumeration: string; // use the real type
}

export function DesktopProductCategoryHeader({ decodedPathEnumeration }: ProductListHeaderProps) {
  return (
    <HeaderContainer>
      <HeaderContainerLeftSide>
        <CategoryHeaderTitle data-testid="category-page-title">
          <ProductCategoriesListHeader decodedPathEnumeration={decodedPathEnumeration} />
        </CategoryHeaderTitle>
        <CategoryHeaderBackButton startIcon={<FontAwesomeIcon icon={faChevronLeft} size="xs" />} to="/product-library">
          Back to all categories
        </CategoryHeaderBackButton>
      </HeaderContainerLeftSide>
      <HeaderContainerRightSide>
        <GlobalProductNameSearch />
      </HeaderContainerRightSide>
    </HeaderContainer>
  );
}
