import { Route, Switch } from 'react-router-dom';

import { Footer } from 'src/components/Footer';
import { Panel, PanelContainer, PanelInnerContainer } from 'src/components/Product/Desktop/Sidebar/styles';
import type { TabProps } from 'src/pages/ProductDetailPage';

import { PartsCataloguePanelDesktop } from '../Panels/PartsCatalogue';
import { WorkInstructionsDesktop } from '../Panels/WorkInstructions';
import { SidebarTabs } from './SidebarTabs';

export function Sidebar(props: TabProps) {
  const { currentTabName, productPath } = props;
  return (
    <PanelContainer>
      <Route path={`${productPath}/:tab`}>
        <SidebarTabs {...props} />
      </Route>
      <Panel data-testid={`product-tab-${currentTabName}`}>
        <PanelInnerContainer>
          <Switch>
            <Route path={`${productPath}/parts-catalogue`}>
              <PartsCataloguePanelDesktop />
            </Route>
            <Route path={`${productPath}/work-instructions`}>
              <WorkInstructionsDesktop />
            </Route>
          </Switch>
        </PanelInnerContainer>
      </Panel>
      <Footer />
    </PanelContainer>
  );
}
