import axios, { CancelToken } from 'axios';
import { DependencyList, useEffect } from 'react';

export const useEffectWithCancellation = (
  name: string,
  effect: (token: CancelToken) => void,
  deps: DependencyList = []
) => {
  useEffect(() => {
    const source = axios.CancelToken.source();

    effect(source.token);

    return () => {
      source.cancel(`Cancelling ${name} request`);
    };
    // Dynamic dependency array doesn't pass eslint, also effect may be referentially unstable and this causes infinite loop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, ...deps]);
};
