import { Footer } from 'src/components/Footer';
import GlobalProductNameSearch from 'src/components/GlobalProductNameSearch/GlobalProductNameSearch';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { ProductLibraryBackground } from 'src/components/ProductLibraryBackground';
import { MainTemplate } from 'src/templates/MainTemplate';
import { Toast } from 'src/components/Toast';
import { useQueryString } from 'src/hooks/useQueryString';
import { ProductCategories, ProductCategory } from '@samsonvt/shared-types/accountsTable';
import { decodeProductCategoryPathEnumeration, productCategoryPathEnumerationSeparator } from 'src/utils/urls';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';
import { CategoriesList } from './CategoriesList';
import {
  CategoriesPageTitle,
  CategotiesPageContentPadding,
  HeaderContainer,
  Subtitle,
  SubtitleContainer,
} from './styles';
import { ProductCategoryPage } from '../ProductCategoryPage/ProductCategoryPage';
import { ProductCategoriesListHeader } from './ProductCategoriesBreadcrumbs';
import { CategoryHeaderBackButton } from '../ProductCategoryPage/Desktop/styles';

interface CategoriesListPageProps {
  showAddProductTile: boolean;
  productCategories: ProductCategories;
  isLoading: boolean;
  isError: boolean;
  products: ProductListAPIResponse;
}

export default function CategoriesListPage({
  productCategories,
  showAddProductTile,
  isLoading,
  products,
  isError,
}: CategoriesListPageProps) {
  const productCategoryPathEnumeration = useQueryString('productCategoryPath');
  const decodedPathEnumeration = productCategoryPathEnumeration
    ? decodeProductCategoryPathEnumeration(productCategoryPathEnumeration)
    : undefined;

  const categoriesToShow = getProductCategoryPathEnumerationChildren(decodedPathEnumeration, productCategories);

  if (isLoading) {
    return <FullscreenLoadingSpinner />;
  }

  if (!categoriesToShow.length) {
    // we have no more child categories, show the products corresponding to the path enumeration
    return (
      <ProductCategoryPage
        showAddProductTile={showAddProductTile}
        decodedPathEnumeration={decodedPathEnumeration || ''}
      />
    );
  }

  return (
    <>
      <MainTemplate>
        <ProductLibraryBackground>
          <CategotiesPageContentPadding>
            <CategoriesPageTitle>Product library</CategoriesPageTitle>
            <HeaderContainer>
              <GlobalProductNameSearch />
            </HeaderContainer>
            <SubtitleContainer>
              {decodedPathEnumeration ? (
                <Subtitle>
                  <ProductCategoriesListHeader decodedPathEnumeration={decodedPathEnumeration} />
                  <CategoryHeaderBackButton
                    startIcon={<FontAwesomeIcon icon={faChevronLeft} size="xs" />}
                    to="/product-library"
                  >
                    Back to all categories
                  </CategoryHeaderBackButton>
                </Subtitle>
              ) : (
                'All categories'
              )}
            </SubtitleContainer>
            <CategoriesList
              categoriesToShow={categoriesToShow}
              products={products}
              decodedPathEnumeration={decodedPathEnumeration || ''}
            />
          </CategotiesPageContentPadding>
        </ProductLibraryBackground>
        <Footer />
      </MainTemplate>
      <Toast
        dependency={isError}
        severity="error"
        title="Sorry there has been a problem"
        message="Please try again later."
      />
    </>
  );
}

const getProductCategoryPathEnumerationChildren = (path: string | undefined, arr: ProductCategories): string[] => {
  if (!path) {
    return arr.map((node) => node.name);
  }
  const pathParts = path.split(productCategoryPathEnumerationSeparator);

  let currentNode: ProductCategory | undefined;

  pathParts.forEach((part) => {
    currentNode = (currentNode ? currentNode.children : arr)?.find((node) => node.name === part);
  });

  return currentNode?.children ? currentNode.children.map((node) => node.name) : [];
};
