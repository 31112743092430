import { NavList } from 'src/components/NavList/NavList';
import type { NamedLink } from 'src/services/partNavigation';
import { PartsTabList } from './PartsTabList';

interface OverviewTabProps {
  partChildren: NamedLink[];
}

export function PartsTab({ partChildren }: OverviewTabProps) {
  return (
    <NavList>
      <PartsTabList partChildren={partChildren} />
    </NavList>
  );
}
