import { styled } from '@mui/material';

import { font } from 'src/constants/fontSizes';

import { LoadingSpinner } from '.';

const ProcessingOverlayContainer = styled('div')`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ProcessingMessage = styled('div')`
  font-family: Arial, sans-serif;
  font-size: ${font.xLarge};
  line-height: 1.19;
  color: ${({ theme }) => theme.palette.grey['800']};
`;

export function ProcessingOverlay() {
  return (
    <ProcessingOverlayContainer data-testid="processing-overlay">
      <LoadingSpinner />
      <ProcessingMessage>Processing, please wait…</ProcessingMessage>
    </ProcessingOverlayContainer>
  );
}
