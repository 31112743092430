import MUIToolbar from '@mui/material/Toolbar';
import { faBars } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Logo } from 'src/components/Logo';
import { FlexContainer } from '../../FlexContainer';
import { LogoLinkElement } from '../styles';
import { MobileHeaderShoppingCartButton } from './MobileHeaderShoppingCartButton';

interface MobileToolbarProps {
  openPanel: () => void;
  triggerMobileProductMenu: () => void;
}

export function MobileToolbar({ openPanel, triggerMobileProductMenu }: MobileToolbarProps) {
  return (
    <MUIToolbar>
      <MobileMenuContainer>
        <MobileHamburgerIcon size="large" onClick={triggerMobileProductMenu} data-testid="nav-hamburger-icon">
          <FontAwesomeIcon icon={faBars} />
        </MobileHamburgerIcon>
        <FlexContainer sx={{ margin: 'auto' }}>
          <LogoLinkElement to="/" data-testid="tenant-logo">
            <Logo />
          </LogoLinkElement>
        </FlexContainer>
        <MobileHeaderShoppingCartButton openPanel={openPanel} />
      </MobileMenuContainer>
    </MUIToolbar>
  );
}

const MobileMenuContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const MobileHamburgerIcon = styled(IconButton)`
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;
