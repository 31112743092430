import { styled } from '@mui/material';
import MUITextField from '@mui/material/TextField';

export const SearchField = styled(MUITextField)`
  border-radius: 3px;
  width: 320px;
  height: 55px;
  padding: 0;
  display: block;

  background-color: ${({ theme }) => theme.palette.grey[300]};

  input {
    margin: 0 1rem;
    padding: 0;
  }
  input::placeholder {
    text-align: right;
    font-style: italic;
  }

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    width: 100%;
    height: 100%;
  }

  & .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiFilledInput-root::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiFilledInput-root::after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[900]};
  }

  input::placeholder {
    text-align: left;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  }
`;

SearchField.defaultProps = {
  variant: 'filled',
  color: 'secondary',
};
