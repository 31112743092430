import { useMediaQuery } from '@mui/material';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { device } from 'src/constants/mediaQueries';
import MyOrdersTableDescription from './MyOrdersTable/MyOrdersTableDescription';
import MyOrdersTableLogic from './MyOrdersTable/MyOrdersTableLogic';

export default function MyOrders() {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  return (
    <>
      {isMobile ? null : (
        <StandardBreadcrumbs
          current={{ name: 'My orders' }}
          parents={[{ name: 'My account', url: '/my-account' }]}
          sx={{ marginTop: '1rem' }}
        />
      )}
      <MyOrdersTableDescription />
      <MyOrdersTableLogic />
    </>
  );
}
