import { useRef, useState, useEffect } from 'react';
import { Canvas, invalidate } from '@react-three/fiber';
import { Vector3, MathUtils } from 'three';
import { styled, useTheme } from '@mui/material';
import MUIBox from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsUp, faChevronsDown } from '@fortawesome/pro-light-svg-icons';

import { spacing } from 'src/constants/spacing';
import CameraControls from 'camera-controls';
import { radius } from 'src/constants/borderSizes';
import { font } from 'src/constants/fontSizes';

import IconReset from './icons/Icon_Reset';
import IconRotateModel from './icons/IconRotateModel';
import IconPanModel from './icons/IconPanModel';
import ZoomControls from './ZoomControls';
import { TransformArrow } from './TransformArrow';
import { CameraTransformer } from './CameraTransformer';
import InteractionCube from './InteractionCube';

interface Props {
  cameraControls?: CameraControls;
  resetCamera: () => void;
  minZoomLevel?: number;
  maxZoomLevel?: number;
}

const ARBITRARY_THROTTLING_THRESHOLD = 1;

const cubeFaceToAngles = {
  top: [0, 0],
  front: [270, 90],
  right: [0, 90],
  left: [180, 90],
  back: [90, 90],
  bottom: [0, 180],
};

export type FaceName = keyof typeof cubeFaceToAngles;

export interface IArrowState {
  left: boolean;
  right: boolean;
  up: boolean;
  down: boolean;
}

export function FullControls({
  cameraControls,
  resetCamera,
  minZoomLevel = 0.5,
  maxZoomLevel = 50, // Passed as prop only to maintain legacy models. In the future it will be constant.
}: Props) {
  const [show, setShow] = useState(true);
  const [rotationActive, setRotationActive] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(10);
  const arrowsHeldDown = useRef<IArrowState>({ left: false, right: false, up: false, down: false });
  const theme = useTheme();

  useEffect(() => {
    const currentCameraControls = cameraControls;
    const cameraTarget = new Vector3();
    const cameraPosition = new Vector3();

    const onCameraPositionChange = () => {
      if (currentCameraControls) {
        currentCameraControls.getTarget(cameraTarget);
        currentCameraControls.getPosition(cameraPosition);

        const distanceFromOrbitCenter = cameraPosition.distanceTo(cameraTarget);
        // Delta is calculated to stop unsignificant changes of zoom
        if (Math.abs(zoomLevel - distanceFromOrbitCenter) > ARBITRARY_THROTTLING_THRESHOLD) {
          setZoomLevel(distanceFromOrbitCenter);
        }
      }
    };

    currentCameraControls?.addEventListener('control', onCameraPositionChange);
    currentCameraControls?.addEventListener('update', onCameraPositionChange);
    onCameraPositionChange(); // Triger immediatly in case camera doesn't move initially

    return () => {
      currentCameraControls?.removeEventListener('control', onCameraPositionChange);
      currentCameraControls?.removeEventListener('update', onCameraPositionChange);
    };
  }, [cameraControls, zoomLevel]);

  useEffect(() => {
    if (!cameraControls) {
      return;
    }

    if (!rotationActive) {
      cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
      cameraControls.touches.one = CameraControls.ACTION.TOUCH_TRUCK;
    } else {
      cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
      cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    }
  }, [rotationActive, cameraControls]);

  const translateCameraToCubeFace = (cubeSideClicked: FaceName) => {
    const [azimuthAngle, polarAngle] = cubeFaceToAngles[cubeSideClicked];
    cameraControls?.normalizeRotations();
    cameraControls?.rotateTo(MathUtils.degToRad(azimuthAngle), MathUtils.degToRad(polarAngle), true);
    invalidate();
  };

  return (
    <ControlsContainer
      style={{
        marginBottom: show ? 0 : '-22.25rem', // Manually calculated HUD height for animation (different elements have different height settings, but all scale in rem)
      }}
      data-testid="hud-container"
    >
      <HideControls onClick={() => setShow((state) => !state)} data-testid="hide-button">
        {show ? (
          <>
            <FontAwesomeIcon icon={faChevronsDown} />
            Hide controls
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faChevronsUp} />
            Show controls
          </>
        )}
      </HideControls>
      <Row>
        <TransformArrow arrowsHeldDown={arrowsHeldDown} direction="up" />
        <ResetButton onClick={resetCamera} data-testid="reset-button">
          <IconReset />
        </ResetButton>
      </Row>
      <Row>
        <TransformArrow arrowsHeldDown={arrowsHeldDown} direction="right" />
        <HUDCanvas>
          {cameraControls ? (
            <Canvas camera={cameraControls.camera} linear flat>
              <InteractionCube
                sceneCamera={cameraControls.camera}
                cubeSideClicked={translateCameraToCubeFace}
                hoverColour={theme.palette.primary.main}
                colour={theme.palette.primary.light}
              />
              <CameraTransformer
                rotationActive={rotationActive}
                cameraControls={cameraControls}
                arrowsHeldDown={arrowsHeldDown}
              />
            </Canvas>
          ) : null}
        </HUDCanvas>
        <TransformArrow arrowsHeldDown={arrowsHeldDown} direction="left" />
      </Row>
      <Row>
        <TransformArrow arrowsHeldDown={arrowsHeldDown} direction="down" />
      </Row>
      <Row>
        <StyledToggleButtonsContainer>
          <StyledToggleButton
            data-testid="rotate-button"
            isActive={rotationActive}
            onClick={() => setRotationActive(true)}
          >
            <IconRotateModel /> Rotate
          </StyledToggleButton>
          <StyledToggleButton
            data-testid="truck-button"
            isActive={!rotationActive}
            onClick={() => setRotationActive(false)}
          >
            <IconPanModel /> Pan
          </StyledToggleButton>
        </StyledToggleButtonsContainer>
      </Row>
      <ZoomControls
        cameraControls={cameraControls}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        minZoomLevel={minZoomLevel}
        maxZoomLevel={maxZoomLevel}
      />
    </ControlsContainer>
  );
}

const ControlsContainer = styled('div')`
  ::before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.palette.primary.light};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${radius.medium};
  }
  user-select: none;
  transition: margin 0.5s;
  position: relative;
`;

const HideControls = styled('div')`
  position: relative; // Keeps it in front of ::before overlay so that clicks work and opacity isn't applied. Zoom controls do the same.
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  justify-content: center;
  border-radius: ${radius.medium} ${radius.medium} 0 0;
  padding: 1rem;
  gap: 1rem;
  cursor: pointer;
`;

const HUDCanvas = styled('div')`
  width: 6.25rem;
  height: 6.25rem;
`;

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ResetButton = styled('div')`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: ${radius.medium};
  width: ${spacing.largeSpacing};
  height: ${spacing.largeSpacing};
  position: absolute;
  right: 0;
  margin: ${spacing.smallSpacing};
  cursor: pointer;
  transition: 0.4s all ease-in-out;

  :hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
  svg {
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
`;

const StyledToggleButtonsContainer = styled('div')`
  width: 100%;
  margin: ${spacing.smallSpacing};
  display: flex;
`;

interface ButtonProps {
  isActive: boolean;
}

const StyledToggleButton = styled(MUIBox)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: ${spacing.xSmallSpacing};
  font-size: ${font.small};
  height: 100%;
  border: none;
  background: ${({ isActive, theme }) => (isActive ? theme.palette.secondary.main : theme.palette.primary.main)};
  color: ${({ isActive, theme }) =>
    isActive ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText};
  cursor: pointer;
  transition: 0.4s all ease-in-out;

  ${({ isActive, theme }) => (isActive ? 'cursor: default;' : `:hover { background: ${theme.palette.primary.light};}`)}

  &:first-of-type {
    border-top-left-radius: ${radius.medium};
    border-bottom-left-radius: ${radius.medium};
  }

  &:last-of-type {
    border-top-right-radius: ${radius.medium};
    border-bottom-right-radius: ${radius.medium};
  }

  svg {
    width: ${spacing.largeSpacing};
    height: ${spacing.largeSpacing};
    margin-right: ${spacing.xSmallSpacing};
  }
`;

StyledToggleButton.defaultProps = { component: 'button' };
