import { useEffect, useState } from 'react';
import { NamedLink } from 'src/services/partNavigation';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { BreadcrumbsEllipsis } from './BreadcrumbsEllipsis';
import { StyledMUILink, StyledNavigateNextIcon, TextWithShadow } from './styles';

interface IProps {
  current?: Pick<PartNode, 'name'> | { name: string };
  parents: NamedLink[];
  maxSteps?: number;
}

export function ContrastBreadcrumbs({ current, parents, maxSteps = 100 }: IProps) {
  const [hideMiddleSteps, setHideMiddleSteps] = useState(false);
  const [breadCrumbsToShow, setbreadCrumbsToShow] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setHideMiddleSteps(parents.length >= maxSteps);
  }, [parents.length, maxSteps]);

  useEffect(() => {
    const links: JSX.Element[] = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < parents.length; index++) {
      const link = parents[index];
      const shouldHide = hideMiddleSteps && index;
      if (shouldHide) {
        links.push(<BreadcrumbsEllipsis key="ellipsis" onClick={() => setHideMiddleSteps(false)} />);
        break;
      }
      links.push(
        <StyledMUILink key={link.url} to={link.url} data-testid="bom-parent-node">
          {link.name}
        </StyledMUILink>
      );
    }
    setbreadCrumbsToShow(links);
  }, [hideMiddleSteps, parents]);

  return current ? (
    <MUIBreadcrumbs separator={<StyledNavigateNextIcon />} maxItems={Number.MAX_VALUE}>
      {breadCrumbsToShow}
      <TextWithShadow data-testid="bom-current-node">{current.name}</TextWithShadow>
    </MUIBreadcrumbs>
  ) : null;
}
