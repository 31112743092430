import { getProducts } from 'src/services/api';
import { useQuery } from '@tanstack/react-query';

let enabled = true;

export const useProductsListQuery = () =>
  useQuery(
    ['productList'],

    getProducts,

    {
      onError: () => {
        enabled = false;
        // Don't refetch on error as this makes handling nested queries problematic.
        // Usual retries will apply anyway, as onError callback is only called after retries.
        // Queries in nested components will use cached data.
      },
      enabled,
    }
  );
