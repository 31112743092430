import { DesktopResetPasswordRequirements, StyledSendResetPasswordFormButton } from '../styles';
import { ResetPasswordFormProps } from '../types';
import RequirementsBoxText, { PasswordGroup } from '../../../components/PasswordRequirementsBox.tsx';
import { LoginPasswordInput } from '../../../components/TextInput/LoginPasswordInput';

export function DesktopResetPasswordForm({
  error,
  onButtonClick,
  resetPasswordFormData,
  setResetPasswordFormData,
  checkPasswordsMatch,
}: ResetPasswordFormProps) {
  const { password, confirmPassword } = resetPasswordFormData;
  return (
    <form onSubmit={(e) => onButtonClick(e, resetPasswordFormData)}>
      <PasswordGroup>
        <div>
          <LoginPasswordInput
            label="Password"
            testId="password"
            value={password}
            error={error}
            onChange={(e: any) => setResetPasswordFormData((prev) => ({ ...prev, password: e.target.value }))}
            match={checkPasswordsMatch()}
          />
        </div>
        <DesktopResetPasswordRequirements>
          <RequirementsBoxText newPassword />
        </DesktopResetPasswordRequirements>
        <div>
          <LoginPasswordInput
            label="Confirm password"
            testId="confirm-password"
            value={confirmPassword}
            error={error}
            onChange={(e: any) => setResetPasswordFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))}
            match={checkPasswordsMatch()}
          />
        </div>
      </PasswordGroup>

      <StyledSendResetPasswordFormButton
        type="submit"
        data-testid="reset-form-submit"
        disabled={!checkPasswordsMatch() || Object.values(resetPasswordFormData).some((value) => value === '')}
      >
        Change your password
      </StyledSendResetPasswordFormButton>
    </form>
  );
}
