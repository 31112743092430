import MUIFormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { LoadingSpinner } from 'src/components/Loading';
import { TelephoneInput } from 'src/components/TextInput/TelephoneInput';

import { MyProfileProps } from '../../AccountDetailsPage';
import {
  FormContainer,
  Row,
  MyProfileSelectLabel,
  MyProfileInputField,
  OptionalMyProfileLabel,
  MyProfileSelectField,
  MyProfileSelectOption,
  UnEditableInputField,
  UnEditableInputFieldLabel,
  UnEditableInputFieldValue,
} from '../../Desktop/MyProfile/styles'; // imported from desktop version as they are common between screens
import { MobileAccountDetailsFormButton } from '../styles';

import {
  MobileMyProfileFormWrapper,
  MobileSectionsContainer,
  TopSection,
  MobileMyProfileSubTitle,
  MobileMyProfileDivider,
  BottomSection,
} from './styles';

export default function MobileMyProfileForm({
  userDetailsFormState,
  handleMyProfileFormChange,
  submitMyProfileForm,
  userEmail,
  isApplyUserLoading,
  countryList,
}: MyProfileProps) {
  const { name, companyName, telephone, buildingName, streetName, city, postalCode, country } = userDetailsFormState;
  const [validate, setValidate] = useState(false);
  const validateOnSubmission = (condition: boolean) => validate && condition;
  return (
    <MobileMyProfileFormWrapper onSubmit={submitMyProfileForm}>
      <FormContainer>
        <MobileSectionsContainer>
          <MobileMyProfileSubTitle>My details:</MobileMyProfileSubTitle>
          <TopSection>
            <MyProfileInputField
              label="Full name"
              name="name"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!name)}
              value={name}
            />
            <MyProfileInputField
              label="Company name"
              name="companyName"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!companyName)}
              value={companyName}
            />
            <TelephoneInput
              name="telephone"
              Component={MyProfileInputField}
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!telephone)}
              value={telephone}
            />
            <UnEditableInputField>
              <UnEditableInputFieldLabel>Email</UnEditableInputFieldLabel>
              <UnEditableInputFieldValue>{userEmail}</UnEditableInputFieldValue>
            </UnEditableInputField>
          </TopSection>

          <MobileMyProfileDivider />

          <MobileMyProfileSubTitle>Address:</MobileMyProfileSubTitle>
          <BottomSection>
            <MyProfileInputField
              label={<OptionalMyProfileLabel label="Building name / Number" />}
              name="buildingName"
              onChange={handleMyProfileFormChange}
              value={buildingName}
            />
            <MyProfileInputField
              label="Street address"
              name="streetName"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!streetName)}
              value={streetName}
            />
            <Row>
              <MyProfileInputField
                label="Town / City"
                name="city"
                onChange={handleMyProfileFormChange}
                required
                error={validateOnSubmission(!city)}
                value={city}
              />
              <MyProfileInputField
                label="Postcode"
                name="postalCode"
                onChange={handleMyProfileFormChange}
                required
                error={validateOnSubmission(!postalCode)}
                value={postalCode}
              />
            </Row>
            <MUIFormControl
              fullWidth
              variant="filled"
              size="small"
              required
              error={validateOnSubmission(!country)}
              data-testid="country-select"
            >
              <MyProfileSelectLabel>Country</MyProfileSelectLabel>
              <MyProfileSelectField value={country} name="country" onChange={handleMyProfileFormChange}>
                {countryList.map((countryName) => (
                  <MyProfileSelectOption value={countryName} key={countryName}>
                    {countryName}
                  </MyProfileSelectOption>
                ))}
              </MyProfileSelectField>
            </MUIFormControl>
          </BottomSection>
        </MobileSectionsContainer>
        <MobileAccountDetailsFormButton type="submit" onClick={() => setValidate(true)}>
          {isApplyUserLoading ? <LoadingSpinner width="100px" height="100px" /> : 'Save details'}
        </MobileAccountDetailsFormButton>
      </FormContainer>
    </MobileMyProfileFormWrapper>
  );
}
