export const breakpoints = {
  xs: 0, // default MUI
  sm: 620, // increased from default MUI 600 as its used in Mobile product drawer and Content pagination
  md: 920, // increased from default MUI 900 to catch what we called tabletL before (border between tablet and desktop)
  lg: 1280, // increased from default MUI 1200 to catch what we called zoomedLaptop before
  xl: 1536, // default MUI
}; // Matches MUI Default structure and naming, to take advantage of built-in styling

export const device = {
  smallMobile: `(max-width: ${breakpoints.sm}px)`, // Used in some mobile views to adjust font size
  mobileOrTablet: `(max-width: ${breakpoints.md}px)`, // !MAIN BREAKPOINT! between mobile and dekstop views
  smallLaptop: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px )`,
  largeLaptopOrSmallDesktop: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px )`, // Used for product list columns
  largeDesktop: `(min-width: ${breakpoints.lg}px)`, // Used for product list columns
} as const;
