import { styled } from '@mui/material';

export default function EmptyCategoryPage() {
  return (
    <EmptyCategoryPageMessage data-testid="no-products">
      There are currently no products available to view for this category
    </EmptyCategoryPageMessage>
  );
}

const EmptyCategoryPageMessage = styled('div')`
  text-align: left;
  margin-top: 3rem;
`;
