import { usePermissions } from 'src/hooks/usePermissions';
import { useUser } from 'src/providers/User';
import { useProductsListQuery } from 'src/providers/useProductsListQuery';
import CategoriesListPage from '../CategoriesListPage/CategoriesListPage';
import { ProductListPage } from '../ProductListPage/ProductListPage';

export default function ProductListings() {
  const { isLoading, isError, data: products = [] } = useProductsListQuery();
  const { productCategories } = useUser();
  const showAddProductTile = usePermissions(['add', 'product']);

  return productCategories?.length ? (
    <CategoriesListPage
      productCategories={productCategories}
      showAddProductTile={showAddProductTile}
      isLoading={isLoading}
      isError={isError}
      products={products}
    />
  ) : (
    <ProductListPage
      showAddProductTile={showAddProductTile}
      isLoading={isLoading}
      isError={isError}
      products={products}
    />
  );
}
