import MUIChip from '@mui/material/Chip';
import { styled } from '@mui/material';
import { ColourSettings } from '@samsonvt/shared-types/accountsTable';

export type ChipProps = Omit<React.ComponentProps<typeof MUIChip>, 'color'> & { color?: ColourSettings };

const Chip = styled(MUIChip)<ChipProps>`
  background-color: ${({ theme, color = 'secondary' }) => theme.palette[color].main};
  color: ${({ theme, color = 'secondary' }) => theme.palette[color].contrastText};

  .MuiChip-label {
    padding-right: 0;
    padding: 0.9rem;
  }
`;

export default Chip;
