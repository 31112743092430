import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { SearchField } from 'src/components/SearchField';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { device } from 'src/constants/mediaQueries';

interface ProductListHeaderProps {
  searchFieldState: string;
  setSearchFieldState: React.Dispatch<React.SetStateAction<string>>;
}

export function ProductListHeader({ searchFieldState, setSearchFieldState }: ProductListHeaderProps) {
  return (
    <HeaderContainer>
      <CategoriesPageSearchField
        InputProps={{ startAdornment: <FontAwesomeIcon icon={faMagnifyingGlass} /> }}
        placeholder="Type a product name to filter"
        value={searchFieldState}
        onChange={(e) => setSearchFieldState(e.target.value)}
        data-testid="product-library-filter"
      />
    </HeaderContainer>
  );
}

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media ${device.smallMobile} {
    justify-content: center;
  }
`;

const CategoriesPageSearchField = styled(SearchField)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 18rem;

  & .MuiFilledInput-input {
    margin-left: 0.5rem;
  }

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    padding: 0;
  }

  & .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  & .MuiFilledInput-root::after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main};
  }

  svg {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    margin-left: 1rem;
  }

  @media ${device.smallMobile} {
    flex-grow: 1;
  }
`;
