import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material';
import MUILink from '@mui/material/Link';
import MUITypography from '@mui/material/Typography';
import MUINavigateNextIcon from '@mui/icons-material/NavigateNext';

import { device } from 'src/constants/mediaQueries';
import { shadows } from 'src/constants/contrastShadows';

export const StyledMUILink = styled(MUILink)<LinkProps>`
  color: ${({ theme }) => theme.palette.grey['800']};
  text-shadow: ${({ theme }) => shadows(1, theme.palette.canvasBackground.linear).join(', ')};
  text-decoration: underline;

  @media ${device.mobileOrTablet} {
    font-size: 0.75rem;
  }
`;
StyledMUILink.defaultProps = { underline: 'hover', component: RouterLink };

export const TextWithShadow = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-shadow: ${({ theme }) => shadows(1, theme.palette.canvasBackground.linear).join(', ')};
  font-weight: 700;
  letter-spacing: 0.4px;

  @media ${device.smallMobile} {
    font-size: 0.75rem;
  }
`;

export const StyledNavigateNextIcon = styled(MUINavigateNextIcon)`
  color: ${({ theme }) => theme.palette.grey['800']};
  filter: ${({ theme }) =>
    shadows(0.5, theme.palette.canvasBackground.linear)
      .map((s) => `drop-shadow(${s})`)
      .join(' ')};
  @media ${device.smallMobile} {
    font-size: 0.75rem;
  }
`;
StyledNavigateNextIcon.defaultProps = { fontSize: 'medium' };
