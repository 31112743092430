import { styled } from '@mui/material';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { EmptyTab } from 'src/components/EmptyTab';
import PartsCatalogue from 'src/components/Product/Desktop/Panels/PartsCatalogue/PartsCatalogue';
import { useProduct } from 'src/providers/Product';

const StyledBomTabPanel = styled('div')`
  padding: 0 1em 1em;
  height: 100%;
`;

const trailingSlashRegex = /\/+$/;

export function PartsCataloguePanelDesktop() {
  const { path, url } = useRouteMatch();
  const { bom } = useProduct();
  return (
    <StyledBomTabPanel>
      <Switch>
        <Route path={`${path}/:detailId`}>
          <PartsCatalogue />
        </Route>
        <Route path={path} exact>
          {bom ? <Redirect to={`${url.replace(trailingSlashRegex, '')}/${bom.id}`} /> : <EmptyTab />}
        </Route>
      </Switch>
    </StyledBomTabPanel>
  );
}
