import { styled } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import MUIIconButton from '@mui/material/IconButton';
import { PrimaryButton } from 'src/pages/UserManagementPage/styles';

export function DeleteButton(props: any) {
  return (
    <StyledIconButton aria-label="delete" {...props}>
      <TrashIcon icon={faTrash} size="xs" />
    </StyledIconButton>
  );
}

const StyledIconButton = styled(MUIIconButton)`
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ theme }) => theme.palette.grey[900]};
  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    background: ${({ theme }) => theme.palette.grey[500]};
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.75rem;
`;

export const Button = styled(PrimaryButton)`
  background: ${({ theme }) => theme.palette.grey[900]};
  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    background: ${({ theme }) => theme.palette.grey[500]};
  }
`;
