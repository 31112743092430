import { parseISO, format, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';

const blankDate = '----';

export const formatDate = (dateString: string = '') => {
  const date = parseISO(dateString);
  return isValid(date) ? format(date, 'dd MMM yy') : blankDate;
};

export const formatDateWithTime = (dateString: string = '') => {
  const date = parseISO(dateString);
  return isValid(date) ? format(date, 'PPp', { locale: enGB }).replace(/,/g, '').toUpperCase() : blankDate;
};

export function dateLabel(from: string | undefined, to: string | undefined, separator: string = 'to') {
  if (!from) {
    if (!to) return '';
    return `To ${formatDateShortMonth(to)}`;
  }
  if (!to) return `From ${formatDateShortMonth(from)}`;
  return `${formatDateShortMonth(from)} ${separator} ${formatDateShortMonth(to)}`;
}

export function formatDateShortMonth(isoFormat: string): string {
  const date = new Date(isoFormat);
  const dateString = date.toLocaleString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' });
  return dateString.toUpperCase();
}

export function formatDateToMaterialInput(isoFormat: string = ''): string {
  const date = parseISO(isoFormat);
  return isValid(date) ? format(date, 'yyyy-MM-dd') : '';
}

export function formatDateFromMaterialInput(materialFormat: string = ''): string {
  const [y, m, d] = materialFormat.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d));
  return date.toISOString();
}
