import { alpha, lighten, styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUICheckbox from '@mui/material/Checkbox';
import MUIDivider from '@mui/material/Divider';
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUIInputLabel from '@mui/material/InputLabel';
import MUIMenuItem from '@mui/material/MenuItem';
import MUITypography from '@mui/material/Typography';
import { StyledMUIButton } from 'src/components/Button';
import { ListFade } from 'src/components/ListFade';
import { StyledSelectField } from 'src/components/Select';
import { WhiteTextField } from 'src/components/TextInput/WhiteTextField';
import { device } from 'src/constants/mediaQueries';

const formMinHeight = 720;

export const Background = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  flex-grow: 1;
  overflow-y: auto;

  ${({ theme }) => theme.mixins.hideScrollbar}
`;

export const DesktopCheckoutPageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.5rem 20%;
  flex-grow: 1;
  @media ${device.smallLaptop} {
    margin: 1.5rem 5%;
  }
`;

export const DesktopCheckoutFormContainer = styled('div')`
  display: flex;
  flex-grow: 1;
`;

export const CheckoutFormWrapper = styled('form')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  min-height: ${formMinHeight}px;
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  border-radius: 0px;
  flex-grow: 0.8;
`;

export const CheckOutTitle = styled(MUITypography)`
  width: 100%;
  margin: 2rem 0 0rem 2rem;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
export const CheckOutSubTitle = styled(MUITypography)`
  width: 100%;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
export const CheckOutSelectOption = styled(MUIMenuItem)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const OrderDetailsDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  width: 2px;
  height: 100%;
`;
OrderDetailsDivider.defaultProps = { orientation: 'vertical' };

export const CheckOutInputField = styled(WhiteTextField)`
  width: 100%;
`;
CheckOutInputField.defaultProps = {
  size: 'small',
  variant: 'filled',
  InputLabelProps: { required: false },
};

export const CheckoutSelectLabel = styled(MUIInputLabel)`
  color: ${({ theme }) => theme.palette.grey[600]};
`;
CheckoutSelectLabel.defaultProps = { required: false };

export const CheckOutSelectField = styled(StyledSelectField)`
  width: 100%;
  height: 100%;

  &.Mui-error::after {
    border-color: ${({ theme }) => theme.palette.error.main} !important;
  }
`;

export const CheckOutCheckBox = styled(MUICheckbox)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const LongCheckOutInputField = styled(CheckOutInputField)`
  width: 100%;
  flex-grow: 1;
  min-height: 300px;

  & .MuiFilledInput-root {
    height: 100%;
  }

  & .MuiFilledInput-input {
    border-bottom: none;
  }
`;

LongCheckOutInputField.defaultProps = {
  multiline: true,
  variant: 'filled',
  inputProps: {
    style: {
      height: '100%',
    },
  },
};

export function OptionalCheckoutLabel({ label }: { label: string }) {
  return (
    <MUITypography>
      {label} <sup>(optional)</sup>
    </MUITypography>
  );
}

export const CheckoutActionBar = styled(MUIBox)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.625rem;
  width: 100%;
  min-height: 68px;
`;

export const CartButton = styled(StyledMUIButton)`
  height: 45px;
  margin-right: 1rem;
  text-align: center;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
CartButton.defaultProps = {
  type: 'submit',
  color: 'secondary',
  variant: 'contained',
  size: 'medium',
};

export const CheckoutCartStyles = styled(MUIBox)`
  border: solid 1px ${({ theme }) => theme.palette.grey[900]};
  color: ${({ theme }) => theme.palette.grey[900]};
  border-left: 0px;

  width: 300px;
  min-height: ${formMinHeight}px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0.2;
`;

export const Row = styled(MUIBox)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Column = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormContainer = styled(Column)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const SectionsContainer = styled(Row)`
  width: 100%;
  height: 80%;
  justify-content: space-around;
  padding: 0 2rem 0 2rem;
`;

export const LeftSection = styled(Column)`
  width: 50%;
  height: 100%;
`;

export const RightSection = styled(Column)`
  width: 50%;
  height: 100%;
`;

export const CheckoutListFade = styled(ListFade)`
  &::after {
    background-image: linear-gradient(
      0deg,
      ${({ theme }) => lighten(theme.palette.common.white, 0.2)} 0%,
      ${({ theme }) => alpha(lighten(theme.palette.sidebarBackground.main, 0.2), 0)} 100%
    );
  }
`;

export const StyledMUICheckbox = styled(MUIFormControlLabel)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;
