import { ContactShadows } from '@react-three/drei';
import { useMemo, useEffect } from 'react';

import { Box3, Euler, Object3D, Event, Sphere, Color } from 'three';
import { horizonRenderOrder, horizonShader, shadowRenderOrder } from './shadowStyle';

interface ModelShadowProps {
  scene: Object3D<Event>;
  scale: number;
  shouldShowShadow: boolean;
  canvasBackground: string; // hex string for canvas colour
}

export default function ModelShadow({ scene, scale, shouldShowShadow, canvasBackground }: ModelShadowProps) {
  const { scaledMinY, shadowScale } = useMemo(() => {
    const sceneClone = scene.clone();
    const removeList: Object3D[] = [];

    sceneClone.traverse((x) => {
      // remove hidden meshes from BB calculation
      if (x.name.includes('_HIDDEN')) {
        removeList.push(x);
      }
    });

    removeList.forEach((x) => sceneClone.remove(x));

    const sizeBox = new Box3().setFromObject(sceneClone);
    const minY = sizeBox.min.y;

    return {
      scaledMinY: minY === Infinity ? 0 : minY * scale, // Box3 uses infinity as the default size for the box we should use 0
      shadowScale: sizeBox.getBoundingSphere(new Sphere()).radius * 2 * scale,
    };
  }, [scale, scene]);

  useEffect(() => {
    scene.traverse((x) => {
      if (x.isObject3D) x.renderOrder = shadowRenderOrder;
      if (x.name.includes('_HIDDEN')) {
        x.traverse((y) => y.layers.disable(0)); // Need to do this before FadeModel renders, as it will return effect composer which breaks the shadow.
      }
    });
  }, [scene]); // always render the scene after the horizon

  return shouldShowShadow ? (
    <>
      <ContactShadows
        opacity={0.7}
        scale={shadowScale * 3} // some models are not centered. As long as the center of the world is 3 times the bounding sphere radius the shadow will work
        blur={1}
        resolution={720}
        position={[0, scaledMinY, 0]}
        renderOrder={shadowRenderOrder}
        frames={10}
      />
      <Horizon scaledMinY={scaledMinY} shadowScale={shadowScale} canvasBackground={canvasBackground} />
    </>
  ) : null;
}

function Horizon({
  scaledMinY,
  shadowScale,
  canvasBackground,
}: {
  scaledMinY: number;
  shadowScale: number;
  canvasBackground: string;
}) {
  return (
    <mesh position={[0, scaledMinY, 0]} rotation={new Euler(-Math.PI / 2, 0, 0)} renderOrder={horizonRenderOrder}>
      <circleBufferGeometry args={[shadowScale * 20, 100]} />
      <shaderMaterial
        args={[horizonShader]}
        depthTest={false}
        uniforms={{
          color: { value: new Color(canvasBackground) },
        }}
      />
    </mesh>
  );
}
