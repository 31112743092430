import { faCircleDollar, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

interface UserManagementProps {
  currentUrl: string;
}

export function UserManagementTabs({ currentUrl }: UserManagementProps) {
  return (
    <UserManagementTabsBar>
      <UserManagementTabsContainer>
        <UserManagementTab
          to="user-management"
          active={currentUrl.includes('user-management')}
          data-testid={currentUrl.includes('user-management') && 'active-tab'}
        >
          <FontAwesomeIcon icon={faUsers} />
          <TabTitle>User management</TabTitle>
        </UserManagementTab>
        <UserManagementTab
          to="discount-management"
          active={currentUrl.includes('discount-management')}
          data-testid={currentUrl.includes('discount-management') && 'active-tab'}
        >
          <FontAwesomeIcon icon={faCircleDollar} />
          <TabTitle>Discount management</TabTitle>
        </UserManagementTab>
      </UserManagementTabsContainer>
    </UserManagementTabsBar>
  );
}

const UserManagementTabsBar = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.black};
`;

const UserManagementTabsContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  min-height: 4.375rem;
  justify-content: flex-end;
  margin-right: 5rem;
`;

const UserManagementTab = styled(Link, { shouldForwardProp: (prop: string) => prop !== 'active' })<{
  active: boolean;
}>`
  background-color: ${({ active, theme }) => (active ? theme.palette.common.white : theme.palette.grey[300])};
  border: ${({ active, theme }) =>
    active ? `solid ${theme.palette.common.black}` : `solid ${theme.palette.common.black}`};
  border-width: ${({ active }) => (active ? '1px 1px 0 1px' : '0')};
  ${({ active }) => active && 'position: relative'};
  ${({ active }) => active && 'top: 1px'};
  border-radius: 4px 4px 0px 0px;
  height: 75%;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabTitle = styled(MUITypography)`
  font-weight: bold;
  margin-left: 0.8rem;
  font-size: 15px;
`;
