import { styled } from '@mui/material';
import { Route } from 'react-router-dom';
import { Feature } from 'src/Feature';
import { ContrastBreadcrumbs } from 'src/components/Breadcrumbs/ContrastBreadcrumbs';
import { ProductBottomBar } from 'src/components/Product/Desktop/ProductBottomBar';
import ShoppingCartPanel from 'src/components/ShoppingCartPanel';
import { ThreeJsContainer } from 'src/components/ThreeJsContainer';
import { CanvasFlexWrapper } from 'src/components/ThreeJsContainer/CanvasFlexWrapper';
import { TabProps } from 'src/pages/ProductDetailPage';
import filterProductOptionsFromParents from 'src/services/filterProductOptionsFromParents';
import { usePart } from 'src/services/part';
import { usePartNavigation } from 'src/services/partNavigation';

const Rows = styled(CanvasFlexWrapper)`
  display: flex;
  flex-direction: column; // Keeps bottom bar in a column layout
  position: relative; // Used for centering loading box and error message
`;

export function DesktopProductViewLayout({ currentTabName, productPath }: TabProps) {
  const { current } = usePart();
  const { parents } = usePartNavigation();
  const breadcrumbsParents = filterProductOptionsFromParents(parents);

  return (
    <>
      <Rows>
        <ThreeJsContainer fullControls />
        <BreadcrumbsPositioning>
          <ContrastBreadcrumbs current={current} parents={breadcrumbsParents} />
        </BreadcrumbsPositioning>
        <Route path={`${productPath}/work-instructions/:section/:step`}>
          <ProductBottomBar />
        </Route>
      </Rows>
      {currentTabName === 'parts-catalogue' && (
        <Feature flag="shopping-cart">
          <ShoppingCartPanel />
        </Feature>
      )}
    </>
  );
}

const BreadcrumbsPositioning = styled('div')`
  display: flex;
  align-items: center;
  position: absolute;

  top: 1.75rem;
  left: 1rem;
  right: 1rem;
`;
