import { styled } from '@mui/material';

export const PanelContainer = styled('div')`
  background: ${({ theme }) => theme.palette.primary.main};
  height: 100%;
  will-change: transform;
  transition: 0.4s all ease-in-out;
  bottom: 0;
  width: 25rem; // 400px / 16 = 25
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Panel = styled('div')`
  display: block;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  overflow-y: auto;
  height: 100%;
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

export const PanelInnerContainer = styled('div')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  min-height: 100%;
  height: 1px;
`;
