import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITypography from '@mui/material/Typography';
import { styled, Popper, darken } from '@mui/material';
import { PartDetails } from '@samsonvt/shared-types/partService';

import { Highlight } from 'src/components/Product/Mobile/Panels/PartsCatalogue/Autocomplete/Highlight';
import { TextField } from '../styles';

interface EditPartsDropdownProps {
  characterLimit: number;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setNewPartNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSvtPartIDToAssignToANode: React.Dispatch<React.SetStateAction<string | undefined>>;
  partsAcrossWholeTenant: PartDetails[];
  isLoadingPartsAcrossWholeTenant: boolean;
}

type Option = {
  title: string;
  optionValue: string;
  type?: 'CREATE_NEW' | 'NON_CLICKABLE';
};

export function EditPartsDropdown({
  characterLimit,
  query,
  setQuery,
  setNewPartNumber,
  setSvtPartIDToAssignToANode,
  partsAcrossWholeTenant,
  isLoadingPartsAcrossWholeTenant,
}: EditPartsDropdownProps) {
  const [open, setOpen] = useState(false);

  const hasExactMatch = partsAcrossWholeTenant.some((partDetails) => partDetails.part.number === query);
  const partNumbersMappedToOptions = partsAcrossWholeTenant.map(
    (partDetails): Option => ({
      title: partDetails.part.number,
      optionValue: partDetails.svtPartID,
    })
  );
  const filterFunction = (option: Option) => new RegExp(query, 'i').test(option.title);
  const hasAnyMatches = partNumbersMappedToOptions.some(filterFunction);

  return (
    <MUIAutocomplete
      data-testid="assignment-dropdown"
      open={open}
      freeSolo
      inputValue={query} // use this instead of value/onchange https://mui.com/material-ui/react-autocomplete/#controlled-states
      onInputChange={(_, newInputValue) => {
        setOpen(true);
        setQuery(newInputValue);
      }}
      onChange={(_, newChosenValue) => {
        if (!newChosenValue) return;
        if (typeof newChosenValue === 'string') {
          // TODO: Check if the manually entered string has exact match with an existing part number
          setNewPartNumber(newChosenValue);
        } // set on enter key for now
        const { optionValue, type } = newChosenValue as Option;
        if (type === 'NON_CLICKABLE') return;
        if (type === 'CREATE_NEW') {
          setNewPartNumber(optionValue);
          setSvtPartIDToAssignToANode(undefined);
        } else {
          setSvtPartIDToAssignToANode(optionValue);
          setNewPartNumber(undefined);
        }
        setOpen(false);
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          // happens on enter in input field
          return option;
        }
        if (option.type === 'CREATE_NEW') {
          return option.optionValue;
        }
        return option.title;
      }}
      options={partNumbersMappedToOptions}
      renderInput={(params) => (
        <TextField {...params} label="Part number" placeholder="Start typing a number..." focused />
      )}
      filterOptions={(options, params) => {
        const filtered = options.filter(filterFunction);
        const { inputValue } = params;

        if (
          !hasExactMatch &&
          // FIXME: temporary measure due to part search limit not implemented in dynamo, probably lambda runs out of memory on too many parts
          query.length
        ) {
          filtered.push({
            optionValue: query,
            title: `Create new part number "${inputValue}"`,
            type: 'CREATE_NEW',
          });
        }

        let title;
        if (query.length < characterLimit) title = `Please put ${characterLimit} or more characters`;
        else if (isLoadingPartsAcrossWholeTenant) title = 'Loading...';
        else if (hasAnyMatches) title = 'Existing part numbers:';
        else title = 'No matches found';
        return [
          {
            title,
            optionValue: '',
            type: 'NON_CLICKABLE',
          } as const,
          ...filtered,
        ];
      }}
      renderOption={(props, option) => {
        if (option.type === 'NON_CLICKABLE') {
          return (
            <EditPartsAutoCompleteOptionsTitle key={option.title}>{option.title}</EditPartsAutoCompleteOptionsTitle>
          );
        }
        if (option.type === 'CREATE_NEW') {
          return (
            <EditPartsAutoCompleteAddNewPartOption {...props} data-testid="assignment-dropdown-new-part-option">
              <FontAwesomeIcon icon={faCirclePlus} size="lg" />
              &nbsp;{` ${query} (New part number)`}
            </EditPartsAutoCompleteAddNewPartOption>
          );
        }
        return (
          <EditPartsAutoCompleteOption
            text={option.title}
            key={option.optionValue}
            regex={new RegExp(query, 'ig')}
            highlightColour="#fc5504"
            data-testid="assignment-dropdown-existing-part-option"
            {...props}
          />
        );
      }}
      PopperComponent={EditPartsAutoCompletePopper}
    />
  );
}

const EditPartsAutoCompleteOptionsTitle = styled(MUITypography)`
  background-color: ${({ theme }) => theme.palette.grey[800]};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
`;

const EditPartsAutoCompleteAddNewPartOption = styled(MUITypography)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[100]};
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[800]};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 0.5rem;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => darken(theme.palette.grey[800], 0.05)};
  }
`;

const EditPartsAutoCompleteOption = styled(Highlight)`
  background-color: ${({ theme }) => theme.palette.grey[800]};
  color: ${({ theme }) => theme.palette.common.white};
`;

const EditPartsAutoCompletePopper = styled(Popper)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.grey[800]};
  }
  .MuiAutocomplete-listbox {
    padding: 0;
  }
`;
