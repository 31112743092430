import { faChevronLeft, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useCurrentTabName } from 'src/hooks/useCurrentTabName';
import { useProduct } from 'src/providers/Product';
import { useQueryString } from 'src/hooks/useQueryString';
import filterProductOptionsFromParents from 'src/services/filterProductOptionsFromParents';
import { usePartNavigation } from 'src/services/partNavigation';
import { workInstructionsLink } from 'src/utils/urls';
import { Link } from 'react-router-dom';
import { StyledHeader } from './StyledHeader';
import { Title } from './Title';
import { preventNavDrawerTrigger } from '.';

interface TitleBoxProps {
  openSearch: (e: React.MouseEvent<any>) => void;
  isExpanded: boolean;
}

export function TitleBox({ openSearch, isExpanded }: TitleBoxProps) {
  // This will need to be updated when we support work instructions too.
  const { pathname: currentPathname, search: tagUrlSection } = useLocation();
  const currentlySelectedRepairTagId = useQueryString('tag');

  const showDrawerIcons = isExpanded;
  const { parents } = usePartNavigation();
  const { productId } = useProduct();
  const currentTab = useCurrentTabName();

  const partsCatalogueBackUrl = useMemo(() => {
    const filteredProductOptions = filterProductOptionsFromParents(parents);
    const parent = filteredProductOptions[filteredProductOptions.length - 1];
    return parent?.url || '/';
  }, [parents]);

  const workInstructionsBackUrl = useMemo(() => {
    const wiRootPathname = workInstructionsLink(productId);

    const isTheUserOnWIRootUrl = currentPathname === wiRootPathname;
    const isTheUserOutsideTagsList = isTheUserOnWIRootUrl && !currentlySelectedRepairTagId;
    const isTheUserInsideTagsList = isTheUserOnWIRootUrl && currentlySelectedRepairTagId;

    let workInstructionsBackURL;

    if (isTheUserOutsideTagsList) {
      workInstructionsBackURL = '/';
    } else if (isTheUserInsideTagsList) {
      workInstructionsBackURL = wiRootPathname;
    } else {
      workInstructionsBackURL = wiRootPathname.concat(tagUrlSection);
    }

    return workInstructionsBackURL;
  }, [currentPathname, productId, tagUrlSection, currentlySelectedRepairTagId]);

  const backLink = currentTab === 'parts-catalogue' ? partsCatalogueBackUrl : workInstructionsBackUrl;

  return (
    <StyledHeader>
      {showDrawerIcons && (
        <BackLink to={backLink} onClick={preventNavDrawerTrigger} data-testid="drawer-backlink">
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </BackLink>
      )}

      <CenteredBlock>
        <Puller />
        <Title />
      </CenteredBlock>

      {showDrawerIcons && <SearchIcon onClick={openSearch} />}
    </StyledHeader>
  );
}

function SearchIcon(props: React.ComponentProps<any>) {
  return (
    <FontAwesomeIcon id="search-icon" data-testid="drawer-searchicon" icon={faMagnifyingGlass} size="lg" {...props} />
  );
}

const CenteredBlock = styled('div')`
  min-width: 0;
  margin: auto;
`;

const Puller = styled('div')`
  width: 30px;
  margin: auto;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  height: 6px;
  border-radius: 3px;
  top: 8px;
`;

const BackLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
