import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';

import { border } from 'src/constants/borderSizes';
import { StyledMUIButtonLink } from 'src/components/Button';

import MUIInputLabel from '@mui/material/InputLabel';
import MUIMenuItem from '@mui/material/MenuItem';

import { StyledSelectField } from 'src/components/Select';
import { LinkProps } from 'react-router-dom';
import { device } from 'src/constants/mediaQueries';

export const HeaderContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${border.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  padding: 1rem;
`;

export const HeaderContainerLeftSide = styled('div')`
  display: flex;
  align-items: center;
  width: 50%;
`;
export const HeaderContainerRightSide = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

export const CategoryHeaderTitle = styled('div')`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CategoryHeaderBackButton = styled(StyledMUIButtonLink)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin: 1rem;
  min-width: fit-content;

  @media ${device.mobileOrTablet} {
    margin: 1rem 0;
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;
export const CategoryHeaderDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  border-color: ${({ theme }) => theme.palette.grey[400]};
  border-width: 1px;
  width: 1px;
  margin: 0.5rem;
  height: 45px;
`;

export const CategoryHeaderSelectLabel = styled(MUIInputLabel)`
  color: ${({ theme }) =>
    theme.palette.primary.contrastText} !important; // important, should always be contrast text in all states
`;
CategoryHeaderSelectLabel.defaultProps = { required: false };

export const CategoryHeaderSelectField = styled(StyledSelectField)`
  height: 55px;
  width: 100%;
  &.MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &.MuiFilledInput-root::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  &.MuiFilledInput-root::after:not(error) {
    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[900]};
  }
`;

export const CategoryHeaderSelectOption = styled(MUIMenuItem)<LinkProps>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.light};
`;
