import { useEffect } from 'react';
import { Mesh } from 'three';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import { invalidate } from '@react-three/fiber';
import { BlendFunction } from 'postprocessing';

type Props = { selected: Mesh[]; enabled: boolean };

const outlineColor = '#C16D00' as unknown as number; // postprocessing type is wrong, it accepts strings just right

export function OutlineEffect({ selected, enabled }: Props) {
  useEffect(invalidate);
  return (
    <EffectComposer autoClear={false} enabled={enabled}>
      <Outline
        blur
        selection={selected.map((s) => ({ current: s }))} // Normally works on refs, but we happen to have object references in fade model
        visibleEdgeColor={outlineColor}
        hiddenEdgeColor={outlineColor}
        edgeStrength={10}
        width={1300}
        xRay
        blendFunction={BlendFunction.ALPHA}
      />
    </EffectComposer>
  );
}
