import MUIList from '@mui/material/List';
import { CartEntry } from 'src/providers/ShoppingCart';
import CartItem from './CartItem';
import EmptyCart from './EmptyCart';
import { ListHeightContainer } from './style';

interface CartItemListProps {
  cart: CartEntry[];
}

export default function CartItemsList({ cart }: CartItemListProps) {
  return (
    <ListHeightContainer>
      {cart.length ? (
        <MUIList>
          {cart.map((cartItem) => (
            <CartItem key={cartItem.partNumber} cart={cart} cartItem={cartItem} />
          ))}
        </MUIList>
      ) : (
        <EmptyCart />
      )}
    </ListHeightContainer>
  );
}
