import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import CartItemsList from 'src/components/ShoppingCart/CartItemsList';
import EmptyCart from 'src/components/ShoppingCart/EmptyCart';
import useShoppingCartMutation from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { postShopifyOrder } from 'src/services/api';
import { BackButton } from '../Button';
import { FullscreenLoadingSpinner } from '../Loading/FullscreenLoadingSpinner';
import { CheckoutStepper } from '../Stepper';
import { Toast } from '../Toast';
import { ActionBar } from './ActionBar';
import { CartItemsListContainer } from './styles';

export default function ShoppingCartPage() {
  const { isFetching, data: cart, refetch } = useShoppingCartQuery();
  const { mutate: mutateCart } = useShoppingCartMutation();
  const { goBack } = useHistory();

  const clearCart = () => mutateCart([]);

  const history = useHistory();

  const {
    data: response,
    mutate: createShopifyOrder,
    isError,
    error,
    isLoading: isCreatingOrder,
  } = useMutation(postShopifyOrder, {
    onSuccess: clearCart,
  });

  // If order is shopify, redirect to checkout page
  // In this case first obtain the checkout url from the backend
  // and then open it in a new tab
  const handleOrder = () => {
    const isShopifyOrder = cart?.some((item) => item.eCommerceId);
    if (isShopifyOrder) {
      // TODO add GTM event dispatch
      createShopifyOrder({
        items: cart.filter(
          // All items must have an eCommerceId
          (item) => item.eCommerceId
        ),
      });
    } else {
      // redirect to checkout page on SVT flow
      history.push('/checkout/shipping-info');
    }
  };

  const isCartEmpty = cart.length === 0;

  const checkoutUrl = response && response.data.shopifyCheckoutUrl;

  
  // open checkout url in new tab
  // `isCartEmpty` to make sure that the cart is empty before redirecting
  const redirectToShopify = checkoutUrl && isCartEmpty;
  if (redirectToShopify) {
    window.location.href = checkoutUrl;
  }

  useEffect(() => {
    if (cart) refetch(); // make sure the data is the most accurate when checking out
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching || redirectToShopify) return <FullscreenLoadingSpinner />;

  const cartHasItems = cart.length !== 0;
  return (
    <Background>
      <ShoppingCartContainer>
        {cartHasItems ? (
          <>
            <BackButton onClick={goBack} />
            <CheckoutStepper currentStep={0} />
          </>
        ) : null}

        <CartItemsListContainer cartHasItems={cartHasItems}>
          <Title>{cartHasItems ? 'Your shopping cart' : 'Your shopping cart is empty'}</Title>
          {cartHasItems ? (
            <>
              <CartItemsList cart={cart} />
              <ActionBar cart={cart} clearCart={clearCart} isLoading={isCreatingOrder} handleOrder={handleOrder} />
            </>
          ) : (
            <EmptyCart />
          )}
        </CartItemsListContainer>
      </ShoppingCartContainer>
      <Toast dependency={isError} severity="error" title="" message={`${error}`} />
    </Background>
  );
}

const Background = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
`;

const Title = styled(MUITypography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-top: 5%;
`;
Title.defaultProps = { variant: 'h4' };

export const ShoppingCartContainer = styled('div')`
  color: ${({ theme }) => theme.palette.grey[900]};
  min-width: 400px;
  margin: 4rem 20%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
