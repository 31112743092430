const checkForIframe = () => {
  if (window.location !== window.parent.location) {
    // The page is in an iframe
    return true;
  }
  // The page is not in an iframe
  return false;
};

export const isWithinIframe = checkForIframe();
