export const can = (user: { permissions: Record<string, string[]> }, action: string, domain: string) => {
  if (!user) return false;
  const { permissions } = user;
  if (!permissions) return false;

  const rules = permissions[domain];
  if (!rules) return false;

  return rules.includes(action);
};
