import { AccountTag } from '@samsonvt/shared-types/accountsTable';
import { useLocation } from 'react-router';
import { StyledRepairListItem, StyledRepairName, TagButton, SecondaryDivider } from './styles';

export function TagList(props: { tags: AccountTag[] }) {
  const { tags } = props;
  const { pathname } = useLocation();
  return (
    <>
      {tags.map((tag) => (
        <div key={tag.id}>
          <StyledRepairListItem data-testid="tag-element">
            <div>
              <StyledRepairName>{tag.name}</StyledRepairName>
            </div>
            <TagButton to={`${pathname}?tag=${tag.id}`} data-testid={`tag-element-button-${tag.id}`}>
              View
            </TagButton>
          </StyledRepairListItem>
          <SecondaryDivider />
        </div>
      ))}
    </>
  );
}
