import { Theme } from '@mui/material';
import { History } from 'history';
import { Hotspot } from 'src/services/partNavigation';
import { FocalPoint } from './FocalPoint';

interface FocalPointRendererProps {
  history: History;
  hotspots: Hotspot[];
  theme: Theme;
}

export function FocalPointRenderer({ history, hotspots, theme }: FocalPointRendererProps) {
  return (
    <>
      {hotspots.map(({ url, position, name }) => (
        <FocalPoint
          key={url}
          position={position}
          label={name}
          onClick={() => history.push(url)}
          attenuateSize={false}
          scale={0.8}
          theme={theme}
        />
      ))}
    </>
  );
}
