import { styled } from '@mui/material';
import MUIModal from '@mui/material/Modal';

import { spacing } from 'src/constants/spacing';

interface IProps {
  isOpen: boolean;
  children: any;
}

const modalWidth = '36.25rem';

const ModalStyles = styled('div')`
  width: ${modalWidth};
  background: ${({ theme }) => theme.palette.common.white};
  padding: ${spacing.largeSpacing};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export function Modal({ isOpen, children }: IProps): JSX.Element {
  return (
    <MUIModal open={isOpen}>
      <ModalStyles>{children}</ModalStyles>
    </MUIModal>
  );
}
