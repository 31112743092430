import { styled } from '@mui/material';
import MUIDialog from '@mui/material/Dialog';
import MUITypography from '@mui/material/Typography';
import { PartDetails } from '@samsonvt/shared-types/partService';
import { StyledMUIButton } from '../Button';
import { CircleXButton } from './CircleXButton';
import { FlexColumn, StyledMUIDialogActions } from './styles';

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
  originalPartDetails: PartDetails;
  numberOfOccurrences: number;
  setUserExtractionDecision: (value: 'EXTRACT' | 'EDIT_TOGETHER') => void;
  handleExtraction: () => void;
};

export function ExtractionDecisionModal({
  modalOpen,
  handleClose,
  originalPartDetails,
  handleExtraction,
  numberOfOccurrences,
  setUserExtractionDecision,
}: Props) {
  return (
    <MUIDialog
      fullWidth
      PaperProps={{ style: { overflow: 'visible', maxWidth: 749 } }}
      onClose={handleClose}
      open={modalOpen}
      data-testid="edit-parts-extraction-decision-modal"
    >
      <CircleXButton onClick={handleClose} />
      <TextContainer>
        <FlexColumn>
          <MUITypography>
            <TitleText>{originalPartDetails.part.name}</TitleText>
            <PartNumberText> ({originalPartDetails.part.number})</PartNumberText> is used {numberOfOccurrences} times in
            various places and models. You have the option to remove this part from the rest or make changes to all the{' '}
            {numberOfOccurrences} parts together.
          </MUITypography>
          <MUITypography>
            {`Keep in mind that removing this specific part will also erase all the information associated with it. If you
            want to make any changes to this part afterward, you'll need to add a part number first.`}
          </MUITypography>
        </FlexColumn>
      </TextContainer>

      <PromptContainer>
        <PromptText>What do you want to do?</PromptText>
      </PromptContainer>
      <StyledMUIDialogActions>
        <StyledMUIButton color="secondary" variant="contained" size="large" type="button" onClick={handleExtraction}>
          Remove this part from the rest
        </StyledMUIButton>
        <StyledMUIButton
          color="secondary"
          variant="contained"
          size="large"
          type="button"
          onClick={() => setUserExtractionDecision('EDIT_TOGETHER')}
        >
          Edit all {numberOfOccurrences} parts together
        </StyledMUIButton>
      </StyledMUIDialogActions>
    </MUIDialog>
  );
}

const PromptContainer = styled('div')`
  padding: 1rem 2rem 0 2rem;
  background-color: ${({ theme }) => theme.palette.grey[900]};
`;

const TextContainer = styled('div')`
  padding: 2rem;
  margin: 0 2rem 0rem 1rem;
`;

const TitleText = styled('span')`
  font-weight: bold;
  font-size: 1.3rem;
`;

const PromptText = styled('span')`
  font-weight: bold;
  font-size: 1.3rem;
  color: white;
`;

const PartNumberText = styled('span')`
  font-weight: bold;
  font-size: 1.1rem;
`;
