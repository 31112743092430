import { alpha, styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import MUIDivider from '@mui/material/Divider';

export const ChangeYourPasswordContainer = styled('form')`
  margin-top: 2rem;
`;

export const ChangeYourPasswordSectionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

export const ChangeYourPasswordDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.16)};
  height: 2px;
`;

ChangeYourPasswordDivider.defaultProps = {
  orientation: 'horizontal',
};

export const ChangeYourPasswordLeftSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
`;

export const ChangeYourPasswordRightSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
`;

export const RequirementBoxContainer = styled('div')`
  background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.16)};
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const ChangeYourPasswordContentContainer = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  border-radius: 4px;
  padding: 2rem;
`;

export const ChangeYourPasswordTitle = styled(MUITypography)`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const ChangeYourPasswordSubTitle = styled(MUITypography)`
  font-size: 1rem;
`;
