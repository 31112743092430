import { MainTemplate } from 'src/templates/MainTemplate';
import ShoppingCart from 'src/components/ShoppingCart';
import { useMediaQuery } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import { Redirect } from 'react-router';

export default function ShoppingCartPage() {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  return <MainTemplate>{isMobile ? <Redirect to="/404" /> : <ShoppingCart />}</MainTemplate>;
}
