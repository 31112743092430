import { styled, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import { spacing } from 'src/constants/spacing';

export const NavList = styled('ul')`
  margin-bottom: ${spacing.mediumSpacing};
`;

export const NavLink = styled(Link)<NavProps>`
  width: 100%;
  padding: ${spacing.mediumSpacing};
  overflow-wrap: anywhere;

  &:first-of-type {
    border-top-right-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s all ease-in-out;

  span {
    word-break: break-all;
    padding-right: ${spacing.mediumSpacing};
  }

  &:hover {
    background: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.3)};
  }
  color: ${({ theme, to }) =>
    to.state && to.state.isActive ? theme.palette.grey['200'] : theme.palette.sidebarBackground.contrastText};
`;

interface NavProps {
  to: {
    pathname?: string;
    state?: {
      isActive?: boolean | undefined;
      activeColour?: string | undefined;
      colour?: string | undefined;
    };
  };
}
