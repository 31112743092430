
export const border = {
  xSmall: '1px',
  small: '2px',
  medium: '5px',
  large: '10px',
  xLarge: '50px',
} as const;

export const radius = {
  xSmall: '0.625rem',
  small: '0.125rem',
  medium: '0.3125rem',
  large: '0.625rem',
  xLarge: '3.125rem',
} as const;

export const shadow = {
  xSmall: '1px',
  small: '3px',
  medium: '6px',
  large: '9px',
  xLarge: '20px',
} as const;
