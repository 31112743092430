import { Attributes } from '@samsonvt/shared-types/partsTable';

export function filterCustomAttributesToShow(attributes: Attributes | undefined) {
  if (!attributes) {
    return undefined;
  }
  const attributesWithoutEmptyFields = { ...attributes };
  Object.keys(attributes).forEach((key) => {
    if (!attributes[key]) {
      delete attributesWithoutEmptyFields[key];
    }
  });

  return attributesWithoutEmptyFields;
}
