import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { Icon } from 'src/components/Icon';
import { RepairSection } from 'src/components/Product/Desktop/Panels/WorkInstructions/RepairSection';
import { workInstructionsLink } from 'src/utils/urls';
import { TabPanelElement } from 'src/components/Product/Shared/TabPanelElement';
import { TrackedRedirect } from 'src/components/TrackedRedirect';
import { useProduct } from 'src/providers/Product';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import { isWithinIframe } from 'src/services/checkForIframe';
import { BackChevron, DesktopWorkInstructionsBackLink, Header, RepairTitle } from './styles';

const StepList = styled('ol')`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
`;

export function RepairSections() {
  const repairDetails = useContext(RepairDetailContext);
  const { search: tagUrlSection } = useLocation();

  const { name: productName, productId } = useProduct();

  const repairsListUrl = `${workInstructionsLink(productId)}${tagUrlSection}`;

  if (!repairDetails) return null;

  const {
    repair: { sections, repairId, name },
    stepNumber: currentStepNumber,
    sectionNumber: currentSectionNumber,
  } = repairDetails;
  const isOneWorkingInstruction = sections.length === 1;

  if (Number.isNaN(currentSectionNumber) || currentSectionNumber > sections.length - 1) {
    return <TrackedRedirect to={repairsListUrl} />;
  }
  if (Number.isNaN(currentStepNumber) || currentStepNumber > sections[currentSectionNumber].steps.length - 1) {
    return <TrackedRedirect to={`${repairsListUrl}/${repairId}/${currentSectionNumber}/0`} />;
  }

  return (
    <TabPanelElement>
      <DesktopWorkInstructionsBackLink to={repairsListUrl} data-testid="repair-back-button">
        <RepairTitle>
          {!isWithinIframe && (
            <BackChevron>
              <Icon name="arrow-left" />
            </BackChevron>
          )}
          <Header>
            <MUITypography variant="h5" data-testid="repair-name">
              {name}
            </MUITypography>

            <MUITypography variant="body1" data-testid="product-name">
              for <strong>{productName}</strong>
            </MUITypography>
          </Header>
        </RepairTitle>
      </DesktopWorkInstructionsBackLink>
      <StepList>
        {sections.map((section, index) => (
          <RepairSection
            key={section.url}
            section={section}
            index={index}
            currentSectionNumber={currentSectionNumber}
            currentStepNumber={currentStepNumber}
            isOneWorkingInstruction={isOneWorkingInstruction}
          />
        ))}
      </StepList>
    </TabPanelElement>
  );
}
