import { styled, alpha } from '@mui/material';
import MUIBox from '@mui/material/Box';

export const ListFade = styled(MUIBox)`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.sidebarBackground.main} 0%,
      ${({ theme }) => alpha(theme.palette.sidebarBackground.main, 0)} 100%
    );
    pointer-events: none;
  }
`;
