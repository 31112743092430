import axios from 'axios';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { getOrders } from 'src/services/api';
import { useQuery } from '@tanstack/react-query';

import { useMediaQuery } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import { FilterTypes, getSortingFunc } from '../../filters';
import DesktopMyOrdersTable from './Desktop/DesktopMyOrdersTable';
import MobileMyOrdersTable from './Mobile/MobileMyOrdersTable';

export default function MyOrdersTableLogic() {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  const [timeFilterStatus, setTimeFilterStatus] = useState(FilterTypes.MOST_RECENT);
  const [orderIdSearchFilter, setOrderIdSearchFilter] = useState('');

  const receivedOrdersQuery = () => {
    const { token } = axios.CancelToken.source();
    return getOrders(token);
  };

  const {
    isLoading: isLoadingOrders,
    data: unFilteredOrders,
    isError: isErrorLoadingOrders,
  } = useQuery(['receivedOrdersList'], receivedOrdersQuery);

  const sortFunc = getSortingFunc(timeFilterStatus);
  const filteredOrders = [...(unFilteredOrders || [])]
    .sort(sortFunc)
    .filter((order) => order.orderId.includes(orderIdSearchFilter));

  const areThereOrdersWithSearchCriteria = filteredOrders.length !== 0;
  const isTheTableEmpty = !isLoadingOrders && !areThereOrdersWithSearchCriteria;
  const areTheOrdersAllLoaded = areThereOrdersWithSearchCriteria;

  const handleTimeFilter = (e: SelectChangeEvent<unknown>) => {
    setTimeFilterStatus(e.target.value as string);
  };

  const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderIdSearchFilter(e.target.value.toUpperCase());
  };

  return isMobile ? (
    <MobileMyOrdersTable
      filteredOrders={filteredOrders}
      areTheOrdersAllLoaded={areTheOrdersAllLoaded}
      isErrorLoadingOrders={isErrorLoadingOrders}
      isLoadingOrders={isLoadingOrders}
      isTheTableEmpty={isTheTableEmpty}
      timeFilterStatus={timeFilterStatus}
      orderIdSearchFilter={orderIdSearchFilter}
      handleTimeFilter={handleTimeFilter}
      handleSearchFilter={handleSearchFilter}
    />
  ) : (
    <DesktopMyOrdersTable
      filteredOrders={filteredOrders}
      areTheOrdersAllLoaded={areTheOrdersAllLoaded}
      isErrorLoadingOrders={isErrorLoadingOrders}
      isLoadingOrders={isLoadingOrders}
      isTheTableEmpty={isTheTableEmpty}
      timeFilterStatus={timeFilterStatus}
      orderIdSearchFilter={orderIdSearchFilter}
      handleTimeFilter={handleTimeFilter}
      handleSearchFilter={handleSearchFilter}
    />
  );
}
