import React, { createContext, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useRouteMatch } from 'react-router-dom';
import type {} from // CancelToken

'axios';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import type { FetchStatus } from 'src/hooks/useFetch';
import { getRepairDetails } from 'src/services/api';
import type { ScreenName } from 'src/App';

import type {
  IRepairSectionView,
  IRepairStepView,
  IRepairView,
  SectionAfterRenaming,
  StepAfterRenaming,
} from '../types';

type RepairParams = {
  productId?: string;
  tab?: ProductFeature;
  detailId?: string;
  repairSection?: string;
  repairStep?: string;
};

export interface RepairDetails extends FetchStatus {
  repair: IRepairView;
  step: IRepairStepView;
  sectionNumber: number;
  stepNumber: number;
}

export const RepairDetailContext = createContext(undefined as RepairDetails | undefined);

export function RepairDetailsProvider({ children }: { children: React.ReactNode }) {
  const match = useRouteMatch<RepairParams>('/product-library/:productId/:tab/:detailId/:repairSection/:repairStep');

  const { productId, detailId, tab, repairSection, repairStep } = match?.params || {};

  const repairDetailsQuery = () => {
    const { token } = axios.CancelToken.source();
    return getRepairDetails(productId!, detailId!, token);
  };

  const enabled = !!productId && !!detailId && tab === 'work-instructions';

  const { data, isLoading, isError } = useQuery(['workInstruction', productId, detailId], repairDetailsQuery, {
    enabled,
  });

  const mapSections = useCallback(
    // Decorate repair with url's
    (sections: SectionAfterRenaming[]): IRepairSectionView[] => {
      const makeStepUrl = (
        section: number,
        step: number = 0,
        productLibraryPath: ScreenName = 'product-library',
        tabName: ProductFeature = 'work-instructions'
      ) => `/${productLibraryPath}/${productId}/${tabName}/${detailId}/${section}/${step}`;

      const mapSteps = (steps: StepAfterRenaming[], section: number): IRepairStepView[] =>
        steps.map((step, index) => ({
          ...step,
          url: makeStepUrl(section, index),
        }));
      return sections.map((section, index) => ({
        ...section,
        steps: mapSteps(section.steps, index),
        url: makeStepUrl(index),
      }));
    },
    [productId, detailId]
  );

  const value = useMemo(() => {
    if (!data || isLoading) {
      return undefined;
    }
    const repair = {
      ...data,
      sections: mapSections(data.sections),
    };

    const sectionNumber = Number(repairSection);
    const stepNumber = Number(repairStep);

    return {
      repair,
      step: repair.sections[sectionNumber]?.steps[stepNumber],
      sectionNumber,
      stepNumber,
      loading: isLoading,
      error: isError,
    };
  }, [data, isLoading, isError, mapSections, repairSection, repairStep]);

  return <RepairDetailContext.Provider value={value}>{children}</RepairDetailContext.Provider>;
}
