import RequirementsBoxText, { PasswordGroup } from 'src/components/PasswordRequirementsBox.tsx';
import { LoginPasswordInput } from 'src/components/TextInput/LoginPasswordInput';
import {
  DesktopResetPasswordRequirements,
  FormDivider,
  StyledSendResetPasswordFormButton,
  TemporaryPasswordReminderNote,
} from '../styles';
import { CreatePasswordWithTempCredentialsFormProps } from '../types';

export function DesktopCreatePasswordWithTempCredentialsForm({
  error,
  onButtonClick,
  createPasswordWithTempCredentialsFormData,
  setCreatePasswordWithTempCredentialsFormData,
  checkPasswordsMatch,
}: CreatePasswordWithTempCredentialsFormProps) {
  const { password, confirmPassword, oldPassword } = createPasswordWithTempCredentialsFormData;

  return (
    <form onSubmit={onButtonClick(createPasswordWithTempCredentialsFormData)}>
      <div>
        <LoginPasswordInput
          label="Temporary password"
          testId="temporary-password"
          value={oldPassword}
          error={error}
          onChange={(e: any) =>
            setCreatePasswordWithTempCredentialsFormData((prev) => ({ ...prev, oldPassword: e.target.value }))
          }
        />
      </div>
      <TemporaryPasswordReminderNote>
        Note: You have received your temporary password in the invitation email
      </TemporaryPasswordReminderNote>
      <FormDivider />
      <PasswordGroup>
        <DesktopResetPasswordRequirements>
          <RequirementsBoxText />
        </DesktopResetPasswordRequirements>
        <div>
          <LoginPasswordInput
            label="Password"
            testId="password"
            value={password}
            error={!!error}
            onChange={(e: any) =>
              setCreatePasswordWithTempCredentialsFormData((prev) => ({ ...prev, password: e.target.value }))
            }
            match={checkPasswordsMatch()}
          />
        </div>

        <div>
          <LoginPasswordInput
            label="Confirm password"
            testId="confirm-password"
            value={confirmPassword}
            error={!!error}
            onChange={(e: any) =>
              setCreatePasswordWithTempCredentialsFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))
            }
            match={checkPasswordsMatch()}
          />
        </div>
      </PasswordGroup>

      <StyledSendResetPasswordFormButton
        type="submit"
        data-testid="reset-form-submit"
        disabled={
          !checkPasswordsMatch() ||
          Object.values(createPasswordWithTempCredentialsFormData).some((value) => value === '')
        }
      >
        Confirm new password
      </StyledSendResetPasswordFormButton>
    </form>
  );
}
