import React, { useState } from 'react';
import { Search } from './Search';
import { TitleBox } from './TitleBox';

interface DrawerHeaderProps {
  fullscreen: boolean;
  isExpanded: boolean;
}

export function DrawerHeader({ fullscreen, isExpanded }: DrawerHeaderProps) {
  const [searchActive, setSearchActive] = useState(false);

  const openSearch = (e: React.MouseEvent<any>) => {
    preventNavDrawerTrigger(e);
    setSearchActive(true);
  };

  const closeSearch = (e: React.MouseEvent<any> | undefined) => {
    if (e) preventNavDrawerTrigger(e); // When choosing an option from search no event is passed
    setSearchActive(false);
  };

  return (
    <>
      {searchActive ? (
        <Search closeSearch={closeSearch} fullscreen={fullscreen} />
      ) : (
        <TitleBox openSearch={openSearch} isExpanded={isExpanded} />
      )}
    </>
  );
}

// Stops the click bubbling up to the parent and triggering the whole
// drawer motion.
export const preventNavDrawerTrigger = (e: React.MouseEvent<any>) => {
  e.stopPropagation();
};
