import { styled } from '@mui/material';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import { CreateDiscountSchemeBody, DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { TableHeader } from 'src/components/Table/MUITableStyles';
import { Toast } from 'src/components/Toast';
import { DiscountSchemeResponse, getDiscountSchemes, postDiscountScheme } from 'src/services/api';
import { CreateDiscountSchemeForm } from './CreateDiscountSchemeForm';
import DiscountSchemeTableRow from './DiscountSchemeTableRow';
import EmptyDiscountTable from './EmptyDiscountTable';

interface DiscountManagementTableProps {
  openCreateDiscountSchemeForm: boolean;
  setOpenCreateDiscountSchemeForm: Dispatch<SetStateAction<boolean>>;
}

export default function DiscountManagementTableQueryManager({
  openCreateDiscountSchemeForm,
  setOpenCreateDiscountSchemeForm,
}: DiscountManagementTableProps) {
  const queryClient = useQueryClient();

  const discountSchemesQuery = async () => getDiscountSchemes();
  const { isLoading, data: discountSchemesList, isError } = useQuery(['discountSchemesList'], discountSchemesQuery);

  const postDiscountSchemeMutation = useMutation(postDiscountScheme, {
    onSuccess: (_res) => {
      setOpenCreateDiscountSchemeForm(false);
      queryClient.invalidateQueries(['discountSchemesList']);
    },
  });

  return (
    <>
      {isLoading ? (
        <FullscreenLoadingSpinner />
      ) : (
        <DiscountManagementTable
          openCreateDiscountSchemeForm={openCreateDiscountSchemeForm}
          setOpenCreateDiscountSchemeForm={setOpenCreateDiscountSchemeForm}
          discountSchemesList={discountSchemesList}
          postDiscountSchemeMutation={postDiscountSchemeMutation}
        />
      )}
      <Toast dependency={isError} severity="error" title="Error" message="Could not load the discount schemes" />
      <Toast
        dependency={postDiscountSchemeMutation.data}
        severity="success"
        title="Success"
        message={`Successfully created ${postDiscountSchemeMutation.data?.data.name}`}
      />
      <Toast
        dependency={postDiscountSchemeMutation.isError}
        severity="error"
        title="Server error"
        message={`Error creating discount. ${postDiscountSchemeMutation.error}`}
      />
    </>
  );
}

function DiscountManagementTable({
  openCreateDiscountSchemeForm,
  setOpenCreateDiscountSchemeForm,
  postDiscountSchemeMutation,
  discountSchemesList,
}: DiscountManagementTableProps & {
  discountSchemesList: DiscountScheme[] | undefined;
  postDiscountSchemeMutation: UseMutationResult<DiscountSchemeResponse, unknown, CreateDiscountSchemeBody, unknown>;
}) {
  const isTableEmpty = !discountSchemesList?.length && !openCreateDiscountSchemeForm;

  if (isTableEmpty) {
    return <EmptyDiscountTable />;
  }

  return (
    <DiscountManagementTableStyles>
      <MUITable>
        <MUITableHead>
          <MUITableRow>
            <TableHeader sx={{ width: '33%' }}>Discount name</TableHeader>
            <TableHeader sx={{ width: '33%' }}>Discount percentage</TableHeader>
            <TableHeader sx={{ width: '33%' }}> </TableHeader>
          </MUITableRow>
        </MUITableHead>
        <MUITableBody>
          {openCreateDiscountSchemeForm && (
            <CreateDiscountSchemeForm
              mutation={postDiscountSchemeMutation}
              cancelCreate={() => setOpenCreateDiscountSchemeForm(false)}
            />
          )}
          {discountSchemesList?.map((scheme) => (
            <DiscountSchemeTableRow key={scheme.id} scheme={scheme} />
          ))}
        </MUITableBody>
      </MUITable>
    </DiscountManagementTableStyles>
  );
}

export const DiscountManagementTableStyles = styled(MUITableContainer)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  margin-bottom: 5rem;
  width: 40%;
  min-width: 40rem;
  align-self: center;
`;
