import { PartNode } from '@samsonvt/shared-types/productLambda';
import type { PartPrice } from '@samsonvt/shared-types/partsTable';

export function isPartBuyable(price: PartPrice): price is NonNullable<PartPrice> {
  return price !== undefined;
}

export type PartWithPrice = PartNode & { price: NonNullable<PartPrice> };
/**
 * If the part has a price, it is buyable.
 */
export function hasPrice(currentPart: PartNode | undefined): currentPart is PartWithPrice {
  return currentPart?.price !== undefined && (typeof currentPart.price === 'number' || currentPart.price === 'por');
}
