import { crc32 } from 'crc';
import type { PartNode } from '@samsonvt/shared-types/productLambda';

type PathHash = string;
export interface PartContext {
  group?: PartNode;
  parents: PartNode[];
}

export interface PartPath extends PartContext {
  part: PartNode;
}

export interface PartPathWithHash extends PartPath {
  pathHash: PathHash;
}

export const hash = (parts: string[]) => {
  const path = parts.join('/');
  return crc32(path).toString(16);
};

export const computeHash = (partPath: PartPath): string => {
  const pathToFocal = partPath.part.focal ? [...partPath.parents, partPath.part] : partPath.parents;
  const ids = pathToFocal.map((x) => x.id);
  return ids.length ? hash(ids) : '';
};
