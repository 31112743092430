import type { Order } from '@samsonvt/shared-types/orderLambda';

export const FilterTypes = {
  MOST_RECENT: 'Most recent',
  OLDEST: 'Oldest',
  ALL_ORDERS: 'All orders',
  ACTIVE_ORDERS: 'Active orders',
  COMPLETED_ORDERS: 'Completed orders',
};

const mostRecentFilter = (prevOrder: Order, nextOrder: Order) => {
  const nextDate = new Date(nextOrder.timestamp);
  const prevDate = new Date(prevOrder.timestamp);
  return (nextDate as any) - (prevDate as any);
};
const oldestFilter = (prevOrder: Order, nextOrder: Order) => {
  const nextDate = new Date(nextOrder.timestamp);
  const prevDate = new Date(prevOrder.timestamp);
  return (prevDate as any) - (nextDate as any);
};
const activeFilter = (order: Order) => order.status === 'ACTIVE';
const completedFilter = (order: Order) => order.status === 'COMPLETE';
const doNotFilter = (order: Order) => order;

export function getSortingFunc(filterType: string) {
  switch (filterType) {
    case FilterTypes.MOST_RECENT:
      return mostRecentFilter;
    case FilterTypes.OLDEST:
      return oldestFilter;
    default:
      return mostRecentFilter;
  }
}

export function getFilterFunc(filterType: string) {
  switch (filterType) {
    case FilterTypes.ACTIVE_ORDERS:
      return activeFilter;
    case FilterTypes.COMPLETED_ORDERS:
      return completedFilter;
    case FilterTypes.ALL_ORDERS:
      return doNotFilter;
    default:
      return doNotFilter;
  }
}

export const filterOnOrderCompletion = (prevOrders: Order[] | undefined, completedOrderId: string) => {
  const completedOrder = prevOrders?.find((order: Order) => order.orderId === completedOrderId);
  const newOrdersList = prevOrders?.map((order: Order) => {
    if (completedOrder !== undefined && order === completedOrder) {
      completedOrder.status = 'COMPLETE';
      return completedOrder;
    }
    return order;
  });
  return newOrdersList;
};
