import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIListItemButton from '@mui/material/ListItemButton';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIListItemText from '@mui/material/ListItemText';
import MUITypography from '@mui/material/Typography';
import MUIList from '@mui/material/List';
import { faChevronDown, faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import type { History } from 'history';
import { useState } from 'react';
import { productFeatureNames } from 'src/constants/features';

interface MobileProductMenuListItemProps {
  productId: string;
  productName: string;
  productFeatures: ProductFeature[];
  selectedProduct?: boolean;
  history: History<unknown>;
  triggerMobileProductMenu: () => void;
}

export default function MobileProductMenuListItem({
  productId,
  productName,
  productFeatures,
  selectedProduct,
  history,
  triggerMobileProductMenu,
}: MobileProductMenuListItemProps) {
  const [expanded, setExpanded] = useState(selectedProduct);

  const navigateToFeature = (feature: ProductFeature) => () => {
    history.push(`/product-library/${productId}/${feature}`);
    triggerMobileProductMenu();
  };

  const triggerAccordion = () => setExpanded((prev) => !prev);
  const expandedLogic = selectedProduct ? selectedProduct && expanded : expanded; // if selected the accordion should open by default, if clicked it will close

  return (
    <StyledAccordion expanded={expandedLogic} onClick={productFeatures && triggerAccordion}>
      <StyledAccordionSummary expandIcon={<StyledIcon icon={faChevronDown} />}>
        <StyledProductTitle>{productName}</StyledProductTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <StyledList>
          {productFeatures.length ? (
            productFeatures.map((feature) => {
              const currentProductFeature = history.location.pathname.includes(feature);
              const shouldHighlight = selectedProduct && currentProductFeature;
              return (
                <StyledListItem selected={shouldHighlight} onClick={navigateToFeature(feature)} key={feature}>
                  <StyledListIcon>
                    <StyledIcon icon={shouldHighlight ? faCircleSolid : faCircleLight} height="10px" width="10px" />
                  </StyledListIcon>
                  <StyledListText>{productFeatureNames[feature]}</StyledListText>
                </StyledListItem>
              );
            })
          ) : (
            <StyledListItem>
              <StyledListText>No features found for this product</StyledListText>
            </StyledListItem>
          )}
        </StyledList>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

const StyledList = styled(MUIList)`
  padding: 0;
`;

const StyledAccordion = styled(MUIAccordion)`
  background-color: transparent;
  box-shadow: none;

  border-bottom: 1px solid ${({ theme }) => theme.palette.whiteGlass};

  &.Mui-expanded {
    margin: 0;
  }

  &::before {
    content: none;
  }
`;

const StyledAccordionSummary = styled(MUIAccordionSummary)`
  margin: 0.5rem 1rem;
  padding: 0;
  height: 3rem;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  &.Mui-expanded {
    min-height: 0;
    max-height: 2.5rem;
  }
`;

const StyledProductTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[100]};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey[100]};
`;

const StyledAccordionDetails = styled(MUIAccordionDetails)`
  padding: 0;
`;

const StyledListIcon = styled(MUIListItemIcon)`
  color: ${({ theme }) => theme.palette.grey[100]};
  margin-right: 1rem;
  min-width: 0px;
`;

const StyledListText = styled(MUIListItemText)`
  color: ${({ theme }) => theme.palette.grey[100]};

  .MuiTypography-root {
    font-size: 0.875rem;
  }
`;

const StyledListItem = styled(MUIListItemButton)`
  padding: 0 1rem;
  min-height: 2.8rem;

  &.Mui-selected {
    border-left: 4px solid ${({ theme }) => theme.palette.grey[100]};
    padding: 0 calc(1rem - 4px); // remove some padding because of the border
    background-color: transparent;
  }
`;
