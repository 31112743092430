import { styled } from '@mui/material';
import { border } from 'src/constants/borderSizes';
import { spacing } from 'src/constants/spacing';

export const EditorToolStyles = styled('div')`
  display: flex;
  position: absolute;
  width: 500px;
  gap: calc(${spacing.mediumSpacing}*2);
  top: ${spacing.mediumSpacing};
  right: ${spacing.mediumSpacing};
  padding: ${spacing.mediumSpacing};
  background: white;
  margin-left: ${spacing.xLargeSpacing};
  margin-top: ${spacing.largeSpacing};
  border: ${border.xSmall} solid red;
`;
