import { useEffect } from 'react';
import { Vector3 } from 'three';
import { Canvas } from '@react-three/fiber';
import { styled, useTheme } from '@mui/material';
import MUITypography from '@mui/material/Typography';

import CameraControls from 'camera-controls';
import InteractionCube from './InteractionCube';

interface IProps {
  cameraControls?: CameraControls;
  resetCamera: () => void;
}

export function ResetControls({ cameraControls, resetCamera }: IProps) {
  const theme = useTheme();

  useEffect(() => {
    const currentCameraControls = cameraControls;
    const cameraTarget = new Vector3();
    const cameraPosition = new Vector3();

    const onCameraPositionChange = () => {
      if (currentCameraControls) {
        currentCameraControls.getTarget(cameraTarget);
        currentCameraControls.getPosition(cameraPosition);
      }
    };

    onCameraPositionChange(); // Triger immediatly in case camera doesn't move initially
  }, [cameraControls]);

  return (
    <ControlsContainer onClick={resetCamera}>
      <HUDCanvas>
        {cameraControls ? (
          <Canvas camera={cameraControls.camera} linear flat>
            <InteractionCube
              sceneCamera={cameraControls.camera}
              hoverColour={theme.palette.primary.main}
              colour={theme.palette.primary.light}
            />
          </Canvas>
        ) : null}
      </HUDCanvas>
      Reset view
    </ControlsContainer>
  );
}

const ControlsContainer = styled(MUITypography)`
  text-align: center;
  -webkit-user-select: none;
`;
ControlsContainer.defaultProps = {
  variant: 'body2',
  as: 'div',
};

const HUDCanvas = styled('div')`
  width: 80px;
  height: 80px;
`;
