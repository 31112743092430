import { faLock, faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIPortal from '@mui/material/Portal';
import MUITypography from '@mui/material/Typography';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { getPartsAcrossWholeTenant } from 'src/services/api';
import { reactQueryGetErrorMessage } from 'src/utils/reactQueryGetErrorMessage';
import PartThumbnail from '../PartThumbnail';
import { Toast } from '../Toast';
import { AddToSupersessionsDecision } from './AddToSupersessionsDecision';
import { PartDetailsForm } from './EditModal';
import { EditPartsDropdown } from './EditPartsDropdown/EditPartsDropdown';
import {
  BlackBorder,
  FlexColumn,
  FlexRow,
  LockButton,
  PseudoInputLabel,
  PseudoInputPadding,
  StyledMUIDialogContent
} from './styles';

interface EditPartsPartNumberAssignmentFormProps {
  showPartDetailsForm: boolean;
  partDetailsForm?: PartDetailsForm;
  partNode: PartNode;
  initialPartNumber: string;
  hasAssociatedPartDetails: boolean;
  object3DReference: string;
  addPrevToSupersessions: boolean | undefined;
  showAddToSupersessionDecision: boolean;
  setNewPartNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setUserPressedContinue: Dispatch<SetStateAction<boolean>>;
  setPartDetailsForm: Dispatch<SetStateAction<PartDetailsForm | undefined>>;
  setSvtPartIDToAssignToANode: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAddPrevToSupersessions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export function EditPartsPartNumberAssignmentForm({
  showPartDetailsForm,
  partDetailsForm,
  partNode,
  initialPartNumber,
  object3DReference,
  showAddToSupersessionDecision,
  addPrevToSupersessions,
  setNewPartNumber,
  setUserPressedContinue,
  setSvtPartIDToAssignToANode,
  setAddPrevToSupersessions,
}: EditPartsPartNumberAssignmentFormProps) {
  const [query, setQuery] = useState(initialPartNumber);

  const showLock = Boolean(partDetailsForm);
  const isLocked = showPartDetailsForm;
  const handleLock = () => {
    setUserPressedContinue(!isLocked);
  };

  const getPartsAcrossWholeTenantQuery = ({ queryKey }: any) => {
    const [, query] = queryKey;
    const { token } = axios.CancelToken.source();
    return getPartsAcrossWholeTenant(query, 5, token);
  };

  const characterLimit = 3;

  const {
    isLoading: isLoadingPartsAcrossWholeTenant,
    isError: isErrorPartsAcrossWholeTenant,
    error: partsAcrossWholeTenantError,
    data: partsAcrossWholeTenantResponse,
  } = useQuery(['partsAcrossWholeTenant', query], getPartsAcrossWholeTenantQuery, {
    enabled: Boolean(query && query.length >= characterLimit),
  });

  return (
    <StyledMUIDialogContent
      onClick={(e) =>
        // SVT-1927 stops unintended selections on underlying <Canvas/>
        e.stopPropagation()
      }
      data-testid="assignment-form"
    >
      <FlexRow>
        <FlexColumn>
          <FlexRow>
            {partNode.children.length ? null : ( // don't show thumbnail if it is not a leaf node because we don't have any 3d mesh
              <BlackBorder>
                <PartThumbnail {...partNode} quantity={1} width={200} height={152} widthResizeConstant={40} />
              </BlackBorder>
            )}
            <FlexColumn>
              {showLock ? (
                <LockButton onKeyPress={handleLock} onClick={handleLock} role="button" tabIndex={0} data-testid="lock">
                  <FontAwesomeIcon icon={isLocked ? faLock : faLockOpen} />
                  Add new part number / add existing part number
                </LockButton>
              ) : null}
              {isLocked ? (
                <PseudoInputPadding>
                  <PseudoInputLabel>Part number</PseudoInputLabel>
                  <MUITypography>{initialPartNumber}</MUITypography>{' '}
                  {/* can be either the part number if it is already assigned or whatever was put into the assignment dropdown */}
                </PseudoInputPadding>
              ) : (
                <EditPartsDropdown
                  characterLimit={3}
                  query={query}
                  setQuery={setQuery}
                  setNewPartNumber={setNewPartNumber}
                  setSvtPartIDToAssignToANode={setSvtPartIDToAssignToANode}
                  partsAcrossWholeTenant={partsAcrossWholeTenantResponse?.partDetails || []}
                  isLoadingPartsAcrossWholeTenant={isLoadingPartsAcrossWholeTenant}
                />
              )}
              {showAddToSupersessionDecision ? (
                <AddToSupersessionsDecision
                  addPrevToSupersessions={addPrevToSupersessions}
                  initialPartNumber={initialPartNumber}
                  setAddPrevToSupersessions={setAddPrevToSupersessions}
                />
              ) : null}
              <PseudoInputPadding>
                <PseudoInputLabel>Object 3D reference</PseudoInputLabel>
                <MUITypography>{object3DReference}</MUITypography>
              </PseudoInputPadding>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
      <MUIPortal>
        <Toast
          dependency={isErrorPartsAcrossWholeTenant}
          severity="error"
          title="Error getting existing parts"
          message={reactQueryGetErrorMessage(partsAcrossWholeTenantError)}
        />
      </MUIPortal>
    </StyledMUIDialogContent>
  );
}
