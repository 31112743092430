import { useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { device } from 'src/constants/mediaQueries';
import { usePermissions } from 'src/hooks/usePermissions';
import { useUser } from 'src/providers/User';
import { MainTemplate } from 'src/templates/MainTemplate';
import { Background } from '../AccountDetailsPage/Desktop/MyProfile/styles';
import { DesktopMyAccountPage } from './Desktop/DesktopMyAccountPage';
import { MobileMyAccountPage } from './Mobile/MobileMyAccountPage';

export default function MyAccountPage() {
  const { logout } = useUser();
  const history = useHistory();
  const isMobile = useMediaQuery(device.mobileOrTablet);

  const canListUsers = usePermissions(['list', 'user']);
  const hasPermissionsToOperateOnAllOfTenantsOrders = usePermissions(
    ['list', 'order.history'],
    ['view', 'order.history'],
    ['update', 'order.history']
  );
  const canAddProducts = usePermissions(['add', 'product']);

  const logoutFromApp = async () => {
    await logout();
    history.push('/');
  };

  return (
    <MainTemplate>
      <Background>
        {isMobile ? (
          <MobileMyAccountPage
            canListUsers={canListUsers}
            hasPermissionsToOperateOnAllOfTenantsOrders={hasPermissionsToOperateOnAllOfTenantsOrders}
            canAddProducts={canAddProducts}
          />
        ) : (
          <DesktopMyAccountPage
            canListUsers={canListUsers}
            hasPermissionsToOperateOnAllOfTenantsOrders={hasPermissionsToOperateOnAllOfTenantsOrders}
            canAddProducts={canAddProducts}
            logoutFromApp={logoutFromApp}
          />
        )}
      </Background>
    </MainTemplate>
  );
}
