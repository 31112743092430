import { TenantDetailsAPIResponse } from '@samsonvt/shared-types/userLambda';

export const numberOfMockTenants = 3;

export const getMockTenantDetails = (index: number = 0): Promise<TenantDetailsAPIResponse> => {
  switch (index) {
    case 0:
      return Promise.resolve({
        accountId: 'defaultAccount',
        name: 'SamsonVT',
        enabledFeatures: [
          'settings',
          'shopping-cart',
          'self-registration',
          'parts-catalogue',
          'work-instructions',
          'show-non-buyable-parts',
          'edit-parts',
          'model-shadow',
          'users',
          'cached-model',
        ],
      } as TenantDetailsAPIResponse);
    case 1:
      return Promise.resolve({
        accountId: 'General-Manufacturing-Ltd',
        colours: {
          secondary: '#AFCDD7',
          sidebarBackground: '#4A4C4F',
          brand: '#FFFFFF',
          primary: '#191A1B',
        } as TenantDetailsAPIResponse['colours'], // Cast as we can ignore email colors here
        logoUrl: 'https://static.develop.svt-app.xyz/_assets/GeneralManufacturing/general-manufacturing-logo.svg',
        name: 'General Manufacturing Ltd',
        enabledFeatures: [
          'shopping-cart',
          'self-registration',
          'parts-catalogue',
          'work-instructions',
          'show-non-buyable-parts',
          'edit-parts',
          'model-shadow',
        ],
      });
    case 2:
      return Promise.resolve({
        accountId: 'Total-Fabrication-Co',
        colours: {
          secondary: '#FFF200',
          sidebarBackground: '#454545',
          brand: '#0B0B0C',
          primary: '#0B0B0C',
        } as TenantDetailsAPIResponse['colours'], // Cast as we can ignore email colors here,
        logoUrl: 'https://static.develop.svt-app.xyz/_assets/GeneralManufacturing/general-manufacturing-logo.svg',
        name: 'Total Fabrication Co',
        enabledFeatures: [
          'shopping-cart',
          'self-registration',
          'parts-catalogue',
          'work-instructions',
          'show-non-buyable-parts',
          'edit-parts',
          'model-shadow',
        ],
      });
    default:
      return Promise.resolve({
        accountId: 'defaultAccount',
        name: 'SamsonVT',
        enabledFeatures: [
          'shopping-cart',
          'self-registration',
          'parts-catalogue',
          'work-instructions',
          'show-non-buyable-parts',
          'edit-parts',
          'model-shadow',
        ],
      } as TenantDetailsAPIResponse);
  }
};
