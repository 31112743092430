import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimationClip, AnimationMixer, LoopOnce } from 'three';
import { useFrame, invalidate } from '@react-three/fiber';
import { Theme } from '@mui/material';
import HtmlStaticPseudoPortal from '../HtmlStaticPseudoPortal';
import { PlaybackControls } from './PlaybackControls/PlaybackControls';

interface IActualAnimationProps {
  mixer: AnimationMixer;
  clip: AnimationClip;
  showPauseButton: boolean;
  theme: Theme;
  cameraHasMovedToNewPositionByProps: boolean;
}

export function AnimationAction({
  mixer,
  clip,
  showPauseButton,
  theme,
  cameraHasMovedToNewPositionByProps,
}: IActualAnimationProps) {
  const { time, updateTime, isPaused, pause, play } = useAnimationMixer(
    mixer,
    clip,
    cameraHasMovedToNewPositionByProps
  );
  return (
    <HtmlStaticPseudoPortal theme={theme}>
      <PlaybackControls
        time={time}
        isPaused={isPaused}
        pause={pause}
        play={play}
        showPauseButton={showPauseButton}
        updateTime={updateTime}
        duration={clip.duration}
      />
    </HtmlStaticPseudoPortal>
  );
}

function useAnimationMixer(mixer: AnimationMixer, clip: AnimationClip, cameraHasMovedToNewPositionByProps: boolean) {
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);

  const currentAction = useMemo(() => {
    const existingAction = mixer.existingAction(clip);
    if (existingAction) {
      return existingAction;
    }
    const currentAction = mixer.clipAction(clip);
    currentAction.setLoop(LoopOnce, 0).clampWhenFinished = true;
    return currentAction;
  }, [clip, mixer]);

  const pause = useCallback(() => {
    currentAction.paused = true;
    setIsPaused(true);
    invalidate();
  }, [setIsPaused, currentAction]);

  const play = useCallback(() => {
    if (currentAction.time === clip.duration) {
      setTime(0);
      currentAction.time = 0;
    }

    setIsPaused(false);
    currentAction.paused = false; // clampWhenFinished pauses action, so we need to unpause.
    currentAction.play();
    mixer.update(0);
    invalidate();
  }, [mixer, currentAction, clip]);

  const updateTime = useCallback(
    (time: number) => {
      setTime(time);
      currentAction.time = time;
      pause();
      mixer.update(0); // Frame updates only run on unpaused animation, so we need to update manually on manual time set.
      invalidate();
    },
    [mixer, currentAction, pause]
  );

  useEffect(() => {
    if (cameraHasMovedToNewPositionByProps) {
      // Play when camera has moved to new position. This happens either after camera movement has finished, or when it didn't occur at all ( either position didn't change or prop was just passed as true )
      play();
    } else {
      // If camera is moving show first frame of new clip, but don't play it yet.
      // It will play soon, when camera has moved to new position, see line above ^.
      currentAction.paused = true;
      currentAction.play(); // It doesn't really play, as it is paused :). We just want to show first frame.
      currentAction.time = 0;
      setTime(0);
      setIsPaused(true);
    }
  }, [currentAction, play, cameraHasMovedToNewPositionByProps]);

  useEffect(
    () => () => {
      // Clean old clip.
      void currentAction.stop();
    },
    [currentAction]
  );

  useEffect(() => {
    // Informing the playback controls that animation has finished via paused state
    const finishedCallback = () => {
      setTime(clip.duration);
      pause();
    };
    mixer.addEventListener('finished', finishedCallback);
    return () => mixer.removeEventListener('finished', finishedCallback);
  }, [mixer, pause, clip.duration]);

  useFrame((_, delta) => {
    if (!currentAction.paused) {
      setTime(currentAction.time);
      mixer.update(Math.min(delta, 0.1));
      invalidate();
    }
  });

  return { time, updateTime, isPaused, pause, play };
}

// REQS -> future test cases
// Animation is playing when clipName present
// Animation is controllable with the slider
// Animation pauses on finish
// Animation pauses on slider move
// On step change new animation plays
// Frame doesn't run indefinitely
// (||) button changes to (>) when animation finishes
// Explotion animation is reset when going back (for example on Norton)
// No error on switching repair steps
