import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { MouseEventHandler } from 'react';

const circleForXButtonSize = 4.5;
const CircleForXButton = styled('div')`
  width: ${circleForXButtonSize}rem;
  height: ${circleForXButtonSize}rem;
  border-radius: ${circleForXButtonSize}rem;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.53);
  background-color: ${({ theme }) => theme.palette.grey[900]};
  position: absolute;
  top: -${circleForXButtonSize / 2}rem;
  right: -${circleForXButtonSize / 2}rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  &:hover svg {
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

const StyledXIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey[100]};
`;

export function CircleXButton({ onClick, ...props }: { onClick?: MouseEventHandler<HTMLDivElement> }) {
  return (
    <CircleForXButton {...props} onClick={onClick} data-testid="close-modal-button">
      <StyledXIcon icon={faXmark} size="2x" />
    </CircleForXButton>
  );
}
