/* eslint-disable no-restricted-syntax */
import { Object3D, Material, Texture } from 'three';

export class ResourceTracker {
  resources: any[];
  constructor() {
    this.resources = [];
    window.addEventListener('beforeunload', () => {
      this.dispose();
    });
  }
  async track(resource: any) {
    if (!resource) {
      return resource;
    }

    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach((resource) => this.track(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof Object3D) {
      this.resources.push(resource);
    }
    if (resource instanceof Object3D) {
      this.track((resource as any).geometry);
      this.track((resource as any).material);
      this.track((resource as any).children);
    } else if (resource instanceof Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof Texture) {
          this.track(value);
        }
      }
    }
    return resource;
  }
  untrack(resource: any) {
    this.resources.splice(this.resources.indexOf(resource), 1);
  }
  async dispose() {
    for (const resource of this.resources) {
      if (resource instanceof Object3D) {
        resource.parent?.remove(resource);
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resources = [];
  }
}
