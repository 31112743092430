/* eslint-disable no-nested-ternary */
import { Box, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faCube,
  faFiles,
} from '@fortawesome/pro-light-svg-icons';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { StyledMUIButton } from 'src/components/Button';
import { FilesToUpload } from 'src/components/FilesToUpload';
import { ProcessingOverlay } from 'src/components/Loading/ProcessingOverlay';
import { Toast } from 'src/components/Toast';
import { UploaderDetailsModal } from 'src/components/Uploader/UploaderDetailsModal';
import { UploadInProgress } from 'src/components/Uploader/UploadInProgress';
import { attachPresignedUrls } from 'src/services/api';
import { error as logError } from 'src/services/log';
import DragAndDropContainer from './DragAndDropContainer';

export enum IUploadState {
  INITIAL = 'initial',
  UPLOADING = 'uploading',
  DONE = 'done',
  ERROR = 'error',
}

export interface IFile extends File {
  uploadState?: IUploadState;
  percentUploaded?: number | undefined;
  presignedUrl?: IPresignedUrl;
}

export interface IProductFile extends IFile {
  productName: string;
  productId: string;
  description: string;
}

interface IPresignedUrl {
  url: string;
  name: string;
}

export const VALIDATION_HIDE_DELAY = 5000;

export function Uploader() {
  const initialFilesForUpload: IProductFile[] = [];
  const [filesForUpload, setFilesForUpload] = useState(initialFilesForUpload);

  const [detailModalFiles, setDetailModalFiles] = useState<IProductFile[]>([]);

  const [fileJustRemoved, setFileJustRemoved] = useState('');

  const initialFilesJustAdded: IProductFile[] = [];
  const [filesJustAdded, setFilesJustAdded] = useState(initialFilesJustAdded);

  const initialFilesAlreadyAdded: IProductFile[] = [];

  const [filesAlreadyAdded, setFilesAlreadyAdded] = useState(initialFilesAlreadyAdded);
  const [generatingPresignedUrls, setGeneratingPresignedUrls] = useState(false);
  const [presignedUrlError, setPresignedUrlError] = useState(false);
  const [uploadRequested, setUploadRequested] = useState(false);

  const initialTimeoutRef: any = null;
  const timeoutRef = useRef(initialTimeoutRef);

  const resetValidation = () => {
    setFilesJustAdded([]);
    setFilesAlreadyAdded([]);
    setFileJustRemoved('');
  };

  const resetValidationRef = useRef(resetValidation);

  useEffect(() => {
    resetValidationRef.current = resetValidation;
  });

  const delayClearValidation = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const currentTimeout = setTimeout(resetValidationRef.current, VALIDATION_HIDE_DELAY);
    timeoutRef.current = currentTimeout;
  };

  useEffect(() => {
    delayClearValidation();

    return () => {
      if (!timeoutRef.current) {
        return;
      }

      clearTimeout(timeoutRef.current);
    };
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: IFile[]) => {
      resetValidation();

      const filesAlreadyInUploadArea: IProductFile[] = [];

      const deduplicatedFiles = acceptedFiles
        .filter((acceptedFile) => {
          const fileAlreadyPresent = filesForUpload.find((file) => file.name === acceptedFile.name);
          if (fileAlreadyPresent) {
            filesAlreadyInUploadArea.push(acceptedFile as IProductFile);
          }
          return !fileAlreadyPresent;
        })
        .map((file) => {
          const newFile = file as IProductFile;
          newFile.uploadState = IUploadState.INITIAL;
          newFile.percentUploaded = 0;
          newFile.productName = '';
          newFile.productId = '';
          newFile.description = '';
          return newFile;
        });

      setFilesAlreadyAdded(filesAlreadyInUploadArea);
      setDetailModalFiles(deduplicatedFiles);
    },
    [filesForUpload]
  );

  const removeFile = useCallback(
    (index: number) => {
      resetValidation();
      const remainingFiles = [...filesForUpload];
      const removedFile = remainingFiles.splice(index, 1);

      setFileJustRemoved(removedFile[0].name);
      setFilesForUpload(remainingFiles);
      delayClearValidation();
    },
    [filesForUpload]
  );

  const cancelDetails = (index: number) => {
    const remainingFiles = [...detailModalFiles];

    for (let i = 0; i < index; i += 1) {
      detailModalFiles.pop();
    }

    setDetailModalFiles(remainingFiles);
    closeDetailsModal();
  };

  const closeDetailsModal = () => {
    setFilesJustAdded(detailModalFiles);
    setFilesForUpload([...filesForUpload, ...detailModalFiles]);
    setDetailModalFiles([]);
    delayClearValidation();
  };

  const confirmSubmission = async () => {
    setGeneratingPresignedUrls(true);
    try {
      const filesWithUploadUrls = await attachPresignedUrls(filesForUpload);
      const resolvedFilesWithUploadUrls = await Promise.all(filesWithUploadUrls);
      setFilesForUpload(resolvedFilesWithUploadUrls);
      setUploadRequested(true);
      setGeneratingPresignedUrls(false);
    } catch (err: any) {
      logError(err);
      setGeneratingPresignedUrls(false);
      setPresignedUrlError(true);
      setTimeout(() => {
        setPresignedUrlError(false);
      }, VALIDATION_HIDE_DELAY);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {generatingPresignedUrls ? <ProcessingOverlay /> : null}

      <>
        <DragAndDropContainer
          data-testid="drag-and-drop"
          {...getRootProps({ onClick: resetValidation })}
          isDragActive={isDragActive}
        >
          <input {...getInputProps()} />
          {filesForUpload.length ? (
            <FilesToUpload files={filesForUpload} removeFile={removeFile} />
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <DropzoneInner>
              <p>Drop files here or</p>
              <FlexRow>
                {/* 
                Commented out until we have back to library functionality
                <StyledMUIButton startIcon={<FontAwesomeIcon icon={faCube} size="sm" />} data-testid="choose-files">
                  Create new product
                </StyledMUIButton>
                <Divider /> */}
                <StyledMUIButton data-testid="choose-files" startIcon={<FontAwesomeIcon icon={faFiles} size="sm" />}>
                  Upload any file(s)
                </StyledMUIButton>
              </FlexRow>
            </DropzoneInner>
          )}
        </DragAndDropContainer>
        <Box component="div">
          <StyledMUIButton
            data-testid="verify-upload-button"
            disabled={!filesForUpload.length}
            onClick={confirmSubmission}
          >
            Start uploading file(s)
          </StyledMUIButton>
        </Box>
        <UploaderDetailsModal detailFiles={detailModalFiles} close={closeDetailsModal} cancel={cancelDetails} />
        {uploadRequested ? (
          <UploadInProgress
            cleanFiles={() => {
              setUploadRequested(false);
              setFilesForUpload([]);
            }}
            filesForUpload={filesForUpload}
          />
        ) : null}
      </>

      <Toast
        dependency={filesJustAdded.length}
        severity="success"
        title={filesJustAdded.length > 1 ? `${filesJustAdded.length} files added` : 'File added'}
        message={filesJustAdded.length > 1 ? 'Ready for upload' : filesJustAdded[0]?.name}
      />

      <Toast
        dependency={fileJustRemoved}
        severity="success"
        title="File removed"
        message={`${fileJustRemoved} was removed`}
      />

      <Toast
        dependency={filesAlreadyAdded.length}
        severity="error"
        title="File Already Added"
        message={`${filesAlreadyAdded.map((file) => `${file.name} `)}already in the upload staging area`}
      />

      <Toast
        dependency={presignedUrlError}
        severity="error"
        title="Unable to process upload"
        message="We were unable to process the upload. Please try again later"
      />
    </>
  );
}

const DropzoneInner = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
// Commented out until we have back to library functionality
// const Divider = styled('div')`
//   border: 1px solid grey;
//   height: 2.25rem;
//   margin: 0.25rem 0;
// `;

const FlexRow = styled('div')`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
