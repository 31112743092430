import { styled } from '@mui/material';

const SidebarTextActive = styled('span')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

const HighlightElement = styled('span')`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

interface IProps {
  text: string;
  regex: RegExp;
  highlightColour?: string;
  textColour?: string;
}

export function Highlight({ text, regex, highlightColour, textColour, ...props }: IProps) {
  if (!text) {
    return null;
  }

  const splitters: string[] = text.match(regex) || [];
  const textChunks: string[] = text.split(regex);
  const content = textChunks.reduce(
    (accumulator: any, chunk, index: number) =>
      accumulator === null
        ? [chunk]
        : [
            ...accumulator,
            <HighlightElement
              data-testid={splitters[index]?.toLowerCase()}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{ color: highlightColour || 'inherit' }}
            >
              {splitters[index - 1]}
            </HighlightElement>,
            chunk,
          ],
    null
  );
  return (
    <SidebarTextActive sx={{ color: textColour || 'inherit' }} {...props}>
      {content}
    </SidebarTextActive>
  );
}
