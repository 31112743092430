import { styled } from '@mui/material';

import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';

interface IProps {
  removeFile: (index: number) => void;
  index: number;
}

const FileToUploadRemoveButton = styled('button')`
  border: none;
  background: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${font.small};
  padding: ${spacing.xSmallSpacing} ${spacing.xSmallSpacing} 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;

export function FileToUploadRemove({ removeFile, index }: IProps) {
  const handleRemove = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    removeFile(index);
  };

  return <FileToUploadRemoveButton onClick={handleRemove}>Remove</FileToUploadRemoveButton>;
}
