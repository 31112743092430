import { styled, alpha } from '@mui/material';
import { StyledMUIButton } from 'src/components/Button';
import MUIDivider from '@mui/material/Divider';
import { device } from 'src/constants/mediaQueries';
import MUITypography from '@mui/material/Typography';

export const StyledHeadingContainer = styled('div')`
  color: ${({ theme }) => theme.palette.brand.contrastText};
  margin-bottom: 2rem;
`;

export const StyledSendResetPasswordFormButton = styled(StyledMUIButton)`
  width: 100%;

  :disabled {
    background-color: ${({ theme }) =>
      alpha(
        theme.palette.secondary.main,
        theme.palette.brand.main === theme.palette.primary.main ? 0.16 : 0.5
      )}; // check if theme is dark i.e. brand === primary
    color: ${({ theme }) =>
      alpha(theme.palette.brand.contrastText, theme.palette.brand.main === theme.palette.primary.main ? 0.16 : 0.5)};
  }
`;

export const DesktopResetPasswordRequirements = styled('div')`
  position: absolute;
  height: 200px;
  width: 360px; // width of the form
  left: calc(100% + 1rem);
  top: 0px;
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;

export const MobileResetPasswordRequirements = styled('div')`
  height: 200px;
  width: 360px; // width of the form
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
  margin-bottom: 1rem;

  @media ${device.mobileOrTablet} {
    width: 320px;
  }
`;

export const FormDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  height: 1px;
  margin-bottom: 1rem;
`;

export const TemporaryPasswordReminderNote = styled(MUITypography)`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  text-align: left;
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;
