import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { StyledMUIButtonLink } from 'src/components/Button';

const NavigationButton = styled(StyledMUIButtonLink, { shouldForwardProp: (prop: string) => prop !== 'hideOnTablet' })`
  gap: 10px;
  line-height: 26px;
  flex-shrink: 0;
  display: flex;
  text-align: center;
  margin: 0.5em;
  flex-direction: row-reverse;
  flex-grow: 0;
  width: auto;
`;

const Chevron = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

interface Props extends React.ComponentProps<typeof StyledMUIButtonLink> {
  icon: IconDefinition;
}

export default function StepButton({ icon, children, ...props }: Props) {
  return (
    <NavigationButton {...props}>
      <Chevron icon={icon} />
      <span>{children}</span>
    </NavigationButton>
  );
}
