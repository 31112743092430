import { styled } from '@mui/material';

import type { Attributes } from '@samsonvt/shared-types/partsTable';

const FlexWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

const Label = styled('div')`
  max-width: 50%;
  color: rgba(255, 255, 255, 0.3);
`;

const Value = styled('div')`
  max-width: 50%;
  color: white;
  white-space: pre-wrap;
`;

export function AttributeList({ attributes }: { attributes: Attributes }) {
  return (
    <>
      {Object.entries(attributes).map(([title, content]) => (
        <FlexWrapper key={title}>
          <Label>{title}</Label>
          <Value>{insertLinebreaksIfArray(content)}</Value>
        </FlexWrapper>
      ))}
    </>
  );
}


const arr: string[] = [];
export const insertLinebreaksIfArray = (content: string | string[]) => arr.concat(content).join('\n');
