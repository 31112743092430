import { Blobish } from './blobStorage';

export const numberOfSlices = 5;
export const fromArrayBuffer = (buffer: ArrayBuffer) => new Blob([buffer]);

export const makeBlobFromPieces = (blobArr: Blobish[]) => {
  const blobsFromArrBuffer: ArrayBuffer[] = blobArr.map((item: Blobish) => item.data);
  return new Blob(blobsFromArrBuffer);
};

export const sliceBlob = (blob: Blob) => {
  const blobSize = blob.size;
  const sliceSize = Math.ceil(blobSize / numberOfSlices);

  return Array.from({ length: numberOfSlices }, (_, index) => {
    const startPosition = Math.round(index * sliceSize);
    return blob.slice(startPosition, startPosition + sliceSize, blob.type);
  });
};
