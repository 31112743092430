import { StyledMUIDialogActions, TextButton } from './styles';

interface Props {
  handleClose: () => void;
  children: React.ReactNode;
}

export default function EditPartsActionBar({ children, handleClose }: Props) {
  return (
    <StyledMUIDialogActions>
      <TextButton onClick={handleClose}>Discard changes</TextButton> {children}
    </StyledMUIDialogActions>
  );
}
