import { useState } from 'react';

import { LoginTextInput } from 'src/components/TextInput/LoginTextInput';

import { LoginPasswordInput } from 'src/components/TextInput/LoginPasswordInput';
import RequirementsBoxText, { PasswordGroup } from 'src/components/PasswordRequirementsBox.tsx';
import { CreateAccountTitle, DesktopPasswordRequirements, FormDivider, SendCreateAccountFormButton } from '../styles';
import { CreateAccountFormData, CreateAccountFormProps } from '../types';

export function DesktopCreateAccountForm({ createNewUser, passwordError, emailError }: CreateAccountFormProps) {
  const [createNewUserFormData, setCreateNewUserFormData] = useState<CreateAccountFormData>({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { password, confirmPassword } = createNewUserFormData;

  const checkPasswordsMatch = () => {
    if (password && confirmPassword) {
      return password === confirmPassword;
    }
    return undefined;
  };

  return (
    <form onSubmit={createNewUser(createNewUserFormData)}>
      <CreateAccountTitle>Fill in the details to create your account:</CreateAccountTitle>
      <LoginTextInput
        label="Name"
        type="text"
        value={createNewUserFormData.name}
        data-testid="create-name"
        onChange={(e: any) => setCreateNewUserFormData((prevData) => ({ ...prevData, name: e.target.value }))}
      />
      <LoginTextInput
        label="Surname"
        type="text"
        value={createNewUserFormData.surname}
        data-testid="create-surname"
        onChange={(e: any) => setCreateNewUserFormData((prevData) => ({ ...prevData, surname: e.target.value }))}
      />
      <FormDivider />
      <LoginTextInput
        label="Email"
        type="email"
        value={createNewUserFormData.email}
        data-testid="create-email"
        onChange={(e: any) => setCreateNewUserFormData((prevData) => ({ ...prevData, email: e.target.value }))}
        error={emailError}
      />

      <PasswordGroup>
        <DesktopPasswordRequirements>
          <RequirementsBoxText />
        </DesktopPasswordRequirements>
        <LoginPasswordInput
          label="Type a password"
          value={createNewUserFormData.password}
          data-testid="create-password"
          onChange={(e: any) => setCreateNewUserFormData((prevData) => ({ ...prevData, password: e.target.value }))}
          match={checkPasswordsMatch()}
          error={passwordError}
        />

        <LoginPasswordInput
          label="Type password again"
          value={createNewUserFormData.confirmPassword}
          data-testid="create-confirm-password"
          onChange={(e: any) =>
            setCreateNewUserFormData((prevData) => ({ ...prevData, confirmPassword: e.target.value }))
          }
          match={checkPasswordsMatch()}
          error={passwordError}
        />
      </PasswordGroup>

      <SendCreateAccountFormButton
        type="submit"
        data-testid="create-submit"
        fullWidth
        disabled={!checkPasswordsMatch() || Object.values(createNewUserFormData).some((value) => value === '')}
      >
        Create account
      </SendCreateAccountFormButton>
    </form>
  );
}
