const PBKDF2ITERATIONS = 10000;
const ALGORITHM = 'AES-CBC';

export async function decrypt(file: ArrayBuffer) {
  const fileAsUint8Array = new Uint8Array(file);
  const pbkdf2source: Uint8Array = new Uint8Array([65, 114, 110, 100, 97, 108, 101]);
  const pbkdf2salt = fileAsUint8Array.slice(8, 16);

  const passwordKey: CryptoKey = await window.crypto.subtle.importKey('raw', pbkdf2source, { name: 'PBKDF2' }, false, [
    'deriveBits',
  ]);

  const pbkdf2bytes = new Uint8Array(
    await window.crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        salt: pbkdf2salt,
        iterations: PBKDF2ITERATIONS,
        hash: 'SHA-256',
      },
      passwordKey,
      384
    )
  );

  const keyData: Uint8Array = pbkdf2bytes.slice(0, 32);
  const iv: Uint8Array = pbkdf2bytes.slice(32);
  const cipher: Uint8Array = fileAsUint8Array.slice(16);

  const key: CryptoKey = await window.crypto.subtle.importKey('raw', keyData, { name: ALGORITHM, length: 256 }, false, [
    'decrypt',
  ]);

  const plaintextbytes = await window.crypto.subtle.decrypt({ name: ALGORITHM, iv }, key, cipher);
  return plaintextbytes;
}
