import Auth, { CognitoUser } from '@aws-amplify/auth';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { validate } from 'email-validator';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { dataLayerName } from 'src/services/initializeGTM';
import { TenantContext } from 'src/providers/Tenant';
import { getUser, getUserRoles } from 'src/services/api';
import { error as logError } from 'src/services/log';
import { SamsonVtUser } from 'src/services/samsonVtUser';
import { isProduction } from 'src/utils/isProduction';
import { TrackJS } from 'trackjs';

Auth.configure({
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
});

const invalidEmailFormatError: Error = new Error('Email format invalid');
invalidEmailFormatError.name = 'InvalidEmailFormat';

export const UserContext = createContext(new SamsonVtUser());

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const { accountId, enabledFeatures, logoUrl, preferredCurrency } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const isAuthenticated = Boolean(cognitoUser);

  const {
    isLoading: userDetailsLoading,
    isError: userDetailsError,
    data: userDetails,
  } = useQuery(['user'], () => getUser(), {
    enabled: isAuthenticated,
  });

  const {
    isLoading: userRolesLoading,
    isError: rolesError,
    data: roles,
  } = useQuery(['user-roles'], getUserRoles, {
    enabled: isAuthenticated,
  });

  const userLoading = userDetailsLoading || userRolesLoading;

  if (userDetailsError || rolesError) {
    logError(new Error('Error fetching user details'));
  }

  const {
    permissions = {},
    discountPercentage,
    tags,
    accounts: userAccounts = [],
    translations,
    productCategories,
    customAttributesNames,
  } = userDetails || {};

  const userRole = roles?.find((role) => role.id === userDetails?.role);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => setCognitoUser(user))
      .catch(() => setCognitoUser(null));

    onAuthUIStateChange((_state, data) => {
      setCognitoUser(data as CognitoUser);
    });
  }, []);

  // Trackers
  if (userDetails?.id) {
    if (TrackJS.isInstalled()) {
      TrackJS.configure({ userId: userDetails.id });
    }
  }

  if (isProduction() && userRole?.name) {
    TagManager.dataLayer({
      dataLayer: {
        user_role: userRole.name,
        event: 'user_role',
      },
      dataLayerName,
    });
  }

  const authClientMetadata = accountId ? { account: accountId } : undefined;

  const signup = (email: string, password: string, name: string, surname: string) =>
    validate(email)
      ? Auth.signUp({
          username: email,
          password,
          clientMetadata: authClientMetadata,
          attributes: { name: `${name} ${surname}` },
        }) // return promise so we can check errors thrown in validation form
          .catch((err) => {
            if (err.message.includes("Value at 'password' failed")) {
              err.name = 'PasswordDoesNotMeetRequirements';
            }
            throw err;
          })
      : Promise.reject(invalidEmailFormatError);

  const confirmSignup = (email: string, code: string) =>
    Auth.confirmSignUp(email, code, { clientMetadata: authClientMetadata }); // return promise so we can check errors thrown in validation form

  const login = (email: string, password: string) => {
    const sanitisedPassword = password.trim();
    return validate(email)
      ? Auth.signIn(email, sanitisedPassword, authClientMetadata)
          .then((user: CognitoUser) => {
            setCognitoUser(user);
            return user;
          })
          .catch((err) => {
            if (err.code === 'UserNotFoundException') {
              err.message = 'Invalid username or password';
            }
            throw err;
          })
      : Promise.reject(invalidEmailFormatError);
  };

  // send email to reset password
  const forgotPassword = (email: string) =>
    validate(email)
      ? Auth.forgotPassword(email)
          .then((res: any) => res)
          .catch((err) => {
            if (err.code === 'UserNotFoundException') {
              err.message = 'Invalid username or password';
            }
            throw err;
          })
      : Promise.reject(invalidEmailFormatError);

  // send the new password to be set as user password
  const forgotPasswordSubmit = (email: string, code: string, newPassword: string) =>
    validate(email)
      ? Auth.forgotPasswordSubmit(email, code, newPassword, authClientMetadata)
          .then((res: any) => res)
          .catch((err) => {
            throw err;
          })
      : Promise.reject(invalidEmailFormatError);

  const logout = () => {
    queryClient.clear();
    // clears all caches held by react-query. When tenant details are moved to react-query we should invalidate everything except the tenant details cache
    return Auth.signOut().then(() => setCognitoUser(null));
  };
  const changePassword = useCallback(
    (oldPassword: string, newPassword: string) => {
      const sanitsedOldPassword = oldPassword.trim();
      return Auth.changePassword(cognitoUser, sanitsedOldPassword, newPassword);
    },
    [cognitoUser]
  );

  const createPasswordWithTempCredentials = async (email: string, tempPassword: string, newPassword: string) => {
    const sanitisedPassword = tempPassword.trim();
    if (validate(email)) {
      try {
        const signedInCognitoUser = await Auth.signIn(email, sanitisedPassword, authClientMetadata);
        const confirmedCognitoUser = await Auth.completeNewPassword(signedInCognitoUser, newPassword, {});
        setCognitoUser(confirmedCognitoUser);
      } catch (err: any) {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password';
        }
        throw err;
      }
      return;
    }

    return Promise.reject(invalidEmailFormatError);
  };

  const refreshSession = () => {
    const refreshToken = cognitoUser && cognitoUser.getSignInUserSession()?.getRefreshToken();
    return new Promise((resolve, reject) => {
      if (!refreshToken) {
        logError(new Error('No refresh token found'));
        reject(new Error('No refresh token found'));
        return;
      }
      cognitoUser.refreshSession(
        refreshToken,

        async (error, session) => {
          if (error) {
            logError(error, 'Error refreshing session');
            reject(error);
          }
          cognitoUser.setSignInUserSession(session);
          setCognitoUser(await Auth.currentAuthenticatedUser());
          resolve(session);
        }
      );
    });
  };

  const disableMfa = useCallback(() => Auth.setPreferredMFA(cognitoUser, 'NOMFA'), [cognitoUser]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values: SamsonVtUser = {
    user: cognitoUser?.challengeName ? null : cognitoUser,
    userAccounts,
    preferredCurrency,
    tags,
    discountPercentage,
    permissions,
    enabledFeatures,
    translations,
    productCategories,
    userLoading,
    customAttributesNames,
    login,
    logout,
    signup,
    confirmSignup,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    createPasswordWithTempCredentials,
    refreshSession,
    disableMfa,
    logoUrl,
  };

  return <UserContext.Provider value={values}> {children} </UserContext.Provider>;
}

export const useUser = (): SamsonVtUser => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }

  return context;
};
