/* eslint-disable no-nested-ternary */
import { faBan, faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { FileUploadStatus } from './UploadInProgress';

type Props = {
  name: string;
  fileUploadStatus: FileUploadStatus | undefined;
};
export function ProgressBar({ name, fileUploadStatus }: Props) {
  if (!fileUploadStatus) return null;
  const { percentage, isSuccess, isError } = fileUploadStatus;

  const statusIcon = isSuccess ? (
    <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
  ) : isError ? (
    <FontAwesomeIcon icon={faXmark} style={{ color: 'red' }} />
  ) : (
    <FontAwesomeIcon icon={faBan} />
  );

  const statusText = isSuccess
    ? 'File uploaded successfully'
    : isError
    ? 'Something went wrong during file upload. Could you please try uploading it again?'
    : 'Uploading';
  return (
    <Container data-testid="file-progress-row">
      <Grid>
        <StatusIcon>{statusIcon}</StatusIcon>
        <FileName isError={isError}>
          <span>{name}</span>
        </FileName>
        <Status>{statusText}</Status>
      </Grid>
      <FillerContainer>
        <BackgroundBar />
        <FillerBackground percentage={percentage} />
        <Filler percentage={percentage} />
      </FillerContainer>
    </Container>
  );
}

const Grid = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  grid-template-areas:
    'icon filename'
    '. status';
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
`;

const StatusIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: icon;
`;

const Status = styled('span')`
  font-size: 0.6rem;
  grid-area: status;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const FileName = styled('div')<{ isError?: boolean }>`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  grid-area: filename;
  span {
    font-size: 0.9rem;
    font-weight: bold;
    color: ${({ isError }) => (isError ? 'red' : 'black')};
  }
`;

const BackgroundBar = styled('div')`
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px 0px ${({ theme }) => theme.palette.secondary.main};
  filter: brightness(0.8);
`;

const Filler = styled('div')<{ percentage: number }>`
  height: 2px;
  width: ${({ percentage }) => percentage}%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: inherit;
  position: absolute;
  filter: none;
`;

const FillerBackground = styled('div')<{ percentage: number }>`
  height: 16px;
  width: ${({ percentage }) => percentage}%;
  background-color: white;
  position: absolute;
  filter: none;
`;

const FillerContainer = styled('div')`
  color: red;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 0.6rem 0;
`;
