import { useTenant } from 'src/providers/Tenant';
import { isFeatureSupported } from 'src/Feature';
import { isPartBuyable } from './isPartBuyable';
import { NamedLink } from './partNavigation';

/**
 * Gets given part's children that have a price (are buyable) or are a group
 */
export const usePartsWithPriceOrWithChildren = (allChildPartsLinks: NamedLink[]) => {
  const { enabledFeatures } = useTenant();

  const shouldFilter = !isFeatureSupported('show-non-buyable-parts', enabledFeatures);
  return shouldFilter
    ? allChildPartsLinks.filter((child: NamedLink) => {
        const isChildAGroup = !!child.data?.children?.length;
        const childHasPrice = isPartBuyable(child?.data?.price);

        return isChildAGroup || childHasPrice;
      })
    : allChildPartsLinks;
};
