import { useTheme } from '@mui/material';
import { Stats, useContextBridge } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Animation } from 'src/components/3DControls/Animation';
import { AnimCamera } from 'src/components/3DControls/CameraControls';
import { DefaultStudioLighting } from 'src/components/3DControls/DefaultStudioLighting';
import { Environment } from 'src/components/3DControls/Environment';
import { FocalPointRenderer } from 'src/components/3DControls/FocalPointRenderer';
import { HoverDetector } from 'src/components/3DControls/HoverDetector';
import { FadeModel } from 'src/components/3DControls/MaterialMutation/FadeModel';
import { Feature } from 'src/Feature';
import { ProductContext } from 'src/providers/Product';
import { TenantContext } from 'src/providers/Tenant';
import { UserContext } from 'src/providers/User';
import exposeAsGlobal from 'src/services/exposeAsGlobal';
import { PartContext } from 'src/services/part';
import { PartNavigationContext, usePartNavigation } from 'src/services/partNavigation';
import { AnimationClip, Box3, Object3D, Vector3, Vector3Tuple } from 'three';
import { meshesWithinObject } from '../3DControls/MaterialMutation/helpers';
import { OutlineEffect } from '../3DControls/OutlineEffect';
import Metrics from '../Metrics';
import ModelShadow from '../ModelShadow';

interface IProps {
  scene: Object3D;
  clip: AnimationClip | undefined;
  fadeModel: {
    show: string[] | undefined;
    hide: string[] | undefined;
    hiddenVariantNames: string[] | undefined;
  };
  highlight: string[];
  orbitCenter: Vector3Tuple | undefined;
  position: Vector3Tuple | undefined;
  setFullyRendered: (val: boolean) => void;
  isPartCatalogue: boolean;
  fullControls: boolean;
}

const SCALE_FACTOR = 4.51;
const CAMERA_POSITION_OFFSET = new Vector3(-4, 1, -2);

export function Scene({
  scene,
  clip,
  fadeModel,
  highlight,
  setFullyRendered,
  orbitCenter,
  position,
  isPartCatalogue,
  fullControls,
}: IProps) {
  exposeAsGlobal('scene', scene);

  const ContextBridge = useContextBridge(
    UserContext,
    TenantContext,
    PartContext,
    PartNavigationContext,
    ProductContext
  );

  const history = useHistory();
  const { hotspots } = usePartNavigation();
  const theme = useTheme();

  const applySvtScaling = normalizedBlenderPluginFlag(scene, 'applySvtScaling');
  const zoom = applySvtScaling ? 1 : 3; // Change camera zoom on models after https://samsonvt.atlassian.net/browse/PHONG-157 to match focal length of Blender

  const { scale, centerOfAScene } = useMemo(() => {
    resetScene(scene); // make sure to reset the scene before any calculation involving the scene object
    const sizeBox = new Box3().setFromObject(scene);

    const scale = applySvtScaling ? SCALE_FACTOR / sizeBox.getSize(new Vector3()).length() : 1;
    const centerOfAScene = sizeBox.getCenter(new Vector3()).multiplyScalar(scale);

    return { scale, centerOfAScene };
  }, [scene, applySvtScaling]);

  const { defaultOrbitCenter, defaultPosition } = useMemo(
    () => ({
      defaultOrbitCenter: orbitCenter ?? centerOfAScene.toArray(),
      defaultPosition: position ?? new Vector3().addVectors(centerOfAScene, CAMERA_POSITION_OFFSET).toArray(),
    }),
    [centerOfAScene, orbitCenter, position]
  );

  const [cameraHasMovedToNewPositionByProps, setCameraHasMovedToNewPositionByProps] = useState(false);

  const highlightedMeshes = useMemo(
    () =>
      highlight
        .map((name) => scene.getObjectByName(name))
        .filter((o) => o)
        .map((o) => meshesWithinObject(o!))
        .flat(),
    [highlight, scene] // Memoizing as scene.getObjectByName calls are expensive
  );
  // Outline effect mounting and unmounting is expensive, so we'd rather use it's enabled property.
  // Also we need this to adjust background's color space.
  const outlineEnabled = !!highlightedMeshes.length;
  // Helper for highlight cypress test.
  exposeAsGlobal('highlight', highlight);

  return (
    <Canvas
      mode="concurrent"
      frameloop="demand"
      performance={{ min: 0.5 }}
      data-testid="three-js-canvas"
      gl={{
        preserveDrawingBuffer: !!(window as Window & { Cypress?: boolean }).Cypress, // For WebGL testing we need to make somescreenshots, and this is how we enable it.
      }}
      camera={{ zoom }}
    >
      <ContextBridge>
        <Metrics />
        <Suspense fallback={null}>
          <DefaultStudioLighting />
          <AnimCamera
            defaultOrbitCenter={defaultOrbitCenter}
            defaultPosition={defaultPosition}
            onCameraHasMovedToNewPositionByProps={setCameraHasMovedToNewPositionByProps}
            theme={theme}
            fullControls={fullControls}
            applySvtScaling={applySvtScaling}
          />
          {/* properties need to be attached to a group to avoid changing the scene directly */}
          <group scale={scale}>
            <primitive object={scene} light={false} />
          </group>

          <Feature flag="model-shadow">
            <ModelShadow
              scene={scene}
              scale={scale}
              shouldShowShadow={!(!!clip || !!fadeModel.hide || !!fadeModel.show)}
              canvasBackground={theme.palette.canvasBackground.linear}
            />
          </Feature>

          <Environment onCreated={() => setFullyRendered(true)} />
          {isPartCatalogue && hotspots.length === 0 && <HoverDetector model={scene} history={history} />}
          <Animation
            scene={scene}
            clip={clip}
            showPauseButton={!isPartCatalogue}
            cameraHasMovedToNewPositionByProps={cameraHasMovedToNewPositionByProps}
            theme={theme}
          />
          <FadeModel {...fadeModel} scene={scene} />
          {isPartCatalogue && <FocalPointRenderer history={history} hotspots={hotspots} theme={theme} />}
          {process.env.REACT_APP_DEV_SCENE_STATS_ENABLED === 'true' && <Stats />}
        </Suspense>
      </ContextBridge>

      <OutlineEffect selected={highlightedMeshes} enabled={outlineEnabled} />
      <color
        attach="background"
        args={[
          // Adjust background color space when outline effect running. https://discourse.threejs.org/t/r3f-outline-issues/42533/5
          theme.palette.canvasBackground[outlineEnabled ? 'srgb' : 'linear'],
        ]}
      />
    </Canvas>
  );
}

function resetScene(scene: Object3D) {
  scene.removeFromParent(); // remove all transformation (scaling, etc.) applied to the group wrapper
}

function normalizedBlenderPluginFlag(scene: Object3D, blenderPluginflagName: string) {
  // Flags set in blender plugin are numbers, 0 or 1. They can also be missing in models exported before a particular flag was introduced.
  const {
    userData: {
      svtGltfExportProps = {}, // For older models the wrapper containing our custom props may be undefined as well.
    },
  } = scene;

  return svtGltfExportProps[blenderPluginflagName] === 1 || !(blenderPluginflagName in svtGltfExportProps);
  // Here we assume that the flag was true before it was defined. It's not obvious if other flags will have the same behaviour.
  // If they don't this code needs to be changed to take account for it.
}
