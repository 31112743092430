import { styled } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import MUITypography from '@mui/material/Typography';

export const CategoriesPageTitle = styled(MUITypography)`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  color: ${({ theme }) => theme.palette.secondary.dark};
  @media ${device.smallMobile} {
    font-size: 2rem;
  }
`;

export const CategotiesPageContentPadding = styled('div')`
  padding: 2rem;
`;

export const CategoriesListStyles = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media ${device.mobileOrTablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.smallMobile} {
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${device.smallLaptop} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.largeLaptopOrSmallDesktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const CategoryCardStyles = styled('div')`
  box-shadow: ${({ theme }) => theme.shadows[2]};
  display: flex;
  flex-direction: column;

  @media ${device.mobileOrTablet} {
    max-width: none;
  }
`;

export const CategoryCartTitle = styled(MUITypography)`
  font-size: 1.25rem;
  padding-bottom: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.dark};
  padding-left: 1.5rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @media ${device.mobileOrTablet} {
    margin-bottom: 2rem;
  }

  @media ${device.smallMobile} {
    justify-content: center;
  }
`;

export const SubtitleContainer = styled('div')`
  margin-bottom: 2rem;
  font-size: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[600]};
`;

export const Subtitle = styled('div')`
  display: flex;
  align-items: center;

  @media ${device.mobileOrTablet} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
