import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUITypography from '@mui/material/Typography';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { EmptyStyledButton } from 'src/components/Button';
import { LoadingSpinner } from 'src/components/Loading';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast } from 'src/components/Toast';
import useTranslation from 'src/hooks/useTranslation';
import { formatDateWithTime } from 'src/services/formatDate';
import { CompletedButton } from '../../styles';
import ReceivedOrderPartsList from './ReceivedOrderPartsList';
import {
  OrderDetailsButtonsContainer,
  OrderDetailsData,
  OrderDetailsDataCell,
  OrderDetailsDataHeader,
  OrderDetailsDataRow,
  OrderDetailsDivider,
  OrderDetailsHeader,
  OrderDetailsLeftPanel,
  OrderDetailsMainContent,
  OrderDetailsPanel,
  OrderDetailsPanelTitle,
  OrderDetailsSection,
  OrderDetailsTitleContainer,
} from './styles';
import { useOrderDetails } from './useOrderDetails';

export function OrderDetails() {
  const {
    placeHolderOrder,
    markAsCompleted,
    sendMailToSupplier,
    isLoadingOrderDetails,
    isLoadingCompletedOrder,
    isLoadingResendEmailToSupplier,
    isErrorCompletedOrder,
    isSuccessResendEmailToSupplier,
    isErrorOrderDetails,
    isErrorResendEmailToSupplier,
    orderDetails,
  } = useOrderDetails();

  const {
    orderId,
    firstName,
    lastName,
    companyName,
    telephone,
    customerReference,
    messageToSeller,
    items,
    createdByEmail: email,
    timestamp,
    status,
    totalPrice,
  } = orderDetails ?? placeHolderOrder;

  const { country, city, address1, address2, postalCode } = orderDetails?.address ?? placeHolderOrder.address;
  const customerAccountFieldValue =
    orderDetails?.['vtaas-ui:checkout:customerAccountField'] ||
    placeHolderOrder['vtaas-ui:checkout:customerAccountField'];

  const customerAccountFieldLabel = `${useTranslation('vtaas-ui:checkout:customerAccountField', 'Customer account')}:`;

  return (
    <>
      {isLoadingOrderDetails ? (
        <FullscreenLoadingSpinner />
      ) : (
        <>
          <StandardBreadcrumbs
            current={{ name: orderId }}
            parents={[
              { name: 'My account', url: '/my-account' },
              { id: 'Received orders', name: 'Received orders', url: '/received-orders' },
            ]}
            sx={{ margin: '1rem 0 2rem 0' }}
          />
          <OrderDetailsHeader>
            <OrderDetailsTitleContainer>
              <MUITypography variant="h5">Order details for {orderId}</MUITypography>
              <MUITypography variant="body1">Order received: {formatDateWithTime(timestamp)} </MUITypography>
            </OrderDetailsTitleContainer>
            <OrderDetailsButtonsContainer>
              <EmptyStyledButton
                startIcon={isLoadingResendEmailToSupplier ? null : <FontAwesomeIcon icon={faEnvelope} />}
                onClick={sendMailToSupplier(orderId)}
              >
                {isLoadingResendEmailToSupplier ? <LoadingSpinner width="100" height="100" /> : 'Resend me the order'}
              </EmptyStyledButton>
              <CompletedButton
                orderStatus={status}
                onClick={markAsCompleted(orderId)}
                loading={isLoadingCompletedOrder}
                data-testid="completed-button"
              />
            </OrderDetailsButtonsContainer>
          </OrderDetailsHeader>
          <OrderDetailsMainContent>
            <OrderDetailsLeftPanel>
              <OrderDetailsSection>
                <OrderDetailsPanelTitle>Customer details</OrderDetailsPanelTitle>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>First name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{firstName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Last name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{lastName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Company name:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{companyName}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Telephone:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{telephone}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Email:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{email}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
              <OrderDetailsDivider />
              <OrderDetailsSection>
                <OrderDetailsPanelTitle>Delivery address</OrderDetailsPanelTitle>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Building name / number:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{address1}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Street address:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{address2}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Town / City:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{city}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Postcode:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{postalCode}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Country:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{country}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
              <OrderDetailsDivider />
              <OrderDetailsSection>
                <OrderDetailsData>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Customer ref:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{customerReference}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>{customerAccountFieldLabel}</OrderDetailsDataHeader>
                    <OrderDetailsDataCell>{customerAccountFieldValue}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                  <OrderDetailsDataRow>
                    <OrderDetailsDataHeader>Message from customer:</OrderDetailsDataHeader>
                    <OrderDetailsDataCell sx={{ fontWeight: 'normal' }}>{messageToSeller}</OrderDetailsDataCell>
                  </OrderDetailsDataRow>
                </OrderDetailsData>
              </OrderDetailsSection>
            </OrderDetailsLeftPanel>
            <OrderDetailsPanel>
              <OrderDetailsSection sx={{ height: '100%' }}>
                <OrderDetailsPanelTitle>Parts ordered</OrderDetailsPanelTitle>
                {items.length !== 0 && !isErrorOrderDetails && (
                  <ReceivedOrderPartsList items={items} totalPrice={totalPrice} />
                )}
              </OrderDetailsSection>
            </OrderDetailsPanel>
          </OrderDetailsMainContent>
        </>
      )}
      <Toast
        dependency={isErrorOrderDetails}
        severity="error"
        title="Error"
        message="There has been an error loading this order"
      />
      <Toast
        dependency={isErrorCompletedOrder}
        severity="error"
        title="Error"
        message="There has been an error completing this order"
      />
      <Toast dependency={isSuccessResendEmailToSupplier} severity="success" title="Success" message="Email resent!" />
      <Toast
        dependency={isErrorResendEmailToSupplier}
        severity="error"
        title="Error"
        message="There has been an error resending the email!"
      />
    </>
  );
}
