import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';

export default function RequirementsBoxText({ newPassword }: { newPassword?: boolean }) {
  return (
    <>
      <RequirementsTitle>
        <TitleIcon icon={faCircleInfo} />
        <MUITypography>{`Your ${newPassword ? 'new' : ''} password must contain:`}</MUITypography>
      </RequirementsTitle>
      <RequirementsText>
        <MUITypography>• A minimum of 8 characters long</MUITypography>
        <MUITypography>• At least one uppercase letter</MUITypography>
        <MUITypography>• At least one lowercase letter</MUITypography>
        <MUITypography>• At least one number</MUITypography>
      </RequirementsText>
    </>
  );
}

const RequirementsTitle = styled('div')`
  text-align: left;
  margin-left: 1rem;
  display: flex;
`;

const TitleIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const RequirementsText = styled('div')`
  text-align: left;
  margin-left: 3.125rem;
`;

export const PasswordGroup = styled('div')`
  position: relative;
`;
