import { TextFieldProps } from '@mui/material';
import { StyledComponent } from '@emotion/styled';
import { ChangeEvent, useState } from 'react';
import { WhiteTextField } from '../WhiteTextField';

const sanitizeTelephoneNumberChar = /[^0-9a-z+]/gi;
export const sanitizeTelephoneNumber = (telephone: string) => telephone.replace(sanitizeTelephoneNumberChar, '');

const cognitoPhoneNoRegExp = /^\+[1-9][0-9]{0,17}$/; // regex based on cognito OpenId Connect phone_number attribute. Updated to 17 digits limit - tested by experiment
export const isValidCognitoNumber = (telephone: string) => cognitoPhoneNoRegExp.test(telephone);

export function TelephoneInput({
  Component = WhiteTextField,
  value = '',
  onChange,
  placeholder = 'E.g. +44123456789',
  label = 'Telephone number',
  name = 'telephone',
  type = 'tel',
  error,
  ...props
}: Omit<TextFieldProps, 'variant'> & {
  Component: StyledComponent<TextFieldProps>;
}) {
  const [innerValue, setInnerValue] = useState(`${value}`);

  const sanitized = sanitizeTelephoneNumber(innerValue);
  const valid =
    !innerValue || // show placeholder when empty
    isValidCognitoNumber(sanitized);

  return (
    <Component
      {...props}
      placeholder={placeholder}
      label={label}
      type={type}
      name={name}
      variant={Component.defaultProps?.variant || 'filled'}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInnerValue(newValue); // Keep internal state exactly as typed
        const newSanitized = sanitizeTelephoneNumber(newValue);
        const newValid = isValidCognitoNumber(newSanitized); // Validate sanitized form
        // Only set value when valid, and sanitize it before setting
        event.target.value = newValid ? newSanitized : ''; // Mutating for brevity. If not valid unset in parent.
        if (onChange) onChange(event);
      }}
      value={innerValue}
      error={
        !valid || error // Handle internal validation and required error from the top component
      }
      helperText={!valid && 'Please add your country code e.g +44. Max 18 digits.'}
    />
  );
}
