import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/pro-light-svg-icons';

export default function EmptyCartMobile() {
  return (
    <EmptyListContainer>
      <FontAwesomeIcon icon={faCartArrowDown} size="3x" />
      Your shopping cart is empty. Use the Parts Catalogue to add parts
    </EmptyListContainer>
  );
}

const EmptyListContainer = styled('div')`
  height: 100%;
  width: 100%;
  margin-left: 5px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
`;
