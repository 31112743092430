import { Button, css, styled } from '@mui/material';

type Size = 'small' | 'medium' | 'large';

interface SetQuantityProps {
  quantity: number | '';
  setQuantity: (quantity: number | '') => void;
  /** If true, allow quantity to be set to zero or empty string */
  allowZero?: boolean;
  size?: Size;
}
export function SetQuantity({ quantity, setQuantity, allowZero = false, size = 'medium' }: SetQuantityProps) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!allowZero && (inputValue === '0' || inputValue === '')) {
      setQuantity(1);
      return;
    }

    if (inputValue === '') {
      setQuantity('');
      return;
    }
    const parsedValue = Number(inputValue);
    if (parsedValue < 0) {
      setQuantity('');
      return;
    }
    setQuantity(parsedValue);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key)) e.preventDefault();
  };

  const onBlur = () => {
    if (quantity === '' || quantity < 1) setQuantity(1);
  };

  const increment = () => {
    setQuantity(quantity === '' ? 1 : quantity + 1);
  };
  const decrement = () => {
    const minValue = allowZero ? 0 : 1;
    setQuantity(quantity === '' || quantity < 2 ? minValue : quantity - 1);
  };

  const displayValue = quantity.toString();

  return (
    <FlexContainer>
      <ChangeQuantityButton onClick={decrement} quantityInputSize={size}>
        -
      </ChangeQuantityButton>
      <ChangeQuantityInput
        type="number"
        onKeyPress={onKeyPress}
        onChange={onChange}
        onBlur={onBlur}
        value={displayValue}
        quantityInputSize={size}
        data-testid="quantity-input"
      />
      <ChangeQuantityButton onClick={increment} quantityInputSize={size}>
        +
      </ChangeQuantityButton>
    </FlexContainer>
  );
}

const FlexContainer = styled('div')`
  display: flex;
`;
const shouldForwardProp = (prop: string) => prop !== 'quantityInputSize';

const ChangeQuantityButton = styled(Button, { shouldForwardProp })<{ quantityInputSize: Size }>`
  ${({ theme, quantityInputSize }) => css`
    min-width: 1.3em;
    width: 1.3em;
    height: ${quantityInputSize === 'small' ? '30px' : '36px'};
    font-size: 1.7em;
    padding: 3px 2px;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    &:hover {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.secondary.contrastText};
    }
    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `}
`;

type ChangeQuantityInputProps = {
  quantityInputSize: Size;
} & React.InputHTMLAttributes<HTMLInputElement>;

const ChangeQuantityInput = styled('input', { shouldForwardProp })<ChangeQuantityInputProps>`
  ${({ quantityInputSize }) => css`
    width: 2em;
    font-size: 1.2em;
    height: ${quantityInputSize === 'small' ? '30px' : '36px'};
    text-align: center;
    overflow: hidden;

    /* Remove arrows from input of type number */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  `}
`;
