import { RepairListAPIResponse, RepairHeader } from '@samsonvt/shared-types/repairLambda';
import { useLocation } from 'react-router';
import { getFormattedRepairTime } from 'src/services/utils';
import {
  RepairsButton,
  SecondaryDivider,
  StyledAccessTimeIcon,
  StyledRepairListDurationBadge,
  StyledRepairListDurationRow,
  StyledRepairListDurationTitle,
  StyledRepairListItem,
  StyledRepairName,
} from './styles';

interface RepairListItemsProps {
  repairs: RepairListAPIResponse | undefined;
  url: string;
  selectedTag: string | undefined;
}

export function RepairListItems({ repairs, url, selectedTag }: RepairListItemsProps) {
  const { search } = useLocation();
  if (!repairs) return null;

  const tagFilter = (repair: RepairHeader) => {
    if (!selectedTag) {
      return true;
    }
    return !!(repair.tags && repair.tags.includes(selectedTag));
  };

  return (
    <>
      {repairs.filter(tagFilter).map((repair) => (
        <div key={repair.repairId}>
          <StyledRepairListItem data-testid="repair-element">
            <div>
              <StyledRepairName>{repair.name}</StyledRepairName>
              {!!repair.duration && (
                <StyledRepairListDurationRow data-testid="repair-list-duration">
                  <StyledAccessTimeIcon />
                  <StyledRepairListDurationTitle>Duration:</StyledRepairListDurationTitle>
                  <StyledRepairListDurationBadge>
                    {getFormattedRepairTime(repair.duration)}
                  </StyledRepairListDurationBadge>
                </StyledRepairListDurationRow>
              )}
            </div>
            <RepairsButton data-testid="repair-element-button" to={`${url}/${repair.repairId}${search}`}>
              View
            </RepairsButton>
          </StyledRepairListItem>
          <SecondaryDivider />
        </div>
      ))}
    </>
  );
}
