/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* Rules disabled to clean console output and improve developer experience when writing new code. Should be reviewed */
import { Html } from '@react-three/drei';
import { invalidate } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Vector3Tuple } from 'three';
import { EditorToolStyles } from './styles';

interface IAxisInputProps {
  axis: number;
  label: string;
  pointCoords: Vector3Tuple;
  inputCoordinatesChanged: (value: string, axis: number) => void;
}

function AxisInput({ axis, label, pointCoords, inputCoordinatesChanged }: IAxisInputProps) {
  return (
    <p>
      <label>{label}</label>
      <input type="number" value={pointCoords[axis]} onChange={(e) => inputCoordinatesChanged(e.target.value, axis)} />
    </p>
  );
}

interface IProps {
  cameraControls: CameraControls;
  setInputCoordinates: Dispatch<SetStateAction<Vector3Tuple>>;
  pointCoords: Vector3Tuple;
  cameraCoords: Vector3Tuple;
}

export function CoordinatesEditorPanel({ cameraControls, setInputCoordinates, pointCoords, cameraCoords }: IProps) {
  const saveToClipBoard = (target: Vector3Tuple) => navigator.clipboard.writeText(JSON.stringify(target));

  const moveCameraToCoords = (newCameraCoords: Vector3Tuple) => {
    cameraControls.setPosition(...newCameraCoords, false);
    invalidate();
  };

  const moveCenterPointToCoords = (newCenterCoords: Vector3Tuple) => {
    cameraControls.setTarget(...newCenterCoords, false);
    invalidate();
  };

  const inputCoordinatesChanged = useCallback(
    (inputValue: string, axis: number) => {
      const parsedValue = Number(inputValue);
      const coordinateArray = pointCoords;
      coordinateArray[axis] = parsedValue;
      setInputCoordinates(coordinateArray);
    },
    [pointCoords, setInputCoordinates]
  );

  return (
    <Html
      calculatePosition={(_el, _camera, size: { width: number; height: number }) => [size.width, 0]} // Override default positioning function, so that we put the element at the bottom right.
      // This is abusing HTML component to serve as a portal.
    >
      <EditorToolStyles>
        <div>
          <span>Point Coordinates:</span>
          <AxisInput axis={0} label="X: " pointCoords={pointCoords} inputCoordinatesChanged={inputCoordinatesChanged} />
          <AxisInput axis={1} label="Y: " pointCoords={pointCoords} inputCoordinatesChanged={inputCoordinatesChanged} />
          <AxisInput axis={2} label="Z: " pointCoords={pointCoords} inputCoordinatesChanged={inputCoordinatesChanged} />
          <button onClick={() => saveToClipBoard(pointCoords)}>Copy</button>
          <button onClick={() => moveCenterPointToCoords(pointCoords)}>Update Center Position</button>
        </div>
        <div>
          <span>Camera Coordinates:</span>
          <p>X: {cameraCoords[0]}</p>
          <p>Y: {cameraCoords[1]}</p>
          <p>Z: {cameraCoords[2]}</p>
          <button onClick={() => saveToClipBoard(cameraCoords)}>Copy</button>
          <button onClick={() => moveCameraToCoords(pointCoords)}>Update Camera Position</button>
        </div>
      </EditorToolStyles>
    </Html>
  );
}
