import { NavLink } from 'react-router-dom';
import { ProductCardImageContainer } from 'src/components/ProductsList/styles';
import { Thumbnail } from 'src/components/Thumbnail';
import {
  encodeProductCategoryName,
  encodeProductCategoryPathEnumeration,
  productCategoryPathEnumerationSeparator,
} from 'src/utils/urls';
import { CategoryCardStyles, CategoryCartTitle } from './styles';

interface CategoryCardProps {
  categoryOfProducts: string;
  thumbnailUrl: string;
  decodedPathEnumeration: string;
}

export default function CategoryCard({ categoryOfProducts, thumbnailUrl, decodedPathEnumeration }: CategoryCardProps) {
  const URLSafeCategory = encodeProductCategoryName(categoryOfProducts);
  const encodedProductCategoryPathEnumeration = encodeProductCategoryPathEnumeration(decodedPathEnumeration);
  return (
    <CategoryCardStyles>
      <ProductCardImageContainer>
        <NavLink
          data-testid="card-navlink"
          to={`/product-library?productCategoryPath=${
            decodedPathEnumeration
              ? `${encodedProductCategoryPathEnumeration}${productCategoryPathEnumerationSeparator}${URLSafeCategory}`
              : `${URLSafeCategory}`
          }`}
        >
          <Thumbnail url={thumbnailUrl} alt={categoryOfProducts} />
        </NavLink>
      </ProductCardImageContainer>
      <CategoryCartTitle sx={{ flexGrow: 1 }}>{categoryOfProducts}</CategoryCartTitle>
    </CategoryCardStyles>
  );
}
