import { useContext, useState } from 'react';

import { LocationType, StyledLogo } from 'src/templates/UnauthenticatedTemplate';

import samsonLogoLarge from 'src/assets/logo-default.svg';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import { useHistory, useLocation } from 'react-router-dom';

import { Feature } from 'src/Feature';
import { LoginForm } from './LoginForm';
import { LoadingSpinner } from '../Loading';
import { StyledFormContainer, StyledDivider, RegisterTitle, CreateAccountButton } from './styles';

function LoginFormContainer() {
  const [loading, setLoading] = useState(false);
  const { logoUrl } = useContext(TenantContext);
  const { login } = useUser();

  const history = useHistory();
  const location = useLocation<LocationType>();

  const switchPage = () => {
    history.push('/send-reset-password-email');
  };

  return (
    <StyledFormContainer>
      {loading ? <LoadingSpinner width="320" /> : <StyledLogo src={logoUrl || samsonLogoLarge} />}
      <LoginForm switchPage={switchPage} setLoading={setLoading} login={login} history={history} location={location} />
      <Feature flag="self-registration">
        <>
          <StyledDivider />
          <div>
            <RegisterTitle>{`Don't have an account?`}</RegisterTitle>
            <CreateAccountButton to="/create-account">Create your Account</CreateAccountButton>
          </div>
        </>
      </Feature>
    </StyledFormContainer>
  );
}

export default LoginFormContainer;
