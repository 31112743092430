import RequirementsBoxText from 'src/components/PasswordRequirementsBox.tsx';
import {
  WhitePasswordInputWithMatchIndicator,
  WhitePasswordInputWithShowHide,
} from 'src/components/TextInput/WhitePasswordInput';
import {
  ChangeYourPasswordContainer,
  ChangeYourPasswordSubTitle,
  ChangeYourPasswordTitle,
} from '../../Desktop/ChangeYourPassword/styles'; // imported from desktop version as they are common between screens
import {
  MobileChangeYourPasswordContentContainer,
  MobileChangeYourPasswordDivider,
  MobileChangeYourPasswordSectionsContainer,
  MobileRequirementBoxContainer,
} from './styles';
import { MobileAccountDetailsFormButton } from '../styles';
import { ChangePasswordFormProps } from '../../AccountDetailsPage';

export default function MobileChangeYourPassword({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  verifyNewPassword,
  setVerifyNewPassword,
  submit,
  checkPasswordsMatch,
  error,
}: ChangePasswordFormProps) {
  const areNewPasswordsMatching = checkPasswordsMatch();
  return (
    <ChangeYourPasswordContainer onSubmit={submit}>
      <ChangeYourPasswordTitle>Change your password</ChangeYourPasswordTitle>
      <MobileChangeYourPasswordContentContainer>
        <ChangeYourPasswordSubTitle>
          Please, type your old password first before changing to a new one:
        </ChangeYourPasswordSubTitle>
        <input hidden autoComplete="username" />
        {/* done to placate chrome warn https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */}
        <MobileChangeYourPasswordSectionsContainer>
          <WhitePasswordInputWithShowHide
            label="Type your old password"
            value={oldPassword}
            onChange={(e: any) => setOldPassword(e.target.value)}
            testId="current-password-change-your-password-mobile"
            error={error}
          />
          <MobileChangeYourPasswordDivider />

          <MobileRequirementBoxContainer>
            <RequirementsBoxText />
          </MobileRequirementBoxContainer>

          <WhitePasswordInputWithMatchIndicator
            label="Type your new password"
            value={newPassword}
            error={error}
            onChange={(e: any) => setNewPassword(e.target.value)}
            match={areNewPasswordsMatching}
            testId="new-password-mobile"
          />
          <WhitePasswordInputWithMatchIndicator
            label="Type your new password"
            value={verifyNewPassword}
            error={error}
            onChange={(e: any) => setVerifyNewPassword(e.target.value)}
            match={areNewPasswordsMatching}
            testId="new-password-verify-mobile"
          />
        </MobileChangeYourPasswordSectionsContainer>
        <MobileAccountDetailsFormButton
          type="submit"
          data-testid="submit-new-pass-mobile"
          disabled={areNewPasswordsMatching !== undefined ? !areNewPasswordsMatching : false}
        >
          Save new password
        </MobileAccountDetailsFormButton>
      </MobileChangeYourPasswordContentContainer>
    </ChangeYourPasswordContainer>
  );
}
