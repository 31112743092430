import { PropsWithChildren } from 'react';
import { UploadingLabel } from './UploadingLabel';
import { UploadPercentage, IProps } from './UploadPercentage';

export function UploadingStatus({ percentUploaded = 0, children }: PropsWithChildren<IProps>) {
  return (
    <>
      <UploadingLabel>{children}</UploadingLabel>
      <UploadPercentage percentUploaded={percentUploaded} />
    </>
  );
}
