import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Panel, PanelInnerContainer } from 'src/components/Product/Mobile/Drawer/styles';
import { ThreeJsContainer } from 'src/components/ThreeJsContainer';
import { useForceUpdate } from 'src/hooks/useForceUpdate';
import { TabProps } from 'src/pages/ProductDetailPage';
import { DrawerSibling } from './Drawer/DrawerSibling';
import { ProductDrawer } from './Drawer/ProductDrawer';
import PartsCataloguePanelMobile from './Panels/PartsCatalogue';
import { WorkInstructionsPanelMobile } from './Panels/WorkInstructions';

export default function MobileProductDetails({ currentTabName, productPath }: TabProps) {
  const [productDrawerExpanded, setProductDrawerExpanded] = useState(false);

  const forceUpdate = useForceUpdate();
  useEffect(() => void window.addEventListener('resize', forceUpdate), [forceUpdate]);

  return (
    <div data-testid="product-detail">
      <ProductDrawer setExpanded={setProductDrawerExpanded}>
        <Panel data-testid={`product-tab-${currentTabName}`}>
          <PanelInnerContainer>
            <Switch>
              <Route path={`${productPath}/parts-catalogue`}>
                <PartsCataloguePanelMobile />
              </Route>
              <Route path={`${productPath}/work-instructions`}>
                <WorkInstructionsPanelMobile />
              </Route>
            </Switch>
          </PanelInnerContainer>
        </Panel>
      </ProductDrawer>
      <DrawerSibling expanded={productDrawerExpanded} offset="0px">
        <ThreeJsContainer fullControls={false} />
      </DrawerSibling>
    </div>
  );
}
