import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, css, styled, Typography } from '@mui/material';
import MUIButton from '@mui/material/Button';
import MUICard from '@mui/material/Card';
import MUICardContent from '@mui/material/CardContent';
import MUIIconButton from '@mui/material/IconButton';
import MUITypography from '@mui/material/Typography';
import { StyledMUIButtonLink } from 'src/components/Button';
import { navHeight } from 'src/constants/sizes';

export const Header = styled(MUITypography)`
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  padding: 1rem;
  display: block;
`;
Header.defaultProps = { variant: 'h5' };

const DRAWER_WIDTH = 290;

export const DrawerContent = styled(Box)`
  height: 100%;
  width: ${DRAWER_WIDTH}px;
  display: flex;
  flex-direction: column;
  padding-top: ${navHeight};
`;

interface DrawerProps {
  isOpen: boolean;
}

export const Drawer = styled(Box)<DrawerProps>`
  ${(p) => css`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: ${p.isOpen ? `${DRAWER_WIDTH}px` : '0px'};
    background: ${p.theme.palette.primary.dark};
    z-index: ${p.theme.zIndex.drawer};
    transition: 0.25s ease-in-out;
  `}
`;

export const ListHeightContainer = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
  height: 0px;

  ${({ theme }) => theme.mixins.hideScrollbar}
`;

export const PositionedIconButton = styled(MUIIconButton)`
  position: absolute;
  top: 10px;
  right: 20px;
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
  :hover {
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const PositionedCard = styled(MUICard)`
  max-width: 345;
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin: 1rem;
  position: relative;
`;

export const PriceTag = styled(MUITypography)`
  font-size: 16px;
  font-weight: bold;
  margin-top: 0.3em;
  margin-bottom: 0.6em;
`;

export const BottomSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const TwoLineEllipsis = styled('div')`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleText = styled(MUITypography)`
  font-size: 16px;
  font-weight: bold;
`;

export const PartNumber = styled(MUITypography)`
  font-size: 16px;
`;

export const OneLineEllipsis = styled('div')`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WhiteTrashIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const TotalWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-top: 17px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  border-top: 2px solid ${({ theme }) => theme.palette.whiteGlass};
`;

export const CheckoutButtonWrapper = styled(Box)`
  display: flex;
  text-align: center;
  width: 100%;
  height: 74px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

export const CheckoutButton = styled(StyledMUIButtonLink)`
  width: 100%;
  margin: 1rem;
`;

export const CheckoutButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

export const DeleteButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  height: 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const QtyText = styled(Typography)`
  font-size: 16px;
  margin-right: 0.5em;
`;

export const Flex = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FlexRow = styled(Box)`
  display: flex;
`;

export const SetQuantityContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const CardContent = styled(MUICardContent)`
  padding: 8px;
  padding-bottom: 8px !important;
`;
