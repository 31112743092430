import { faCartArrowDown, faListTree } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { StyledMUIPrimaryButton } from 'src/components/Button';
import { spacing } from './styles';

type LocationProps = { from?: string | undefined } | undefined;

export default function EmptyCart() {
  const { goBack, location } = useHistory<LocationProps>();
  const history = useHistory();

  const isComingFromPartsCatalogue = location.state?.from?.includes('/parts-catalogue');

  const handleClick = () => {
    if (isComingFromPartsCatalogue) {
      goBack();
    } else {
      history.push('/product-library');
    }
  };

  return (
    <EmptyListContainer>
      <FontAwesomeIcon icon={faCartArrowDown} size="3x" />
      <MUITypography>
        Your shopping cart is empty.
        <br /> Use the Parts Catalogue to add parts.
      </MUITypography>
      <BackToPartCatalogueOrProductLibraryButton data-testid="empty-cart-button" onClick={handleClick}>
        <FontAwesomeIcon icon={faListTree} size="sm" style={{ margin: '0 0.5rem' }} />
        {isComingFromPartsCatalogue ? 'Browse the Parts Catalogue' : 'Back to product library'}
      </BackToPartCatalogueOrProductLibraryButton>
    </EmptyListContainer>
  );
}

const EmptyListContainer = styled('div')`
  height: 100%;
  width: 300px;
  text-align: center;
  margin: auto;
  display: flex;
  gap: ${spacing}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const BackToPartCatalogueOrProductLibraryButton = styled(StyledMUIPrimaryButton)`
  width: fit-content;
  height: fit-content;
`;
