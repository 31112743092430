import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RepairTabPanel } from 'src/components/Product/Desktop/Panels/WorkInstructions/RepairTabPanel';
import { SidebarRepairList } from 'src/components/Product/Desktop/Panels/WorkInstructions/SidebarRepairList';

export function WorkInstructionsDesktop() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <SidebarRepairList />
      </Route>
      <Route path={`${path}/:detailId`}>
        <RepairTabPanel />
      </Route>
    </Switch>
  );
}
