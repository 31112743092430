import { styled, useMediaQuery } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { FlexContainer } from 'src/components/FlexContainer';
import { Header } from 'src/components/Header';
import MobileProductMenu from 'src/components/MobileProductMenu';
import ShoppingCartPanel from 'src/components/ShoppingCartPanel';
import { device } from 'src/constants/mediaQueries';
import { Feature } from 'src/Feature';
import { useProduct } from 'src/providers/Product';
import { useProductsListQuery } from 'src/providers/useProductsListQuery';
import { useUser } from 'src/providers/User';
import { isWithinIframe } from 'src/services/checkForIframe';

const Container = styled(FlexContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentPane = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

export function MainTemplate({ children }: { children: ReactNode }) {
  const [openMobileProductMenu, setOpenMobileProductMenu] = useState(false);

  const isMobile = useMediaQuery(device.mobileOrTablet);
  const isWorkInstructions = useLocation().pathname.includes('work-instructions');

  const history = useHistory();
  const { data: products = [] } = useProductsListQuery();
  const { name: currentProductName } = useProduct();

  const { logout } = useUser();

  const logOutFromApp = async () => {
    await logout();
    history.push('/');
  };

  return (
    <Container>
      <Header isWithinIframe={isWithinIframe} isMobile={isMobile} setOpenMobileProductMenu={setOpenMobileProductMenu} />
      <ContentPane data-testid="content-pane">{children}</ContentPane>
      {isMobile && (
        <MobileProductMenu
          openMobileProductMenu={openMobileProductMenu}
          setOpenMobileProductMenu={setOpenMobileProductMenu}
          products={products}
          currentProductName={currentProductName}
          history={history}
          logout={logOutFromApp}
        />
      )}
      {isMobile && !isWorkInstructions && (
        <Feature flag="shopping-cart">
          <ShoppingCartPanel isMobile />
        </Feature>
      )}
    </Container>
  );
}
