import { Color, MathUtils } from 'three';
import { PreparedMaterial } from './helpers';
import { MutableProperty, PropertyValue } from './threeStyles';

type MutatorFunction = (material: PreparedMaterial, target: PropertyValue, speed: number) => boolean; // Returns isFinished when transtion complete

// Provides mutator functions for every property that we want to update on the model.
// As the properties vary in type, update method, and interpolation, each has it's own function.
// The function mutates the provided material, and returns true when transition reaches the target state.
const mutators: { [key in MutableProperty]: MutatorFunction } = {
  opacity: (material, target, speed = 1) => {
    const targetNumber = target as number;
    const current = material.opacity;
    if (Math.abs(targetNumber - current) < 0.01) {
      material.opacity = targetNumber;
      return true;
    }
    material.opacity = MathUtils.lerp(current, targetNumber, speed);
    return false;
  },
  color: (material, target, speed = 1) => {
    const targetColor = target as Color;
    const current = new Color(material.color);
    const { r, g, b } = new Color(current).sub(targetColor);
    const distance = r + g + b;
    if (distance < 0.01) {
      material.color = targetColor;
      return true;
    }
    const newValue = current.lerp(targetColor, speed);
    material.color = newValue;
    return false;
  },
};

export default mutators;
