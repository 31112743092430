import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { spacing } from 'src/constants/spacing';
import { NamedLink, usePartNavigation } from 'src/services/partNavigation';
import { PartsCatalogueAccordion, StyledListText } from './PartsCatalogueAccordion';
import { AccordionItemContainer } from './styles';

/**
 * Accordion listing part locations - where else part can be found
 */
export function PartLocations() {
  const { relatedParts } = usePartNavigation();

  if (!relatedParts.length) {
    return null;
  }
  const parts = [...relatedParts].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <PartsCatalogueAccordion title="Locations">
      {parts.map((item) => (
        <PartLocation key={item.url} item={item} />
      ))}
    </PartsCatalogueAccordion>
  );
}

function PartLocation({ item }: { item: NamedLink }) {
  return (
    <StyledListText onClick={doNothing}>
      <AccordionItemContainer>
        <StyledLabelContainer>
          <StyledLabel>{item.name}</StyledLabel>
          <StyledButton to={item.url} data-testid="locations-link-button">
            View
          </StyledButton>
        </StyledLabelContainer>
      </AccordionItemContainer>
    </StyledListText>
  );
}

const doNothing = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  e.stopPropagation();
};

const StyledLabelContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: ${spacing.smallSpacing} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.sidebarBackground.contrastText}50;
  transition: 0.4s all ease-in-out;
`;

const StyledLabel = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

const StyledButton = styled(Link)`
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: ${spacing.xSmallSpacing} ${spacing.smallSpacing};

  &:hover {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }
`;
