import { faSquareRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { TableDataRow, TableData } from 'src/components/Table/MUITableStyles';
import useFormattedPrice from 'src/services/formatCurrency';
import { formatDateWithTime } from 'src/services/formatDate';
import { ConfirmationNumber } from '../../../styles';

interface MyOrdersTableRowProps {
  order: Order;
}

export default function DesktopMyOrdersTableRow({ order }: MyOrdersTableRowProps) {
  const date = formatDateWithTime(order.timestamp);

  const totalPriceFormatted = useFormattedPrice({
    price: order.totalPrice.amount,
    currency: order.totalPrice.currencyCode,
  });

  return (
    <TableDataRow data-testid="received-orders-row">
      <TableData>
        <ConfirmationNumber to={`/my-orders/${order.orderId}`} data-testid="confirmationNo-link">
          <FontAwesomeIcon icon={faSquareRight} style={{ marginRight: '.5rem' }} />
          {order.orderId}
        </ConfirmationNumber>
      </TableData>
      <TableData>{date}</TableData>
      <TableData>{order.companyName}</TableData>
      <TableData>{totalPriceFormatted}</TableData>
    </TableDataRow>
  );
}
