import { faCircleInfo, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { PartDetails } from '@samsonvt/shared-types/partService';

import {
  SnackBarTextContainer,
  StyledInfoIcon,
  BlueSnackBarText,
  Scenario1SubTitle,
  YellowSnackBarText,
  FlexRow,
} from './styles';
import { PartDetailsForm } from './EditModal';

interface EditPartsSnackBarProps {
  partDetailsForm?: PartDetailsForm;
  originalPartDetails: PartDetails | undefined;
  newPartDetails: PartDetails | undefined;
}

function MissingAssignment() {
  return (
    <BlueSnackBarText>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FlexRow>
          <StyledInfoIcon icon={faCircleInfo} size="lg" />
          <strong>A part number is missing for this part. To make any changes, please add a part number first:</strong>
        </FlexRow>
        <Scenario1SubTitle>
          {`• If you use an existing part number, all the information associated with that part (such as its name,
price, previous part numbers) will be copied to this part.`}
          <br />
          {`• If you use a new part number, you'll
need to add all the relevant information to this part (such as its name, price, previous part numbers).`}
        </Scenario1SubTitle>
      </div>
    </BlueSnackBarText>
  );
}

function NewPartDetails() {
  return (
    <BlueSnackBarText>
      <StyledInfoIcon icon={faCircleInfo} size="lg" />
      You’ve chosen a new part number. Please fill in the necessary information below.
    </BlueSnackBarText>
  );
}

function FloatingPartDetails() {
  return (
    <BlueSnackBarText>
      <StyledInfoIcon icon={faCircleInfo} size="lg" />
      You’ve chosen an existing part number. Please feel free to make any changes to the information below.
    </BlueSnackBarText>
  );
}

function AssignedPartDetails({ howManyAssigned }: { howManyAssigned: number }) {
  const plural = howManyAssigned > 1;
  return (
    <YellowSnackBarText>
      <StyledInfoIcon icon={faExclamationTriangle} size="lg" />
      {plural ? 'These' : 'This'} part details are used {howManyAssigned} time{plural ? 's' : ''} in various places and
      models. If you make any edits to this part, those changes will affect all the parts with the same part number
      across all the models that use them.
    </YellowSnackBarText>
  );
}

function AssignmentTip() {
  return (
    <BlueSnackBarText>
      <StyledInfoIcon icon={faCircleInfo} size="lg" />
      Please, add a new part number or an existing part number
    </BlueSnackBarText>
  );
}

function AssignedPartDetailsExtended({ howManyAssigned }: { howManyAssigned: number }) {
  const plural = howManyAssigned > 1;

  return (
    <YellowSnackBarText>
      <StyledInfoIcon icon={faExclamationTriangle} size="lg" />
      You’ve chosen an existing part number. {plural ? 'These' : 'This'} part details are now used {howManyAssigned}{' '}
      time{plural ? 's' : ''} in various places and models. If you make any edits to {plural ? 'these' : 'this'} part
      details, those changes will affect all the parts with the same part number across all the models that use it.
    </YellowSnackBarText>
  );
}

function MessageLogic({ originalPartDetails, newPartDetails, partDetailsForm }: EditPartsSnackBarProps) {
  const hasBeenPreviouslyAssociated = originalPartDetails;
  const hasBeenPreviouslyAssociatedToPartDetailsAssociatedWithMultipleNodes =
    originalPartDetails?.modelNodeIDs && originalPartDetails?.modelNodeIDs.length > 1;
  const isBeforeAssignment =
    !partDetailsForm || (originalPartDetails && originalPartDetails.svtPartID === partDetailsForm.svtPartID);
  const isBeingAssignedToNewPart = partDetailsForm && !partDetailsForm.svtPartID;
  const isBeingAssignedToAFloatingPart =
    partDetailsForm && partDetailsForm.svtPartID && newPartDetails?.modelNodeIDs.length === 0;
  const isBeingAssignedToAnAlreadyAssignedPartDetails =
    partDetailsForm &&
    newPartDetails &&
    partDetailsForm.svtPartID === newPartDetails?.svtPartID &&
    newPartDetails?.modelNodeIDs.length > 0;

  if (!hasBeenPreviouslyAssociated) {
    if (isBeforeAssignment) {
      return <MissingAssignment />;
    }
    if (isBeingAssignedToNewPart) {
      return <NewPartDetails />;
    }
    if (isBeingAssignedToAFloatingPart) {
      return <FloatingPartDetails />;
    }
    if (isBeingAssignedToAnAlreadyAssignedPartDetails) {
      return <AssignedPartDetails howManyAssigned={newPartDetails.modelNodeIDs.length} />;
    }
  }
  if (hasBeenPreviouslyAssociated) {
    if (!partDetailsForm) {
      return <AssignmentTip />;
    }
    if (isBeforeAssignment) {
      if (hasBeenPreviouslyAssociatedToPartDetailsAssociatedWithMultipleNodes)
        return <AssignedPartDetails howManyAssigned={originalPartDetails.modelNodeIDs.length} />;
    }
    if (isBeingAssignedToNewPart) {
      return <NewPartDetails />;
    }
    if (isBeingAssignedToAFloatingPart) {
      return <FloatingPartDetails />;
    }
    if (isBeingAssignedToAnAlreadyAssignedPartDetails) {
      return (
        <AssignedPartDetailsExtended
          howManyAssigned={originalPartDetails.modelNodeIDs.length + newPartDetails.modelNodeIDs.length}
        />
      );
    }
  }
  return null;
}

export default function EditPartsSnackBar(props: EditPartsSnackBarProps) {
  return (
    <SnackBarTextContainer>
      <MessageLogic {...props} />
    </SnackBarTextContainer>
  );
}
