import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { Object3D } from 'three';

import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { Toast } from 'src/components/Toast';
import { addToIndexedDBAsBuffer } from 'src/hooks/useIndexedDBState';
import useShoppingCartQuery from './useShoppingCartQuery';
import useShoppingCartMutation from './useShoppingCartMutation';

export type MetaObject = { geometries: any; materials: any; textures: any; images: any; metadata: any };

export type CartEntry = ShoppingCartItem & { metaObject?: MetaObject };

export type ShoppingCart = {
  panelOpen: boolean;
  setPanelOpen: Dispatch<SetStateAction<boolean>>;
};

export const ShoppingCartPanelContext = createContext<ShoppingCart>({} as ShoppingCart);

export function ShoppingCartPanelProvider({ children }: any) {
  const [panelOpen, setPanelOpen] = useState(false);

  const { isError: isErrorLoading, isRefetchError } = useShoppingCartQuery();
  const { isError: isErrorMutating } = useShoppingCartMutation();

  const value = useMemo(
    () => ({
      panelOpen,
      setPanelOpen,
    }),
    [panelOpen]
  );

  return (
    <ShoppingCartPanelContext.Provider value={value}>
      {children}
      <Toast
        dependency={isErrorLoading || isRefetchError || isErrorMutating}
        title="There has been an error loading your shopping cart"
        severity="error"
      />
    </ShoppingCartPanelContext.Provider>
  );
}

export const extractAndSaveMetaObject = ({ object3DName = '', partNumber }: PartNode, model?: Object3D): void => {
  const foundObject = model?.getObjectByName(object3DName);
  if (foundObject) {
    addToIndexedDBAsBuffer(`svt:part:${partNumber}`, foundObject.toJSON());
  }
};
