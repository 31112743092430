import { styled, alpha } from '@mui/material';
import MUITextField from '@mui/material/TextField';

interface TextInputProps {
  label: string;
  type: string;
  value: string;
  isRequired?: boolean;
  onChange: any;
  error?: boolean;
  testId?: string;
}

export function LoginTextInput({ label, type, value, isRequired = false, onChange, error, testId }: TextInputProps) {
  return (
    <LoginTextField
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      required={isRequired}
      InputProps={{
        autoComplete: type,
      }}
      error={!!error}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        'data-testid': testId,
      }}
    />
  );
}

const LoginTextField = styled(MUITextField)<{ type: string }>`
  box-shadow: 0 0 2px ${({ theme }) => theme.palette.grey[700]};
  border-radius: 5px 4px 0px 0px;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.palette.brand.contrastText};

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  }

  & .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.2)};
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) =>
      theme.palette.brand.contrastText}!important; // override autofill background color and text color
    background-color: transparent !important;
  }

  & .MuiFilledInput-root::before {
    border-bottom: none;
  }

  & .MuiFilledInput-root::after {
    border-bottom: 1px solid ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiFilledInput-root.Mui-error {
    border-bottom: none;
  }

  & .MuiInputLabel-root.Mui-error {
    color: ${({ theme }) => theme.palette.error.main};
  }

  & .MuiFilledInput-root.Mui-error::after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.error.main};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.brand.contrastText};
    caret-color: ${({ theme }) => theme.palette.brand.contrastText};
  }
`;

LoginTextField.defaultProps = {
  variant: 'filled',
  fullWidth: true,
  InputLabelProps: { required: false },
};
