import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIBox from '@mui/material/Box';
import MUITypography from '@mui/material/Typography';

export const MobileMyOrdersDetailsHeader = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.grey[900]};
  min-width: 820px;
  margin: 1rem 0;
`;

export const OrderDetailsMainContent = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const OrderDetailsPanelTitle = styled(MUITypography)`
  margin-top: 1rem;
`;
OrderDetailsPanelTitle.defaultProps = { variant: 'h6', fontWeight: 'bold' };

export const OrderDetailsDivider = styled(MUIDivider)`
  width: 100%;
  height: 2px;
  align-self: center;
  border-color: ${({ theme }) => theme.palette.grey[300]};
`;

export const TotalDividerLight = styled(MUIDivider)`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  align-self: flex-end;
`;
