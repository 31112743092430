import Markdown from 'markdown-to-jsx';
import { useEffect, useRef } from 'react';
import MarkdownLink from './MarkdownLink';
import { RepairText, ScrollContainer } from './styles';

interface Props {
  description: string;
}

export default function Description({ description = '' }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [description]);

  return (
    <RepairText data-testid="repair-description">
      <ScrollContainer ref={ref}>
        <Markdown
          key={
            description
            // SVT-2113, by forcing unmount of entire component we go around google translate problem when it inserts addditional <font> nodes in the DOM, see https://github.com/facebook/react/issues/11538
          }
          options={{
            overrides: {
              a: MarkdownLink,
            },
          }}
        >
          {description}
        </Markdown>
      </ScrollContainer>
    </RepairText>
  );
}
