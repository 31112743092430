import { DevTools } from 'src/components/DevTools';
import LoginFormContainer from 'src/components/LoginForm';
import { UnauthenticatedTemplate } from 'src/templates/UnauthenticatedTemplate';

export function WelcomePage() {
  return (
    <UnauthenticatedTemplate>
      <LoginFormContainer />
      {process.env.REACT_APP_DEV_TOOLS_ENABLED === 'true' && <DevTools />}
    </UnauthenticatedTemplate>
  );
}
