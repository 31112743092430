import { styled } from '@mui/material';
import { StyledMUIPrimaryButton } from 'src/components/Button';
import MUITypography from '@mui/material/Typography';

export const MyAccountContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 95%;
  width: min-content;
  margin: auto;
`;

export const MyAccountTitleContainer = styled('div')`
  display: flex;
`;

export const MyAccountTitle = styled(MUITypography)`
  margin-left: 1rem;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const LogOutButton = styled(StyledMUIPrimaryButton)`
  display: flex;
  justify-content: space-around;
  width: 7rem;
  white-space: nowrap;
`;

export const MyAccountHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const MyAccountOptionCardsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  div {
    margin-left: 1rem;
  }
  div:first-of-type {
    margin-left: 0;
  }
`;

export const MobileMyAccountTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  margin: auto 1.5rem;
  font-size: 1.5rem;
`;

export const MobileMyAccountContainer = styled(MyAccountContainer)`
  height: auto;
  width: 70%;
`;

export const MobileMyAccountHeader = styled(MyAccountHeader)`
  margin-top: 1rem;
  font-size: 3rem;
`;

export const MobileMyAccountOptionCardsContainer = styled(MyAccountOptionCardsContainer)`
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  div {
    margin-bottom: 1rem;
    margin-left: 0;
  }
  div:last-of-type {
    margin-bottom: 0;
  }
`;
