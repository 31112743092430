import { styled } from '@mui/material';

import { StyledMUIButton } from 'src/components/Button';
import { FlexContainer } from 'src/components/FlexContainer';
import { FlexItem } from 'src/components/FlexItem';
import { spacing } from 'src/constants/spacing';

export const ButtonWrapper = styled(FlexContainer)`
  margin-top: ${spacing.largeSpacing};
`;

export const ConfirmContainer = styled(FlexItem)`
  flex-grow: 1;
  margin-right: ${spacing.mediumSpacing};
`;

export const ModalButton = styled(StyledMUIButton)`
  display: block;
  width: 100%;
`;

export const CancelContainer = styled(FlexItem)`
  flex-grow: 1;
`;
