import { Link } from 'react-router-dom';
import { styled, darken, alpha } from '@mui/material';

import { Icon } from 'src/components/Icon';
import { spacing } from 'src/constants/spacing';
import { border, radius } from 'src/constants/borderSizes';
import { stepNumberCircleDiameter } from 'src/constants/sizes';
import type { IRepairStepView } from 'src/providers/repairs/types';

const StepLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 ${spacing.smallSpacing} ${spacing.smallSpacing};

  :not(.current) {
    .step-number {
      background: ${({ theme }) => darken(theme.palette.sidebarBackground.contrastText, 0.56)};

      :hover {
        background-color: ${({ theme }) => theme.palette.secondary.dark};
        transition: all ease 0.3s;
      }
    }
  }

  &.current {
    .step-number {
      background: ${({ theme }) => theme.palette.secondary.dark};
    }
    .step-name {
      color: ${({ theme }) => theme.palette.sidebarBackground.contrastText} !important;
    }
  }
`;

const StepText = styled('h4')`
  padding: ${spacing.smallSpacing};
  margin: 0;
  font-weight: normal;
`;

const StepNumberCircle = styled('div')`
  border-radius: ${radius.xLarge};
  border: ${border.xSmall} solid ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  min-width: ${stepNumberCircleDiameter};
  height: ${stepNumberCircleDiameter};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 1;

  &.step-complete {
    background: ${({ theme }) => theme.palette.secondary.contrastText} !important;
  }
`;

const StepNumber = styled('h4')`
  margin: 0;
`;

const StepName = styled(StepText)`
  max-width: 18.75rem;
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.7)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const VerticalLine = styled('div')`
  border-left: ${({ theme }) => theme.palette.sidebarBackground.contrastText} solid ${border.xSmall};
  height: 14px;
  position: relative;
  left: 1.125rem;
  top: -23px;
`;

interface IProps {
  step: IRepairStepView;
  index: number;
  currentStepNumber: number;
}

export function RepairStep({ step, index, currentStepNumber }: IProps) {
  const isCurrentStep = currentStepNumber === index;
  const isComplete = currentStepNumber > index;

  return (
    <li>
      <StepLink to={step.url} replace className={isCurrentStep ? 'current' : ''} data-testid="repair-step-content">
        {index > 0 && <VerticalLine />}
        <StepNumberCircle className={`step-number ${isComplete && 'step-complete'}`} data-testid="repair-step-number">
          {isComplete ? <Icon name="COMPLETED" label="Completed" /> : <StepNumber>{index + 1}</StepNumber>}
        </StepNumberCircle>
        <StepName className="step-name" data-testid="repair-step-name">
          {step.name}
        </StepName>
      </StepLink>
    </li>
  );
}
