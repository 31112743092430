import { faCartCircleCheck, faListTree } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { StyledMUIButton } from 'src/components/Button';
import { CheckoutConfirmationProps } from '../Confirmation';

type MobileCheckoutConfirmationProps = Omit<CheckoutConfirmationProps, 'gtmData'>;

export function MobileCheckoutConfirmation({ confirmationNo, isOrderPOR }: MobileCheckoutConfirmationProps) {
  const { push } = useHistory();

  return (
    <ConfirmationContainer>
      <ConfirmationTitle>
        Thank you, your {isOrderPOR ? 'quote' : 'order'} has been submitted successfully
      </ConfirmationTitle>
      <MainContent>
        <FontAwesomeIcon icon={faCartCircleCheck} size="6x" />
        <ConfirmationBody>
          The confirmation number for your {isOrderPOR ? 'quote' : 'order'} is <strong>{confirmationNo}</strong>.{' '}
          {isOrderPOR ? 'A' : 'An order'} summary has been sent to your email.
          <br />
          This can take a few minutes, if nothing is received please check your Spam folder.
        </ConfirmationBody>
      </MainContent>
      <BottomPageSection>
        <MobileConfirmationBody>
          Please use the button below to continue ordering from the Parts Catalogue
        </MobileConfirmationBody>
        <Footer>
          <StyledMUIButton
            data-testid="empty-cart-button"
            startIcon={<FontAwesomeIcon icon={faListTree} size="sm" />}
            onClick={() => push('/product-library')}
          >
            Browse the Parts Catalogue
          </StyledMUIButton>
        </Footer>
      </BottomPageSection>
    </ConfirmationContainer>
  );
}

const MainContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ConfirmationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[900]};
  width: 100%;
  height: 100%;
`;

const ConfirmationTitle = styled(MUITypography)`
  text-align: center;
  margin-top: 5rem;
`;
ConfirmationTitle.defaultProps = { variant: 'h4' };

const ConfirmationBody = styled(MUITypography)`
  text-align: center;
  margin-top: 2rem;
`;
ConfirmationBody.defaultProps = { variant: 'body1' };

const MobileConfirmationBody = styled(MUITypography)`
  text-align: center;
  margin: 1.5rem 0;
`;
MobileConfirmationBody.defaultProps = { variant: 'body1' };
const Footer = styled('div')`
  width: 100%;
  display: flex;
  height: 68px;

  background-color: ${({ theme }) => theme.palette.primary.main};

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BottomPageSection = styled('div')`
  width: 100%;
`;
