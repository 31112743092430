import { styled } from '@mui/material';

import { radius } from 'src/constants/borderSizes';
import { spacing } from 'src/constants/spacing';

export interface IProps {
  percentUploaded: number | undefined;
}

const UploadPercentageContainer = styled('div')`
  background: ${({ theme }) => theme.palette.grey['300']};
  border-radius: ${radius.medium};
  margin-bottom: ${spacing.xSmallSpacing};
  width: 100%;
`;

const darkYellow = '#e5c82b';
const yellow = '#ebdf79';

const UploadPercentageFill = styled('div')`
  height: ${spacing.mediumSpacing};
  max-width: 100%;
  background-image: linear-gradient(
    45deg,
    ${darkYellow} 20%,
    ${yellow} 20%,
    ${yellow} 40%,
    ${darkYellow} 40%,
    ${darkYellow} 60%,
    ${yellow} 60%,
    ${yellow} 80%,
    ${darkYellow} 80%,
    ${darkYellow}
  );
  border-radius: ${radius.medium};
  background-size: ${spacing.mediumSpacing} ${spacing.largeSpacing};
  transition: width 50ms linear;

  animation: progress-bar-stripes 1s linear infinite;

  @keyframes progress-bar-stripes {
    from {
      background-position: ${spacing.xLargeSpacing} 0;
    }
    to {
      background-position: 0 0;
    }
  }
`;

export function UploadPercentage({ percentUploaded = 0 }: IProps) {
  return (
    <UploadPercentageContainer data-testid="upload-percentage">
      <UploadPercentageFill style={{ width: `${percentUploaded}%` }} />
    </UploadPercentageContainer>
  );
}
