import { styled } from '@mui/material';

import MUITextField from '@mui/material/TextField';

export const WhiteTextField = styled(MUITextField)`
  & .MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) => theme.palette.grey[900]} !important; // override autofill background color and text color
    background-color: transparent !important; // override autofill background color and text color
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    border-bottom: none;
  }

  .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  & .MuiFilledInput-root::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiFilledInput-root:hover::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.grey[900]};
  }

  ${({ theme }) => theme.mixins.hideArrowsInNumberTypeInput}
`;
WhiteTextField.defaultProps = {
  variant: 'filled',
};
