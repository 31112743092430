import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { ResourceType } from '@samsonvt/shared-types/userLambda';

export const productFeatureNames: { [key in ProductFeature]: string } = {
  'parts-catalogue': 'Parts Catalogue',
  'work-instructions': 'Work Instructions',
};

export const productFeaturePermissions: { [key in ProductFeature]: ResourceType } = {
  'parts-catalogue': 'product.partsCatalogue',
  'work-instructions': 'product.workInstructions',
};
