import { useEffect, useState } from 'react';
import MUISnackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material';
import MUIAlert, { AlertColor } from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-light-svg-icons';

interface ToastProps<T> {
  autoHideDuration?: number | null;
  dependency: T; // Must not be referentially stable if you want to show the toast on every set
  title: string;
  message?: string;
  severity: AlertColor;
  predicate?: (dependency: T) => boolean; // Must be referentially stable
  onClose?: () => void;
}

export function Toast<T>({
  severity,
  autoHideDuration = 5000,
  dependency,
  title,
  message = '',
  predicate = Boolean,
  onClose,
}: ToastProps<T>) {
  const [opened, setOpened] = useState(false);
  useEffect(() => setOpened(predicate(dependency)), [dependency, predicate]);

  const handleClose = () => {
    setOpened(false);
    onClose?.();
  };

  return (
    <MUISnackbar
      open={opened}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ClickAwayListenerProps={{ mouseEvent: 'onMouseDown' }}
    >
      <MUIAlert
        severity={severity}
        variant="filled"
        iconMapping={{
          success: <FontAwesomeIcon icon={faCircleCheck} size="1x" />,
          error: <FontAwesomeIcon icon={faCircleXmark} size="1x" />,
        }}
        data-testid="toast-notification"
      >
        <ColumnWithGap>
          <strong>{title}</strong>
          {message}
        </ColumnWithGap>
      </MUIAlert>
    </MUISnackbar>
  );
}

// use it instead of useState() if you want to trigger the toast imperatively from an arbitrary place
export const useTrigger = () => {
  const [refVal, setRefVal] = useState<object | null>(null);
  return [refVal, () => setRefVal({})] as const;
};

const ColumnWithGap = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 9px;
  text-align: left;
`;
