import { styled } from '@mui/material';
import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';

interface FooterProps {
  position?: string;
  transparent?: boolean;
}

const innerFooterHeight = '2.25rem';

const shouldForwardProp = (prop: string) => !['position', 'transparent'].includes(prop);

const StyledFooter = styled('div', { shouldForwardProp })<FooterProps>`
  font-size: ${font.xSmall};
  display: flex;
  padding: ${({ transparent }) => `0 ${transparent ? spacing.mediumSpacing : spacing.largeSpacing}`};
  background: ${({ theme, transparent }) => (transparent ? 'transparent' : theme.palette.primary.main)};
  width: 100%;
  border-top: ${({ theme, transparent }) => (transparent ? `2px solid ${theme.palette.primary.contrastText}` : 'none')};
  align-items: center;
  justify-content: space-between;
  height: ${innerFooterHeight};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  bottom: 0;
  position: ${({ position }) => position || 'inherit'};
  flex-shrink: 0;
`;

const StyledA = styled('a')`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-decoration: underline;
`;

export function Footer(props: FooterProps) {
  return (
    <StyledFooter {...props}>
      <span>
        Powered by&nbsp;
        <StyledA href="https://partful.io/">
          <strong>Partful</strong>
        </StyledA>
      </span>
      <span>© Copyright {new Date().getFullYear()}</span>
    </StyledFooter>
  );
}
