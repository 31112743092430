import { Box, styled } from '@mui/material';
import MUISkeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import NotFoundPNG from './image-not-loading.png';

interface ThumbnailProps {
  url?: string;
  alt: string;
}

interface ThumbnailDisplayProps {
  loading?: boolean;
  errored?: boolean;
}

const removeUnwantedPropsFromHtmlElement = {
  shouldForwardProp: (propName: string) => !['errored', 'loading'].includes(propName),
};

const ThumbnailContainer = styled('div', removeUnwantedPropsFromHtmlElement)<ThumbnailDisplayProps>`
  background: ${({ theme }) => theme.palette.grey['500']};
  aspect-ratio: 4/3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, errored }) =>
    errored ? theme.palette.common.white : ({ theme }) => theme.palette.common.white};
`;

const LoadingDisplay = styled(MUISkeleton)`
  width: 100%;
  height: 100%;
  display: flex;
  transform: none;
`;

const NotLoadedText = styled('p')`
  color: white;
  height: 1.5rem;
  width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 1.5rem;
`;

// Using !important for height since an MUI style (MuiCardMedia-root img) has a higher precedence
// than the custom defined styles.
const ThumbnailImage = styled('img', removeUnwantedPropsFromHtmlElement)<ThumbnailDisplayProps>`
  opacity: 0;
  transition: opacity 1s ease-in;
  -webkit-animation: opacity 1s ease-in;

  height: ${({ loading, errored }) => (loading || errored ? '0' : 'fit-content')} !important;
  margin-bottom: 0 !important;
  max-height: 100%;
  position: ${({ loading, errored }) => (loading || errored ? 'absolute' : 'relative')};
  opacity: ${({ loading }) => (loading ? '0' : '1')};
  display: ${({ errored }) => (errored ? 'none' : 'block')};
`;

const NotFound = styled(Box)`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export function Thumbnail({ url, alt }: ThumbnailProps) {
  const [loading, setIsLoading] = useState(true);
  const [errored, setHasErrored] = useState(false);

  // Images must be in a 4:3 aspect ratio
  return (
    <ThumbnailContainer errored={errored}>
      {loading && (
        <>
          <LoadingDisplay animation="wave" />
          <NotLoadedText>{alt}</NotLoadedText>
        </>
      )}
      {errored && (
        <NotFound>
          <img src={NotFoundPNG} alt="This preview could not be found" />
        </NotFound>
      )}
      {url && (
        <ThumbnailImage
          src={url}
          // @ts-ignore
          loading={loading}
          errored={errored}
          alt={alt}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setHasErrored(true);
            setIsLoading(false);
          }}
        />
      )}
    </ThumbnailContainer>
  );
}
