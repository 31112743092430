import MUIPortal from '@mui/material/Portal';
import { useMutation } from '@tanstack/react-query';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import type { EditPartDetailsRequestBody, NewPartDetailsRequestBody } from '@samsonvt/shared-types/partService';
import { useInvalidateProductQuery } from 'src/services/useInvalidateProductQuery';
import { createPartDetails, editPartDetails, nodesAssign, nodesDissoc } from 'src/services/api';
import { reactQueryGetErrorMessage } from 'src/utils/reactQueryGetErrorMessage';
import { DetailsQueryHost } from './DetailsQueryHost';
import { Toast } from '../Toast';

export type CreateAndAssignParams = { partDetails: NewPartDetailsRequestBody; editedNodes: string[] };
export type UpdateAndAssignParams = { partDetails: EditPartDetailsRequestBody; editedNodes: string[] };
export type DisassociateParams = { editedNodes: string[] };

type Props = {
  partNode: PartNode & { modelNodeID: string };
  handleClose: () => void;
  modalOpen: boolean;
};
/** Serves as mutation host and displays toasts on mutation success.
 *  Must not be removed when modal closes, so that success toast won't disappear.
 */
export function MutationsHost(props: Props) {
  const { invalidateProductQuery } = useInvalidateProductQuery();

  const {
    mutateAsync: mutateCreateAndAssignPartDetails,
    isLoading: isLoadingCreateAndAssignPartDetails,
    isError: isErrorCreateAndAssignPartDetails,
    error: errorCreateAndAssignPartDetails,
    data: dataCreateAndAssignPartDetails,
  } = useMutation(
    ['createPartDetails'],
    async ({ partDetails, editedNodes }: CreateAndAssignParams) => {
      const {
        data: { svtPartID: svtPartIdToMatch },
      } = await createPartDetails(partDetails);
      return nodesAssign({ modelNodeIdsToMatch: editedNodes, svtPartIdToMatch });
    },
    { onSuccess: invalidateProductQuery }
  );

  const {
    mutateAsync: mutateUpdateAndAssignPartDetails,
    isLoading: isLoadingUpdateAndAssignPartDetails,
    isError: isErrorUpdateAndAssignPartDetails,
    error: errorUpdateAndAssignPartDetails,
    data: dataUpdateAndAssignPartDetails,
  } = useMutation(
    ['createPartDetails'],
    async ({ partDetails, editedNodes }: UpdateAndAssignParams) => {
      editPartDetails(partDetails);
      return nodesAssign({ modelNodeIdsToMatch: editedNodes, svtPartIdToMatch: partDetails.svtPartID });
    },
    { onSuccess: invalidateProductQuery }
  );

  const {
    mutateAsync: mutateRemovePartDetailsFromNode,
    isError: isErrorRemovePartDetails,
    error: errorRemovePartDetails,
    data: dataRemovePartDetails,
  } = useMutation(
    ['removePartDetails'],
    async ({ editedNodes }: DisassociateParams) => nodesDissoc({ modelNodeIdsToMatch: editedNodes }),
    { onSuccess: invalidateProductQuery }
  );

  return (
    <>
      {
        // eslint-disable-next-line react/destructuring-assignment
        props.modalOpen ? ( // Unmount and clean queries on modal close
          <DetailsQueryHost
            {...props}
            mutateRemovePartDetailsFromNode={mutateRemovePartDetailsFromNode}
            mutateUpdateAndAssignPartDetails={mutateUpdateAndAssignPartDetails}
            mutateCreateAndAssignPartDetails={mutateCreateAndAssignPartDetails}
            isLoadingMutations={isLoadingUpdateAndAssignPartDetails || isLoadingCreateAndAssignPartDetails}
          />
        ) : null
      }
      <MUIPortal>
        <Toast
          dependency={dataUpdateAndAssignPartDetails}
          severity="success"
          title="Successfully updated and assigned part"
        />
        <Toast
          dependency={isErrorUpdateAndAssignPartDetails}
          severity="error"
          title="Error editing or assigning this part"
          message={reactQueryGetErrorMessage(errorUpdateAndAssignPartDetails)}
        />
        <Toast
          dependency={dataCreateAndAssignPartDetails}
          severity="success"
          title="Successfully created and assigned part"
        />
        <Toast
          dependency={isErrorCreateAndAssignPartDetails}
          severity="error"
          title="Error creating or assigning part"
          message={reactQueryGetErrorMessage(errorCreateAndAssignPartDetails)}
        />
        <Toast dependency={dataRemovePartDetails} severity="success" title="Successfully removed part details" />
        <Toast
          dependency={isErrorRemovePartDetails}
          severity="error"
          title="Error removing part details"
          message={reactQueryGetErrorMessage(errorRemovePartDetails)}
        />
      </MUIPortal>
    </>
  );
}
