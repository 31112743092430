export const horizonRenderOrder = 0;
export const shadowRenderOrder = 1;

export const horizonShader = {
  vertexShader: `  
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
  fragmentShader: `
      varying vec2 vUv;
      uniform vec3 color;
          void main(){
              vec2 res = vUv - vec2(0.5, 0.5);
              float intensity = pow(0.4, length(res));
              vec3 finalColor = color * intensity;
              vec4 finalColorWithAlpha = vec4(finalColor.x, finalColor.y, finalColor.z, 1.0);
              gl_FragColor = finalColorWithAlpha;
          }`,
};
