import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { StyledMUIButtonLink } from 'src/components/Button';
import useFormattedPrice from 'src/services/formatCurrency';

interface CheckoutSectionProps {
  isCartEmpty: boolean;
  totalPrice: DiscountedPartPrice;
  triggerPanel: () => void;
}

export default function MobileCheckoutSection({ totalPrice, isCartEmpty, triggerPanel }: CheckoutSectionProps) {
  const nextPath = isCartEmpty
    ? '#'
    : {
        pathname: '/checkout/shipping-info', // goes to the checkout details form
        state: { from: window.location.pathname },
      };

  const totalPriceToDisplay = isCartEmpty ? undefined : totalPrice;

  return (
    <>
      <TotalWrapper>
        <TotalLabel>Total:</TotalLabel>
        <TotalPrice data-testid="shopping-cart-panel-total-price">
          {useFormattedPrice({ price: totalPriceToDisplay })}
        </TotalPrice>
      </TotalWrapper>
      <CheckoutButtonWrapper>
        <CheckoutButton
          to={nextPath}
          onClick={triggerPanel}
          disabled={isCartEmpty}
          size="large"
          data-testid="shopping-cart-panel-checkout-button"
        >
          Continue
        </CheckoutButton>
      </CheckoutButtonWrapper>
    </>
  );
}

const TotalSectionHeight = '70px';
const CheckoutButtonHeight = '74px';

const TotalWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: ${TotalSectionHeight};
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  border-top: 2px solid ${({ theme }) => theme.palette.whiteGlass};
`;

const CheckoutButtonWrapper = styled('div')`
  text-align: center;
  width: 100%;
  height: ${CheckoutButtonHeight};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.grey[900]};
  padding: 1rem;
`;

const CheckoutButton = styled(StyledMUIButtonLink)`
  width: 100%;
  margin: 0;
`;

const TotalLabel = styled(MUITypography)`
  margin-right: 1rem;
  line-height: 1.8rem;
`;

const TotalPrice = styled(MUITypography)`
  white-space: pre;
  margin-right: 1rem;
`;
TotalPrice.defaultProps = {
  variant: 'h5',
  fontWeight: 'bold',
};
