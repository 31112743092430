import { Mesh, MeshNormalMaterial, Object3D, DodecahedronGeometry } from 'three';

const testMesh = new Mesh();
testMesh.geometry = new DodecahedronGeometry(100, 0);
testMesh.material = new MeshNormalMaterial();
const scene = new Object3D();
scene.add(testMesh);

export const randomItem = {
  partName: 'Alpha',
  partNumber: 'S-001',
  unitPrice: 0.17,
  quantity: 1,
  metaObject: scene.toJSON(),
  productId: 'product',
};
