import { faCartShopping, faGridHorizontal, faListCheck, faCartCircleCheck } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, css, styled } from '@mui/material';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useContext } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';
import samsonLogoUrl from 'src/assets/logo-default.svg';
import { FlexContainer } from 'src/components/FlexContainer';
import { isFeatureSupported } from 'src/Feature';
import { usePermissions } from 'src/hooks/usePermissions';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import { PAGES } from '..';
import { LogoLinkElement, StyledClientLogo } from '../styles';
import { TenantSwitcher } from '../TenantSwitcher';
import { MyAccountLink } from '../MyAccountLink';

export function DesktopMenu() {
  const { logoUrl, name } = useUser();
  const { enabledFeatures } = useContext(TenantContext);

  const hasPermissionsToOperateOnAllOfTenantsOrders = usePermissions(
    ['list', 'order.history'],
    ['view', 'order.history'],
    ['update', 'order.history']
  );

  const { data: cart } = useShoppingCartQuery();
  const hasPermissionsToViewOwnsOrders = usePermissions(['list', 'order'], ['view', 'order'], ['email', 'order']);

  const itemsInCart = cart.reduce((total, { quantity }) => total + quantity, 0);

  const { pathname } = useLocation();
  const TABS_PATHS = Object.values(PAGES).map(({ path }) => path);
  const activeTabIndex = TABS_PATHS.findIndex((tab) => pathname.startsWith(tab));
  const activeTab = activeTabIndex === -1 ? '/product-library' : TABS_PATHS[activeTabIndex];

  const displayReceivedOrders =
    hasPermissionsToOperateOnAllOfTenantsOrders && isFeatureSupported('shopping-cart', enabledFeatures);
  const displayMyOrders =
    !hasPermissionsToOperateOnAllOfTenantsOrders &&
    hasPermissionsToViewOwnsOrders &&
    isFeatureSupported('shopping-cart', enabledFeatures);

  const displayShoppingCart = isFeatureSupported('shopping-cart', enabledFeatures);

  return (
    <DesktopMenuContainer>
      <LogoLinkElement to="/" data-testid="tenant-logo">
        <StyledClientLogo alt={name} src={logoUrl ?? samsonLogoUrl} />
      </LogoLinkElement>
      <FlexContainer sx={{ alignItems: 'center', mx: 2 }}>
        <TenantSwitcher />
      </FlexContainer>
      <StyledTabs
        value={activeTab}
        TabIndicatorProps={{
          sx: { background: (theme) => theme.palette.secondary.main },
        }}
      >
        <StyledTab
          sx={{ mr: 'auto' }}
          icon={<FontAwesomeIcon icon={faGridHorizontal} size="lg" />}
          label={PAGES.library.label}
          value={PAGES.library.path}
          to={PAGES.library.path}
          component={Link}
        />

        {displayReceivedOrders ? (
          <StyledTab
            icon={<FontAwesomeIcon icon={faListCheck} size="lg" />}
            value={PAGES.received_orders.path}
            label={PAGES.received_orders.label}
            to={PAGES.received_orders.path}
            component={Link}
          />
        ) : null}

        {displayMyOrders ? (
          <StyledTab
            icon={<FontAwesomeIcon icon={faCartCircleCheck} size="lg" />}
            value={PAGES.my_orders.path}
            label={PAGES.my_orders.label}
            to={PAGES.my_orders.path}
            component={Link}
          />
        ) : null}

        {displayShoppingCart ? (
          <StyledTab
            icon={<FontAwesomeIcon icon={faCartShopping} size="lg" />}
            value={PAGES.shopping_cart.path}
            label={PAGES.shopping_cart.label(itemsInCart)}
            to={PAGES.shopping_cart.path}
            component={Link}
            data-testid="nav-shopping-cart"
          />
        ) : null}
      </StyledTabs>
      <MyAccountLink />
    </DesktopMenuContainer>
  );
}

const StyledTabs = styled(Tabs)`
  ${({ theme }) => css`
    .MuiTab-root,
    .MuiTab-root.Mui-selected {
      color: ${theme.palette.brand.contrastText};
    }
    width: 100%;
    margin-right: ${theme.spacing(2)}px;
  `}
`;

const StyledTab = styled(Tab)<TabProps & LinkProps>`
  min-height: 4.5rem; // SVT-2029 overriding material's 72px with rem's to enable scaling.
`;

const DesktopMenuContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
