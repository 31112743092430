import { styled } from '@mui/material';
import MUIStep from '@mui/material/Step';
import MUIStepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';
import MUITypography from '@mui/material/Typography';

import { CustomIcon } from './CustomIcon';

const Stepper = styled(MUIStepper)`
  margin-top: 1rem;
  margin-bottom: 3rem;
  & .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

const Label = styled(MUITypography, { shouldForwardProp: (prop: string) => prop !== 'active' && prop !== 'done' })<{
  active: boolean;
  done: boolean;
}>`
  color: ${({ theme, active, done }) =>
    (active && theme.palette.grey[900]) || (done && theme.palette.grey[900]) || (!active && theme.palette.grey[600])};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

Label.defaultProps = { variant: 'body2' };

interface CheckoutStepperProps {
  currentStep: number;
}

export function CheckoutStepper({ currentStep }: CheckoutStepperProps) {
  return (
    <Stepper activeStep={currentStep}>
      <MUIStep>
        <MUIStepLabel StepIconComponent={CustomIcon}>
          <Label active={currentStep === 0} done={currentStep > 0}>
            Review your order
          </Label>
        </MUIStepLabel>
      </MUIStep>
      <MUIStep>
        <MUIStepLabel StepIconComponent={CustomIcon}>
          <Label active={currentStep === 1} done={currentStep > 1}>
            Add order details
          </Label>
        </MUIStepLabel>
      </MUIStep>
      <MUIStep>
        <MUIStepLabel StepIconComponent={CustomIcon}>
          <Label active={currentStep === 2} done={currentStep > 2}>
            Order completed
          </Label>
        </MUIStepLabel>
      </MUIStep>
    </Stepper>
  );
}
