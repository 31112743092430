import { faCircleUser } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

export function MyAccountLink() {
  return (
    <Box component="div" sx={{ flexGrow: 0 }}>
      <Tooltip title="My account">
        <UserSettingIconButton to="/my-account" sx={{ mr: 2 }} data-testid="my-account-link">
          <FontAwesomeIcon icon={faCircleUser} size="2x" />
        </UserSettingIconButton>
      </Tooltip>
    </Box>
  );
}

const UserSettingIconButton = styled(Link)`
  color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.87)};
  background-color: ${({ theme }) => theme.palette.brand.main};
  padding: 0.5rem 1rem;
`;
