import { styled } from '@mui/material';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { StyledMUIButtonLink } from 'src/components/Button';
import { spacing } from 'src/constants/spacing';
import { productFeatureNames } from 'src/constants/features';

const ProductActions = styled('div')`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${spacing.smallSpacing};
`;
interface ProductCardActionsProps {
  productFeatures: ProductFeature[];
  productId: string;
  canViewPartsCatalogue: boolean;
  canViewWorkInstructions: boolean;
}

export function ProductCardActions({
  productFeatures,
  productId,
  canViewPartsCatalogue,
  canViewWorkInstructions,
}: ProductCardActionsProps) {
  const permissions = {
    'parts-catalogue': canViewPartsCatalogue,
    'work-instructions': canViewWorkInstructions,
  };
  return (
    <ProductActions>
      {productFeatures.map((productFeature) => {
        const name = productFeatureNames[productFeature];
        const isPermitted = permissions[productFeature];
        return isPermitted ? (
          <StyledMUIButtonLink
            sx={{ margin: '0 4px' }}
            key={productFeature}
            to={`/product-library/${productId}/${productFeature}`}
            data-testid={`action-button-${productFeature}`}
          >
            {name}
          </StyledMUIButtonLink>
        ) : null;
      })}
    </ProductActions>
  );
}
