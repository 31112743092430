import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import GlobalProductNameSearch from 'src/components/GlobalProductNameSearch/GlobalProductNameSearch';
import {
  HeaderContainer,
  HeaderContainerFirstRow,
  CategoryHeaderBackButton,
  CategoryHeaderSearchButton,
  HeaderContainerSecondRow,
} from './styles';

export function MobileProductCategoryHeader() {
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <HeaderContainer>
      <HeaderContainerFirstRow>
        <CategoryHeaderBackButton startIcon={<FontAwesomeIcon icon={faChevronLeft} size="xs" />} to="/product-library">
          All categories
        </CategoryHeaderBackButton>
        <CategoryHeaderSearchButton
          onClick={() => setOpenSearch((prev) => !prev)}
          data-testid="open-search-category-header"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </CategoryHeaderSearchButton>
      </HeaderContainerFirstRow>
      {openSearch && (
        <HeaderContainerSecondRow>
          <GlobalProductNameSearch />
        </HeaderContainerSecondRow>
      )}
    </HeaderContainer>
  );
}
