import { styled } from '@mui/material/styles';
import { drawerHeight } from './constants';

export const DrawerSibling = styled('div', { shouldForwardProp: (prop: string) => prop !== 'expanded' }) <{
  expanded: boolean;
  offset: string;
}>`
  height: ${({ expanded, offset = '0px' }) =>
    expanded ? `calc(50% - ${offset})` : `calc(100% - ${drawerHeight}px - ${offset})`};
  top: ${({ offset }) => offset};
  width: 100%;
  position: fixed;
  display: flex;
`;
