import MUITypography from '@mui/material/Typography';
import { useRouteMatch } from 'react-router';
import { Icon } from 'src/components/Icon';
import { BackChevron, DesktopWorkInstructionsBackLink, Header, RepairTitle } from './styles';

interface NestedListHeaderProps {
  productName: string | undefined;
  tagName: string | undefined;
}

export default function NestedListHeader({ productName, tagName }: NestedListHeaderProps) {
  const { url } = useRouteMatch(); // We want to cut out query params for back link, and matched url doesnt have them
  return (
    <DesktopWorkInstructionsBackLink to={url}>
      <RepairTitle>
        <BackChevron>
          <Icon name="arrow-left" />
        </BackChevron>
        <Header>
          <MUITypography variant="h5" data-testid="repair-name">
            {tagName}
          </MUITypography>

          <MUITypography variant="body1" data-testid="product-name">
            for <strong>{productName}</strong>
          </MUITypography>
        </Header>
      </RepairTitle>
    </DesktopWorkInstructionsBackLink>
  );
}
