import MUIFormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { LoadingSpinner } from 'src/components/Loading';
import { TelephoneInput } from 'src/components/TextInput/TelephoneInput';

import { MyProfileProps } from '../../AccountDetailsPage';
import { AccountDetailsFormButton } from '../styles';
import {
  MyProfileFormWrapper,
  FormContainer,
  SectionsContainer,
  LeftSection,
  Row,
  MyProfileInputField,
  MyProfileSubTitle,
  OptionalMyProfileLabel,
  MyProfileSelectLabel,
  MyProfileSelectField,
  MyProfileSelectOption,
  MyProfileDivider,
  RightSection,
  UnEditableInputField,
  UnEditableInputFieldLabel,
  UnEditableInputFieldValue,
  SectionsTitlesContainer,
} from './styles';

export default function MyProfileForm({
  userDetailsFormState,
  handleMyProfileFormChange,
  submitMyProfileForm,
  userEmail,
  isApplyUserLoading,
  countryList,
}: MyProfileProps) {
  const { name, companyName, telephone, buildingName, streetName, city, postalCode, country } = userDetailsFormState;

  const [validate, setValidate] = useState(false);
  const validateOnSubmission = (condition: boolean) => validate && condition;

  return (
    <MyProfileFormWrapper onSubmit={submitMyProfileForm}>
      <FormContainer>
        <SectionsTitlesContainer>
          <MyProfileSubTitle>My details:</MyProfileSubTitle>
          <MyProfileSubTitle>Address:</MyProfileSubTitle>
        </SectionsTitlesContainer>
        <SectionsContainer>
          <LeftSection>
            <MyProfileInputField
              label="Full name"
              name="name"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!name)}
              value={name}
            />
            <MyProfileInputField
              label="Company name"
              name="companyName"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!companyName)}
              value={companyName}
            />
            <TelephoneInput
              Component={MyProfileInputField}
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!telephone)}
              value={telephone}
            />
            <UnEditableInputField>
              <UnEditableInputFieldLabel>Email</UnEditableInputFieldLabel>
              <UnEditableInputFieldValue>{userEmail}</UnEditableInputFieldValue>
            </UnEditableInputField>
          </LeftSection>

          <MyProfileDivider />

          <RightSection>
            <MyProfileInputField
              label={<OptionalMyProfileLabel label="Building name / Number" />}
              name="buildingName"
              onChange={handleMyProfileFormChange}
              value={buildingName}
            />
            <MyProfileInputField
              label="Street address"
              name="streetName"
              onChange={handleMyProfileFormChange}
              required
              error={validateOnSubmission(!streetName)}
              value={streetName}
            />
            <Row>
              <MyProfileInputField
                label="Town / City"
                name="city"
                onChange={handleMyProfileFormChange}
                required
                error={validateOnSubmission(!city)}
                value={city}
              />
              <MyProfileInputField
                label="Postcode"
                name="postalCode"
                onChange={handleMyProfileFormChange}
                required
                error={validateOnSubmission(!postalCode)}
                value={postalCode}
              />
            </Row>
            <MUIFormControl
              fullWidth
              variant="filled"
              size="small"
              required
              error={validateOnSubmission(!country)}
              data-testid="country-select"
            >
              <MyProfileSelectLabel>Country</MyProfileSelectLabel>
              <MyProfileSelectField value={country} name="country" onChange={handleMyProfileFormChange}>
                {countryList.map((countryName) => (
                  <MyProfileSelectOption value={countryName} key={countryName}>
                    {countryName}
                  </MyProfileSelectOption>
                ))}
              </MyProfileSelectField>
            </MUIFormControl>
          </RightSection>
        </SectionsContainer>
        <AccountDetailsFormButton type="submit" onClick={() => setValidate(true)} data-testid="submit-account-details">
          {isApplyUserLoading ? <LoadingSpinner width="100px" height="100px" /> : 'Save details'}
        </AccountDetailsFormButton>
      </FormContainer>
    </MyProfileFormWrapper>
  );
}
