import styled from '@emotion/styled';
import UploadIcon from './UploadIcon';
// import { Uploader } from 'src/components/Uploader';
// import { MainTemplate } from 'src/templates/MainTemplate';
// import { ContentPadding } from 'src/components/ContentPane';

export function Header() {
  return (
    <Rows>
      <div style={{ fontSize: '1.5rem', display: 'flex', gap: '0.5rem' }}>
        <UploadIcon />
        Uploads
      </div>
      <Columns>
        {/* 
        Commented out until we have product creation
        <div style={{ width: '50%', lineHeight: 1.5 }}>
          <strong>Welcome to the Uploads page!</strong>
          <br />
          Here, you can easily create your 3D products that will appear in the Library page.
        </div> */}
        <div style={{ width: '50%', lineHeight: '1.5rem' }}>
          You can also upload any additional files, such as instruction manuals, BOM data, PDFs, and spreadsheets, which
          will assist us in creating your Parts Catalogue or Work Instructions.
        </div>
      </Columns>
    </Rows>
  );
}

const Rows = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Columns = styled('div')`
  display: flex;
  flex-direction: row;
`;
