export function DefaultStudioLighting() {
  return (
    <>
      <directionalLight
        position={[-0.021620146930217743, 1.4572889804840088, 2.5636305809020996]}
        rotation={[-0.9306818754685443, -0.38385020191945607, -0.46593567629603494]}
        intensity={0.2}
      />
      <directionalLight
        position={[-1.4427666664123535, 1.4572889804840088, -3.6674907207489014]}
        rotation={[-2.8691385788104666, -0.4717099967963045, -2.83753080693173]}
        intensity={0.2}
      />
      <directionalLight
        position={[1.2379957437515259, 1.4572889804840088, -3.6674907207489014]}
        rotation={[-2.9280135086150874, 0.03508449215431484, 2.7606639469238328]}
        intensity={0.2}
      />
    </>
  );
}
