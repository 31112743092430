import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIList from '@mui/material/List';
import MUITypography from '@mui/material/Typography';
import type { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import useShoppingCartMutation, { updateQuantity, without } from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useFormattedPrice from 'src/services/formatCurrency';
import { calculateTotalWithPOR } from 'src/services/isPOR';
import CartItem from './CartItem';
import { spacing } from './styles';

interface CartItemListProps {
  cart: ShoppingCartItem[];
}

export default function CartItemsList({ cart }: CartItemListProps) {
  const cartTotal = calculateTotalWithPOR(cart);
  const { mutate } = useShoppingCartMutation();

  return (
    <ListWrapper>
      <ScrollableList>
        {cart.map((item) => (
          <CartItem
            key={item.partNumber}
            {...item}
            removeItem={() => mutate(without(cart, item.partNumber))}
            updateItemQuantity={(quantity: number) => mutate(updateQuantity(cart, quantity, item.partNumber))}
          />
        ))}
      </ScrollableList>
      <TotalDivider />
      <Total>
        <White fontWeight="bold">Total:</White>
        <White data-testid="shopping-cart-total-price">
          {useFormattedPrice({ price: cartTotal })}
        </White>
      </Total>
    </ListWrapper>
  );
}

const TotalDivider = styled(MUIDivider)`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  align-self: flex-end;
`;
const Total = styled('div')`
  color: ${({ theme }) => theme.palette.grey[900]};
  height: 80px;
  padding: ${spacing}px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
`;

const ListWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
`;

const White = styled(MUITypography)`
  line-height: 1;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const ScrollableList = styled(MUIList)`
  flex-grow: 1;
  ${({ theme }) => theme.mixins.hideScrollbar}
`;
