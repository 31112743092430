import { styled } from '@mui/material';

import { LoadingSpinner } from 'src/components/Loading';

const LoaderContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
export function FullscreenLoadingSpinner() {
  return (
    <LoaderContainer data-testid="loader">
      <LoadingSpinner />
    </LoaderContainer>
  );
}
