import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { AnimationClip, Object3D } from 'three';

import { error as logError } from 'src/services/log';
import { getBlob } from 'src/services/blobFetcher';
import { loadModel } from 'src/services/threeLoadingManager';
import { ResourceTracker } from 'src/services/ResourceTracker';
import { useProduct } from '../Product';
import { TenantContext } from '../Tenant';

export type Model = {
  model: Object3D | undefined;
  animations: AnimationClip[];
  loadingProgress: number;
  isError: boolean;
};

export const ModelContext = createContext<Model>(undefined!);

export function ModelProvider({ children }: any) {
  const { enabledFeatures } = useContext(TenantContext);
  const { currentProduct } = useProduct();

  const [productModel, setProductModel] = useState<Object3D>();
  const [animations, setAnimations] = useState<AnimationClip[]>([]);
  const [loadingProgress, setloadingProgress] = useState(0);
  const [isError, setIsError] = useState(false);

  const modelUrl = currentProduct?.modelUrl;

  useEffect(() => {
    if (!modelUrl) {
      return;
    }

    let cancelled = false;
    const cancellationTokenSource = axios.CancelToken.source();
    cancellationTokenSource.token.promise.then(() => {
      cancelled = true;
    });

    setProductModel(undefined);

    const config = {
      onProgress: (complete: number) => {
        if (!cancelled) {
          setloadingProgress(complete);
        }
      },

      cancelToken: cancellationTokenSource.token,
    };

    const loadUrl = async (modelUrl: string) => {
      try {
        const loadingConfig = {
          cancelToken: config.cancelToken,
          onProgress: (complete: number) => config.onProgress(complete),
        };
        const databaseSupported = true; // = iFeatureSupported('cached-model', enabledFeatures); We removed this flag. TODO:clean up;
        const blob = await getBlob(modelUrl, loadingConfig, databaseSupported);

        if (!cancelled) {
          return await loadModel({ blob, cancelled: () => cancelled });
        }
      } catch (err: any) {
        logError(err, 'An error happened downloading the 3d model');

        if (!cancelled) {
          setIsError(true);
        }
      }
    };

    (async () => {
      const object = await loadUrl(modelUrl);

      if (!object) {
        return;
      }

      const loadedAnimationClipGroups = object?.animations || [];
      const scene = object?.scene;

      const firstScene = scene;
      const parent = firstScene?.children[0];

      if (!firstScene || !parent) {
        return;
      }

      parent.add(scene.children[0].children[0]);

      const anims = loadedAnimationClipGroups;

      if (!cancelled) {
        setProductModel(firstScene);
        setAnimations(anims || []);
      }
    })();

    return () => {
      cancellationTokenSource.cancel('Cancelling model request');
    };
  }, [modelUrl, setIsError, enabledFeatures]);

  useEffect(() => {
    const resourceManager = new ResourceTracker();
    resourceManager.track(productModel);
    return () => {
      resourceManager.dispose();
    };
  }, [productModel]);

  const value = useMemo(
    () => ({
      model: productModel,
      animations,
      loadingProgress,
      isError,
    }),
    [productModel, animations, loadingProgress, isError]
  );
  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>;
}
