/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { styled, Drawer } from '@mui/material';

import { DrawerHeader } from 'src/components/Product/Mobile/Drawer/DrawerHeader';
import { navHeightMobile } from 'src/constants/sizes';

import { drawerHeight } from './constants';

export enum DisplayStage {
  CLOSED,
  HALF,
  FULLSCREEN,
  HALF_AFTER_FULLSCREEN,
}

interface DrawerProps {
  setExpanded: (expanded: boolean) => void;
  children: any;
}

interface StyledDrawerProps {
  displaystage: DisplayStage;
}

// Override needed to ensure the drawer displays the correct height
const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
  &.MuiDrawer-root > .MuiPaper-root {
    height: ${({ displaystage }) => {
      if (displaystage === DisplayStage.FULLSCREEN) {
        return `calc(100% - ${navHeightMobile} - ${drawerHeight}px)`;
      }
      if (displaystage === DisplayStage.HALF || displaystage === DisplayStage.HALF_AFTER_FULLSCREEN) {
        return `calc(50% - ${drawerHeight}px)`;
      }
      return 'auto';
    }};
    overflow: visible;
    transition: height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  }
`;

export function ProductDrawer({ setExpanded, children }: DrawerProps) {
  const [displayStage, setDisplayStage] = useState(DisplayStage.CLOSED);
  const open = displayStage !== DisplayStage.CLOSED;
  const fullscreen = displayStage === DisplayStage.FULLSCREEN;
  return (
    <StyledDrawer
      displaystage={displayStage.valueOf()}
      BackdropProps={{ invisible: !fullscreen }}
      ModalProps={{ keepMounted: true }}
      open={open}
      anchor="bottom"
    >
      <div
        role="tabpanel"
        onClick={() => {
          toggleExpansionStates(displayStage, setExpanded, setDisplayStage);
        }}
      >
        <DrawerHeader isExpanded={open} fullscreen={fullscreen} />
      </div>

      {children}
    </StyledDrawer>
  );
}

/*
   The drawer has four states. Due to the implementation of MUI drawer, we can't combine these all into one enum
   variable. Hence, we have to manage two variables to express these states.

   The states follow this cycle:
   CLOSED -> HALF -> FULLSCREEN -> HALF_AFTER_FULLSCREEN -> CLOSED
 */
export function toggleExpansionStates(
  displayStage: DisplayStage,
  setExpanded: (newState: boolean) => void,
  setDisplayStage: (newStage: DisplayStage) => void
) {
  const stagesInOrder = [
    DisplayStage.CLOSED,
    DisplayStage.HALF,
    DisplayStage.FULLSCREEN,
    DisplayStage.HALF_AFTER_FULLSCREEN,
  ];

  // Cycle through to the next stage.
  const nextStage = stagesInOrder[(displayStage.valueOf() + 1) % stagesInOrder.length];
  setDisplayStage(nextStage);

  if (nextStage === DisplayStage.HALF) {
    setExpanded(true);
  }
  if (nextStage === DisplayStage.CLOSED) {
    setExpanded(false);
  }
}
