/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* Rules disabled to clean console output and improve developer experience when writing new code. Should be reviewed */
import React from 'react';
import * as log from './services/log';

export class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    log.error(error);
  }

  render() {
    if ((this.state as any).hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
