import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, InputAdornment, Portal, styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import { CreateDiscountSchemeBody } from '@samsonvt/shared-types/discountLambda';
import { UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';
import { StyledMUISecondaryButton } from 'src/components/Button';
import { LoadingSpinner } from 'src/components/Loading';
import { TableData, TableDataRow } from 'src/components/Table/MUITableStyles';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';
import { Toast } from 'src/components/Toast';
import { DiscountSchemeResponse } from 'src/services/api';

type CreateDiscountSchemeFormProps = {
  mutation: UseMutationResult<DiscountSchemeResponse, any, CreateDiscountSchemeBody, unknown>;
  cancelCreate: () => void;
};

const whitespaceRegex = /\s+/g;

export function CreateDiscountSchemeForm({
  mutation: { isLoading, mutate: createDiscountScheme },
  cancelCreate,
}: CreateDiscountSchemeFormProps) {
  const [discountName, setDiscountName] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);

  const [validationError, setValidationError] = useState<{ message: string }>(); // { message: string } and not just string, So that we compare by ref in toast deps to display message even if it is the same

  const validateAndSendCreateDiscountSchemeBody = (newDiscountSchemeBody: CreateDiscountSchemeBody) => {
    if (!newDiscountSchemeBody.name) {
      setValidationError({ message: 'The discount scheme should contain a name' });
      return;
    }
    if (!newDiscountSchemeBody.discountPercentage && newDiscountSchemeBody.discountPercentage !== 0) {
      setValidationError({ message: 'The discount scheme should contain a discount percentage' });
      return;
    }
    if (newDiscountSchemeBody.discountPercentage > 100 || newDiscountSchemeBody.discountPercentage < 0) {
      setValidationError({ message: 'The discount scheme discount percentage is outside the allowed 0-100 range' });
      return;
    }

    setValidationError(undefined);
    /**
     * This removes trailing spaces and double space between words,
     * if we don't the display names of words with spaces will look identical on the list
     */
    const serialisedBody = {
      ...newDiscountSchemeBody,
      name: newDiscountSchemeBody.name.trim().replace(whitespaceRegex, ' '),
    };
    createDiscountScheme(serialisedBody);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountName(e.target.value);
  };

  const onDiscountPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountPercentage(parseFloat(e.target.value));
  };

  const onSave = () => {
    validateAndSendCreateDiscountSchemeBody({ name: discountName, discountPercentage });
  };

  return (
    <TableDataRow data-testid="create-scheme-form">
      <TableData>
        <WhiteTextFieldWithoutLabel
          placeholder="Type a discount name"
          onChange={onNameChange}
          value={discountName}
          fullWidth
          data-testid="name-input"
        />
      </TableData>
      <TableData sx={{ width: '10%' }}>
        <WhiteTextFieldWithoutLabel
          placeholder="Type a discount percentage"
          onChange={onDiscountPercentageChange}
          value={discountPercentage}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          data-testid="percentage-input"
        />
      </TableData>
      <TableData sx={{ textAlign: 'center' }}>
        <SaveDiscountButton onClick={onSave}>
          {isLoading ? (
            <LoadingSpinner height="28px" width="120px" viewBox="30 35 40 30" />
          ) : (
            <>
              <TickIcon icon={faCheck} />
              Save
            </>
          )}
        </SaveDiscountButton>
        <CancelButton onClick={cancelCreate}>Cancel</CancelButton>
      </TableData>
      <Portal>
        <Toast
          dependency={validationError}
          severity="error"
          title="Validation error"
          message={validationError?.message}
        />
      </Portal>
    </TableDataRow>
  );
}

const SaveDiscountButton = styled(StyledMUISecondaryButton)`
  width: fit-content;
  font-weight: bold;
  height: 2rem;
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const CancelButton = styled(MUIButton)`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: none;
  font-weight: 600;
  text-decoration-thickness: 2px;
  margin-left: 0.5rem;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    background-color: ${({ theme }) => alpha(theme.palette.secondary.light, 0.25)};
  }
`;
