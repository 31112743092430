import { styled } from '@mui/material';
import SliderUnstyled from '@mui/base/SliderUnstyled';
import MUITypography from '@mui/material/Typography';

import { spacing } from 'src/constants/spacing';
import { font } from 'src/constants/fontSizes';
import { device } from 'src/constants/mediaQueries';
import { shadows } from 'src/constants/contrastShadows';

const borderWidth = 0.75;
const thumbSize = 1.25;
const thumbSizeMobile = 1;
const railHeight = thumbSize / 2;
const railHeightMobile = thumbSizeMobile / 3;
const totalHeight = railHeight + 2 * borderWidth;
const totalHeightMobile = railHeightMobile + 2 * borderWidth;

export const PlaybackControlsWrapper = styled('div')`
  user-select: none;
  display: flex;
  width: 28rem;
  position: absolute;
  left: ${spacing.largeSpacing};
  bottom: ${spacing.largeSpacing};
  @media ${device.smallLaptop} {
    max-width: 18rem;
  }

  @media ${device.mobileOrTablet} {
    margin: 0 auto;
    right: 140px; // Make place for widget
    left: ${spacing.mediumSpacing};
    bottom: ${spacing.smallSpacing};
    width: auto;
    display: block;
    max-width: 400px;
    display: flex;
  }
`;

export const PlaybackControlsLabelContainer = styled('div')`
  width: 100%;
  text-align: center;
  font-size: ${font.small};
  font-weight: bold;
  text-shadow: ${({ theme }) => shadows(1.5, theme.palette.canvasBackground.linear).join(', ')};
`;

export const PlayButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: ${totalHeight}rem;
  height: ${totalHeight}rem;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  flex-shrink: 0;
  padding: ${spacing.smallSpacing};
  transition: 0.4s all ease;
  border-radius: 2.2rem;
  margin-right: ${spacing.smallSpacing};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  @media ${device.mobileOrTablet} {
    width: ${totalHeightMobile}rem;
    height: ${totalHeightMobile}rem;
    box-shadow: #eee 0px 0px 5px 0px;
  }
`;

export const PlaybackTitle = styled(MUITypography)`
  margin: 1rem;
`;

export const StyledSlider = styled(SliderUnstyled)`
  &.MuiSlider-root {
    width: 100%;
    height: ${railHeight}rem;
    display: block;
    position: relative;

    .MuiSlider-rail {
      display: block;
      position: absolute;
      inset: 0;
      height: ${railHeight}rem;
      border-radius: ${railHeight}rem;
      outline: ${({ theme }) => `1px solid ${theme.palette.whiteGlass}`};
      background: ${({ theme }) => theme.palette.canvasBackground.linear};
      margin: 0 -${borderWidth / 2}rem;
    }

    .MuiSlider-track {
      display: block;
      position: absolute;
      background: ${({ theme }) => theme.palette.secondary.dark};
      height: ${railHeight}rem;
      border-radius: ${railHeight}rem;
      margin: 0 -${borderWidth / 2}rem;
    }

    .MuiSlider-thumb {
      cursor: pointer;
      position: absolute;
      width: ${thumbSize}rem;
      height: ${thumbSize}rem;
      margin-left: -${thumbSize / 2}rem;
      margin-top: -${railHeight / 2}rem;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.palette.secondary.main};
    }

    height: ${railHeightMobile}rem;

    .MuiSlider-rail {
      height: ${railHeightMobile}rem;
      border-radius: ${railHeightMobile}rem;
      outline: ${({ theme }) => `1px solid ${theme.palette.whiteGlass}`};
      margin: 0 -${borderWidth / 2}rem;
      background: ${({ theme }) => theme.palette.grey['500']};
    }

    .MuiSlider-track {
      background: ${({ theme }) => theme.palette.secondary.dark};
      height: ${railHeightMobile}rem;
      border-radius: ${railHeightMobile}rem;
      margin: 0 -${borderWidth / 2}rem;
      box-shadow: #eee 0px 0px 5px 0px;
    }

    .MuiSlider-thumb {
      width: ${thumbSizeMobile}rem;
      height: ${thumbSizeMobile}rem;
      margin-top: -${railHeightMobile}rem;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
