import { Portal, styled, Typography } from '@mui/material';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import { UserConfirmationStatus } from '@samsonvt/shared-types/cognito';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { useQuery } from '@tanstack/react-query';
import { uniqueId } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast } from 'src/components/Toast';
import { useUser } from 'src/providers/User';
import { getAllUsers, getDiscountSchemes, getUserRoles } from 'src/services/api';
import { StyledFilterIcon, TableContainer } from './styles';
import { UserListRow } from './UserListRow';
import type { FullUser } from './UserManagement';

export interface UserHeader {
  role: string;
  name?: string; // Some users have no name, for instance technical users - it's possible in cognito
  email: string;
}

export interface UserItem extends UserHeader {
  id: string;
  created: string;
  lastActive?: string;
  isNewUser?: boolean;
  discountId?: string;
  confirmationStatus?: UserConfirmationStatus;
}

interface UserManagementTableProps {
  isAddingNewUser: boolean;
  onAddNewUserCancel: () => void;
  setSortedUserList: (sortedUserList: FullUser[]) => void;
  searchValue: string;
}

export function UserManagementTable({
  searchValue,
  isAddingNewUser,
  onAddNewUserCancel,
  setSortedUserList,
}: UserManagementTableProps) {
  const [lastSortedBy, setLastSortedBy] = useState<keyof UserItem | undefined>('created');
  const [sortBy, setSortBy] = useState<keyof UserItem>('created');
  const [saveUserMessage, setSaveUserMessage] = useState<string | undefined>();
  const [successSendingResetPassword, setSuccessSendingResetPassword] = useState(false);
  const [errorSendingResetPassword, setErrorSendingResetPassword] = useState('');
  const [newUserId] = useState(uniqueId());
  const { forgotPassword } = useUser();

  const sendResetPasswordEmail = async (email: string) => {
    try {
      setSuccessSendingResetPassword(false);
      await forgotPassword(email);
      setSuccessSendingResetPassword(true);
    } catch (error) {
      setErrorSendingResetPassword('Error sending the reset password email');
    }
  };

  const discountSchemesQuery = async () => getDiscountSchemes();

  const { isLoading: usersLoading, isError: usersError, data: users } = useQuery(['users'], getAllUsers);
  const { isLoading: rolesLoading, isError: rolesError, data: roles } = useQuery(['user-roles'], getUserRoles);
  const {
    isLoading: discountsLoading,
    data: discounts,
    isError: discountsError,
  } = useQuery(['discountSchemesList'], discountSchemesQuery);

  const isLoading = usersLoading || rolesLoading || discountsLoading;
  const isError = usersError || rolesError || discountsError;

  const isSortedByThisProp = lastSortedBy === sortBy;
  const sortAscending = !isSortedByThisProp;

  // Sorted users list
  const userList = useMemo(
    () => sortUsers({ userList: users, sortBy, sortAscending, discounts }),
    [users, sortBy, sortAscending, discounts]
  );

  const filteredUsers = userList.filter(
    (item) =>
      searchValue.length < 3 ||
      Object.values(item)
        .join('')
        .match(new RegExp(`${searchValue}`, 'i'))
  );

  const activeUsers = filteredUsers.filter((item) => item.role !== 'noaccess');
  const disabledUsers = filteredUsers.filter((item) => item.role === 'noaccess');

  useEffect(() => {
    // Used for exporting the sorted list to CSV
    const fullUsers = userList.map((user) => {
      const discount = discounts?.find((discount) => discount.id === user.discountId);
      const userRole = roles?.find((role) => role.id === user.role);
      return { ...user, discount, userRole };
    });

    setSortedUserList(fullUsers);
  }, [userList, setSortedUserList, discounts, roles]);

  const sortUsersByProperty = (sortBy: keyof UserItem) => {
    setSortBy(sortBy);
    setLastSortedBy(isSortedByThisProp ? undefined : sortBy);
  };

  const defaultRole = roles?.find((role) => role.id === 'readonly')?.name || '';
  const newUserItem: UserItem = {
    isNewUser: true,
    id: newUserId,
    name: '',
    email: '',
    role: defaultRole,
    created: '',
  };

  const successToastTrigger = saveUserMessage && users?.length;

  const onSaveUserMessage = (message: string) => {
    setSaveUserMessage(message);
  };

  const onSuccessToastClose = () => {
    setSaveUserMessage(undefined);
  };

  if (isLoading) return <FullscreenLoadingSpinner />;

  if (isError || !userList || !roles || !discounts) {
    return (
      <Toast dependency severity="error" title="Sorry there has been a problem" message="Please try again later." />
    );
  }

  return (
    <TableContainer>
      <MUITable>
        <MUITableHead>
          <MUITableRow>
            <ActionHeader text="Name" onClick={() => sortUsersByProperty('name')} />
            <ActionHeader text="Email" onClick={() => sortUsersByProperty('email')} />
            <ActionHeader text="Role" onClick={() => sortUsersByProperty('role')} />
            <ActionHeader text="Discount" onClick={() => sortUsersByProperty('discountId')} />
            <ActionHeader text="Invited On" onClick={() => sortUsersByProperty('created')} />
            <ActionHeader text="Last Active" onClick={() => sortUsersByProperty('lastActive')} />
            <TableHeader sx={{ width: '20%' }}> </TableHeader>
          </MUITableRow>
        </MUITableHead>
        <MUITableBody>
          {isAddingNewUser ? (
            <UserListRow
              userItem={newUserItem}
              roles={roles}
              discounts={discounts}
              key={newUserItem.id}
              onAddNewUserCancel={onAddNewUserCancel}
              onSaveUserMessage={onSaveUserMessage}
              sendResetPasswordEmail={sendResetPasswordEmail}
            />
          ) : null}
          {activeUsers.map((userItem) => (
            <UserListRow
              userItem={userItem}
              roles={roles}
              discounts={discounts}
              key={userItem.id}
              onAddNewUserCancel={onAddNewUserCancel}
              onSaveUserMessage={onSaveUserMessage}
              sendResetPasswordEmail={sendResetPasswordEmail}
            />
          ))}
          {disabledUsers.length ? (
            <>
              <MUITableRow>
                <TableHeader colSpan={7} sx={{ textAlign: 'center' }}>
                  Disabled users
                </TableHeader>
              </MUITableRow>
              {disabledUsers.map((userItem) => (
                <UserListRow
                  userItem={userItem}
                  roles={roles}
                  discounts={discounts}
                  key={userItem.id}
                  onAddNewUserCancel={onAddNewUserCancel}
                  onSaveUserMessage={onSaveUserMessage}
                  sendResetPasswordEmail={sendResetPasswordEmail}
                />
              ))}
            </>
          ) : null}
        </MUITableBody>
        <Portal>
          <Toast
            dependency={successToastTrigger}
            severity="success"
            title="Success"
            message={saveUserMessage}
            onClose={onSuccessToastClose}
          />
          <Toast
            dependency={successSendingResetPassword}
            severity="success"
            title="Success"
            message="Reset password email successfully sent"
          />
          <Toast
            dependency={errorSendingResetPassword}
            severity="error"
            title="Error"
            message="There was an error sending the reset password email, please try again later"
          />
        </Portal>
      </MUITable>
    </TableContainer>
  );
}

function ActionHeader({ onClick, text }: { onClick?: () => void; text: string }) {
  return (
    <TableHeader>
      <TableHeaderText onClick={onClick}>
        {text} <StyledFilterIcon fontSize="small" />
      </TableHeaderText>
    </TableHeader>
  );
}

interface SortUsersParams {
  userList: UserItem[] | undefined;
  sortBy: keyof UserItem;
  sortAscending?: boolean;
  discounts?: DiscountScheme[] | undefined;
}

export function sortUsers({ userList = [], sortBy, sortAscending = true, discounts }: SortUsersParams) {
  const comparingFunction = (firstElement: UserItem, secondElement: UserItem) => {
    if (sortBy === 'created' || sortBy === 'lastActive') {
      const lastActiveFirstElement = new Date(firstElement[sortBy] || 0).getTime();
      const lastActiveSecondElement = new Date(secondElement[sortBy] || 0).getTime();

      return (lastActiveSecondElement - lastActiveFirstElement) * (sortAscending ? 1 : -1);
    }

    if (sortBy === 'discountId') {
      // Actually sort by discount value
      if (!discounts?.length) return 0;
      const lastActiveFirstElement =
        discounts.find((discount) => discount.id === firstElement.discountId)?.discountPercentage || 0;
      const lastActiveSecondElement =
        discounts.find((discount) => discount.id === secondElement.discountId)?.discountPercentage || 0;

      return (lastActiveSecondElement - lastActiveFirstElement) * (sortAscending ? 1 : -1);
    }

    if (sortBy !== 'isNewUser') {
      const lastActiveFirstElement = firstElement[sortBy] || '';
      const lastActiveSecondElement = secondElement[sortBy] || '';

      return lastActiveFirstElement.localeCompare(lastActiveSecondElement) * (sortAscending ? 1 : -1);
    }
    return 0;
  };

  const sortedData = [...userList].sort(comparingFunction);
  return sortedData;
}

export const TableHeader = styled(MUITableCell)`
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const TableHeaderText = styled(Typography)<{ onClick?: () => void }>`
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`;
