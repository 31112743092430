import { Typography } from '@mui/material';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { formatDate } from 'src/services/formatDate';
import { ResendInviteButton } from './ResendInviteButton';
import { ResetPasswordButton } from './ResetPasswordButton';
import { SmallerText, TableData, TableDataRow, TableRowButton } from './styles';
import { UserItem } from './UserManagementTable';

interface StaticUserRowProps {
  userItem: UserItem;
  editUser: () => void;
  userRoleName: string;
  resendInvite: () => Promise<void>;
  disableResendButton: boolean;
  discount: DiscountScheme | undefined;
  sendResetPasswordEmail: (email: string) => Promise<void>;
}

export function StaticUserRow({
  userItem,
  editUser,
  userRoleName,
  discount,
  resendInvite,
  disableResendButton,
  sendResetPasswordEmail,
}: StaticUserRowProps) {
  const { name, email, created, lastActive, isNewUser, confirmationStatus } = userItem;

  const discountTableData = discount ? (
    <Typography>
      {discount.name} <SmallerText>({discount.discountPercentage}%)</SmallerText>
    </Typography>
  ) : (
    <Typography>No discount</Typography>
  );

  const resendInviteOrSendResetPasswordButton =
    confirmationStatus === 'RESET_REQUIRED' ? (
      <ResetPasswordButton sendResetPasswordEmail={sendResetPasswordEmail} emailToSendTheResetPassword={email} />
    ) : (
      <ResendInviteButton disableResendButton={disableResendButton} resendInvite={resendInvite} />
    );

  return (
    <TableDataRow>
      <TableData>
        <Typography>{name}</Typography>
      </TableData>
      <TableData>
        <Typography>{email}</Typography>
      </TableData>
      <TableData>
        <Typography>{userRoleName}</Typography>
      </TableData>
      <TableData>{discountTableData}</TableData>
      <TableData>
        <Typography>{formatDate(created)}</Typography>
      </TableData>
      <TableData>
        {lastActive || isNewUser ? (
          <Typography>{formatDate(lastActive)}</Typography>
        ) : (
          resendInviteOrSendResetPasswordButton
        )}
      </TableData>
      <TableData>
        <TableRowButton color="success" onClick={editUser}>
          Edit
        </TableRowButton>
      </TableData>
    </TableDataRow>
  );
}
