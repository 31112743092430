import { styled } from '@mui/material';
import { invalidate } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import { Slider } from 'src/components/Slider/Slider';

const StyledZoomRow = styled('div')`
  height:4rem;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.palette.primary.main};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  .zoom__header {
    h5 {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      margin-bottom: 0;
    }
  }
`;

const StyledSlider = styled(Slider)`
  .MuiSlider-rail {
    height: 0.375rem;
    opacity: 1;
    background: ${({ theme }) => theme.palette.primary.light};
    border: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
    border-radius: 0.375rem;
  }

  .MuiSlider-track {
    background: transparent;
  }

  .MuiSlider-thumb {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface Props {
  cameraControls: CameraControls | undefined;
  zoomLevel: number;
  setZoomLevel: (e: number) => void;
  minZoomLevel: number;
  maxZoomLevel: number;
}

export default function ZoomControls({ cameraControls, zoomLevel, setZoomLevel, minZoomLevel, maxZoomLevel }: Props) {
  return (
    <StyledZoomRow>
      <div className="zoom__header">
        <h5>Zoom</h5>
      </div>
      <StyledSlider
        value={maxZoomLevel - zoomLevel}
        step={0.25}
        onChange={(_: object, data: number | number[]) => {
          const level = maxZoomLevel - (data as number); // number[] is for range sliders
          setZoomLevel(level);
          cameraControls?.dollyTo(level, true);
          invalidate();
        }}
        min={minZoomLevel}
        max={maxZoomLevel}
      />
    </StyledZoomRow>
  );
}
