import { UnauthenticatedTemplate } from 'src/templates/UnauthenticatedTemplate';
import { CreateAccoutFormContainer } from './CreateAccountFormContainer';

export function CreateAccountPage() {
  return (
    <UnauthenticatedTemplate>
      <CreateAccoutFormContainer />
    </UnauthenticatedTemplate>
  );
}
