import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons';

import { isWithinIframe } from 'src/services/checkForIframe';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import type { IRepairStepView, IRepairView } from 'src/providers/repairs/types';
import Description from 'src/components/Product/Desktop/ProductBottomBar/Description';
import StepButton from 'src/components/Product/Desktop/ProductBottomBar/StepButton';
import { BottomBar } from 'src/components/Product/Desktop/ProductBottomBar/styles';
import { workInstructionsLink } from 'src/utils/urls';

interface ProductParams {
  productId: string;
  section: string;
}

export function ProductBottomBar() {
  const repairDetails = useContext(RepairDetailContext);
  const { productId, section } = useParams<ProductParams>();
  const { search } = useLocation();

  if (!repairDetails) {
    return null;
  }

  const { step, repair } = repairDetails;

  const { nextStepUrl, prevStepUrl } = stepUrls(repair, step);
  const repairsHome = workInstructionsLink(productId);
  const currentRepairStart = workInstructionsLink(productId, section);

  const prevStepURL = `${prevStepUrl}${search}`;
  const nextStepURL = `${isWithinIframe ? nextStepUrl || currentRepairStart : nextStepUrl || repairsHome}${search}`;

  return (
    <BottomBar data-testid="repair-bar">
      <StepButton
        icon={faChevronLeft}
        to={prevStepURL || '#'}
        disabled={!prevStepUrl}
        hideOnTablet
        data-testid="prev-step-button"
      >
        Prev Step
      </StepButton>
      <Description description={step.description} />
      <StepButton icon={faChevronRight} to={nextStepURL} data-testid="next-step-button">
        {nextStepUrl ? 'Next Step' : 'Complete'}
      </StepButton>
    </BottomBar>
  );
}

const stepUrls = (repairDetails: IRepairView, step: IRepairStepView) => {
  const flattenedSteps = repairDetails.sections.map((section) => section.steps).flat();
  const currentStepIndex = flattenedSteps.findIndex((s) => s.url === step.url);
  const prevStep = flattenedSteps[currentStepIndex - 1];
  const nextStep = flattenedSteps[currentStepIndex + 1];
  return {
    prevStepUrl: prevStep?.url,
    nextStepUrl: nextStep?.url,
  };
};
