import { styled } from '@mui/material';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { EmptyTab } from 'src/components/EmptyTab';
import { Footer } from 'src/components/Footer';
import PartsCatalogue from 'src/components/Product/Mobile/Panels/PartsCatalogue/PartsCatalogue';
import { useProduct } from 'src/providers/Product';

const StyledBomTabPanel = styled('div')`
  min-height: calc(100% - 2.27em);
  background-color: ${({ theme }) => theme.palette.whiteGlass};
`;

const trailingSlashRegex = /\/+$/;

export default function PartsCataloguePanelMobile() {
  const { path, url } = useRouteMatch();
  const { bom } = useProduct();
  return (
    <>
      <StyledBomTabPanel>
        <Switch>
          <Route path={`${path}/:detailId`}>
            <PartsCatalogue />
          </Route>
          <Route path={path} exact>
            {bom ? <Redirect to={`${url.replace(trailingSlashRegex, '')}/${bom.id}`} /> : <EmptyTab />}
          </Route>
        </Switch>
      </StyledBomTabPanel>
      <Footer transparent />
    </>
  );
}
