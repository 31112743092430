/* eslint-disable no-nested-ternary */
/* Rule disabled to clean console output and improve developer experience when writing new code. Should be reviewed */
import { MutableRefObject } from 'react';
import { invalidate } from '@react-three/fiber';
import { styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { spacing } from 'src/constants/spacing';
import { IArrowState } from './FullControls';

interface ArrowProps {
  direction: string;
}

const Arrow = styled('div')<ArrowProps>`
  background: ${({ theme }) => theme.palette.primary.contrastText};
  color: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${spacing.largeSpacing};
  width: ${spacing.largeSpacing};
  height: ${spacing.largeSpacing};
  position: relative;
  margin: ${spacing.smallSpacing};
  cursor: pointer;
  transition: 0.1s all ease-in-out;
  :hover {
    background: ${({ theme }) => theme.palette.grey['300']};
  }
  svg {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    transform: ${({ direction }) =>
      direction === 'up'
        ? 'rotate(180deg)'
        : direction === 'left'
        ? 'rotate(-90deg)'
        : direction === 'right'
        ? 'rotate(90deg)'
        : 'inherit'};
  }
`;

interface TransformArrowProps {
  arrowsHeldDown: MutableRefObject<IArrowState>;
  direction: string;
}
export function TransformArrow({ arrowsHeldDown, direction }: TransformArrowProps) {
  const directionHeld = direction as keyof typeof arrowsHeldDown.current;
  // Rule disabled to clean console output and improve developer experience when writing new code. Should be reviewed
  // eslint-disable-next-line no-return-assign
  const stopHolding = () => (arrowsHeldDown.current[directionHeld] = false);

  return (
    <Arrow
      direction={direction}
      onPointerDown={() => {
        arrowsHeldDown.current[directionHeld] = true;
        invalidate();
      }}
      onPointerUp={stopHolding}
      onPointerOut={() => {
        if (arrowsHeldDown.current[directionHeld]) {
          stopHolding();
        }
      }}
    >
      <ExpandMore aria-label={`Arrow ${direction}`} />
    </Arrow>
  );
}
