import CameraControls from 'camera-controls';
import { MutableRefObject, useCallback } from 'react';
import { useFrame, invalidate } from '@react-three/fiber';
import { IArrowState } from './FullControls';

const rotationSpeed = 0.05;
const truckSpeed = 0.02;

interface CameraTransformerProps {
  cameraControls?: CameraControls;
  arrowsHeldDown: MutableRefObject<IArrowState>;
  rotationActive: boolean;
}

type TransformAction = (x: number, y: number) => void;

export function CameraTransformer({ cameraControls, rotationActive, arrowsHeldDown }: CameraTransformerProps) {
  const transform = useCallback(
    (transformAction: TransformAction, currentlyHeldDown: IArrowState, rotationSpeed: number) => {
      const { up, down, left, right } = currentlyHeldDown;

      if (up) {
        transformAction(0, -rotationSpeed);
      }
      if (down) {
        transformAction(0, rotationSpeed);
      }
      if (left) {
        transformAction(-rotationSpeed, 0);
      }
      if (right) {
        transformAction(rotationSpeed, 0);
      }

      if (up || down || left || right) {
        invalidate();
      }
    },
    []
  );

  useFrame(() => {
    if (rotationActive) {
      transform((x, y) => cameraControls?.rotate(x, y, true), arrowsHeldDown.current, rotationSpeed);
    } else {
      transform((x, y) => cameraControls?.truck(x, y, true), arrowsHeldDown.current, truckSpeed);
    }
  });
  return null;
}
