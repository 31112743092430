import { faEnvelope, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { StyledMUIButton } from 'src/components/Button';
import MUIBox from '@mui/material/Box';

interface MobileCheckoutActionBarProps {
  setValidate: Dispatch<SetStateAction<boolean>>;
  isOrderPOR: boolean;
  isFetching: boolean;
  goBack: () => void;
}

export default function MobileCheckoutActionBar({
  setValidate,
  isOrderPOR,
  isFetching,
  goBack,
}: MobileCheckoutActionBarProps) {
  return (
    <MobileCheckoutActionBarContainer>
      <BackButton onClick={goBack}>
        <BackIcon icon={faChevronLeft} />
        Back
      </BackButton>
      <MobileCartButton data-testid="order-parts-button" onClick={() => setValidate(true)} disabled={isFetching}>
        {isOrderPOR ? 'Request Quote' : 'Order Parts'}
        <EnvelopeIcon icon={faEnvelope} />
      </MobileCartButton>
    </MobileCheckoutActionBarContainer>
  );
}

const MobileCheckoutActionBarContainer = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  background: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  min-height: 68px;
`;
const BackButton = styled(StyledMUIButton)`
  height: 45px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;

  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  box-shadow: none;
  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const EnvelopeIcon = styled(FontAwesomeIcon)`
  padding-left: 0.5rem;
`;

const BackIcon = styled(FontAwesomeIcon)`
  padding-right: 0.5rem;
`;

const MobileCartButton = styled(StyledMUIButton)`
  height: 45px;
  margin-right: 1rem;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  padding: 0.7rem;
`;
MobileCartButton.defaultProps = {
  type: 'submit',
  color: 'secondary',
  variant: 'contained',
  size: 'medium',
};
