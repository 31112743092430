import { Link } from 'react-router-dom';

type Props = {
  [key: string]: any;
  href: string;
};

const urlSchemeRegex = /^(https?:)?\/\//;

export default function MarkdownLink({ href, ...props }: Props) {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return href.match(urlSchemeRegex) ? <a {...props} href={href} /> : <Link {...props} to={href} />;
}
