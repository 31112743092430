import { styled } from '@mui/material';
import MUISlider from '@mui/material/Slider';

export const Slider = styled(MUISlider)`
  &.MuiSlider-root {
    display: flex;

    .MuiSlider-thumb {
      background: ${({ theme }) => theme.palette.secondary.main};
      border: 1px solid ${({ theme }) => theme.palette.primary.contrastText};

      &.MuiSlider-active {
        box-shadow: none;
        filter: saturate(3);
      }

      &:after {
        display: none;
      }

      svg {
        position: absolute;
        font-size: 1rem;
        top: 50%;
        transform: rotate(90deg) translate(-50%, 50%);
        left: 50%;
        color: white;
      }
    }
  }
`;
