import { useEffect, useState } from 'react';

export const useStickHeaderWithIntersectionObserver = () => {
  const [isHeaderInNormalPosition, setIsHeaderInNormalPosition] = useState(false);
  useEffect(() => {
    setIsHeaderInNormalPosition(false);
    const el = document.getElementById('sticky-header');
    const observer = new IntersectionObserver(() => setIsHeaderInNormalPosition((prev) => !prev), { threshold: [1] });
    if (el) observer.observe(el);
    return () => observer.disconnect();
  }, [setIsHeaderInNormalPosition]);
  return isHeaderInNormalPosition;
};
