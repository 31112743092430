import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import { spacing } from 'src/constants/spacing';

const dropzoneHeight = '10rem';

export default styled(MUIBox)<{ isDragActive?: boolean }>`
  min-height: ${dropzoneHeight};
  margin: ${spacing.largeSpacing} 0;
  padding: ${spacing.largeSpacing};
  text-align: center;
  border-radius: ${spacing.xSmallSpacing};
  border: 4px dashed ${({ theme, isDragActive }) => (isDragActive ? theme.palette.success.main : '#BDBDBD')};
  background-color: #eeeeee;
  color: ${({ theme }) => theme.palette.common.black};
  outline: none;
  transition: border 0.2s ease-in-out;
  position: relative;
`;
