import { styled } from '@mui/material';
import MUIList from '@mui/material/List';
import MUITypography from '@mui/material/Typography';
import type { Order, OrderItem } from '@samsonvt/shared-types/orderLambda';
import useFormattedPrice from 'src/services/formatCurrency';
import { ReceivedOrderItem } from './ReceivedOrderItem';
import { TotalDivider } from './styles';

type ReceivedOrderPartsListProps = {
  items: OrderItem[];
  totalPrice: Order['totalPrice'];
};

export default function ReceivedOrderPartsList({
  items,
  totalPrice: { amount, currencyCode },
}: ReceivedOrderPartsListProps) {

  return (
    <ReceivedOrdersPartListWrapper>
      <ReceivedOrderParts>
        {items.map((item) => (
          <ReceivedOrderItem
            key={item.partNumber}
            partName={item.partName}
            partNumber={item.partNumber}
            quantity={item.quantity}
            price={item.unitPrice}
            currency={currencyCode}
          />
        ))}
      </ReceivedOrderParts>
      <TotalDivider />
      <Total>
        <MUITypography fontWeight="bold">Total:</MUITypography>
        <MUITypography variant="h4" data-testid="shopping-cart-total-price">
          {useFormattedPrice({ price: amount, currency: currencyCode })}
        </MUITypography>
      </Total>
    </ReceivedOrdersPartListWrapper>
  );
}

const ReceivedOrdersPartListWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ReceivedOrderParts = styled(MUIList)`
  flex-grow: 1;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[900]};
  & li:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome Safari Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Total = styled('div')`
  height: 80px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 2rem;
`;
