import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { Link } from 'react-router-dom';
import { productFeatureNames } from 'src/constants/features';
import { useCurrentTabName } from 'src/hooks/useCurrentTabName';
import { useProduct } from 'src/providers/Product';
import { useProductFeatures } from 'src/hooks/useProductFeatures';
import { tabLink } from 'src/utils/urls';

export function EmptyTab() {
  const { productId } = useProduct();

  const currentTabName = useCurrentTabName();
  const availableTabs = useProductFeatures();
  const otherAvailableTabs = availableTabs.filter((otherTabName) => otherTabName !== currentTabName);

  return (
    <PaddedBox data-testid={`empty-tab-${currentTabName}`}>
      <CenteredP>
        <BigIcon icon={faCircleInfo} />
      </CenteredP>
      <MarginP>No information available yet for {productFeatureNames[currentTabName as ProductFeature]}.</MarginP>
      {otherAvailableTabs.length ? (
        <MarginP>
          Please, check{' '}
          {otherAvailableTabs.map((otherTabName, index) => (
            <span key={otherTabName}>
              {index > 0 && ' or '}
              <UnderlinedLink key={otherTabName} to={tabLink(productId, otherTabName)}>
                {productFeatureNames[otherTabName]}
              </UnderlinedLink>
            </span>
          ))}
          .
        </MarginP>
      ) : null}
    </PaddedBox>
  );
}

const MarginP = styled('p')`
  padding-bottom: 1rem;
`;

const CenteredP = styled(MarginP)`
  text-align: center;
`;

const PaddedBox = styled('div')`
  padding: 4rem 1rem;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

const UnderlinedLink = styled(Link)`
  text-decoration: underline;
`;

const BigIcon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
`;
