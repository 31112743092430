import { AnimationClip, Loader, LoadingManager, Object3D } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { fromArrayBuffer } from './blobConverter';
import { error as logError, info as logInfo } from './log';

class SvtLoader implements TypedLoader {
  constructor(private loader: Loader) {}

  async loadAsync(url: string, onProgress?: (event: ProgressEvent<EventTarget>) => void): Promise<LoaderResult> {
    return this.loader.loadAsync(url, onProgress);
  }
}
interface TypedLoader {
  loadAsync(url: string, onProgress?: (event: ProgressEvent) => void): Promise<LoaderResult>;
}
interface LoaderResult {
  animations?: AnimationClip[];
  scene: Object3D;
}

const modelLoader = (context: string): TypedLoader => {
  const loadingManager = createLoadingManager(context);
  const loader = new GLTFLoader(loadingManager);
  const dracoLoader = new DRACOLoader(loadingManager);
  dracoLoader.setDecoderPath('/draco/');
  dracoLoader.setDecoderConfig({ type: 'js' });
  loader.setDRACOLoader(dracoLoader);

  return new SvtLoader(loader);
};

const createLoadingManager = (context: string) =>
  new LoadingManager(
    undefined,
    (url) => logInfo(`LoadingManager:${context}:onProgress`, url),
    (url) => logError(new Error('loader error'), `LoadingManager:${context}:onError ${url}`)
  );

interface LoadingProps {
  blob: ArrayBuffer;
  cancelled: () => boolean;
}

export const loadModel = async ({ blob, cancelled }: LoadingProps) => {
  const url = URL.createObjectURL(fromArrayBuffer(blob));
  const loader = modelLoader('model');
  const object = await loader.loadAsync(url);

  if (!cancelled()) {
    return object;
  }
};
