import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { styled } from '@mui/material';
import { filterCustomAttributesToShow } from 'src/components/Product/Shared/Helpers/filterCustomAttributesToShow';
import { AttributeList } from './AttributeList';
import { PaddedBlock } from '../../styles';

interface OverviewTabProps {
  current: PartNode;
}

const NoInformation = styled('p')`
  text-align: center;
  margin-top: 2rem;
`;

export default function OverviewTab({ current: { attributes } }: OverviewTabProps) {
  const attributesWithoutEmptyFields = filterCustomAttributesToShow(attributes);

  if (!attributesWithoutEmptyFields || !Object.keys(attributesWithoutEmptyFields).length) {
    return <NoInformation>There is no further information to display</NoInformation>;
  }
  return (
    <PaddedBlock>
      <AttributeList attributes={attributesWithoutEmptyFields} />
    </PaddedBlock>
  );
}
