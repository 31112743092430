import { faChevronLeft, faHouse } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUIIconButton from '@mui/material/IconButton';
import MUITypography from '@mui/material/Typography';
import { Link, LinkProps } from 'react-router-dom';
import { ThinDivider } from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import { spacing } from 'src/constants/spacing';
import filterProductOptionsFromParents from 'src/services/filterProductOptionsFromParents';
import { NamedLink } from 'src/services/partNavigation';

interface SidebarNavigationProps {
  partParents: NamedLink[];
}

export function SidebarNavigation({ partParents }: SidebarNavigationProps) {
  const filteredProductOptions = filterProductOptionsFromParents(partParents);

  return (
    <>
      <StyledNavigationContainer data-testid="sidebar-navigation">
        {filteredProductOptions.map((parent, index) => {
          const isIndexZero = index === 0;
          const productArrLength = filteredProductOptions.length;
          const isFirstOrLast = isIndexZero || index === productArrLength - 1;
          const showHomeIcon = isIndexZero && productArrLength > 1;
          const showButtonName = (isIndexZero && filteredProductOptions.length === 1) || !isIndexZero;

          if (isFirstOrLast) {
            if (showHomeIcon) {
              return (
                <HomeLink key={parent.url} data-testid="sidebar-parent-node" to={parent.url}>
                  <FontAwesomeIcon icon={faHouse} size="sm" data-testid="home-icon" name="home" />
                </HomeLink>
              );
            }

            if (showButtonName) {
              return (
                <ImmediateParentWrapper key={parent.url}>
                  <BackToImmediateParentLink data-testid="sidebar-parent-node" to={parent.url}>
                    <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                    <ParentName> Back to {parent.name}</ParentName>
                  </BackToImmediateParentLink>
                </ImmediateParentWrapper>
              );
            }
          }

          return null;
        })}
      </StyledNavigationContainer>
      <ThinDivider />
    </>
  );
}

const StyledNavigationContainer = styled('div')`
  display: flex;
  padding: ${spacing.mediumSpacing} 0;
  align-items: center;
`;

const HomeLink = styled(MUIIconButton)<LinkProps>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  width: 43px;
  height: 43px;
  margin-right: 1rem;

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

HomeLink.defaultProps = { component: Link };

const BackToImmediateParentLink = styled(MUIIconButton)<LinkProps>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 3px;
  background: ${({ theme }) => theme.palette.primary.main};
  flex-grow: 1;
  height: 31px;
  max-width: 100%;

  justify-content: flex-start;

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

BackToImmediateParentLink.defaultProps = { component: Link };

const ImmediateParentWrapper = styled('div')`
  overflow: hidden;
  flex-grow: 1;
`;

const ParentName = styled(MUITypography)`
  margin: 0 auto;
  font-weight: inherit;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.5rem;
`;
