/**
 * Downloads given file to user device
 */
export function downloadFile(file: Blob, filename: string) {
  const objectURL = URL.createObjectURL(file);
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', objectURL);
  downloadLink.setAttribute('download', filename);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(objectURL);
}
