import { styled } from '@mui/material';
import MUITab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';
import { useState } from 'react';
import Overview, { OverviewTabProps } from './Overview';
import PartHistory, { PartHistoryProps } from './PartHistory';
import Attributes from './Attributes';

type TabsProps = OverviewTabProps & PartHistoryProps;
type ActiveTabName = 'Overview' | 'PartHistory' | 'Attributes';
type ActiveTabComponent = typeof Overview | typeof PartHistory | typeof Attributes;

export default function Tabs(props: TabsProps) {
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>('Overview');

  const TabComponent: ActiveTabComponent = { Overview, PartHistory, Attributes }[activeTabName];

  return (
    <div>
      <StyledTabs value={activeTabName} onChange={(_, value) => setActiveTabName(value)}>
        <MUITab label="Overview" value="Overview" />
        <MUITab label="Attributes" value="Attributes" />
        <MUITab label="Previous Part Numbers" value="PartHistory" />
      </StyledTabs>
      <StyledTabContentPanel>
        <TabComponent {...props} />
      </StyledTabContentPanel>
    </div>
  );
}

const StyledTabs = styled(MUITabs)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  overflow: visible;
  & > div {
    overflow: visible !important;
  }
  & button {
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.grey[900]};
    border-radius: 4px 4px 0px 0px;

    text-transform: none;
  }

  & button.Mui-selected {
    font-weight: bold;

    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.grey[900]};
    border-radius: 4px 4px 0px 0px;

    background-color: ${({ theme }) => theme.palette.common.white};
    overflow: visible;
  }
`;

StyledTabs.defaultProps = { TabIndicatorProps: { sx: { background: 'none' } }, variant: 'fullWidth' };

const StyledTabContentPanel = styled('div')`
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 0px 0px 4px 4px;

  padding: 1rem;
`;
