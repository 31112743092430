import { Link, LinkProps } from 'react-router-dom';
import { styled, alpha } from '@mui/material';
import MUIButton from '@mui/material/Button';
import { spacing } from 'src/constants/spacing';
import MUIIconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { MouseEventHandler } from 'react';

export const StyledMUIButtonLink = styled(MUIButton)<LinkProps>`
  margin: 0rem 1rem;
  text-transform: none;
  font-weight: bold;

  background-color: ${({ theme, disabled }) => disabled && alpha(theme.palette.secondary.main, 0.16)} !important;
  color: ${({ theme, disabled }) => disabled && alpha(theme.palette.secondary.contrastText, 0.87)} !important;
  // To since :disabled pseudo-class doesn't work on anchor links, this is used to overwrite a stronger selector coming from MUI. It's arguably better to use !important than to hardcode the MUI class name.
`;
StyledMUIButtonLink.defaultProps = { color: 'secondary', variant: 'contained', size: 'medium', component: Link };

export const StyledMUIButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  :disabled {
    background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.16)};
    color: ${({ theme }) => alpha(theme.palette.secondary.contrastText, 0.87)};
  }
`;
StyledMUIButton.defaultProps = { color: 'secondary', variant: 'contained', size: 'medium' };

export const StyledMUISecondaryButton = styled(MUIButton)`
  text-transform: none;
  & svg {
    font-size: 16px !important;
  }
`;
StyledMUISecondaryButton.defaultProps = { color: 'secondary', variant: 'contained', size: 'medium' };

export const StyledAddToCartButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  padding: ${spacing.smallSpacing};
  margin-bottom: ${spacing.mediumSpacing};

  // special disable state when item is not available for purchase
  :disabled {
    background-color: ${({ theme }) => alpha(theme.palette.action.disabledBackground, 0.16)};
    color: ${({ theme }) => alpha(theme.palette.secondary.contrastText, 0.87)};
    pointer-events: visible;
    cursor: not-allowed;
  }
`;
StyledAddToCartButton.defaultProps = { color: 'secondary', variant: 'contained', size: 'medium' };

export const StyledMUIPrimaryButton = styled(MUIIconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 3px;
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-around;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background: ${({ theme }) => theme.palette.secondary.main};
  }

  &.Mui-disabled {
    background: ${({ theme }) => alpha(theme.palette.action.disabledBackground, 0.16)};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export function BackButton({ children, onClick }: { children?: any; onClick?: MouseEventHandler<HTMLButtonElement> }) {
  return (
    <StyledMUIPrimaryButton onClick={onClick} sx={{ fontSize: '0.875rem' }}>
      <FontAwesomeIcon icon={faChevronLeft} />
      {children || 'Back'}
    </StyledMUIPrimaryButton>
  );
}

// this button is transparent
export const EmptyStyledButton = styled(StyledMUIButton)`
  justify-self: flex-end;
  font-size: 0.8rem;
  height: 2rem;
  margin-right: 1rem;
  padding: 1.2rem;
  color: ${({ theme }) => theme.palette.grey[900]};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  background: none;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    box-shadow: none;
  }
`;
