import { styled } from '@mui/material';

import { useProduct } from 'src/providers/Product';
import { Part, usePart } from 'src/services/part';
import { device } from 'src/constants/mediaQueries';

export function Title() {
  const { currentProduct } = useProduct();
  const part = safelyGetPart(usePart());
  const title = part?.name || currentProduct?.name || 'Unknown product';

  return <HeaderText>{title}</HeaderText>;
}

const HeaderText = styled('p')`
  font-size: 22px;

  @media ${device.smallMobile} {
    font-size: 4vw; // Scale the text to the width of the screen
  }
  overflow-wrap: anywhere;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 700;
`;

function safelyGetPart(part: Part) {
  let { current } = part;

  if (!current) {
    current = part.partPath?.part;
  }

  return current;
}
