import { alpha, styled } from '@mui/material';
import MUIMenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import MUITypography from '@mui/material/Typography';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useProduct } from 'src/providers/Product';
import useFormattedPrice from 'src/services/formatCurrency';
import { usePart } from 'src/services/part';
import { NamedLink } from 'src/services/partNavigation';
import { makePartUrl } from 'src/services/partUrl';

interface PartOptionsProps {
  currentPart: PartNode;
  parents: NamedLink[];
}

/**
 * Component with accordion for selecting a part option (also called a variant)
 */
export function PartOptions({ currentPart, parents }: PartOptionsProps) {
  const [selectedOption, setSelectedOption] = useState(currentPart.name || '');
  const history = useHistory();
  const { partPath } = usePart();
  const { productId, toggleVariantNames, hiddenVariantNames } = useProduct();

  const options = extractOptions(parents, currentPart);

  const currentObject3DNameAsArray = currentPart.object3DName ? [currentPart.object3DName] : [];

  useEffect(() => {
    // Set hidden names on first render when current part is set from url
    // This is done here and not in provider due to nesting of providers, neither current nor variant group are easily accessible in providers.
    if (currentPart.object3DName && hiddenVariantNames?.includes(currentPart.object3DName)) {
      toggleVariantNames([currentPart.object3DName], currentPart.object3DName);
    }
  });

  if (!options.length) return null;

  const getClickHandler = (item: PartNode) => () => {
    const selectedId = item.object3DName;
    if (!selectedId) return;

    const url = makePartUrl(productId, item.id, { group: partPath?.pathHash, mesh: selectedId });
    setSelectedOption(selectedId);
    toggleVariantNames(currentObject3DNameAsArray, selectedId);
    history.push(url);
  };

  return (
    <>
      <StyledOptionsTitle>Part options:</StyledOptionsTitle>
      <ProductOptionsSelect value={selectedOption}>
        {options.map((item: PartNode) => (
          <OptionsMenuItem key={item.id} onClick={getClickHandler(item)} value={item.id}>
            <ProductOptionsItem item={item} />
          </OptionsMenuItem>
        ))}

        <DummyMenuItem hidden key={selectedOption} value={selectedOption}>
          {selectedOption}
        </DummyMenuItem>
      </ProductOptionsSelect>
    </>
  );
}

function ProductOptionsItem({ item }: { item: PartNode }) {
  const { name, partNumber, price } = item;
  const formattedPrice = useFormattedPrice({ price });

  return (
    <StyledItem>
      <StyledOptionPartName>{name}</StyledOptionPartName>
      <NameAndPriceSection>
        <Flex>
          <StyledOptionPartNumber>{partNumber}</StyledOptionPartNumber>
        </Flex>
        <Flex sx={{ marginLeft: 'auto' }}>
          <StyledPrice>{formattedPrice}</StyledPrice>
        </Flex>
      </NameAndPriceSection>
    </StyledItem>
  );
}

function extractOptions(parents: NamedLink[], current: PartNode) {
  const closestParent = parents[parents.length - 1];
  const hasOptionsParent = closestParent?.data?.objectType === 'product-option';
  if (!hasOptionsParent) return [];

  const productOptions = closestParent?.data?.children || [];
  return productOptions.filter((child: PartNode) => child.id !== current.id);
}

/** This is a hack to make the select work properly */
const DummyMenuItem = styled(MUIMenuItem)`
  display: none;
`;

const Flex = styled('div')`
  display: flex;
`;

const OptionsMenuItem = styled(MUIMenuItem)``;

const StyledOptionPartName = styled(MUITypography)`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const StyledOptionsTitle = styled(MUITypography)`
  margin-bottom: 0.3em;
`;

const StyledOptionPartNumber = styled(MUITypography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledItem = styled('div')`
  width: 100%;
`;

const StyledPrice = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  span {
    font-size: 1.2em;
  }
  margin-left: 0.5em;
`;

const NameAndPriceSection = styled(Flex)`
  p {
    align-self: center;
  }
`;

const ProductOptionsSelect = styled(MUISelect)`
  background-color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.1)};
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  margin-bottom: 1.5em;

  .MuiOutlinedInput-input,
  .MuiSelect-outlined {
    padding: 1em;
  }

  fieldset {
    border: none;
  }

  & .MuiSvgIcon-root {
    background-color: ${({ theme }) => theme.palette.grey[900]};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;
