import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, styled } from '@mui/material';
import { faRightFromBracket, faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { StyledMUIButtonLink } from '../Button';

export default function MobileProductMenuFooter({ logout }: { logout: () => Promise<void> }) {
  return (
    <MobileProductMenuFooterWrapper>
      <MobileProductMenuFooterButton to="/my-account">
        <MobileProductMenuFooterMyAccountIcon icon={faCircleUser} />
        My account
      </MobileProductMenuFooterButton>
      <MobileProductMenuDivider />

      <MobileProductMenuFooterButton to="/" onClick={logout}>
        <MobileProductMenuFooterLogoutIcon icon={faRightFromBracket} />
        Log out
      </MobileProductMenuFooterButton>
    </MobileProductMenuFooterWrapper>
  );
}

const MobileProductMenuFooterWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.light};
  min-height: 70px;
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`;

const MobileProductMenuFooterButton = styled(StyledMUIButtonLink)`
  background-color: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.common.white};
  width: 50%;
  white-space: nowrap;
  padding: 0;
  font-size: 1rem;
  font-weight: normal;

  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const MobileProductMenuFooterMyAccountIcon = styled(FontAwesomeIcon)`
  font-size: 1.9rem;
  margin-right: 0.75rem;
`;

const MobileProductMenuFooterLogoutIcon = styled(FontAwesomeIcon)`
  font-size: 1.9rem;
  margin-right: 0.75rem;
`;

const MobileProductMenuDivider = styled(Divider)`
  height: 50%;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
`;

MobileProductMenuDivider.defaultProps = {
  orientation: 'vertical',
};
