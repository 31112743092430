import ResizeObserver from 'resize-observer-polyfill';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom';
import { ShoppingCartPanelProvider } from 'src/providers/ShoppingCart';
import { TenantProvider } from 'src/providers/Tenant';
import { ThemeProvider } from 'src/providers/Theme';
import { UserProvider } from 'src/providers/User';
import { App } from './App';
import { ErrorBoundary } from './ErrorBoundary';
import './index.css';

// // ↶ Use this together with npx msw init public/ to use MSW in development ↷
// import { handlers } from 'src/utils/testing/mocks/handlers';
// import { setupWorker } from 'msw';
// const worker = setupWorker(...handlers);
// worker.start({
//   onUnhandledRequest(req, print) {
//    // Suppress warnings for webpack hoy-reload requests
//     const pathname = req.url.pathname.toString();
//     if (pathname.includes('hot-update') || pathname.includes('.hdr')) {
//       return
//     }
//     print.warning()
//   }
// });
// // Very handy!

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // Atm we have retry logic handled via axios retry plugin. Once we move all our fetches to react-query we should remove the plugin and unset this option
      refetchOnMount: false, // Set here for two reasons. One, it is impractical for us to every time in most cases.
      // Second, it is impossible to overwrite per-query settings in tests, and this option makes queries never run in tests.
      refetchOnWindowFocus: false,
    },
  },
});

const ro = new ResizeObserver(() => {});
ro.observe(document.body);

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <TenantProvider>
        <UserProvider>
          <ShoppingCartPanelProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </ShoppingCartPanelProvider>
        </UserProvider>
      </TenantProvider>
      {/* <ReactQueryDevtools initialIsOpen /> // <- Useful debugger for react-query fine tuning! */}
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
