import { alpha, styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';

export const MobileChangeYourPasswordSectionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const MobileChangeYourPasswordDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.16)};
  height: 2px;
`;

MobileChangeYourPasswordDivider.defaultProps = {
  orientation: 'horizontal',
};

export const MobileRequirementBoxContainer = styled('div')`
  background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.16)};
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1rem;
`;

export const MobileChangeYourPasswordContentContainer = styled('div')`
  border-radius: 4px;
`;
