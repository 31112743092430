import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faUserSlash } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Portal, SelectChangeEvent, Typography } from '@mui/material';
import { RoleId } from '@samsonvt/shared-types/cognito';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { validate } from 'email-validator';
import { useState } from 'react';
import { StyledMUIButton } from 'src/components/Button';
import { Toast, useTrigger } from 'src/components/Toast';
import { formatDate } from 'src/services/formatDate';
import { DiscountSelect } from './DiscountSelect';
import { ResendInviteButton } from './ResendInviteButton';
import { ResetPasswordButton } from './ResetPasswordButton';
import { RoleSelect } from './RoleSelect';
import { CancelButton, RowFilledInput, TableData, TableDataRow, TableRowButton } from './styles';
import { useInvitationEmail } from './useInvitationEmail';
import { UserRole } from './UserManagement';
import { UserHeader, UserItem } from './UserManagementTable';

type CreateUserRequest = ({ user }: { user: UserHeader }) => void;
type UpdateUserRequest = ({ user, userId }: { user: UserHeader; userId: string }) => void;
type DeleteUserRequest = ({ userId }: { userId: string }) => void;

interface EditUserRowProps {
  userItem: UserItem;
  roles: UserRole[];
  discounts: DiscountScheme[];
  createUserRequest: CreateUserRequest;
  updateUserRequest: UpdateUserRequest;
  deleteUserRequest: DeleteUserRequest;
  cancelEdit: () => void;
  isLoading: boolean;
  sendResetPasswordEmail: (email: string) => Promise<void>;
}

const maxNameLength = 2048;

export function EditUserRow({
  userItem,
  roles,
  cancelEdit,
  createUserRequest,
  updateUserRequest,
  deleteUserRequest,
  isLoading,
  discounts,
  sendResetPasswordEmail,
}: EditUserRowProps) {
  const currentRole = roles.find((role) => role.id === userItem.role);

  const [validationToast, openValidationToast] = useTrigger();
  const [selectedRole, setSelectedRole] = useState<UserRole | undefined>(currentRole);
  const [selectedDiscountId, setSelectedDiscountId] = useState<DiscountScheme['id'] | undefined>(userItem?.discountId);
  const [name, setName] = useState(userItem.name || '');
  const [email, setEmail] = useState(userItem.email);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [roleTouched, setRoleTouched] = useState(false);

  const strippedName = name.trim();
  const invalidName = nameTouched && (!strippedName || strippedName.length > maxNameLength);

  const strippedEmail = email.trim();
  const invalidEmail = emailTouched && (!strippedEmail || !validate(strippedEmail));

  const invalidRole = roleTouched && !selectedRole;

  const isUserActive = userItem.role !== 'noaccess';

  const { resendInvite, disableResendButton, setEmailSent, emailSentToast, emailErrorToast } =
    useInvitationEmail(userItem);

  const createOrUpdateUser = (roleId?: RoleId) => {
    if (invalidName || invalidEmail) {
      openValidationToast();
      return;
    }

    const role = roleId || selectedRole?.id || currentRole?.id;
    setEmailTouched(true);
    setNameTouched(true);
    setRoleTouched(true);

    if (!role) {
      openValidationToast();
      return;
    }

    const discountId = selectedDiscountId || null;
    const user = { name: strippedName, role, discountId, email: strippedEmail };

    if (userItem.isNewUser) {
      createUserRequest({ user });
      setEmailSent(strippedEmail);
    } else {
      const userId = userItem.id;
      updateUserRequest({ userId, user });
    }
  };

  const enableUser = () => createOrUpdateUser('readonly');
  const disableUser = () => createOrUpdateUser('noaccess');
  const deleteUser = () => deleteUserRequest({ userId: userItem.id });

  const handleSelectRole = (event: SelectChangeEvent<unknown>) => {
    const chosenRole = roles.find((role) => role.id === event.target.value);
    setSelectedRole(chosenRole);
  };

  const handleSelectDiscount = (event: SelectChangeEvent<unknown>) => {
    const chosenDiscount = discounts.find((discount) => discount.id === event.target.value);
    setSelectedDiscountId(chosenDiscount?.id);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (!nameTouched) setNameTouched(true);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!emailTouched) setEmailTouched(true);
  };

  const resendInviteOrSendResetPasswordButton =
    userItem.confirmationStatus === 'RESET_REQUIRED' ? (
      <ResetPasswordButton sendResetPasswordEmail={sendResetPasswordEmail} emailToSendTheResetPassword={email} />
    ) : (
      <ResendInviteButton disableResendButton={disableResendButton} resendInvite={resendInvite} />
    );

  return (
    <TableDataRow data-testid="user-edit-row">
      <TableData>
        <RowFilledInput
          placeholder="Type name"
          fullWidth
          value={name}
          onChange={handleNameChange}
          disabled={isLoading || !isUserActive}
          error={invalidName}
          data-testid="user-name-input"
        />
      </TableData>
      <TableData>
        <RowFilledInput
          placeholder="Type a valid email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          disabled={isLoading || !userItem.isNewUser}
          error={invalidEmail}
          data-testid="user-email-input"
        />
      </TableData>
      <TableData>
        {isUserActive ? (
          <RoleSelect
            onChange={handleSelectRole}
            options={roles}
            disabled={isLoading}
            value={selectedRole}
            required
            error={invalidRole}
            data-testid="role-select"
          />
        ) : (
          <Typography>{userItem.role}</Typography>
        )}
      </TableData>
      <TableData>
        {discounts.length > 0 && (
          <DiscountSelect
            onChange={handleSelectDiscount}
            options={discounts}
            disabled={isLoading}
            selectedDiscountId={selectedDiscountId}
            required
            data-testid="discount-select"
          />
        )}
      </TableData>
      <TableData>
        <Typography>{formatDate(userItem.created)}</Typography>
      </TableData>
      <TableData>
        {userItem.lastActive || userItem.isNewUser ? (
          <Typography>{formatDate(userItem.lastActive)}</Typography>
        ) : (
          resendInviteOrSendResetPasswordButton
        )}
      </TableData>
      <TableData>
        {isUserActive ? (
          <>
            <TableRowButton color="success" onClick={() => createOrUpdateUser()} data-testid="save-user">
              Save
            </TableRowButton>
            <CancelButton onClick={cancelEdit}>Cancel</CancelButton>
            {!userItem.isNewUser ? (
              <TableRowButton
                sx={{ marginLeft: 1 }}
                color="success"
                onClick={disableUser}
                startIcon={<FontAwesomeIcon icon={faUserSlash} />}
              >
                Disable user
              </TableRowButton>
            ) : null}
          </>
        ) : (
          <>
            <StyledMUIButton color="secondary" onClick={enableUser}>
              Enable user
            </StyledMUIButton>
            <CancelButton onClick={cancelEdit}>Cancel</CancelButton>
            <TableRowButton
              sx={{ marginLeft: 1 }}
              color="success"
              onClick={deleteUser}
              startIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              Delete user
            </TableRowButton>
          </>
        )}
      </TableData>
      <Portal>
        <Toast
          dependency={validationToast}
          severity="error"
          title="Oops!"
          message={validationMessage(strippedName, strippedEmail, selectedRole)}
        />
        <Toast
          dependency={emailErrorToast}
          severity="error"
          title="Error"
          message="It looks like there was an error sending the invite email to this user."
        />
        <Toast dependency={emailSentToast} severity="success" title="Success" message="Email sent successfully." />
      </Portal>
    </TableDataRow>
  );
}

const validationMessage = (
  strippedName: string | undefined,
  strippedEmail: string | undefined,
  selectedRole: UserRole | undefined
) => {
  switch (true) {
    case !strippedName:
      return 'Please, add a Name.';
    case strippedName && strippedName.length > maxNameLength:
      return `The Name field cannot be longer than ${maxNameLength + 1} letters long. Please, shorten it.`;
    case !strippedEmail:
      return 'Please, add an Email address.';
    case strippedEmail && !validate(strippedEmail):
      return "There's a problem with the Email entered. Please, check if it's a valid email.";
    case !selectedRole:
      return 'Please, select a Role.';
    default:
      return '';
  }
};
