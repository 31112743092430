import { PerspectiveCamera, useCamera } from '@react-three/drei';
import { Camera, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Matrix4, Scene, Texture } from 'three';
import { FaceName } from './FullControls';

const createCanvas = (width: number, height: number, set2dTransform = true) => {
  if (!window || !document) {
    return;
  }

  const ratio = Math.ceil(window.devicePixelRatio) || 1;
  const canvas = document.createElement('canvas');
  canvas.width = width * ratio;
  canvas.height = height * ratio;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;

  if (set2dTransform) {
    canvas.getContext('2d')?.setTransform(ratio, 0, 0, ratio, 0, 0);
  }
  return canvas;
};

const createCubeFaceWithText = (faceIndex: number, fill: string) => {
  const canvas = createCanvas(100, 100);
  const ctx = canvas?.getContext('2d');

  if (!ctx || !canvas) {
    return;
  }

  const canvasWidth = 500;
  const canvasHeight = 500;

  canvas.setAttribute('width', `${canvasWidth}`);
  canvas.setAttribute('height', `${canvasHeight}`);

  ctx.fillStyle = fill;
  ctx.strokeStyle = '#ffffff';
  ctx.lineWidth = 5;

  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.strokeRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.font = 'Bold 120px Arial';
  ctx.fillStyle = '#ffffff';

  const canvasTexture = new Texture(canvas);
  canvasTexture.needsUpdate = true;

  return canvasTexture;
};

interface Props {
  sceneCamera: Camera;
  cubeSideClicked?: (val: FaceName) => void;
  hoverColour: string;
  colour: string;
}

export default function InteractionCube({ sceneCamera, cubeSideClicked, hoverColour, colour }: Props) {
  const [hover, setHover] = useState<number | null>(null);
  const { gl, scene, camera } = useThree();
  const virtualScene = useMemo(() => new Scene(), []);
  const virtualCam: any = useRef();
  const ref: any = useRef();
  const matrix = new Matrix4();

  useEffect(() => {
    document.body.style.cursor = hover ? 'pointer' : 'auto';
  }, [hover]);

  useFrame(() => {
    matrix.copy(sceneCamera.matrix).invert();
    ref?.current?.quaternion.setFromRotationMatrix(matrix);
    gl.autoClear = true;
    gl.render(scene, camera);
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(virtualScene, virtualCam.current);
  }, 1);

  const useMouse = !!cubeSideClicked;

  return (
    <>
      <PerspectiveCamera ref={virtualCam} makeDefault position={[0, 0, 50]} />
      <mesh
        ref={ref}
        raycast={useCamera(virtualCam)}
        onPointerOut={() => setHover(null)}
        onClick={() => {
          if (useMouse && hover) cubeSideClicked(faceNameByIndex(hover).toLowerCase() as FaceName);
        }}
        onPointerMove={(e: any) => {
          if (useMouse) setHover(Math.floor(e.faceIndex / 2));
        }}
      >
        <boxBufferGeometry attach="geometry" args={[23, 23, 23]} />
        {[...Array(6).keys()].map((index) => (
          <meshBasicMaterial
            key={index}
            attachArray="material"
            map={hover === index ? createCubeFaceWithText(index, hoverColour) : createCubeFaceWithText(index, colour)}
          />
        ))}
      </mesh>
    </>
  );
}

const faceNameByIndex = (cubeSideIndex: number) => ['Back', 'Front', 'Top', 'Bottom', 'Right', 'Left'][cubeSideIndex];
