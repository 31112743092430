import { useMediaQuery } from '@mui/material';
import { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { DesktopProductDetails } from 'src/components/Product/Desktop/DesktopProductDetails';
import MobileProductDetails from 'src/components/Product/Mobile/MobileProductDetails';
import { device } from 'src/constants/mediaQueries';
import { useDocumentTitle } from 'src/hooks/DocumentTitle/useDocumentTitle';
import { useCurrentTabName } from 'src/hooks/useCurrentTabName';
import { useProductFeatures } from 'src/hooks/useProductFeatures';
import { useProduct } from 'src/providers/Product';
import { usePart } from 'src/services/part';
import { usePartNavigation } from 'src/services/partNavigation';
import { MainTemplate } from 'src/templates/MainTemplate';

export type TabProps = {
  currentTabName: ProductFeature | undefined;
  availableTabs: ProductFeature[];
  productPath: string;
};

const trailingSlashRegex = /\/$/;

export function ProductDetailPage() {
  // Set product page document title based on part providers
  const { current } = usePart(); // After https://samsonvt.atlassian.net/browse/SVT-1672 move inside useDocumentTitle hook
  const { parents } = usePartNavigation(); // After https://samsonvt.atlassian.net/browse/SVT-1672 move inside useDocumentTitle hook
  useDocumentTitle(current, parents);

  // Get device info for further use in return values. Can't be pushed down due to conditional hook call.
  const isOnTablet = useMediaQuery(device.mobileOrTablet);

  // Set up redirects and current tab context
  const { currentProduct } = useProduct();
  const { url, path: productPath } = useRouteMatch();

  const availableTabs = useProductFeatures();
  const currentTabName = useCurrentTabName();
  const trimmedUrl = url.replace(trailingSlashRegex, ''); // Prevents rare error when user enters manually url ending with /

  if (currentProduct?.isDeleted || !availableTabs.length) {
    return <Redirect to="/404" />; // Product misconfigured or user attempt to see old product.
  }

  if (!currentTabName) {
    return <Redirect from={productPath} to={`${trimmedUrl}/${availableTabs[0]}`} />; // User attempt to get unavailable tab.
  }

  const tabContext: TabProps = { currentTabName, availableTabs, productPath };

  return (
    <MainTemplate>
      {isOnTablet ? <MobileProductDetails {...tabContext} /> : <DesktopProductDetails {...tabContext} />}
    </MainTemplate>
  );
}
