import type { ProductListResult } from '@samsonvt/shared-types/productLambda';
import { ProductCard } from './ProductCard';
import { ProductListStyles } from './styles';
import UploadProductCard from './UploadProductCard';

interface IProps {
  products: ProductListResult[];
  showAddProductTile: boolean;
}

export function ProductList({ products, showAddProductTile }: IProps) {
  return (
    <ProductListStyles>
      {products.map((product) => (
        <ProductCard key={product.productId} product={product} />
      ))}
      {showAddProductTile ? <UploadProductCard /> : null}
    </ProductListStyles>
  );
}
