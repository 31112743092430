import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import MUIDivider from '@mui/material/Divider';

interface RepairListHeaderProps {
  productName: string | undefined;
  tagListActive: boolean | undefined;
}

export default function RepairListHeader({ productName, tagListActive }: RepairListHeaderProps) {
  return (
    <>
      <HeaderWithMargin>{productName}</HeaderWithMargin>
      <PrimaryDivider />
      <BodyWithMargin>
        {tagListActive ? 'Select from the below:' : 'Select from the below working instructions:'}
      </BodyWithMargin>
    </>
  );
}

const HeaderWithMargin = styled(MUITypography)`
  margin-top: 0.65rem;
  margin-bottom: 1.45rem;
`;
HeaderWithMargin.defaultProps = { variant: 'h1' };

const BodyWithMargin = styled(MUITypography)`
  margin-top: 0.45rem;
`;
BodyWithMargin.defaultProps = { variant: 'body1' };

const PrimaryDivider = styled(MUIDivider)`
  border-color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  border-bottom-width: 2px;
  margin: 0;
`;
PrimaryDivider.defaultProps = { variant: 'middle' };
