import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { device } from 'src/constants/mediaQueries';
import { clientLogoWidth } from 'src/constants/sizes';
import { spacing } from 'src/constants/spacing';

export const LogoLinkElement = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: ${spacing.mediumSpacing};
  user-select: none; // Prevents caret showing up in logo
`;

export const StyledClientLogo = styled('img')`
  display: block;
  width: auto;
  max-height: 3.125rem;
  max-width: ${clientLogoWidth};
  @media ${device.mobileOrTablet} {
    max-width: 8rem;
  }
`;
