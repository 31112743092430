import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

const BreadcrumbsEllipsisStyles = styled('div')`
  width: 24px;
  height: 16px;
  text-align: center;
  line-height: 110%;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.grey[600]};
  color: ${({ theme }) => theme.palette.white56};

  :hover {
    cursor: pointer;
  }
`;

export function BreadcrumbsEllipsis({ onClick }: { onClick: () => void }) {
  return (
    <BreadcrumbsEllipsisStyles>
      <FontAwesomeIcon icon={faEllipsis} size="sm" onClick={onClick} />
    </BreadcrumbsEllipsisStyles>
  );
}
