import { styled } from '@mui/material';
import { border } from 'src/constants/borderSizes';

import { StyledMUIButton, StyledMUIButtonLink } from 'src/components/Button';

import MUIInputLabel from '@mui/material/InputLabel';
import MUIMenuItem from '@mui/material/MenuItem';

import { StyledSelectField } from 'src/components/Select';
import { LinkProps } from 'react-router-dom';

export const HeaderContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  width: 100%;
  border-radius: ${border.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  justify-content: center;
  padding: 0 1rem;
`;

export const Row = styled('div')`
  display: flex;
  margin: 0.5rem 0;
  width: 100%;
`;

export const HeaderContainerFirstRow = styled(Row)`
  justify-content: space-between;
`;
export const HeaderContainerSecondRow = styled(Row)`
  justify-content: center;

  & .MuiAutocomplete-root {
    width: 100%; // override the global search components styles
  }
`;

export const HeaderContainerThirdRow = styled(Row)`
  justify-content: center;
`;

export const CategoryHeaderBackButton = styled(StyledMUIButtonLink)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin: 0;
  white-space: nowrap;
  box-shadow: none;

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const CategoryHeaderSearchButton = styled(StyledMUIButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 0;
  min-width: 3rem;
  box-shadow: none;

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const CategoryHeaderSelectLabel = styled(MUIInputLabel)`
  color: ${({ theme }) =>
    theme.palette.primary.contrastText} !important; // important, should always be contrast text in all states
`;
CategoryHeaderSelectLabel.defaultProps = { required: false };

export const CategoryHeaderSelectField = styled(StyledSelectField)`
  height: 55px;
  &.MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  &.MuiFilledInput-root::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  &.MuiFilledInput-root::after:not(error) {
    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[900]};
  }
`;

export const CategoryHeaderSelectOption = styled(MUIMenuItem)<LinkProps>`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.light};
`;
