import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import Chip from 'src/components/Chip';
import { useUser } from 'src/providers/User';
import useFormattedPrice, { calculateDiscountedPrice } from 'src/services/formatCurrency';

type PriceSectionProps = {
  price: DiscountedPartPrice;
};

export function PriceSection({ price }: PriceSectionProps) {
  const { discountPercentage } = useUser();
  const discountedPrice = calculateDiscountedPrice(price, discountPercentage);

  return (
    <PriceRow>
      <MUITypography variant="h4" data-testid="price-tag">
        {useFormattedPrice({ price: discountedPrice })}
      </MUITypography>
      {discountPercentage && price !== 'por' ? (
        <DiscountDetails price={price} discountPercentage={discountPercentage} />
      ) : null}
    </PriceRow>
  );
}

function DiscountDetails({ price, discountPercentage }: DiscountRowProps) {
  return (
    <StyledDiscountDetails>
      <PriceChip label={`Disc. ${formatPercent(discountPercentage)}`} data-testid="overview-discount-tag" />
      <SemiTransparentCaption variant="caption" data-testid="original-price-tag">
        Price <s>{useFormattedPrice({ price })}</s>
      </SemiTransparentCaption>
    </StyledDiscountDetails>
  );
}

const StyledDiscountDetails = styled('div')`
  display: flex;
  align-items: center;
`;

const PriceChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const PriceRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

type DiscountRowProps = {
  price: number;
  discountPercentage: number;
};

const formatPercent = (val: number) => `${val.toFixed(0)}%`;

const SemiTransparentCaption = styled(MUITypography)`
  opacity: 0.7;
  margin-left: 0.5rem;
  line-height: 1rem;
`;
SemiTransparentCaption.defaultProps = { variant: 'caption' };
