import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { TenantContext } from 'src/providers/Tenant';
import { NamedLink } from 'src/services/partNavigation';
import { initializeGTM } from 'src/services/initializeGTM';
import filterProductOptionsFromParents from 'src/services/filterProductOptionsFromParents';

const matchSlashRegex = /\//g;
const matchHyphenRegex = /-/g;
const matchInitialsRegex = /\b\w/g;

export function useDocumentTitle(selectedPart?: PartNode, partParents?: NamedLink[]): void {
  const { name: tenantName } = useContext(TenantContext) || {}; // Always called from within TenantProvider. Default provided for convenience in tests.

  const { pathname } = useLocation();

  useEffect(() => {
    const pageName = pathname
      .slice(1)
      .replace(matchSlashRegex, ' | ')
      .replace(matchHyphenRegex, ' ')
      .replace(matchInitialsRegex, (l) => l.toUpperCase());

    document.title = `${pageName || 'Welcome'} | ${tenantName ?? '...'}`;
    initializeGTM();
  }, [tenantName, pathname]);

  useEffect(() => {
    if (selectedPart && partParents) {
      const breadCrumbsNames = filterProductOptionsFromParents(partParents).map((breadCrumb) => breadCrumb.name);
      const selectedPartString = selectedPart.name;
      const breadCrumbsString = [selectedPartString, ...breadCrumbsNames.reverse()]
        .join(' | ')
        .replace(matchInitialsRegex, (l) => l.toUpperCase());
      const resultingDocumentTitle = `${breadCrumbsString} | ${tenantName}`;
      document.title = resultingDocumentTitle;
    }
    initializeGTM();
  }, [selectedPart, partParents, tenantName]);
}
