import { faCircleDollar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { BackButton } from 'src/components/Button';
import { MainTemplate } from 'src/templates/MainTemplate';
import { UserManagementTabs } from '../UserManagementPage/UserManagementTabs';
import DiscountManagementTable from './DiscountManagementTable';
import {
  DiscountManagementAddButton,
  DiscountManagementContentPadding,
  DiscountManagementTitle,
  DiscountManagementTitleContainer,
} from './styles';

export function DiscountManagement() {
  const location = useLocation();
  const { goBack } = useHistory();

  const [openCreateDiscountSchemeForm, setOpenCreateDiscountSchemeForm] = useState(false);
  return (
    <MainTemplate>
      <UserManagementTabs currentUrl={location.pathname} />
      <DiscountManagementContentPadding>
        <BackButton onClick={goBack} />
        <StandardBreadcrumbs
          current={{ name: 'User and discount management' }}
          parents={[{ name: 'My account', url: '/my-account' }]}
        />
        <DiscountManagementTitleContainer>
          <FontAwesomeIcon icon={faCircleDollar} size="2x" />
          <DiscountManagementTitle>Discount management</DiscountManagementTitle>
          <DiscountManagementAddButton onClick={() => setOpenCreateDiscountSchemeForm(true)}>
            Add new discount
          </DiscountManagementAddButton>
        </DiscountManagementTitleContainer>
        <DiscountManagementTable
          openCreateDiscountSchemeForm={openCreateDiscountSchemeForm}
          setOpenCreateDiscountSchemeForm={setOpenCreateDiscountSchemeForm}
        />
      </DiscountManagementContentPadding>
    </MainTemplate>
  );
}
