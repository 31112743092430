import {
  faCartCircleCheck,
  faCircleUser,
  faCloudArrowUp,
  faListCheck,
  faUserPen,
  faUsers,
  faArrowRightFromBracket,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from 'src/Feature';
import MyAccountPageOption from '../MyAccountPageOption';
import {
  LogOutButton,
  MyAccountContainer,
  MyAccountHeader,
  MyAccountOptionCardsContainer,
  MyAccountTitle,
  MyAccountTitleContainer,
} from '../styles';
import { MyAccountPageProps } from '../types';

export function DesktopMyAccountPage({
  logoutFromApp,
  hasPermissionsToOperateOnAllOfTenantsOrders,
  canListUsers,
  canAddProducts,
}: MyAccountPageProps) {
  return (
    <MyAccountContainer>
      <MyAccountHeader>
        <MyAccountTitleContainer>
          <FontAwesomeIcon icon={faCircleUser} size="2x" />
          <MyAccountTitle>My Account</MyAccountTitle>
        </MyAccountTitleContainer>
        <LogOutButton onClick={logoutFromApp} data-testid="logout-button">
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          Log out
        </LogOutButton>
      </MyAccountHeader>
      <MyAccountOptionCardsContainer>
        <Feature flag="shopping-cart">
          {hasPermissionsToOperateOnAllOfTenantsOrders ? (
            <MyAccountPageOption
              title="Received orders"
              icon={faListCheck}
              subtitle={
                <p>
                  View all the orders submitted from any of your customers, parts ordered and total cost, delivery
                  address and customer details.
                </p>
              }
              buttonText="View received orders"
              to="received-orders"
            />
          ) : (
            <MyAccountPageOption
              title="My orders"
              icon={faCartCircleCheck}
              subtitle={
                <p>
                  View all your submitted orders in the shopping cart. See all the details of the orders, including part
                  names, part numbers, total cost and delivery address.
                </p>
              }
              buttonText="View my orders"
              to="my-orders"
            />
          )}
        </Feature>
        {canListUsers ? (
          <MyAccountPageOption
            title="User management"
            icon={faUsers}
            subtitle={
              <ul>
                <li>See all the users registered into the platform and edit their role.</li>
                <li>
                  In the discount management tab you will manage what discounts (if any) these users will have when
                  buying parts from the parts catalogue.
                </li>
              </ul>
            }
            buttonText="Manage users and discounts"
            to="user-management"
          />
        ) : null}
        {canAddProducts ? (
          <MyAccountPageOption
            title="Uploads"
            icon={faCloudArrowUp}
            subtitle={
              <>
                <p>Upload files that you would want to share with us.</p>
                <ul>
                  <li>These files can be: 3D models (.step, .sldasm, .sldparts) </li>
                  <li>Documents (.pdf, .xls, .xlsx, .doc, .docx)</li>
                  <li>Any other files</li>
                </ul>
              </>
            }
            buttonText="Upload files"
            to="upload"
          />
        ) : null}
        <MyAccountPageOption
          title="Account details"
          icon={faUserPen}
          subtitle={
            <ul>
              <li>Change your account details and delivery address</li>
              <li>Change your password</li>
            </ul>
          }
          buttonText="Edit my details"
          to="account-details"
        />
      </MyAccountOptionCardsContainer>
    </MyAccountContainer>
  );
}
