import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';
import { spacing } from 'src/constants/spacing';
import type { ProductListResult } from '@samsonvt/shared-types/productLambda';
import type { ModelStatus } from '@samsonvt/shared-types/productsTable';
import { useProductFeatures } from 'src/hooks/useProductFeatures';
import { usePermissions } from 'src/hooks/usePermissions';
import { ProductCardEllipsesDropdown } from './ProductCardEllipsesDropdown';
import { ProductCardStyles, ProductCardImageContainer, ProductCardTextContainer, StyledBadge } from './styles';
import { Thumbnail } from '../Thumbnail';
import { ProductCardActions } from './ProductCardActions';

interface ProductCardProps {
  product: ProductListResult;
}

const StyledModelStatus = styled(StyledBadge)`
  position: absolute;
  top: ${spacing.smallSpacing};
  left: ${spacing.smallSpacing};
`;

export function transformStatusString(modelStatus?: ModelStatus) {
  if (modelStatus === 'COMPLETED') {
    return 'Live';
  }
  return modelStatus?.replace('_', ' ').toLowerCase();
}

export function ProductCard({ product }: ProductCardProps) {
  const { productId, modelStatus, thumbnailUrl, name: productName, enabledFeatures: productEnabledFeatures } = product;

  const tenantsProductFeatures = useProductFeatures();
  const productFeatures = [...new Set(tenantsProductFeatures.concat(productEnabledFeatures || []))]; // add any product feature not included at tenant level and make it unique to avoid duplication
  const [productStatusDisplayed, setProductStatusDisplayed] = useState(modelStatus);
  const canDeleteProduct = usePermissions(['delete', 'product']);
  const canUpdateProduct = usePermissions(['update', 'product']);
  const canViewPartsCatalogue = usePermissions(['view', 'product.partsCatalogue']);
  const canViewWorkInstructions = usePermissions(['view', 'product.workInstructions']);

  const statusString = transformStatusString(productStatusDisplayed);
  const canViewBadges = usePermissions(['view', 'draft']);

  const actionButtonsVisible = productFeatures.length > 1;

  return (
    <ProductCardStyles
      data-status={productStatusDisplayed}
      data-productname={productName}
      data-testid={`product-card-${productId}`}
    >
      <ProductCardImageContainer>
        {actionButtonsVisible ? (
          <Thumbnail url={thumbnailUrl} alt={productName} />
        ) : (
          <NavLink data-testid="card-navlink" to={`/product-library/${productId}`}>
            <Thumbnail url={thumbnailUrl} alt={productName} />
          </NavLink>
        )}
      </ProductCardImageContainer>
      {canViewBadges && <StyledModelStatus status={productStatusDisplayed}>{statusString}</StyledModelStatus>}
      {(canDeleteProduct || canUpdateProduct) && (
        <ProductCardEllipsesDropdown
          product={product}
          productStatusDisplayed={productStatusDisplayed}
          setProductStatusDisplayed={setProductStatusDisplayed}
          canUpdateProduct={canUpdateProduct}
          canDeleteProduct={canDeleteProduct}
        />
      )}
      <ProductCardTextContainer>
        {actionButtonsVisible ? (
          <>
            <span>{productName}</span>
            <ProductCardActions
              data-testid="product-card-actions"
              productFeatures={productFeatures}
              productId={productId}
              canViewPartsCatalogue={canViewPartsCatalogue}
              canViewWorkInstructions={canViewWorkInstructions}
            />
          </>
        ) : (
          <NavLink data-testid="card-navlink" to={`/product-library/${productId}`}>
            <span>{productName}</span>
          </NavLink>
        )}
      </ProductCardTextContainer>
    </ProductCardStyles>
  );
}
