import { CognitoUserSession } from 'amazon-cognito-identity-js';

const roleAttributeName = 'custom:vtaas-role';
const noAccessRole = 'noaccess';

export const isAuthorised = (session: CognitoUserSession | null) => {
  const token = session && session.getIdToken();

  return token && token.payload[roleAttributeName] !== noAccessRole;
};
