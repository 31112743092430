import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { Toast, useTrigger } from 'src/components/Toast';
import { usePost } from 'src/hooks/usePost';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';

export default function ConfirmAccountPage({
  triggerSuccessToast,
}: {
  triggerSuccessToast: (title: string, message: string) => void;
}) {
  const { confirmSignup, login } = useUser();
  const { accountId } = useContext(TenantContext);

  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location?.search);
  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setToastErrorMessage] = useState<string | undefined>();

  const { triggerPostRequest: triggerSignupConfirmation } = usePost({
    handler: (body: { email: string; code: string }) => {
      const { email, code } = body;
      return confirmSignup(email, code);
    },
    onSuccess: async (res, setData, body?: { email: string; code: string }) => {
      triggerSuccessToast('Account activated', 'Your account has been succesfully activated. Enjoy the platform!');
      const { email } = body || {};
      const password = localStorage.getItem('password');

      if (email && password) {
        await login(email, password); // check if password and emails are existing (if the user uses a different browser the password will be null). User should be redirected to login screen if no password is found
        localStorage.removeItem('password');
      }

      history.push('/product-library');
    },
    onError: (error) => {
      switch (error.name) {
        case 'ExpiredCodeException':
          showErrorToast('Error confirming your account. Please request a new code');
          break;
        default:
          showErrorToast(error.message);
      }
    },
  });

  const showErrorToast = useCallback((message: string) => {
    setToastErrorMessage(message);
    setErrorToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountId && email && code) {
      triggerSignupConfirmation({ email, code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, email, code]);

  return (
    <>
      <FullscreenLoadingSpinner />
      <LoadingText>
        {errorToast ? (
          <ErrorText>{`An error occured confirming your account: ${errorToastMessage}`}</ErrorText>
        ) : (
          `Confirming your account. ${!email || !code ? 'No username or code found, please request a new link' : ''}`
        )}
      </LoadingText>
      <Toast
        dependency={errorToast}
        severity="error"
        title="Error confirming your account"
        message={errorToastMessage || ''}
      />
    </>
  );
}

const LoadingText = styled('div')`
  position: absolute;
  top: 60%;
  width: 100%;
  text-align: center;
`;

const ErrorText = styled(MUITypography)`
  width: 100%;
  text-align: center;
  font-weight: bold;
`;
