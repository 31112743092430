import { styled, alpha } from '@mui/material';
import MUIDivider from '@mui/material/Divider';

import { StyledMUIButton, StyledMUIButtonLink } from 'src/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MUITypography from '@mui/material/Typography';
import { device } from 'src/constants/mediaQueries';

export const StyledCreateAccountFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 360px;

  @media ${device.mobileOrTablet} {
    width: 320px;
    margin-bottom: 2rem;
  }
`;

export const StyledDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  height: 2px;
  margin-top: 3.8rem;
`;

export const BackToLoginButton = styled(StyledMUIButtonLink)`
  background-color: transparent;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.palette.brand.contrastText};
  color: ${({ theme }) => theme.palette.brand.contrastText};
  width: 50%;
  margin: 0;
  margin-top: 1rem;
  white-space: nowrap;
  min-width: 160px;

  :hover {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }
`;

export const ChevronBack = styled(FontAwesomeIcon)`
  font-size: 1rem !important; // use important to override more specific mui font-size
  margin: 0 1rem;
`;

export const CreateAccountTitle = styled(MUITypography)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.brand.contrastText};
  text-align: left;
  margin: 1.5rem 0;
`;

export const FormDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  height: 1px;
  margin-bottom: 1rem;
`;

export const DesktopPasswordRequirements = styled('div')`
  position: absolute;
  height: 200px;
  width: 360px; // width of the form
  left: calc(100% + 1rem);
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;

export const MobilePasswordRequirements = styled('div')`
  height: 200px;
  width: 360px; // width of the form on mobile
  background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
  margin-bottom: 1rem;

  @media ${device.mobileOrTablet} {
    width: 320px;
  }
`;

export const SendCreateAccountFormButton = styled(StyledMUIButton)`
  :disabled {
    background-color: ${({ theme }) =>
      alpha(
        theme.palette.secondary.main,
        theme.palette.brand.main === theme.palette.primary.main ? 0.16 : 0.5
      )}; // check if theme is dark i.e. brand === primary
    color: ${({ theme }) =>
      alpha(theme.palette.brand.contrastText, theme.palette.brand.main === theme.palette.primary.main ? 0.16 : 0.5)};
  }
`;
