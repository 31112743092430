import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, styled } from '@mui/material';
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIList from '@mui/material/List';
import MUIListItemButton from '@mui/material/ListItemButton';
import MUIListItemText from '@mui/material/ListItemText';
import MUITypography from '@mui/material/Typography';
import React, { useState } from 'react';

interface MobileProductMenuListItemProps {
  title: string;
  children: React.ReactNode;
  open?: boolean;
}

export function PartsCatalogueAccordion({ title, children, open = false }: MobileProductMenuListItemProps) {
  const [expanded, setExpanded] = useState(open);

  const onClick = () => setExpanded((prev) => !prev);

  const numberOfItems = React.Children.count(children);
  const displayTitle = `${title} (${numberOfItems})`;

  return (
    <StyledAccordion expanded={expanded} onClick={onClick} data-testid={`tab-${title}`}>
      <StyledAccordionSummary expandIcon={<StyledIcon icon={faChevronDown} />}>
        <StyledTitle>{displayTitle}</StyledTitle>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <StyledList>{children}</StyledList>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

const StyledList = styled(MUIList)`
  padding: 0;
`;

const StyledAccordion = styled(MUIAccordion)`
  background-color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.1)};
  box-shadow: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 1em;

  &.Mui-expanded {
    margin: 0;
    margin-bottom: 1em;
  }

  &::before {
    content: none;
  }
`;

const StyledAccordionSummary = styled(MUIAccordionSummary)`
  margin: 0.5rem 1rem;
  padding: 0;
  height: 3rem;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  &.Mui-expanded {
    min-height: 0;
    max-height: 2.5rem;
  }
`;

const StyledTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[100]};
  font-size: 1rem;
  font-weight: 700;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey[100]};
  font-size: 1.2rem;
`;

const StyledAccordionDetails = styled(MUIAccordionDetails)`
  padding: 0;

  .MuiListItemText-root:last-of-type .MuiBox-root {
    border-bottom: none;
  }
`;

export const StyledListText = styled(MUIListItemText)`
  color: ${({ theme }) => theme.palette.grey[100]};

  .MuiTypography-root {
    font-size: 0.875rem;
  }

  &:first-of-type .MuiBox-root {
    padding-top: 0;
  }
`;

export const StyledListItem = styled(MUIListItemButton)`
  padding: 0 1rem;
  min-height: 2.8rem;

  &.Mui-selected {
    border-left: 4px solid ${({ theme }) => theme.palette.grey[100]};
    padding: 0 calc(1rem - 4px); // remove some padding because of the border
    background-color: transparent;
  }
`;
