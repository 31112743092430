import { useEffect, useState, DependencyList, useRef } from 'react';
import axios, { CancelToken } from 'axios';
import { error as logError } from 'src/services/log';

export function useFetch<T>(
  fetchFunction: ((token: CancelToken) => Promise<T>) | undefined,
  deps: DependencyList = [],
  errorMessage?: string
) {
  const mountedRef = useRef(true); // For cancelling non-axios requests, so that if component unmonts before fetchFunction completes we won't attempt to update unmounted component's state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(undefined);
  const [data, setData] = useState<T | undefined>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    const source = axios.CancelToken.source();
    (async () => {
      try {
        if (
          fetchFunction // enable conditional fetching
        ) {
          const response = await fetchFunction(source.token);
          if (mountedRef.current) setData(response);
        }
      } catch (err: unknown) {
        if (mountedRef.current) setError(err);
        logError(err as Error, errorMessage || 'Failed to fetch from API');
      } finally {
        if (mountedRef.current) setLoading(false);
      }
    })();
    return () => source.cancel('Cancelling API request');
    // Dynamic dependency array doesn't pass eslint, also fetchFunction may be referentially unstable and this causes infinite loop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, ...deps]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );

  return { data, loading, error, setError, setData, setLoading };
}

export interface FetchStatus {
  loading: boolean;
  error: unknown;
}
