import { styled } from '@mui/material';
import { useState } from 'react';
import { useUser } from 'src/providers/User';

import type { PartDetailsForm } from '../EditModal';
import AttributeTextField from './AttributeTextField';
import AttributeSwitch from './AttributeSwitch';
import AttributePriceField from './AttributePriceField';

export type OverviewTabProps = PartDetailsForm & {
  setPartForm: React.Dispatch<React.SetStateAction<PartDetailsForm | undefined>>;
};

export default function OverviewTab({ price, availableToOrder, isPor, setPartForm, displayName }: OverviewTabProps) {
  const { preferredCurrency } = useUser();
  const [touched, setTouched] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPartForm((partDetailsForm) => ({ ...partDetailsForm!, displayName: e.target.value }));
    setTouched(true);
  };

  const porDisabled = !availableToOrder;
  const priceInputDisabled = !availableToOrder || isPor;

  const nameError = touched && !displayName?.length ? 'Display name is required' : undefined;
  const displayNameValidation = {
    error: Boolean(nameError),
    helperText: nameError,
  };

  return (
    <Column>
      <AttributeTextField
        label="Part display name"
        data-testid="display-name-field"
        value={displayName}
        onChange={handleNameChange}
        onBlur={() => setTouched(true)}
        {...displayNameValidation}
      />
      <AttributeSwitch
        label="Available to order"
        checked={availableToOrder}
        onChange={(e, v) => setPartForm((partForm) => ({ ...partForm!, availableToOrder: v }))}
        data-testid="edit-parts-available-switch"
      />
      <AttributeSwitch
        label=" Price On Request"
        disabled={porDisabled}
        checked={isPor}
        onChange={(e, v) => setPartForm((partForm) => ({ ...partForm!, isPor: v }))}
        data-testid="edit-parts-por-switch"
      />
      <AttributePriceField
        label="Price"
        disabled={priceInputDisabled}
        size="small"
        value={price ?? ''}
        type="number"
        inputProps={{ style: { textAlign: 'right' } }}
        onChange={
          (e) => setPartForm((partForm) => ({ ...partForm!, price: Number(e.target.value) || partForm!.price })) // if any value is Nan or 0 default to original price
        }
        data-testid="edit-parts-price-input"
        preferredCurrency={preferredCurrency}
      />
    </Column>
  );
}

const Column = styled('div')`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
`;
