import {
  ModelBlobsIndexDBKey,
  addToStorage,
  getFromStorage,
  indexDBKeyEtagDelimiter,
  removeOldModels,
} from './blobStorage';
import { readSvtFile } from './svtFile';
import { error as logError } from './log';
import { DownloadConfig, downloadObject, getHeaders, maxDownloadProgress } from './s3';

export const getBlob = async (url: string, config: DownloadConfig, shouldCache: boolean): Promise<ArrayBuffer> => {
  const makeBlobKey = async (): Promise<ModelBlobsIndexDBKey> => {
    const headers = await getHeaders(url, config);
    const { pathname } = new URL(url);

    return `svt:blob:${pathname}${indexDBKeyEtagDelimiter}${headers.etag}`;
  };

  const fetchAndCacheBlob = async (_key: ModelBlobsIndexDBKey) => {
    const download = await downloadObject(url, config);

    let blob;

    if (isURLOfSvtFile(url)) {
      blob = await readSvtFile(download);
    } else {
      blob = download;
    }

    if (shouldCache) {
      removeOldModels(key);
      addToStorage(key, blob);
    }

    return blob;
  };

  const fetchBlob = async (key: ModelBlobsIndexDBKey) => {
    let fromStorage;

    try {
      fromStorage = await getFromStorage(key);
      if (fromStorage) {
        config.onProgress(maxDownloadProgress);
      }
    } catch (error: any) {
      logError(error, 'Failed to get blob from indexeddDB');
    }

    return fromStorage || fetchAndCacheBlob(key);
  };

  const key = await makeBlobKey();
  return shouldCache ? fetchBlob(key) : fetchAndCacheBlob(key);
};

const isURLOfSvtFile = (url: string) => new URL(url).pathname.endsWith('svt');
