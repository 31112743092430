import { styled } from '@mui/material';
import MUITab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';

export const Tabs = styled(MUITabs)`
  .MuiTabs-indicator {
    display: none;
  }

  padding: 0 15px;
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

const shouldForwardProp = (prop: string) => prop !== 'inner';

export const Tab = styled(MUITab, { shouldForwardProp })<{ inner?: boolean }>`
  min-width: auto;
  text-transform: none;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  opacity: 0.5;
  padding: 12px 14px; // MUI uses px
  font-weight: bold;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
    border-bottom: 3px solid white;
    opacity: 1;
  }
`;
