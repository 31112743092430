import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { ScreenName } from 'src/App';
import { StyledMUIButtonLink } from 'src/components/Button';
import { device } from 'src/constants/mediaQueries';

interface MyAccountPageOptionProps {
  title: string;
  subtitle: JSX.Element;
  icon: IconDefinition;
  buttonText: string;
  to: ScreenName;
  disabled?: boolean;
}

export default function MyAccountPageOption({
  title,
  subtitle,
  icon,
  buttonText,
  to,
  disabled,
}: MyAccountPageOptionProps) {
  return (
    <MyAccountOptionCard>
      <MyAccountOptionCardTitle>{title}</MyAccountOptionCardTitle>
      <MyAccountOptionCardIcon>
        <FontAwesomeIcon icon={icon} size="3x" />
      </MyAccountOptionCardIcon>
      <MyAccountOptionCardSubTitle>{subtitle}</MyAccountOptionCardSubTitle>
      <MyAccountOptionButton to={to} disabled={disabled}>
        {disabled ? 'Only available on desktop' : buttonText}
      </MyAccountOptionButton>
    </MyAccountOptionCard>
  );
}

const MyAccountOptionCard = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  width: 250px;
  height: 100%;
  border-radius: 4px;

  li {
    list-style-position: inside;
  }

  @media ${device.mobileOrTablet} {
    height: 100%;
    width: 100%;
  }
`;

const MyAccountOptionCardTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem;
`;

const MyAccountOptionCardIcon = styled('div')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const MyAccountOptionCardSubTitle = styled('div')`
  color: ${({ theme }) => theme.palette.grey[900]};
  word-break: break-word;
  white-space: normal;
  margin: 1rem 2rem;
  flex-grow: 1;

  @media ${device.mobileOrTablet} {
    padding: 0 1rem;
    margin: 0;
  }
`;

const MyAccountOptionButton = styled(StyledMUIButtonLink)`
  display: flex;
  justify-content: space-around;
  min-width: 230px;
  white-space: nowrap;
  margin: 1rem;
  box-shadow: none;
  background-color: ${({ theme, disabled }) =>
    disabled ? alpha(theme.palette.common.black, 0.24) : theme.palette.primary.main} !important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey['600'] : theme.palette.sidebarBackground.contrastText} !important;
`;
