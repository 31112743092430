import { styled } from '@mui/material';

import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { NavigateNext as MUINavigateNextIcon } from '@mui/icons-material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import MUILink from '@mui/material/Link';
import MUITypography from '@mui/material/Typography';

export const BreadcrumbsContainer = styled(MUIBreadcrumbs)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const BreadcrumbsSeparator = styled(MUINavigateNextIcon)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;

BreadcrumbsSeparator.defaultProps = { fontSize: 'medium' };

export const BreadcrumbsLink = styled(MUILink)<LinkProps>`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-decoration-color: ${({ theme }) => theme.palette.grey[900]};
  text-decoration-thickness: 2px;
  &:hover {
    text-decoration: none;
  }
`;

BreadcrumbsLink.defaultProps = { underline: 'always', component: RouterLink };

export const CurrentBreadcrumb = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-weight: bold;
`;
