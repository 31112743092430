import { styled, StepIconProps as MUIStepIconProps } from '@mui/material';
import MUIStepIcon from '@mui/material/StepIcon';
import MUIIconDoneRounded from '@mui/icons-material/DoneRounded';

const DoneIcon = styled(MUIIconDoneRounded)`
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const ActiveIcon = styled(MUIStepIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  & .MuiStepIcon-text {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const InactiveIcon = styled(MUIStepIcon)`
  color: ${({ theme }) => theme.palette.grey[500]};
  & .MuiStepIcon-text {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export function CustomIcon({ completed, active, icon }: MUIStepIconProps) {
  const NextIcon = active ? ActiveIcon : InactiveIcon; // inactive gives a darker background to the icon
  return completed ? <DoneIcon /> : <NextIcon icon={icon} />; // icon prop contains the number of the step by default
}
