import { styled } from '@mui/material';

import { spacing } from 'src/constants/spacing';

export const FileToUploadName = styled('div')`
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: ${spacing.xSmallSpacing} 0;
`;
