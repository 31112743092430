import { DeleteOutline } from '@mui/icons-material';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { RowSelectField, SmallerText } from './styles';

type DiscountSelectProps = React.ComponentProps<typeof MUISelect> & {
  options: DiscountScheme[];
  selectedDiscountId: string | undefined;
};

export function DiscountSelect({ selectedDiscountId = 'no-discount', options, ...props }: DiscountSelectProps) {

  return (
    <RowSelectField value={selectedDiscountId} name="discount" size="small" {...props}>
      {options.map((discount) => (
        <MUIMenuItem value={discount.id} key={discount.id}>
          {discount.name} <SmallerText>({discount.discountPercentage}%)</SmallerText>
        </MUIMenuItem>
      ))}
      <MUIMenuItem value="no-discount" key="no-discount">
        {selectedDiscountId === 'no-discount' ? (
          'No discount'
        ) : (
          <>
            <MUIListItemIcon>
              <DeleteOutline color="error" />
            </MUIListItemIcon>
            Remove discount
          </>
        )}
      </MUIMenuItem>
    </RowSelectField>
  );
}
