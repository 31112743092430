import { ResourceAction, ResourceType } from '@samsonvt/shared-types/userLambda';
import { useUser } from 'src/providers/User';
import { can } from 'src/services/Can';

export type Permission = [ResourceAction, ResourceType];

export function usePermissions(...permissions: Permission[]) {
  const user = useUser();
  return permissions.every(([action, domain]) => can(user, action, domain));
}
