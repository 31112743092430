import { v4 as uuidv4 } from 'uuid';

import {
  EditPartDetailsRequestBody,
  NewPartDetailsRequestBody,
  PartDetails,
  PartTypes,
  SupersessionV2,
} from '@samsonvt/shared-types/partService';
import { PartDetailsForm } from './EditModal';

export const createDefaultCustomAttributes = (customAttributesNames?: string[]) => {
  if (customAttributesNames) {
    const defaultAttributes = {} as Record<string, string>;
    customAttributesNames.forEach((key) => {
      defaultAttributes[key] = '';
    });

    return defaultAttributes;
  }

  return undefined;
};

export const partDetailsToPartForm = (
  partDetails: Omit<PartDetails, 'svtPartID' | 'previousPartNumbers'> & {
    svtPartID?: string; // Allow using this function to create extracted details
    previousPartNumbers?: SupersessionV2[];
  }
): PartDetailsForm => ({
  svtPartID: partDetails.svtPartID,
  partNumber: partDetails.part.number,
  displayName: partDetails.part.name,
  price: partDetails.shopping?.price,
  isPor: partDetails.shopping?.priceOnRequest || false, // use shopping POR do not infer from price because when partDetails.shopping?.priceOnRequest === true price is undefined in the DB
  availableToOrder: Boolean(partDetails.shopping?.availability),
  supersessions:
    partDetails.previousPartNumbers?.map((previousPartNumber) =>
      // UI will need temporary IDs to manage supersession list
      ({ ...previousPartNumber, tempID: uuidv4() })
    ) || [],
  customAttributes: partDetails.part.customAttributes,
});

export const formToPartDetails = (
  form: PartDetailsForm,
  originalPartDetails: PartDetails | undefined,
): NewPartDetailsRequestBody | EditPartDetailsRequestBody => {
  const { isPor, availableToOrder, price, displayName, partNumber, svtPartID, customAttributes } = form;
  const priceAsNumber = isPor ? undefined : (price as number | undefined); // Probably this should be typed as number also in PartDetailsForm
  const requestBody: NewPartDetailsRequestBody = {
    part: {
      name: displayName || '',
      number: partNumber,
      subType: 'Part' as PartTypes,
      customAttributes,
    },
    shopping: {
      price: priceAsNumber,
      priceOnRequest: isPor,
      availability: availableToOrder,
    },
    previousPartNumbers: [],
  };
  if (svtPartID) {
    (requestBody as EditPartDetailsRequestBody).svtPartID = svtPartID;
  }

  if(originalPartDetails?.shopping?.eCommerceID) {
    (requestBody).shopping = {
      ...requestBody.shopping!,
      eCommerceID: originalPartDetails.shopping.eCommerceID,
    }
  }

  if (form.supersessions) {
    (requestBody as EditPartDetailsRequestBody).previousPartNumbers = form.supersessions.map(({ part, svtPartID }) => ({
      part: {
        // Assign constants to new supersession and dissasociate tempID
        number: part.number || '',
        notes: part.notes || '',
        name: part.name || 'supersession-stub-name',
        subType: part.subType || ('Supersession' as PartTypes),
      },
      svtPartID,
    }));
  }

  return requestBody;
};

export const isNew = (
  partDetails: NewPartDetailsRequestBody | EditPartDetailsRequestBody
): partDetails is NewPartDetailsRequestBody => !('svtPartID' in partDetails) || !partDetails.svtPartID;
