import { Dexie } from 'dexie';
import { error as logError } from './log';

export const indexDBKeyEtagDelimiter = ':etag:';
type Etag = string;
type Pathname = string;
export type ModelBlobsIndexDBKey = `svt:blob:${Pathname}${typeof indexDBKeyEtagDelimiter}${Etag}`;
export interface Blobish {
  key: string;
  data: ArrayBuffer;
}
class BlobDatabase extends Dexie {
  blobs: Dexie.Table<Blobish, string>;

  constructor() {
    super('svt-blobs');
    this.version(1).stores({
      blobs: 'key',
    });
    this.blobs = this.table('blobs');
  }
}

const db = new BlobDatabase();

export const getFromStorage = async (key: string): Promise<ArrayBuffer | undefined> => {
  const blob = await db.blobs.get(key);
  return blob?.data;
};

export const removeOldModels = async (key: ModelBlobsIndexDBKey): Promise<void> => {
  const [keyPathName] = key.split(indexDBKeyEtagDelimiter);
  try {
    const oldModelsIndexDBKey = (await db.blobs.where('key').startsWith(keyPathName).toArray()).map(({ key }) => key);
    db.blobs.bulkDelete(oldModelsIndexDBKey);
  } catch (error: any) {
    logError(error, 'Failed to remove old models from indexeddDB');
  }
};

export const addToStorage = async (key: string, blob: ArrayBuffer) => {
  const blobish: Blobish = {
    key,
    data: blob,
  };
  try {
    await db.blobs.put(blobish);
  } catch (error: any) {
    logError(error, 'Failed to add blob from indexeddDB');
  }
};
