import { styled } from '@mui/material';

import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';

const headingStyle = `
  margin: 0 0 ${spacing.smallSpacing};
  font-weight: 400;
`;

export const H1 = styled('h1')`
  ${headingStyle}
  font-family: Arial, sans-serif;
  font-size: ${font.xLarge};
`;

export const H3 = styled('h3')`
  ${headingStyle}
  font-size: ${font.large};
  font-family: Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 0;
`;

export const ValidationSubtleHeading = styled('h3')`
  margin: 0;
  padding: 0 ${spacing.mediumSpacing} 0 ${spacing.smallSpacing};
  font-size: ${font.large};
  font-family: Arial, sans-serif;
  font-weight: 700;
`;

export const ParagraphLarge = styled('p')`
  font-size: ${font.xLarge};
`;
