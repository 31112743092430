import { useRef, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import { Vector3Tuple } from 'three';
import { TransformControls } from '@react-three/drei';
import CameraControls from 'camera-controls';
import { invalidate } from '@react-three/fiber';
import { translateCoordinates } from './TranslateCoordinates';

interface IProps {
  cameraControls: CameraControls;
  setPointCoords: Dispatch<SetStateAction<Vector3Tuple>>;
  inputCoordinates: Vector3Tuple;
}

export function EditorTools({ cameraControls, setPointCoords, inputCoordinates }: IProps) {
  const transformControlsRef = useRef<any>();

  const updatedCoordinateValues = useCallback(
    (event: THREE.Event) => {
      const positionArray = event.target.worldPosition.toArray();
      setPointCoords(positionArray);
    },
    [setPointCoords]
  );

  useEffect(() => {
    const transformObject = transformControlsRef.current?.object;

    if (!transformObject || !inputCoordinates) {
      return;
    }

    const newPosition = translateCoordinates(transformObject, inputCoordinates);

    setPointCoords(newPosition);
    invalidate();
  }, [inputCoordinates, setPointCoords]);

  useEffect(() => {
    const controls = transformControlsRef.current || null;

    if (!controls || !controls.addEventListener || !controls.removeEventListener) {
      return undefined;
    }

    const callback = (event: THREE.Event) => {
      cameraControls.enabled = !event.value;
    };

    controls.addEventListener('dragging-changed', callback);
    controls.addEventListener('change', updatedCoordinateValues);

    return () => {
      if (!controls.removeEventListener) {
        return undefined;
      }

      controls.removeEventListener('dragging-changed', callback);
      controls.removeEventListener('change', updatedCoordinateValues);
    };
  }, [transformControlsRef, cameraControls, updatedCoordinateValues]);

  return <TransformControls ref={transformControlsRef} />;
}
