import { faCircleInfo, faListTree } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import {
  EmptyPartsTab,
  EmptyPartsText,
  StyledTabContainer,
  StyledTabContentPanel,
} from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import { PartsTab } from 'src/components/Product/Shared/PartsTab/PartsTab';
import { Tab, Tabs } from 'src/components/Tabs';
import { NamedLink } from 'src/services/partNavigation';

interface PartChildrenProps {
  childrenToDisplay: NamedLink[];
}

/**
 * Displays list of part's buyable children parts (children that have a price, or are a group of parts)
 */
export function PartChildren({ childrenToDisplay }: PartChildrenProps) {
  if (!childrenToDisplay.length) {
    return (
      <EmptyPartsTab>
        <FontAwesomeIcon icon={faCircleInfo} size="2x" />
        <EmptyPartsText>No parts to buy</EmptyPartsText>
      </EmptyPartsTab>
    );
  }

  return (
    <StyledTabContainer data-testid="parts-tab-panel">
      <Tabs value="parts">
        <IconTab label="Parts" value="parts" icon={<FontAwesomeIcon icon={faListTree} />} iconPosition="start" inner />
      </Tabs>
      <StyledTabContentPanel>
        <PartsTab partChildren={childrenToDisplay} />
      </StyledTabContentPanel>
    </StyledTabContainer>
  );
}

const IconTab = styled(Tab)`
  min-height: 48px;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;
