import { styled } from '@mui/material';
import { DesktopProductViewLayout } from 'src/components/Product/Desktop';
import type { TabProps } from 'src/pages/ProductDetailPage';

import { Sidebar } from './Sidebar';

const Columns = styled('div')`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  background-color: ${({ theme }) => theme.palette.canvasBackground.linear};
`;

export function DesktopProductDetails(props: TabProps) {
  return (
    <Columns data-testid="product-detail">
      <Sidebar {...props} />
      <DesktopProductViewLayout {...props} />
    </Columns>
  );
}
