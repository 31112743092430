import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceClouds } from '@fortawesome/pro-thin-svg-icons';
import { FlexContainer } from 'src/components/FlexContainer';
import { spacing } from 'src/constants/spacing';
import { H1 } from 'src/components/Type';
import { StyledMUIButtonLink } from 'src/components/Button';

import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { device } from '../constants/mediaQueries';
import { Footer } from '../components/Footer';

const NotFoundContainer = styled(FlexContainer)`
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 425px;
  max-width: 800px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${spacing.largeSpacing};

  @media ${device.smallMobile} {
    max-width: 100%;
    height: fit-content;
  }
`;

const H1404 = styled(H1)`
  margin-bottom: ${spacing.largeSpacing};
  font-size: 1.5rem;
  margin-top: ${spacing.largeSpacing};
`;

const CloudIcon = styled(FontAwesomeIcon)`
  font-size: 7rem;
`;

const InfoText = styled('p')`
  font-size: 0.9rem;
  max-width: 600px;
  margin-bottom: 2rem;
`;

const NotFoundWrapper = styled('div')`
  height: calc(100vh - (3.75rem * 2));
`;

export function NotFoundPageUnAuthorised() {
  return (
    <>
      <NotFoundWrapper>
        <NotFoundContainer data-testid="not-found-page-container">
          <CloudIcon icon={faFaceClouds} />
          <H1404>We looked everywhere and we couldn&apos;t find your page</H1404>
          <InfoText>
            Maybe check the address again, try navigating to one of the sections on the top, or simply click on the
            below button to go back
          </InfoText>
          <StyledMUIButtonLink
            to="/"
            data-testid="not-found-page-link-unauth"
            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          >
            Go Back
          </StyledMUIButtonLink>
        </NotFoundContainer>
      </NotFoundWrapper>
      <Footer position="absolute" />
    </>
  );
}
