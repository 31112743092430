import { DeleteOutline } from '@mui/icons-material';
import { styled } from '@mui/material';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from '@mui/material/MenuItem';
import MUISelect from '@mui/material/Select';
import { RowSelectField } from './styles';
import { UserRole } from './UserManagement';

type Props = React.ComponentProps<typeof MUISelect> & { options: UserRole[]; value: UserRole | undefined };

export function RoleSelect({ value, options, ...props }: Props) {
  const displayValue = value ? value.id : 'none';
  return (
    <RowSelectField value={displayValue} name="role" {...props}>
      <HiddenOption value="none" disabled>
        <PlaceholderText>Select a role</PlaceholderText>
      </HiddenOption>

      {options.map(({ id, name }) => (
        <MUIMenuItem value={id || ''} key={id}>
          {id === 'noaccess' && (
            <MUIListItemIcon>
              <DeleteOutline color="error" />
            </MUIListItemIcon>
          )}
          {name}
        </MUIMenuItem>
      ))}
    </RowSelectField>
  );
}

const HiddenOption = styled(MUIMenuItem)`
  display: none;
`;

const PlaceholderText = styled('em')`
  opacity: 0.4;
`;
