export default function IconReset() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="white">
      <g id="Icon_OptimiseInvert">
        <path
          id="Path_176"
          data-name="Path 176"
          d="M40.512,2.511V.154a.17.17,0,0,1,.276-.119L45.66,3.595a.149.149,0,0,1,0,.241L40.788,7.395a.171.171,0,0,1-.276-.119V4.924a6.337,6.337,0,1,0,6.395,7.343,1.21,1.21,0,0,1,1.2-1.01h0a1.2,1.2,0,0,1,1.193,1.37A8.791,8.791,0,1,1,40.512,2.511Z"
          transform="translate(-20.815 10)"
        />
      </g>
    </svg>
  );
}
