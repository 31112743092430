import { styled, SelectChangeEvent } from '@mui/material';

import MUIBox from '@mui/material/Box';
import MUITypograhy from '@mui/material/Typography';
import MUIFormControl from '@mui/material/FormControl';
import MUIMenuItem from '@mui/material/MenuItem';

import { StyledSelectField } from 'src/components/Select';
import { SearchField } from 'src/components/SearchField';
import { MagnifyingGlassSearchIcon } from 'src/pages/OrdersPages/styles';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { useStickHeaderWithIntersectionObserver } from 'src/hooks/useStickHeaderWithIntersectionObserver';
import { FilterTypes } from '../../../filters';

const MyOrdersHeader = styled(MUITypograhy)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
MyOrdersHeader.defaultProps = { variant: 'h5' };

const StickyHeader = styled(MUIBox)<{ isHeaderInNormalPosition: boolean }>`
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid
    ${({ theme, isHeaderInNormalPosition }) => (!isHeaderInNormalPosition ? theme.palette.grey[900] : 'transparent')};
  & .is-pinned {
    background-color: red;
  }
`;

const TableControls = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const CheckedText = styled(MUITypograhy)`
  font-weight: bold;
  padding-left: 1rem;
`;
CheckedText.defaultProps = { variant: 'body2' };

const OrderFilterSelect = styled(MUIFormControl)`
  min-width: 120px;
  width: 150px;

  & .MuiSelect-select {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

OrderFilterSelect.defaultProps = {
  variant: 'filled',
  size: 'small',
};

export interface MyOrdersControlsProps {
  timeFilterStatus: string;
  orderIdSearchFilter: string;
  handleTimeFilter: (e: SelectChangeEvent<unknown>) => void;
  handleSearchFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function MobileMyOrdersTableHeader({
  timeFilterStatus,
  orderIdSearchFilter,
  handleTimeFilter,
  handleSearchFilter,
}: MyOrdersControlsProps) {
  const isHeaderInNormalPosition = useStickHeaderWithIntersectionObserver();
  return (
    <StickyHeader id="sticky-header" isHeaderInNormalPosition={isHeaderInNormalPosition}>
      <MUITypograhy>Sort orders by:</MUITypograhy>
      <TableControls>
        <OrderFilterSelect>
          <StyledSelectField value={timeFilterStatus} name="completedActiveFilter" onChange={handleTimeFilter}>
            <MUIMenuItem value={FilterTypes.MOST_RECENT}>{FilterTypes.MOST_RECENT}</MUIMenuItem>
            <MUIMenuItem value={FilterTypes.OLDEST}>{FilterTypes.OLDEST}</MUIMenuItem>
          </StyledSelectField>
        </OrderFilterSelect>
        <SearchField
          InputProps={{ endAdornment: <MagnifyingGlassSearchIcon icon={faMagnifyingGlass} /> }}
          placeholder="Search"
          value={orderIdSearchFilter}
          onChange={handleSearchFilter}
          data-testid="received-orders-search"
          sx={{ maxWidth: '120px', marginLeft: '2rem' }}
        />
      </TableControls>
    </StickyHeader>
  );
}
