import { useMediaQuery } from '@mui/material';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { BackButton } from 'src/components/Button';
import { Footer } from 'src/components/Footer';
import { device } from 'src/constants/mediaQueries';
import { MainTemplate } from 'src/templates/MainTemplate';
import { Background, ContentContainer } from '../styles';
import MyOrders from './MyOrders';
import DesktopMyOrderDetails from './MyOrdersDetails/Desktop/DesktopMyOrderDetails';
import MobileMyOrderDetails from './MyOrdersDetails/Mobile/MobileMyOrderDetails';

export default function MyOrdersPage() {
  const isMobile = useMediaQuery(device.mobileOrTablet);

  const { path } = useRouteMatch();
  const { goBack } = useHistory();
  return (
    <MainTemplate>
      <Background>
        <ContentContainer>
          <BackButton onClick={goBack} />
          <Route exact path={path}>
            <MyOrders />
          </Route>
          <Route path={`${path}/:orderId`}>{isMobile ? <MobileMyOrderDetails /> : <DesktopMyOrderDetails />}</Route>
        </ContentContainer>
        <Footer />
      </Background>
    </MainTemplate>
  );
}
