import { downloadFile } from 'src/utils/downloadFile';
import type { FullUser } from './UserManagement';

export function exportUserList(userList: FullUser[]) {
  const header = ['Name', 'Email', 'Role', 'Discount', 'Invited On', 'Last Active'];
  const users = userList.map((user) => {
    const { name, email, role, created, lastActive, discount, userRole } = user;
    const displayRole = userRole?.name ?? role;
    const displayDiscount = discount ? `${discount.name} - ${discount.discountPercentage}%` : 'N/A';
    return [name, email, displayRole, displayDiscount, created, lastActive];
  });
  const rows = [header, ...users];
  const csvContent = rows.map((row) => row.join(',')).join('\n');
  const csvFile = new Blob([csvContent], { type: 'text/csv' });

  downloadFile(csvFile, 'Users list');
}
