import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import ReceivedOrdersTableDescription from './ReceivedOrdersTable/ReceivedOrdersTableDescription';
import ReceivedOrdersTableLogic from './ReceivedOrdersTable/ReceivedOrderTableLogic';

export default function ReceivedOrders() {
  return (
    <>
      <StandardBreadcrumbs
        current={{ name: 'Received orders' }}
        parents={[{ name: 'My account', url: '/my-account' }]}
        sx={{ margin: '1rem 0' }}
      />
      <ReceivedOrdersTableDescription />
      <ReceivedOrdersTableLogic />
    </>
  );
}
