import { PartAttributes, PartDetails, PartTypes } from '@samsonvt/shared-types/partService';
import { PartPrice } from '@samsonvt/shared-types/partsTable';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import EditPartsActionBar from './ActionBar';
import { CircleXButton } from './CircleXButton';
import ContinueButton from './ContinueButton';
import { EditPartsDetails } from './EditPartsDetails';
import { EditPartsPartNumberAssignmentForm } from './PartNumberAssignmentForm';
import SaveButton from './SaveButton';
import EditPartsSnackBar from './SnackBar';
import { EditPartsTitle } from './Title';
import { ScrollableEditPartDetailsContainer, StyledMuiDialog } from './styles';
import { createDefaultCustomAttributes, partDetailsToPartForm } from './utils';

type EditModalProps = {
  newPartDetails?: PartDetails;
  editedNodes: string[];
  isLoadingPartDetails: boolean;
  isFetchingPartDetails: boolean;
  setSvtPartIDToAssignToANode: Dispatch<SetStateAction<string | undefined>>;
  svtPartIDToAssignToANode: string | undefined;
  handleClose: () => void;
  modalOpen: boolean;
  partNode: PartNode;
  userPressedContinue: boolean;
  setUserPressedContinue: Dispatch<SetStateAction<boolean>>;
  initialPartForm?: PartDetailsForm;
  customAttributesNames?: string[];
} & Omit<React.ComponentProps<typeof SaveButton>, 'partDetailsForm'>;

export type FormsSupersession = {
  svtPartID?: string;
  part: Partial<PartAttributes>;
  tempID: string;
};

export type PartDetailsForm = {
  svtPartID?: string; // Informs whether it's a new part
  partNumber: string;
  availableToOrder: boolean;
  isPor: boolean;
  displayName?: string;
  price?: PartPrice;
  supersessions: FormsSupersession[];
  customAttributes?: Record<string, string | string[]>;
};

const emptyNewPartForm: Omit<PartDetailsForm, 'partNumber'> = {
  displayName: '',
  price: undefined,
  isPor: false,
  availableToOrder: false,
  supersessions: [],
};

export function EditModal({
  originalPartDetails,
  newPartDetails,
  editedNodes,
  setSvtPartIDToAssignToANode,
  svtPartIDToAssignToANode,
  isFetchingPartDetails,
  handleClose,
  modalOpen,
  partNode: { object3DName, source, ...partNode },
  userPressedContinue,
  setUserPressedContinue,
  initialPartForm,
  customAttributesNames,
  ...mutationProps
}: EditModalProps) {
  const [newPartNumber, setNewPartNumber] = useState<string>();
  const [addPrevToSupersessions, setAddPrevToSupersessions] = useState<boolean>();

  const hasAssociatedPartDetails = Boolean(originalPartDetails || newPartDetails);

  const [partDetailsForm, setPartDetailsForm] = useState<PartDetailsForm | undefined>(initialPartForm);

  const showPartDetailsForm = Boolean(partDetailsForm && userPressedContinue);
  const showContinueButton = !showPartDetailsForm && !userPressedContinue;

  // temporary commented out for release
  // const showAddToSupersessionDecision = Boolean(
  //   !userPressedContinue &&
  //     initialPartForm &&
  //     (newPartDetails?.part.number || (newPartNumber && partDetailsForm?.partNumber !== newPartNumber))
  // );

  const handleContinue = () => {
    if (newPartDetails && svtPartIDToAssignToANode) {
      setPartDetailsForm(partDetailsToPartForm(newPartDetails));
    } else if (newPartNumber && !isFetchingPartDetails) {
      if (originalPartDetails) {
        const { svtPartID, ...clone } = originalPartDetails;
        // When creating a new part for a part that has original assignment, clone the data SVT-2383
        // Don't clone the part number, and supersessions
        const newPartDetailsForm = {
          ...emptyNewPartForm,
          ...partDetailsToPartForm(clone),
          partNumber: newPartNumber,
        };
        if (addPrevToSupersessions) {
          const originalPartDetailsCopy = JSON.parse(JSON.stringify(originalPartDetails)) as PartDetails;
          originalPartDetailsCopy.part.current = false;
          originalPartDetailsCopy.part.subType = 'Supersession' as PartTypes;
          delete (originalPartDetailsCopy as any).previousPartNumbers;
          delete (originalPartDetailsCopy as any).modelNodeIDs;
          newPartDetailsForm.supersessions = [
            { ...originalPartDetailsCopy, tempID: uuidv4() },
            ...newPartDetailsForm.supersessions!,
          ];
        }

        setPartDetailsForm(newPartDetailsForm);
      } else {
        setPartDetailsForm({
          ...emptyNewPartForm,
          customAttributes: createDefaultCustomAttributes(customAttributesNames),
          partNumber: newPartNumber,
        });
      }
    }
    setUserPressedContinue(true);
  };

  return (
    <StyledMuiDialog
      fullWidth
      PaperProps={{ style: { overflow: 'visible', maxWidth: 749 } }}
      onClose={handleClose}
      open={modalOpen}
      data-testid="edit-parts-modal"
      scrollEnabled={!!showPartDetailsForm}
    >
      <CircleXButton onClick={handleClose} />
      <EditPartsTitle isEditingDetails={Boolean(partDetailsForm)} editedNodes={editedNodes} />
      <ScrollableEditPartDetailsContainer scrollEnabled={!!showPartDetailsForm}>
        <EditPartsSnackBar
          originalPartDetails={originalPartDetails}
          newPartDetails={newPartDetails}
          partDetailsForm={partDetailsForm}
        />
        <EditPartsPartNumberAssignmentForm
          showPartDetailsForm={showPartDetailsForm}
          partDetailsForm={partDetailsForm}
          partNode={partNode}
          initialPartNumber={partDetailsForm?.partNumber || ''}
          hasAssociatedPartDetails={hasAssociatedPartDetails}
          object3DReference={object3DName || partNode.id}
          addPrevToSupersessions={addPrevToSupersessions}
          showAddToSupersessionDecision={
            false
            // showAddToSupersessionDecision
          }
          setAddPrevToSupersessions={setAddPrevToSupersessions}
          setSvtPartIDToAssignToANode={setSvtPartIDToAssignToANode}
          setUserPressedContinue={setUserPressedContinue}
          setPartDetailsForm={setPartDetailsForm}
          setNewPartNumber={setNewPartNumber}
        />
        {showPartDetailsForm ? (
          <EditPartsDetails
            partForm={partDetailsForm!}
            isFetchingPartDetails={isFetchingPartDetails}
            originalPartDetails={originalPartDetails}
            setPartDetailsForm={setPartDetailsForm}
          />
        ) : null}
      </ScrollableEditPartDetailsContainer>
      <EditPartsActionBar handleClose={handleClose}>
        {showContinueButton ? (
          <ContinueButton
            disabled={isFetchingPartDetails || !(newPartDetails || newPartNumber)}
            handleContinue={handleContinue}
          />
        ) : (
          <SaveButton
            {...mutationProps}
            editedNodes={editedNodes}
            partDetailsForm={partDetailsForm}
            handleClose={handleClose}
            originalPartDetails={originalPartDetails}
          />
        )}
      </EditPartsActionBar>
    </StyledMuiDialog>
  );
}
