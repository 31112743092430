import { styled } from '@mui/material';
import { StyledMUIPrimaryButton } from 'src/components/Button';

export const ContentContainer = styled('div')`
  max-width: 926px;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 600px;
`;

export const BreadCrumbsMargin = styled('div')`
  margin: 1rem 0;
`;

export const InnerContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
`;

export const AccountDetailsFormButton = styled(StyledMUIPrimaryButton)`
  width: fit-content;
  margin-top: 1.5rem;
  padding: 1.25rem 1.5rem;
`;
