import { Color, Mesh, MeshStandardMaterial, Object3D, RGBAFormat, ColorRepresentation } from 'three';
import { StyleDeclaration } from './threeStyles';

type TransitionedMaterialUserData = {
  transitions: StyleDeclaration;
  originalProperties: StyleDeclaration;
  meshIds: number[];
};

export type PreparedMaterial = Omit<MeshStandardMaterial, 'userData'> & {
  userData: TransitionedMaterialUserData;
};

export function meshesWithinObject(object: Object3D): Mesh[] {
  // Return all meshes within an arbitrary Object3D
  const meshes: Mesh[] = [];
  object.traverse((child) => {
    if (isMesh(child)) {
      meshes.push(child);
    }
  });
  return meshes;
}

export function materialWithinMesh(mesh: Mesh) {
  // Assuming just one material per mesh for performance and because we don't have more currently.
  return mesh.material as MeshStandardMaterial;
}

export function cloneObjectWithColor(obj: Object = {}) {
  // When cloning an object which has a THREE.Color as a value we need to re-instantiate it
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (key === 'color') {
        return [key, new Color(value as ColorRepresentation)];
      }
      return [key, value];
    })
  );
}

export function prepareMaterial(
  originalMaterial: MeshStandardMaterial,
  preparedMaterialsStore: PreparedMaterial[],
  style: StyleDeclaration
) {
  // Tries to find an already prepared material for this transition. If fails, clones material and add it to an array.
  const matchingMaterial = preparedMaterialsStore.find(
    ({ name, userData: { transitions }, color, opacity }) =>
      transitions.opacity === style.opacity &&
      transitions.speed === style.speed &&
      transitions.visible === style.visible &&
      transitions.transparent === style.transparent &&
      originalMaterial.name === name &&
      originalMaterial.color.equals(color) &&
      originalMaterial.opacity === opacity // Actual properties need to be compared because the material transitions for a whole group. If we don't do this ongoing fades will blink
  );
  // Return it or proceed with cloning if nothing found
  if (matchingMaterial) {
    return matchingMaterial;
  }
  // GLTF export is using MeshStandardMaterial.
  const clonedMaterial = originalMaterial.clone();

  // Every property that we will mutate needs to be saved for reversal.
  const originalPropertiesForStyle = Object.fromEntries(
    Object.keys(style).map((key) => [key, originalMaterial[key as keyof MeshStandardMaterial]])
  );

  // Manually save transaprent for fading transitions. This is cheaper than having transparency mutator and keeping it in styles.
  if ('opacity' in style) originalPropertiesForStyle.transparent = originalMaterial.transparent;

  const userData: TransitionedMaterialUserData = {
    // Persist old transitions (when it's a clone of a clone)
    transitions: {
      ...cloneObjectWithColor(clonedMaterial.userData.transitions),
      ...style,
    },
    // Merge in original properties if additional property is being now mutated on a previously cloned material
    originalProperties: {
      ...originalPropertiesForStyle,
      ...clonedMaterial.userData.originalProperties,
    },
    // Assign an empty array of meshIds here, so that we can just push without check on meshes reusing the same material. Small optimization
    meshIds: [],
  };
  clonedMaterial.userData = userData;
  // Instead of mutating transparency every frame we just do this once if opacity is mutated. Can be moved to mutators if we ever want to have non-transparent material with opacity < 1. Small optimization
  if (style.opacity !== undefined) {
    clonedMaterial.transparent = true;
  }
  // See https://github.com/mrdoob/three.js/issues/22598
  clonedMaterial.format = RGBAFormat;
  // Saved the cloned material for later so that we won't clone over again
  preparedMaterialsStore.push(clonedMaterial as PreparedMaterial);
  return clonedMaterial;
}

export const updateAssociatedMeshesVisibility = (
  material: PreparedMaterial,
  meshCache: React.MutableRefObject<{
    [key: string]: Mesh;
  }>,
  visible: boolean
) => {
  material.userData.meshIds.forEach((meshId: number) => {
    const mesh = meshCache.current[meshId];
    // When transitioning materials back we may have some old mesh refs on them, when new command for the same mesh was issued in the meantime.
    // So we need to check if is still attached before updating visibility.
    // Imagine that mesh is simoultanously on show and hide list - it should not disappear on finished transitions because something else is transtioning it.
    if (mesh && material === mesh.material) {
      updateMeshVisibility(mesh, visible);
    }
  });
};

export const updateMeshVisibility = (mesh: Mesh, visible: boolean) => {
  if (visible) {
    // Manually set high render order  for tranisitioned meshes. This helps with semi-transparent rendering glitches
    mesh.renderOrder = 10;
    // Layers make it independent from raycaster implementation.
    mesh.layers.enable(
      // default camera layer.
      0
    );
  } else {
    mesh.renderOrder = 1;
    mesh.layers.disable(0);
  }
};

export const isMesh = (o: Object3D): o is Mesh => 'isMesh' in o;
