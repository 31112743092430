import { styled } from '@mui/material';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';
import { FormsSupersession } from '../../EditModal';
import { Button } from './Buttons';

type Props = {
  supersession: FormsSupersession;
  closeForm: () => void;
  setSupersession: (s: Partial<FormsSupersession>) => void;
};

export default function SupersessionForm({ supersession, setSupersession, closeForm }: Props) {
  const {
    part: { notes, number },
  } = supersession;

  const editSupersessionFieldFactory = (
    supersessionUpdater: (supersession: FormsSupersession) => FormsSupersession
  ) => {
    const superSessionClone: FormsSupersession = JSON.parse(JSON.stringify(supersession));
    const newSupersession = supersessionUpdater(superSessionClone);
    setSupersession(newSupersession);
  };

  const editPartNumber = (e: React.ChangeEvent<HTMLInputElement>) =>
    editSupersessionFieldFactory((supersession) => {
      supersession.part.number = e.target.value;
      return supersession;
    });

  const editNotes = (e: React.ChangeEvent<HTMLInputElement>) =>
    editSupersessionFieldFactory((supersession) => {
      supersession.part.notes = e.target.value;
      return supersession;
    });

  return (
    <Column>
      <Row>
        <Label>
          <LabelText>Part Number</LabelText>
          <TextField
            value={number}
            onChange={editPartNumber}
            disabled={'svtPartID' in supersession}
            placeholder="Add new part number"
            data-testid="edit-supersession-part-number-input"
          />
        </Label>
      </Row>
      <Row>
        <Label>
          <LabelText>Notes</LabelText>
          <TextField
            value={notes ?? ''}
            onChange={editNotes}
            placeholder="Add any notes for this part number"
            data-testid="edit-supersession-notes-input"
          />
        </Label>
      </Row>
      <Row>
        <CancelButton size="small" onClick={closeForm}>
          Close
        </CancelButton>
      </Row>
    </Column>
  );
}

const CancelButton = styled(Button)`
  margin-left: auto;
`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 14px;
  gap: 0.5rem;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

const TextField = styled(WhiteTextFieldWithoutLabel)`
  flex-grow: 1;
  & input {
    font-size: 0.75rem;
    ::placeholder {
      font-style: italic;
    }
  }
`;

TextField.defaultProps = { ...TextField.defaultProps, size: 'small' };

const Label = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.25 : 1)};
  width: 100%;
`;

const LabelText = styled('div')`
  width: 10rem;
`;
