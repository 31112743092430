import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import { Toast } from 'src/components/Toast';
import type { Order } from '@samsonvt/shared-types/orderLambda';
import { TableHeader } from 'src/components/Table/MUITableStyles';
import { ReceivedOrdersControls, ReceivedOrdersControlsProps } from './ReceivedOrdersTableHeader';
import { EmptyTable, TableStyles } from '../../styles';
import { TableFooter } from '../../TableFooter';
import ReceivedOrdersTableRow from './ReceivedOrdersTableRow';

interface ReceivedOrderTableProps extends ReceivedOrdersControlsProps {
  filteredOrders: Order[];
  areTheOrdersAllLoaded: boolean;
  isLoadingOrders: boolean;
  isErrorLoadingOrders: boolean;
  isTheTableEmpty: boolean;
}

export default function ReceivedOrdersTable(props: ReceivedOrderTableProps) {
  const {
    filteredOrders,
    areTheOrdersAllLoaded,
    isErrorLoadingOrders,
    isLoadingOrders,
    isTheTableEmpty,
    completedFilterStatus,
    timeFilterStatus,
    orderIdSearchFilter,
    handleCompletedFilter,
    handleTimeFilter,
    handleSearchFilter,
  } = props;

  return (
    <>
      <ReceivedOrdersControls
        completedFilterStatus={completedFilterStatus}
        timeFilterStatus={timeFilterStatus}
        orderIdSearchFilter={orderIdSearchFilter}
        handleCompletedFilter={handleCompletedFilter}
        handleTimeFilter={handleTimeFilter}
        handleSearchFilter={handleSearchFilter}
      />
      <TableStyles>
        <MUITable>
          <MUITableHead>
            <MUITableRow>
              <TableHeader>Confirmation number</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Company name</TableHeader>
              <TableHeader>Total</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader sx={{ width: '20%' }}> </TableHeader>
            </MUITableRow>
          </MUITableHead>
          <MUITableBody>
            {filteredOrders.map((order: Order) => (
              <ReceivedOrdersTableRow order={order} key={order.orderId} />
            ))}
          </MUITableBody>
        </MUITable>
        <TableFooter
          areTheOrdersAllLoaded={areTheOrdersAllLoaded}
          isLoading={isLoadingOrders}
          filteredOrdersLength={filteredOrders.length}
        />
        {isTheTableEmpty && (
          <EmptyTable data-testid="empty-table">
            {isErrorLoadingOrders ? 'There has been an error while loading your orders' : 'No orders found'}
          </EmptyTable>
        )}
      </TableStyles>
      <Toast
        dependency={isErrorLoadingOrders}
        severity="error"
        title="Error"
        message="There has been an error while loading your orders"
      />
    </>
  );
}
