import { styled } from '@mui/material';

interface EmptyProductListProps {
  areThereAnyFetchedProducts: boolean;
  searchFieldState?: string;
}

export default function EmptyProductList({ areThereAnyFetchedProducts, searchFieldState }: EmptyProductListProps) {
  return areThereAnyFetchedProducts ? ( // if true it means that products are fetched but did not pass the filtering
    <EmptyProductListMessage data-testid="no-filtered-products">
      {`Sorry, it doesn't look like we were able to find what you searched for:`} <Bold>{searchFieldState}</Bold>
    </EmptyProductListMessage>
  ) : (
    <EmptyProductListMessage data-testid="no-products">
      There are currently no products available to view
    </EmptyProductListMessage>
  );
}

const EmptyProductListMessage = styled('div')`
  text-align: center;
  margin-top: 3rem;
`;

const Bold = styled('span')`
  font-weight: bold;
  font-size: 1.25rem;
`;
