import { useThree, useFrame } from '@react-three/fiber';
import { useEffect, useState, useMemo } from 'react';
import { useProduct } from 'src/providers/Product';
import { info } from 'src/services/log';

interface Memory {
  used: number;
  available: number;
}

interface FramerateDistribution {
  [key: number]: number;
}

interface FrameTimes {
  longestFrameTime: number;
  shortestFrameTime: number;
  averageFrameTime: number;
  modelLoadFrameTime?: number;
}

interface PerformanceStats {
  frameTimes: FrameTimes;
  framerateDistribution: FramerateDistribution;
  memory?: Memory;
  averageFPS?: number; // New property
}

interface MetricsData {
  gpu?: string;
  performanceStats: PerformanceStats;
  accountId?: string
  productId?: string;
  size?: { width: number; height: number };
  sizeInBytes?: number;
  datetime?: string;
  os?: string;
  inputMethod?: string;
  memory?: Memory;
}

const megabyteDivider = 1048576;
declare global {
  interface Performance {
    memory: any;
  }
}

let measurmentStart: number | false = false;
let inputMethod: 'touch' | 'mouse' = 'mouse'
const datetimeLondon = new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' });




const handleTouch = () => {
  inputMethod = 'touch';
  window.removeEventListener('touchstart', handleTouch);
};

window.addEventListener('touchstart', handleTouch);

// Frame time calculation
let frameStartTime = 0;
let currentStats: PerformanceStats = {
  frameTimes: {
    longestFrameTime: 0,
    shortestFrameTime: 0,
    averageFrameTime: 0,
  },
  averageFPS: 0,
  framerateDistribution: {
    0: 0,
    10: 0,
    20: 0,
    30: 0,
    40: 0,
    50: 0,
    60: 0,
  },
  memory: {
    used: window.performance.memory ? window.performance.memory.usedJSHeapSize / megabyteDivider : 0,
    available: window.performance.memory ? window.performance.memory.jsHeapSizeLimit / megabyteDivider : 0,
  },
};

let frameTimesArray: number[] = [];
let frameEndTime: number = 0;
let frameTime: number = 0;
let totalFrames: number = 0;
let totalFrameTime: number = 0;

const frameTimeCalculation = () => {
  if (frameStartTime === 0) {
    frameStartTime = performance.now();
  } else {
    frameEndTime = performance.now();
    frameTime = frameEndTime - frameStartTime;

    // Save the frameTime
    frameTimesArray.push(frameTime);

    frameStartTime = performance.now();
  }
};



export default function Metrics() {
  const { size, gl } = useThree();
  const {
    currentProduct: { accountId, productId },
  } = useProduct();
  const [isCalculated, setIsCalculated] = useState(false);

  // Get opengl context and find gpu name from it
  const gpu = useMemo(() => {
    const glContext = gl.getContext();
    const debugRenderInfo = glContext.getExtension('WEBGL_debug_renderer_info');
    if (debugRenderInfo) {
      return glContext.getParameter(debugRenderInfo?.UNMASKED_RENDERER_WEBGL) as string;
    }
  }, [gl]);

  useEffect(() => {
    measurmentStart = performance.now();
    currentStats = {
      frameTimes: {
        longestFrameTime: 0,
        shortestFrameTime: Infinity,
        averageFrameTime: 0,
      },
      averageFPS: 0,
      framerateDistribution: {},
      memory: {
        used: window.performance.memory ? window.performance.memory.usedJSHeapSize / megabyteDivider : 0,
        available: window.performance.memory ? window.performance.memory.jsHeapSizeLimit / megabyteDivider : 0,
      },
    };
  }, []);

  useFrame(() => {
    if (measurmentStart && !isCalculated) {
      frameTimeCalculation();

      if (performance.now() - measurmentStart > 10000) {
        frameTimesArray.forEach((frameTime) => {
          totalFrameTime += frameTime;
          totalFrames += 1;

          const fpsRange = Math.min(Math.floor((1000 / frameTime) / 10) * 10, 60);

          currentStats.framerateDistribution[fpsRange] = (currentStats.framerateDistribution[fpsRange] || 0) + 1;

          currentStats.frameTimes.shortestFrameTime = Math.min(currentStats.frameTimes.shortestFrameTime, frameTime);
          currentStats.frameTimes.longestFrameTime = Math.max(currentStats.frameTimes.longestFrameTime, frameTime);
        });

        currentStats.averageFPS = totalFrames / (totalFrameTime / 1000);
        currentStats.frameTimes.averageFrameTime = totalFrameTime / totalFrames;

        const metricsData: MetricsData = {
          accountId,
          productId,
          performanceStats: currentStats,
          size: { width: size.width, height: size.height },
          gpu,
          datetime: datetimeLondon,
          os: navigator.platform,
          inputMethod,
          memory: {
            used: window.performance.memory ? window.performance.memory.usedJSHeapSize / megabyteDivider : 0,
            available: window.performance.memory ? window.performance.memory.jsHeapSizeLimit / megabyteDivider : 0,
          },
        };

        metricsData.performanceStats.frameTimes.modelLoadFrameTime = currentStats.frameTimes.modelLoadFrameTime;

        info('Performance Stats', JSON.stringify(metricsData, null, 2));

        setIsCalculated(true);

        // Reset data for next calculation
        frameTimesArray = [];
        totalFrames = 0;
        totalFrameTime = 0;
      }
    }
  });

  return null;
}

