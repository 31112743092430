import { SaveButton } from './styles';

interface Props {
  disabled: boolean;
  handleContinue: () => void;
}

export default function ContinueButton({ handleContinue, disabled }: Props) {
  return (
    <SaveButton onClick={handleContinue} disabled={disabled} data-testid="assignment-continue-button">
      Continue
    </SaveButton>
  );
}
