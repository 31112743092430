import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { EmptyStyledButton, StyledMUIButton } from 'src/components/Button';
import { LoadingSpinner } from '../Loading';
import { spacing } from './styles';

interface ActionBarProps {
  cart: ShoppingCartItem[];
  clearCart: () => void;
  isLoading: boolean;
  handleOrder: () => void;
}

export function ActionBar({ cart, clearCart, isLoading, handleOrder }: ActionBarProps) {
  return (
    <Wrapper>
      <BlackActionContainer>
        <ClearCartButton
          data-testid="clear-cart-button"
          startIcon={<FontAwesomeIcon icon={faTrash} size="xs" />}
          disabled={!cart.length}
          onClick={clearCart}
        >
          Empty shopping cart
        </ClearCartButton>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <OrderButton data-testid="order-cart-button" disabled={!cart.length} onClick={handleOrder}>
            Continue
          </OrderButton>
        )}
      </BlackActionContainer>
    </Wrapper>
  );
}

const OrderButton = styled(StyledMUIButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 2.5rem;
  width: 12rem;
  text-align: center;
  margin: 1rem;
`;

const ClearCartButton = styled(EmptyStyledButton)`
  height: 2.5rem;
  width: 12rem;
  text-align: center;
  margin: 1rem;
`;

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 71px;
  display: flex;
  align-items: center;
  display: flex;
  gap: ${spacing}px;
`;

const BlackActionContainer = styled('div')`
  flex-grow: 1;
  height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
