import { alpha, styled } from '@mui/material';
import type { Supersession as SupersessionType } from '@samsonvt/shared-types/partsTable';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { dateLabel } from 'src/services/formatDate';
import { useQueryStringValue } from 'src/services/partUrl';
import { PartsCatalogueAccordion, StyledListText } from './PartsCatalogueAccordion';
import { AccordionItemContainer } from './styles';

interface PartHistoryProps {
  currentPart: PartNode;
}

/**
 * Previous part numbers
 * Accordion for part history - also called supersessions
 */
export function PartHistory({ currentPart }: PartHistoryProps) {
  const supersessions = currentPart.supersessions || [];
  const supersessionParameterInQueryString = useQueryStringValue('supersession');

  if (!supersessions.length) {
    return null;
  }

  const getSearchResultHighlight = (supersession: SupersessionType) =>
    supersessionParameterInQueryString === supersession.partNumber;

  return (
    <PartsCatalogueAccordion
      title="Previous part numbers"
      open={Boolean(supersessionParameterInQueryString)}
      key={supersessionParameterInQueryString}
    >
      {supersessions.map((supersession) => (
        <Supersession
          key={supersession.partNumber}
          supersession={supersession}
          searchResultHighlight={getSearchResultHighlight(supersession)}
        />
      ))}
    </PartsCatalogueAccordion>
  );
}

interface SupersessionProps {
  supersession: SupersessionType;
  searchResultHighlight: boolean;
}
function Supersession({ supersession, searchResultHighlight }: SupersessionProps) {
  const { partNumber, notes, dateFrom, dateTo } = supersession;

  const datesLabeText = dateLabel(dateFrom, dateTo);
  const dates = datesLabeText ? `Dates: ${datesLabeText}` : '';

  return (
    <StyledListText onClick={doNothing} key={supersession.partNumber}>
      <AccordionItemContainer highlight={searchResultHighlight}>
        <PartNumber>{partNumber}</PartNumber>
        <Notes>{notes}</Notes>
        <ReverseSpacer>
          <Dates>{dates}</Dates>
        </ReverseSpacer>
      </AccordionItemContainer>
    </StyledListText>
  );
}

const doNothing = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  e.stopPropagation();
};

const ReverseSpacer = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const Notes = styled('div')`
  font-size: 0.75rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PartNumber = styled('div')`
  font-weight: bold;
`;

const Dates = styled('div')`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.7)};
`;
