import { ModifyShoppingCartContentBody, ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { endpoints, putUrl } from 'src/services/api';
import { CartEntry } from '.';
import { shoppingCartQueryKey } from './useShoppingCartQuery';

const putShoppingCart = async (cart: ShoppingCartItem[]): Promise<{ data: ShoppingCartItem[] }> =>
  putUrl<ModifyShoppingCartContentBody>(endpoints.shoppingCart, { cartContent: cart });

export default () => {
  const queryClient = useQueryClient();
  return useMutation(putShoppingCart, {
    onSuccess: ({ data }) => queryClient.setQueryData(shoppingCartQueryKey, data),
  });
};

export const updateQuantity = (cart: ShoppingCartItem[], quantity: number, partNumber: string) =>
  cart.map((i) => (i.partNumber === partNumber ? { ...i, quantity } : i));

export const without = (array: CartEntry[], partNumber: string) => array.filter((i) => i.partNumber !== partNumber);

interface IncreaseQuantityParams {
  cart: ShoppingCartItem[];
  partNumber: string;
  quantity: number;
}
const increaseQuantity = ({ cart, partNumber, quantity }: IncreaseQuantityParams) =>
  cart.map((i) => (i.partNumber === partNumber ? { ...i, quantity: i.quantity + quantity } : i));

export const addOne = (cart: ShoppingCartItem[], item: ShoppingCartItem) => {
  const { partNumber } = item;
  const existingItem = cart.find((i) => i.partNumber === partNumber);
  if (existingItem) {
    return increaseQuantity({ cart, partNumber, quantity: 1 });
  }
  return [...cart, item];
};

interface AddQuantityParams {
  cart: ShoppingCartItem[];
  quantityToAdd: number;
  item: Omit<ShoppingCartItem, 'quantity'>;
}
export const addQuantity = ({ cart, quantityToAdd, item }: AddQuantityParams) => {
  const { partNumber } = item;
  const existingItem = cart.find((i) => i.partNumber === partNumber);

  if (existingItem) {
    return increaseQuantity({ cart, partNumber, quantity: quantityToAdd });
  }
  return [...cart, { ...item, quantity: quantityToAdd }];
};
