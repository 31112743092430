import { MeshStandardMaterial, Color, Mesh } from 'three';

export type PropertyValue = number | Color | boolean;
export type MutableProperty = 'opacity' | 'color';
export type StyleDeclaration = Partial<
  Pick<MeshStandardMaterial, MutableProperty | 'transparent'> &
    Pick<Mesh, 'visible'> & {
      speed?: number;
    }
>;

export const styles: { [key: string]: StyleDeclaration } = {
  hiddenBecauseNotShown: {
    opacity: 0,
    visible: false,
    speed: 0.1,
    transparent: true,
  },
  shownBecauseOverridingHiddenTag: {
    opacity: 1,
    visible: true,
    speed: 0.1,
    transparent: false,
  },
  shownDirectlyByName: {
    opacity: 1,
    visible: true,
    speed: 0.1,
    transparent: false,
  },
  hide: {
    opacity: 0,
    visible: false,
    speed: 0.5,
    transparent: true,
  },
  hiddenVariantNames: {
    opacity: 0,
    speed: 1,
    visible: false,
    transparent: true,
  },
  hiddenTag: {
    opacity: 0,
    visible: false,
    speed: 1,
    transparent: true,
  },
};
