import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { UserContext } from 'src/providers/User';
import { RepairListContext } from 'src/providers/repairs/RepairList';
import { EmptyTab } from 'src/components/EmptyTab';
import { useProduct } from 'src/providers/Product';
import { useQueryString } from 'src/hooks/useQueryString';

import { LoadingSpinner } from 'src/components/Loading';
import { TagList } from './TagList';

import BasicListHeader from './BasicListHeader';
import NestedListHeader from './NestedListHeader';
import { RepairListItems } from './RepairListItems';
import { StyledLoadingSpinner, StyledRepairList } from './styles';

export function SidebarRepairList() {
  const { repairs, loading } = useContext(RepairListContext);
  const currentlySelectedRepairTagId = useQueryString('tag');
  const { tags } = useContext(UserContext);
  const { url } = useRouteMatch();
  const { name: productName } = useProduct();

  const tagListActive = tags && !currentlySelectedRepairTagId;
  const tagName = tags?.find((tag) => tag.id === currentlySelectedRepairTagId)?.name;

  if (loading)
    return (
      <StyledLoadingSpinner>
        <LoadingSpinner />
      </StyledLoadingSpinner>
    );

  if (!repairs || repairs.length === 0) {
    return <EmptyTab />;
  }

  return (
    <>
      {currentlySelectedRepairTagId ? (
        <NestedListHeader productName={productName} tagName={tagName} />
      ) : (
        <BasicListHeader productName={productName} tagListActive={tagListActive} />
      )}
      <StyledRepairList data-testid="repair-list-screen">
        {tagListActive ? (
          <TagList tags={tags} />
        ) : (
          <RepairListItems repairs={repairs} url={url} selectedTag={currentlySelectedRepairTagId} />
        )}
      </StyledRepairList>
    </>
  );
}
