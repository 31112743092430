import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Toast, useTrigger } from 'src/components/Toast';
import { device } from 'src/constants/mediaQueries';
import { useUser } from 'src/providers/User';
import { MainTemplate } from 'src/templates/MainTemplate';
import { getNames } from 'country-list';
import { useHistory } from 'react-router-dom';
import { useMutateAccountDetails, MutateAccountDetailsInput, getAccountDetails } from 'src/hooks/useAccountDetails';
import DesktopAccountDetails from './Desktop/DesktopAccountDetails';
import MobileAccountDetails from './Mobile/MobileAccountDetails';

export interface ChangePasswordFormProps {
  oldPassword: string;
  setOldPassword: React.Dispatch<React.SetStateAction<string>>;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  verifyNewPassword: string;
  setVerifyNewPassword: React.Dispatch<React.SetStateAction<string>>;
  submit: (e: any) => Promise<void>;
  checkPasswordsMatch: () => boolean | undefined;
  error: boolean;
}

export interface MyProfileProps {
  userDetailsFormState: MutateAccountDetailsInput;
  handleMyProfileFormChange: (e: any) => void;
  submitMyProfileForm: (e: any) => void;
  userEmail: string;
  isApplyUserLoading: boolean;
  countryList: string[];
}
interface BackButtonFunctionality {
  onClickBackButton: () => void;
}

export type AccountDetailsProps = ChangePasswordFormProps & MyProfileProps & BackButtonFunctionality;

export default function AccountDetailsPage() {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  const { changePassword, user } = useUser();
  const { name, telephone, email, companyName, buildingName, streetName, city, country, postalCode } =
    getAccountDetails(user);

  const {
    applyUserDetails,
    isApplyUserDetailsError,
    isApplyUserLoading,
    isApplyUserDetailsSuccess,
    applyUserDetailsError,
  } = useMutateAccountDetails();

  const countryList = getNames();
  const { goBack } = useHistory();

  const onClickBackButton = () => {
    goBack();
  };

  const [userDetailsFormState, setUserDetailsFormState] = useState<MutateAccountDetailsInput>({
    name,
    companyName,
    telephone,
    buildingName,
    streetName,
    city,
    postalCode,
    country,
  });

  const handleUserProfileFormChange = (e: any) => {
    const { name, value } = e.target;
    setUserDetailsFormState((formState) => ({ ...formState, [name]: value }));
  };

  const submitMyProfileForm = (e: any) => {
    e.preventDefault(); // prevent form reloading page

    if (!userDetailsFormState.telephone) {
      return; // Telephone now does not prevent form submition because it can be empty in the form state, but filled in component.
      // This is the price we pay for delegating validation to component.
    }
    applyUserDetails(userDetailsFormState);
  };

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyNewPassword, setVerifyNewPassword] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setErrorToastMessage] = useState<string | undefined>();

  const submit = async (e: any) => {
    e.preventDefault(); // prevent form reloading page
    try {
      await changePassword(oldPassword, newPassword);
      setPasswordChanged(true);
      setErrorToastMessage('');
      setOldPassword('');
      setNewPassword('');
      setVerifyNewPassword('');
    } catch (e: any) {
      if (
        e.code === 'NotAuthorizedException' ||
        (e.code === 'InvalidParameterException' && e.message.includes('previousPassword'))
      ) {
        setErrorToastMessage('The previous password provided is incorrect.');
      } else if (e.code === 'InvalidParameterException' && e.message.includes('proposedPassword')) {
        setErrorToastMessage('The new password provided does not meet requirements.');
      } else if (e.code === 'LimitExceededException') {
        setErrorToastMessage(
          'You have tried to change your password too many times, please try again after some time.'
        );
      }
      setPasswordChanged(false);
      setErrorToast();
    }
  };

  const checkPasswordsMatch = () => {
    if (verifyNewPassword && newPassword) {
      return verifyNewPassword === newPassword;
    }
    return undefined;
  };

  return (
    <>
      <MainTemplate>
        {isMobile ? (
          <MobileAccountDetails
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            verifyNewPassword={verifyNewPassword}
            setVerifyNewPassword={setVerifyNewPassword}
            submit={submit}
            checkPasswordsMatch={checkPasswordsMatch}
            error={!passwordChanged && !!errorToast}
            userDetailsFormState={userDetailsFormState}
            handleMyProfileFormChange={handleUserProfileFormChange}
            submitMyProfileForm={submitMyProfileForm}
            userEmail={email}
            isApplyUserLoading={isApplyUserLoading}
            countryList={countryList}
            onClickBackButton={onClickBackButton}
          />
        ) : (
          <DesktopAccountDetails
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            verifyNewPassword={verifyNewPassword}
            setVerifyNewPassword={setVerifyNewPassword}
            submit={submit}
            checkPasswordsMatch={checkPasswordsMatch}
            error={!passwordChanged && !!errorToast}
            userDetailsFormState={userDetailsFormState}
            handleMyProfileFormChange={handleUserProfileFormChange}
            submitMyProfileForm={submitMyProfileForm}
            userEmail={email}
            isApplyUserLoading={isApplyUserLoading}
            countryList={countryList}
            onClickBackButton={onClickBackButton}
          />
        )}
      </MainTemplate>
      <Toast
        dependency={isApplyUserDetailsSuccess}
        severity="success"
        title="User details"
        message="User details successfully changed"
      />
      <Toast
        dependency={isApplyUserDetailsError}
        severity="error"
        title="User details"
        message={
          (applyUserDetailsError as Error)?.message.includes('Invalid phone number format.')
            ? 'Invalid phone number format. Please add a country code and remove any non numeric values'
            : (applyUserDetailsError as Error)?.message || 'There was a problem updating you user details'
        }
      />
      <Toast
        dependency={passwordChanged}
        severity="success"
        title="Password change"
        message="Password successfully changed"
      />
      <Toast
        dependency={errorToast}
        severity="error"
        title="Password change"
        message={errorToastMessage || 'There was a problem changing your password'}
      />
    </>
  );
}
