import { styled } from '@mui/material';

import { border, radius } from 'src/constants/borderSizes';
import { font } from 'src/constants/fontSizes';
import { FileIcon, Icon } from 'src/components/Icon';
import { spacing } from 'src/constants/spacing';
import { IUploadState } from 'src/components/Uploader';
import { UploadingStatus } from 'src/components/Uploader/UploadingStatus';

import { FileToUploadRemove } from './FileToUploadRemove';
import { FileToUploadName } from './FileToUploadName';

interface IProps {
  name: string;
  productName: string;
  productId: string;
  removeFile?: (index: number) => void;
  index: number;
  percentUploaded: number | undefined;
  uploadState: IUploadState | undefined;
}

const uploadFileWidth = '12.5rem';

const FileToUploadWrapper = styled('li')`
  border: ${border.xSmall} solid ${({ theme }) => theme.palette.common.black};
  border-radius: ${radius.medium};
  padding: ${spacing.mediumSpacing};
  position: relative;
  width: ${uploadFileWidth};
  list-style: none;
  margin: ${spacing.smallSpacing};
  float: left;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: ${font.medium};
`;

const StatusIconTick = styled('div')`
  position: absolute;
  top: ${spacing.smallSpacing};
  right: ${spacing.smallSpacing};
  width: ${spacing.largeSpacing};
  height: ${spacing.largeSpacing};
  svg {
    height: 100%;
    width: 100%;
  }
`;

const StatusIconError = styled(StatusIconTick)`
  path {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

const FileIconWrapper = styled('div')`
  margin-bottom: ${spacing.xSmallSpacing};
  width: ${spacing.xLargeSpacing};
  height: ${spacing.xLargeSpacing};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const UploadError = styled('div')`
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: ${spacing.xSmallSpacing};
`;

export function FileToUpload({
  removeFile,
  index,
  percentUploaded,
  uploadState,
  name = '',
  productName = '',
  productId = '',
}: IProps) {
  const nameSplit = name.split('.');
  const fileExtension = nameSplit.pop()?.toUpperCase() || '';

  return (
    <FileToUploadWrapper data-testid="file-to-upload">
      <>
        {uploadState === IUploadState.DONE ? (
          <StatusIconTick>
            <Icon name="tick" />
          </StatusIconTick>
        ) : null}
        {uploadState === IUploadState.ERROR ? (
          <StatusIconError>
            <Icon name="error" />
          </StatusIconError>
        ) : null}

        <FileIconWrapper>
          <FileIcon name={fileExtension} />
        </FileIconWrapper>
        {uploadState === IUploadState.UPLOADING ? (
          <UploadingStatus percentUploaded={percentUploaded}>Uploading</UploadingStatus>
        ) : null}
        <FileToUploadName>{name}</FileToUploadName>
        <FileToUploadName>{productName}</FileToUploadName>
        <FileToUploadName>{productId}</FileToUploadName>
        {uploadState === IUploadState.ERROR ? (
          <UploadError>
            File <strong>not</strong> uploaded
          </UploadError>
        ) : null}
        {removeFile ? <FileToUploadRemove removeFile={removeFile} index={index} /> : null}
      </>
    </FileToUploadWrapper>
  );
}
