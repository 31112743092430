import { styled } from '@mui/material';

import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';

export const UploadingLabel = styled('div')`
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: ${font.xSmall};
  line-height: ${spacing.mediumSpacing};
  margin-bottom: ${spacing.xSmallSpacing};
  display: block;
  text-align: center;
`;
