import axios, { AxiosError, CancelToken } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { error as logError } from 'src/services/log';

interface UsePostParams<T, S> {
  handler: (requestParams: S, cancelToken?: CancelToken) => Promise<any>;
  onSuccess?: (res: T, setData?: Dispatch<SetStateAction<T | undefined>>, body?: S) => void;
  onError?: (err: Error, setData?: Dispatch<SetStateAction<boolean>>, body?: S) => void;
  onFinally?: () => void;
  errorMessage?: string;
}
export function usePost<T, S = any>({ handler, onSuccess, onError, onFinally, errorMessage }: UsePostParams<T, S>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<T>();

  const source = axios.CancelToken.source();

  const triggerPostRequest = (requestParams: S) => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    handler(requestParams, source.token)
      .then((res) => {
        setError(false);
        setSuccess(true);
        setData(res?.data);
        if (onSuccess) onSuccess(res?.data, setData, requestParams);
      })
      .catch((err: AxiosError) => {
        setError(true);
        setSuccess(false);
        logError(err as Error, errorMessage || 'Failed to post request to API');
        if (onError) onError(err, setError, requestParams);
      })
      .finally(() => {
        setLoading(false);
        if (onFinally) onFinally();
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => source.cancel('Cancelling API request'), []);

  return {
    data,
    loading,
    error,
    success,
    triggerPostRequest,
    setError,
    setLoading,
    setData,
    setSuccess,
  };
}
