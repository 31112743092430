import { Box3, Mesh, Object3D, Vector3 } from 'three';
import { Px } from '../ShoppingCart/styles';

type DimensionName = 'x' | 'y' | 'z';

export default function scaleModel(
  model: Object3D,
  width: Px,
  height: Px,
  widthResizeConstant = 20,
  heightResizeConstant = 20
) {
  const boundingBox = new Box3(
    new Vector3(0, 0, 0),
    new Vector3(width / widthResizeConstant, height / heightResizeConstant, 4)
  );

  if ('isMesh' in model) {
    const mesh = model as Mesh;
    mesh.geometry.center();
  } else {
    // It's not a single mesh and it isn't centered. We rely on it being centered properly in gltf. Example scenario - multiple materials meshes.
  }

  model.position.setScalar(0);
  model.scale.setScalar(1);

  const boundingBoxSize = boundingBox.getSize(new Vector3());
  const objectSize = new Box3().setFromObject(model).getSize(new Vector3());

  const dimensionsByLength = Object.entries(objectSize).sort(([_, a], [__, b]) => b - a);
  const longestDimensionName = dimensionsByLength[0][0] as DimensionName;
  const secondLongestDimensionName = dimensionsByLength[1][0] as DimensionName;

  const scale = Math.min(
    boundingBoxSize.y / objectSize[secondLongestDimensionName],
    boundingBoxSize.x / objectSize[longestDimensionName]
  );

  model.scale.setScalar(scale);

  if (longestDimensionName === 'y') {
    model.rotation.set(Math.PI / 2, 0, Math.PI / 2);
  } else if (longestDimensionName === 'z') {
    model.rotation.set(0, Math.PI / 2, 0);
  }

  return model;
}
