import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/pro-light-svg-icons';

export default function EmptyList() {
  return (
    <EmptyListContainer>
      <FontAwesomeIcon icon={faCartArrowDown} size="3x" />
      Your shopping cart is empty
    </EmptyListContainer>
  );
}

const EmptyListContainer = styled('div')`
  height: 100%;
  width: 60%;
  margin: auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
