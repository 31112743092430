import { styled } from '@mui/material';
import MUIMenuItem from '@mui/material/MenuItem';
import { AccountHeader } from '@samsonvt/shared-types/userLambda';
import { useContext } from 'react';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import Initials from './Initials';
import SelectField from './SelectField';

const redirectTenant = (url: string) => {
  if (url) {
    window.location.href = url;
  }
};

export function TenantSwitcher() {
  const { userAccounts } = useUser();
  const { accountId: currentTenantId } = useContext(TenantContext) || {}; // Always called from within TenantProvider. Default provided for convenience in tests.
  const currentAccount = userAccounts.find((item) => item.id === currentTenantId);
  // Only show accounts with a URL ordered by name
  const accountsWithURL = userAccounts.filter((item) => item.url).sort((a, b) => a.name.localeCompare(b.name));

  return accountsWithURL.length > 1 ? (
    <SelectField
      placeholder="Switch account..."
      value={currentAccount?.url || ''}
      handleChange={redirectTenant}
      data-testid="tenant-picker"
    >
      {accountsWithURL.map(({ url, name, brandColours }: AccountHeader) => (
        <MUIMenuItem key={name} value={url}>
          <TextAligner>
            <Initials swatchColour={brandColours?.secondary} name={name} />
            {name}
          </TextAligner>
        </MUIMenuItem>
      ))}
    </SelectField>
  ) : null;
}

export function MobileTenantSwitcher() {
  const { userAccounts } = useUser();
  const accountsWithURL = userAccounts.filter((item) => item.url);
  return accountsWithURL.length > 1 ? (
    <SwitcherContainer>
      <TenantSwitcher />
    </SwitcherContainer>
  ) : null;
}

const SwitcherContainer = styled('div')`
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-align: center;

  .MuiFormControl-root {
    width: 100% !important;
  }
`;

const TextAligner = styled('div')`
  display: flex;
  align-items: center;
`;
