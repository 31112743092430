import { styled, TextField } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIIconButton from '@mui/material/IconButton';
import MUIListItem from '@mui/material/ListItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import MUIListItemText from '@mui/material/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { calculateTotalWithPOR } from 'src/services/isPOR';
import useFormattedPrice from 'src/services/formatCurrency';
import { useEffect, useState } from 'react';
import PartThumbnail from '../PartThumbnail';

interface CartItemProps extends ShoppingCartItem {
  removeItem: () => void;
  updateItemQuantity: (quantity: number) => void;
}

export default function CartItem({
  partName,
  quantity,
  partNumber,
  unitPrice,
  removeItem,
  updateItemQuantity,
}: CartItemProps) {
  const [inputFieldQuantity, setInputFieldQuantity] = useState(quantity.toString());

  useEffect(() => {
    setInputFieldQuantity(quantity.toString()); // if there are any changes to the quantity from BE override everything
  }, [quantity]);

  const updateWithInputField = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setInputFieldQuantity('1');
      updateItemQuantity(1);
      return;
    }
    const value = e.target.value === '0' ? '1' : e.target.value;
    updateItemQuantity(parseInt(value));
  };

  const onChangeInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '0' ? '1' : e.target.value;
    setInputFieldQuantity(value);
  };

  const totalPrice = calculateTotalWithPOR([{ quantity, unitPrice }]);
  const priceTag = useFormattedPrice({ price: totalPrice, smallFont: true });

  return (
    <StyledListItem data-testid="shopping-cart-item">
      <StyledListItemIcon>
        <MUIIconButton
          edge="end"
          aria-label="delete"
          onClick={removeItem}
          data-testid="shopping-cart-item-delete-button"
        >
          <TrashIcon icon={faTrash} size="xs" />
        </MUIIconButton>
      </StyledListItemIcon>
      <ListIconDivider orientation="vertical" />
      <PartThumbnail partNumber={partNumber} quantity={quantity} transparent width={120} height={100} />
      <MUIListItemText
        sx={{ color: (theme) => theme.palette.sidebarBackground.contrastText }}
        primary={
          <Ellipsis>
            <strong>{partName} • </strong>
            {partNumber}
          </Ellipsis>
        }
        secondary={
          <SpaceBetween>
            Quantity:
            <QuantityInputField
              value={inputFieldQuantity}
              onBlur={updateWithInputField}
              onChange={onChangeInputField}
            />
            <PriceTag data-testid="cart-item-price">{priceTag}</PriceTag>
          </SpaceBetween>
        }
        secondaryTypographyProps={{ color: ({ palette }) => palette.text.primary, component: 'div' }}
      />
    </StyledListItem>
  );
}

const PriceTag = styled('strong')`
  margin-left: auto;
  font-size: 18px;
`;

const SpaceBetween = styled('div')`
  color: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  align-items: center;
`;

const Ellipsis = styled('span')`
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const StyledListItemIcon = styled(MUIListItemIcon)`
  min-width: 30px;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey[900]};
`;

const ListIconDivider = styled(MUIDivider)`
  height: 33px;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  margin: 10px;
`;

ListIconDivider.defaultProps = { orientation: 'horizontal' };

const StyledListItem = styled(MUIListItem)`
  background: transparent;
`;

const QuantityInputField = styled(TextField)`
  .MuiInput-root {
    height: 0.875rem;
    width: 2rem;

    ${({ theme }) => theme.mixins.hideArrowsInNumberTypeInput}
  }

  .MuiInput-input {
    text-align: center;
  }
`;

QuantityInputField.defaultProps = {
  variant: 'standard',
  size: 'small',
  type: 'number',
};
