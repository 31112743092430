import { styled } from '@mui/material';

const SidebarTextActive = styled('span')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

const HighlightElement = styled('span')`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

interface IProps {
  text: string;
  regex: RegExp;
}

export function Highlight({ text, regex }: IProps) {
  if (!text) {
    return null;
  }

  const splitters: string[] = text.match(regex) || [];
  const textChunks: string[] = text.split(regex);

  const content = textChunks.reduce(
    (accumulator: any, chunk, _index: number) =>
      accumulator === null
        ? [chunk]
        : [
            ...accumulator,
            <HighlightElement data-testid={splitters[0]?.toLowerCase()} key={chunk}>
              {splitters.shift()}
            </HighlightElement>,
            chunk,
          ],
    null
  );
  return <SidebarTextActive>{content}</SidebarTextActive>;
}
