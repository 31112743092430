import { useEffect, useState } from 'react';

import { NamedLink, usePartNavigation } from 'src/services/partNavigation';
import { useQueryStringValue } from 'src/services/partUrl';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { StyledTabContainer, StyledTabContentPanel } from 'src/components/Product/Mobile/Panels/PartsCatalogue/styles';
import OverviewTab from 'src/components/Product/Mobile/Panels/PartsCatalogue/InnerTabs/OverviewTab';
import LocationsTab from 'src/components/Product/Mobile/Panels/PartsCatalogue/InnerTabs/LocationsTab';
import AlternativeOptionsTab from 'src/components/Product/Mobile/Panels/PartsCatalogue/InnerTabs/AlternativeOptionsTab';
import SupersessionsTab from 'src/components/Product/Mobile/Panels/PartsCatalogue/InnerTabs/SupersessionsTab';
import { Tabs, Tab } from './Tab';

type TabName = 'Overview' | 'Options' | 'Locations' | 'Part history';

const extractAlternativeOptions = (parents: NamedLink[], current: PartNode) => {
  const closestParent = parents[parents.length - 1];
  const hasOptionsParent = closestParent?.data?.objectType === 'product-option';
  const { children: productOptions = [] } = (hasOptionsParent && closestParent?.data) || {};
  return productOptions.filter((child: PartNode) => child.id !== current.id);
};

export default function InnerTabs({ current, parents }: { current: PartNode; parents: NamedLink[] }) {
  const { relatedParts } = usePartNavigation();
  const [activeTab, setActiveTab] = useState<TabName>('Overview');

  const supersessionParameterInQueryString = useQueryStringValue('supersession');

  const alternativeOptions = extractAlternativeOptions(parents, current);

  const supersessions = current?.supersessions || [];

  useEffect(() => {
    if (supersessionParameterInQueryString) {
      setActiveTab('Part history');
    }
  }, [supersessionParameterInQueryString]);

  const handleTabUpdate = (_: React.SyntheticEvent, newValue: TabName) => {
    setActiveTab(newValue);
  };

  return (
    <StyledTabContainer>
      <Tabs value={activeTab} onChange={handleTabUpdate} data-testid="inner-tabs">
        <Tab label="Overview" value="Overview" inner />
        <CollectionTab value="Locations" collection={relatedParts} activeTab={activeTab} />
        <CollectionTab value="Options" collection={alternativeOptions} activeTab={activeTab} />
        <CollectionTab value="Part history" collection={supersessions} activeTab={activeTab} />
      </Tabs>
      <StyledTabContentPanel>
        {activeTab === 'Overview' && <OverviewTab current={current} />}
        {activeTab === 'Locations' && <LocationsTab relatedParts={relatedParts} />}
        {activeTab === 'Options' && <AlternativeOptionsTab current={current} alternativeOptions={alternativeOptions} />}
        {activeTab === 'Part history' && (
          <SupersessionsTab
            supersessions={supersessions}
            supersessionParameterInQueryString={supersessionParameterInQueryString}
          />
        )}
      </StyledTabContentPanel>
    </StyledTabContainer>
  );
}

function CollectionTab({
  collection,
  value,
  activeTab,
  ...props
}: {
  collection: unknown[];
  value: string;
  activeTab: string;
}) {
  return (
    <Tab
      label={`${value} (${collection.length})`}
      inner
      data-testid={`${value}-tab`}
      value={value}
      style={{ display: collection.length || activeTab === value ? 'inherit' : 'none' }}
      {...props}
    />
  );
}
