import Markdown from 'markdown-to-jsx';

import MarkdownLink from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/MarkdownLink';
import { RepairText, ScrollContainer } from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText/styles';

interface Props {
  description: string;
}

export default function Description({ description = '' }: Props) {
  return (
    <RepairText data-testid="repair-description">
      <ScrollContainer>
        <Markdown
          key={
            description
            // SVT-2113, by forcing unmount of entire component we go around google translate problem when it inserts addditional <font> nodes in the DOM, see https://github.com/facebook/react/issues/11538
          }
          options={{
            overrides: {
              a: MarkdownLink,
            },
          }}
        >
          {description}
        </Markdown>
      </ScrollContainer>
    </RepairText>
  );
}
