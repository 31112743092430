import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { NamedLink } from 'src/services/partNavigation';
import { useProduct } from 'src/providers/Product';
import { NavLink } from 'src/components/NavList/NavList';
import Chip from 'src/components/Chip';
import { device } from 'src/constants/mediaQueries';

interface BomItemLinkProps extends NamedLink {
  quantity: number;
}

const Chevron = styled(FontAwesomeIcon)`
  vertical-align: middle;
`;

export function BomItemLink(props: BomItemLinkProps) {
  const { data } = props;
  return data ? <ProductOptionItemLink {...(props as Required<BomItemLinkProps>)} /> : <ProductItemLink {...props} />;
}

function ProductItemLink({ name, url, quantity }: BomItemLinkProps) {
  const shouldShowQuantityChip = quantity > 1;
  return (
    <NavLink data-testid="bom-child-node" to={url}>
      <BomItemLinkName isQuantityChipPresent={shouldShowQuantityChip}>{name}</BomItemLinkName>
      {shouldShowQuantityChip && <Chip label={`Qty: ${quantity}`} color="primary" size="small" />}
      <Chevron icon={faChevronRight} />
    </NavLink>
  );
}

function ProductOptionItemLink({ name, url, data, id, quantity }: Required<NamedLink> & BomItemLinkProps) {
  const { toggleVariantNames } = useProduct();
  const otherVariantNames = data.children?.map((child) => child.object3DName).filter((name): name is string => !!name);
  const shouldShowQuantityChip = quantity > 1;

  return (
    <NavLink
      data-testid="bom-child-node"
      to={url}
      onClick={() => {
        toggleVariantNames(otherVariantNames, id);
      }}
    >
      <BomItemLinkName isQuantityChipPresent={shouldShowQuantityChip}>{name}</BomItemLinkName>
      {shouldShowQuantityChip && <Chip label={`Qty: ${quantity}`} color="primary" size="small" />}
      <Chevron icon={faChevronRight} />
    </NavLink>
  );
}

const BomItemLinkName = styled(MUITypography, {
  shouldForwardProp: (prop: string) => prop !== 'isQuantityChipPresent',
})<{ isQuantityChipPresent?: boolean }>`
  font-size: 1rem;
  ${({ isQuantityChipPresent }) => isQuantityChipPresent && 'width: 70%'};

  @media ${device.mobileOrTablet} {
    ${({ isQuantityChipPresent }) => isQuantityChipPresent && 'width: 80%'};
  }
`;
