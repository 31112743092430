import TagManager from 'react-gtm-module';
import { isProduction } from 'src/utils/isProduction';

export const dataLayerName = 'PageDataLayer';

let gtmInitialized = false;

export const initializeGTM = () => {
  // SVT-2077 Only start Google Tag Manager on production, after title has been set, and just once.

  if (!gtmInitialized && isProduction()) {
    gtmInitialized = true;
    TagManager.initialize({
      gtmId: 'GTM-MFVWW9X',
      dataLayerName,
    });
  }
};
