import RequirementsBoxText from 'src/components/PasswordRequirementsBox.tsx';
import { LoginPasswordInput } from 'src/components/TextInput/LoginPasswordInput';
import { MobileResetPasswordRequirements, StyledSendResetPasswordFormButton } from '../styles';
import { ResetPasswordFormProps } from '../types';

export function MobileResetPasswordForm({
  error,
  onButtonClick,
  resetPasswordFormData,
  setResetPasswordFormData,
  checkPasswordsMatch,
}: ResetPasswordFormProps) {
  const { password, confirmPassword } = resetPasswordFormData;
  return (
    <>
      <MobileResetPasswordRequirements>
        <RequirementsBoxText newPassword />
      </MobileResetPasswordRequirements>
      <form onSubmit={(e) => onButtonClick(e, resetPasswordFormData)}>
        <div>
          <LoginPasswordInput
            label="Password"
            testId="password"
            value={password}
            error={error}
            onChange={(e: any) => setResetPasswordFormData((prev) => ({ ...prev, password: e.target.value }))}
            match={checkPasswordsMatch()}
          />
        </div>
        <div>
          <LoginPasswordInput
            label="Confirm password"
            testId="confirm-password"
            value={confirmPassword}
            error={error}
            onChange={(e: any) => setResetPasswordFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))}
            match={checkPasswordsMatch()}
          />
        </div>
        <StyledSendResetPasswordFormButton
          type="submit"
          data-testid="reset-form-submit"
          disabled={!checkPasswordsMatch() || Object.values(resetPasswordFormData).some((value) => value === '')}
        >
          Change your password
        </StyledSendResetPasswordFormButton>
      </form>
    </>
  );
}
