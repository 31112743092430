import { faBars, faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ContrastBreadcrumbs } from 'src/components/Breadcrumbs/ContrastBreadcrumbs';
import { StyledMUIButton } from 'src/components/Button';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { Feature } from 'src/Feature';
import { NamedLink } from 'src/services/partNavigation';

interface MobileProductViewHeaderProps {
  current?: PartNode;
  parents: NamedLink[];
  numberOfCartItems: number;
  setPanelOpen: Dispatch<SetStateAction<boolean>>;
  enableShoppingCart: boolean;
  triggerMobileProductMenu: () => void;
}

export default function MobileProductViewHeader({
  current,
  parents,
  numberOfCartItems,
  setPanelOpen,
  enableShoppingCart,
  triggerMobileProductMenu,
}: MobileProductViewHeaderProps) {
  const shouldShowNumberCartItem = !!numberOfCartItems;

  return (
    <MobileProductViewHeaderPositioning>
      <HeaderMenuIcon onClick={triggerMobileProductMenu}>
        <FontAwesomeIcon icon={faBars} size="lg" />
      </HeaderMenuIcon>
      <ContrastBreadcrumbs current={current} parents={parents} maxSteps={2} />
      {enableShoppingCart && (
        <Feature flag="shopping-cart">
          <HeaderCartIcon onClick={() => setPanelOpen((prev) => !prev)}>
            {shouldShowNumberCartItem && <HeaderCartBadge>{numberOfCartItems}</HeaderCartBadge>}
            <FontAwesomeIcon icon={faCartShopping} size="lg" />
          </HeaderCartIcon>
        </Feature>
      )}
    </MobileProductViewHeaderPositioning>
  );
}

const MobileProductViewHeaderPositioning = styled('div')`
  display: flex;
  align-items: center;
  position: absolute;

  top: 2.8rem;
  left: 1rem;
  right: 1rem;
`;

const HeaderMenuIcon = styled(StyledMUIButton)`
  min-width: 2.25rem;
  min-height: 2.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 1.5rem;

  text-align: center;
  line-height: 2.25rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 0;

  :hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const HeaderCartIcon = styled(HeaderMenuIcon)`
  margin-left: auto;
  margin-right: 0;
`;

const HeaderCartBadge = styled('div')`
  position: absolute;
  top: -9px;
  right: -30px;
  min-width: 1.25rem;
  min-height: 1.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  font-size: 0.75rem;
  text-align: center;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
