import { useContext, useState } from 'react';
import MUIIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';
import { extractAndSaveMetaObject } from 'src/providers/ShoppingCart';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModelContext } from 'src/providers/Model';
import { MutationsHost } from './MutationsHost';

export function EditButton({ part }: { part: PartNode }) {
  const { model } = useContext(ModelContext);

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);

  const handleOpen = () => {
    extractAndSaveMetaObject(part, model);
    setModalOpen(true);
  };

  if (!hasModelNodeID(part)) {
    // eslint-disable-next-line no-console
    console.warn(
      "Missing model nodeID on part node. Are you trying to edit an old database structure? Don't do this, it will break. Migrate first."
    );
    return null;
  }

  return (
    <>
      <StyledButton aria-label="Edit part" onClick={handleOpen} data-testid="edit-parts-button">
        <FontAwesomeIcon icon={faPenToSquare} size="xs" />
      </StyledButton>
      <MutationsHost partNode={part} handleClose={handleClose} modalOpen={modalOpen} />
    </>
  );
}

type PartWithModelNodeID = PartNode & { modelNodeID: string };
const hasModelNodeID = (part: PartNode): part is PartWithModelNodeID => Boolean(part.modelNodeID);

const StyledButton = styled(MUIIconButton)`
  color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.grey[900]};
  width: 33px;
  height: 33px;
  position: absolute;
  right: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;
