import type { RoleId } from '@samsonvt/shared-types/cognito';
import type { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { MainTemplate } from 'src/templates/MainTemplate';
import { ContentContainer } from './styles';
import { UserManagementHeader } from './UserManagementHeader';
import { UserItem, UserManagementTable } from './UserManagementTable';
import { UserManagementTabs } from './UserManagementTabs';

export interface UserRole {
  id: RoleId;
  name: string;
}

export type FullUser = UserItem & { discount: DiscountScheme | undefined; userRole: UserRole | undefined };

export function UserManagement() {
  const location = useLocation();

  const [sortedUserList, setSortedUserList] = useState<FullUser[]>([]);
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onAddNewUser = () => setIsAddingNewUser(true);
  const onAddNewUserCancel = () => setIsAddingNewUser(false);

  return (
    <MainTemplate>
      <UserManagementTabs currentUrl={location.pathname} />
      <ContentContainer>
        <UserManagementHeader
          sortedUserList={sortedUserList}
          onAddNewUser={onAddNewUser}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <UserManagementTable
          searchValue={searchValue}
          setSortedUserList={setSortedUserList}
          isAddingNewUser={isAddingNewUser}
          onAddNewUserCancel={onAddNewUserCancel}
        />
      </ContentContainer>
      <Footer />
    </MainTemplate>
  );
}
