import { styled, alpha } from '@mui/material';
import { StyledMUIButtonLink } from 'src/components/Button';
import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';
import MUITypography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MUIDivider from '@mui/material/Divider';

export const DesktopWorkInstructionsBackLink = styled(StyledMUIButtonLink)`
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-bottom: none;
  border-radius: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const RepairTitle = styled('div')`
  color: inherit; // inherit from DesktopWorkInstructionsBackLink
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const BackChevron = styled('div')`
  svg {
    cursor: pointer;
    transform: rotate(180deg);
    margin: 0;
    height: ${spacing.largeSpacing};
  }
`;

export const Header = styled('div')`
  flex-grow: 1;
  padding: ${spacing.mediumSpacing};
`;

export const RepairsButton = styled(StyledMUIButtonLink)`
  font-size: ${font.xSmall};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: inline-block;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
  &:hover {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const StyledRepairListDurationRow = styled('div')`
  font-size: ${font.small};
  display: flex;
`;

export const StyledRepairListDurationTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  padding-left: 5px;
`;

export const StyledRepairListDurationBadge = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  font-weight: bold;
  padding-left: 5px;
`;

export const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  width: 1rem;
`;

export const StyledRepairList = styled('div')`
  padding: ${spacing.mediumSpacing};
`;

export const TagButton = styled(StyledMUIButtonLink)`
  font-size: ${font.xSmall};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: inline-block;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
  &:hover {
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const StyledRepairListItem = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spacing.mediumSpacing} 0 ${spacing.xSmallSpacing} 0;

  &:last-of-type {
    border-bottom: none;
  }

  p {
    text-transform: capitalize;
    margin-bottom: ${spacing.xSmallSpacing};
  }
`;

export const StyledRepairName = styled(MUITypography)`
  line-height: 1.65rem;
`;
StyledRepairName.defaultProps = { variant: 'body1', fontWeight: 'bold' };

export const SecondaryDivider = styled(MUIDivider)`
  border-color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.5)};
  border-bottom-width: 1px;
  margin: 0;
`;

export const StyledLoadingSpinner = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
