import { UploadingStatus } from 'src/components/Uploader/UploadingStatus';
import { styled } from '@mui/material';

type Props = {
  progress: number;
};

const threejsLoadingWidth = '15rem';

export default function LoadingOverlay({ progress }: Props) {
  return (
    <Overlay>
      <LoadingContainer data-testid="three-js-loading">
        <UploadingStatus percentUploaded={progress}>Loading 3D Model</UploadingStatus>
      </LoadingContainer>
    </Overlay>
  );
}

const Overlay = styled('div')`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.canvasBackground.linear};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled('div')`
  width: ${threejsLoadingWidth};
`;
