import axios, { AxiosRequestConfig, CancelToken } from 'axios';

export interface RequestConfig {
  cancelToken: CancelToken;
}

export interface DownloadConfig extends RequestConfig {
  onProgress: (progress: number) => void;
}

export interface Headers {
  ['content-type']: string;
  ['last-modified']: string;
  etag: string;
}

export const maxDownloadProgress = 100;

export const getHeaders = async (url: string, config: RequestConfig): Promise<Headers> => {
  const req: AxiosRequestConfig = {
    url,
    // url is a signed s3 url with the http method baked
    // into the signature - HEAD won't pass signature validation
    method: 'GET',
    cancelToken: config.cancelToken,
    // simulate HEAD request by fetching only the first byte
    headers: { Range: 'bytes=0-0' },
  };

  const { headers } = await axios(req);

  return headers as Headers;
};

export const downloadObject = async (url: string, config: DownloadConfig): Promise<ArrayBuffer> => {
  const onDownloadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * maxDownloadProgress) / progressEvent.total);

    config.onProgress(percentCompleted);
  };

  const req: AxiosRequestConfig = {
    url,
    method: 'GET',
    responseType: 'arraybuffer',
    cancelToken: config.cancelToken,
    onDownloadProgress,
  };

  const res = await axios(req);

  return res.data;
};
