import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DevTools } from 'src/components/DevTools';
import { ModelProvider } from 'src/providers/Model';
import { ProductProvider } from 'src/providers/Product';
import { RepairDetailsProvider } from 'src/providers/repairs/RepairDetails';
import { RepairListProvider } from 'src/providers/repairs/RepairList';
import { PartProvider } from 'src/services/part';
import { PartNavigationProvider } from 'src/services/partNavigation';
import { ProductDetailPage } from '../ProductDetailPage';
import ProductListings from '../ProductListings/ProductListings';

export function ProductsPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ProductListings />
      </Route>
      {/* This needs to NOT be exact to match */}
      <Route path={`${path}/:productId`}>
        <ProductProvider>
          <ModelProvider>
            <RepairListProvider>
              <RepairDetailsProvider>
                <PartProvider>
                  <PartNavigationProvider>
                    <ProductDetailPage />
                  </PartNavigationProvider>
                </PartProvider>
              </RepairDetailsProvider>
            </RepairListProvider>
          </ModelProvider>
        </ProductProvider>
      </Route>
      {process.env.REACT_APP_DEV_TOOLS_ENABLED === 'true' && <DevTools />}
    </Switch>
  );
}
