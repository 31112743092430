import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useState } from 'react';

export type PORProps = {
  smallFont?: boolean;
  shortLabel?: boolean;
};

export function PORComponent({ smallFont = false, shortLabel = false }: PORProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen((prevState) => !prevState);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  return (
    <LightTooltip
      title="Price On Request. The total cannot be calculated as some/all items don’t have a price"
      placement="top"
      arrow
      open={open}
    >
      <PORText smallFont={smallFont} data-testid="por-tooltip" onClick={handleOpen}>
        {shortLabel ? 'POR' : 'Price On Request'}
      </PORText>
    </LightTooltip>
  );
}

// documentation way of customising the tooltip https://mui.com/material-ui/react-tooltip/

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
}));

const shouldForwardProp = (prop: string) => prop !== 'smallFont';

const PORText = styled('span', { shouldForwardProp })<{ smallFont: boolean }>`
  color: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: ${({ smallFont }) => (smallFont ? '16px' : '20px')};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;
