import { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { StyledMUIButton } from 'src/components/Button';
import { Toast, useTrigger } from 'src/components/Toast';

import { error as logError } from 'src/services/log';
import { StyledLink } from './styles';
import { LoginFormData, LoginFormProps } from './types';
import { LoginTextInput } from '../TextInput/LoginTextInput';
import { LoginPasswordInput } from '../TextInput/LoginPasswordInput';

export function LoginForm({ switchPage, setLoading, login, history, location }: LoginFormProps) {
  const [data, setData] = useState<LoginFormData>({
    email: '',
    password: '',
  });

  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setToastErrorMessage] = useState<string | undefined>();

  const validateBeforeSending = () => {
    const error = new Error();

    if (!data.password || !data.email) {
      error.name = 'FieldsEmpty';
      throw error;
    }
  };

  const showErrorToast = (message: string) => {
    setLoading(false);
    setToastErrorMessage(message);
    setErrorToast();
  };

  const onButtonClick = async (e: any, data: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      validateBeforeSending();
      await login(data.email, data.password).then(async (res: CognitoUser) => {
        if (res?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return history.push(
            `/create-password-with-temp-credentials?requiresPasswordChange=true&email=${encodeURIComponent(data.email)}`
          );
        }
        return history.push(location.state?.redirectUrl ?? '/product-library');
      });
    } catch (e: any) {
      logError(e);

      switch (e.name) {
        case 'NotAuthorizedException':
          showErrorToast('Username or password incorrect');
          break;
        case 'FieldsEmpty':
          showErrorToast('Fields cannot be empty');
          break;
        case 'UserLambdaValidationException':
          showErrorToast('Email address cannot be found, please double-check it.');
          break;
        case 'InvalidEmailFormat':
          showErrorToast('The email provided is not valid');
          break;
        default:
          showErrorToast(e.toString());
          break;
      }
    }
  };

  return (
    <>
      <form onSubmit={(e) => onButtonClick(e, data)}>
        <LoginTextInput
          label="Email"
          type="email"
          value={data.email}
          testId="login-email"
          onChange={(e: any) => setData((prevData) => ({ ...prevData, email: e.target.value }))}
          error={!!errorToast}
        />

        <LoginPasswordInput
          label="Password"
          value={data.password}
          testId="login-password"
          onChange={(e: any) => setData((prevData) => ({ ...prevData, password: e.target.value }))}
          error={!!errorToast}
        />

        <StyledLink onClick={() => switchPage()}>Forgot password?</StyledLink>
        <StyledMUIButton type="submit" data-testid="login-submit" fullWidth>
          Login
        </StyledMUIButton>
      </form>

      <Toast dependency={errorToast} severity="error" title="Error logging in" message={errorToastMessage || ''} />
    </>
  );
}
