import { useMediaQuery } from '@mui/material';
import { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { device } from 'src/constants/mediaQueries';
import { dataLayerName } from 'src/services/initializeGTM';
import { DesktopCheckoutConfirmation } from './Desktop/DesktopCheckoutConfirmation';
import { MobileCheckoutConfirmation } from './Mobile/MobileCheckoutConfirmation';

export interface CheckoutConfirmationProps {
  confirmationNo: string | undefined; // undefined is when the order has not yet been placed
  isOrderPOR: boolean;
  gtmData: GTMData;
}

export default function Confirmation({ gtmData, ...rest }: CheckoutConfirmationProps) {
  const isMobile = useMediaQuery(device.mobileOrTablet);

  useEffect(() => {
    if (gtmData.items.length > 0 && gtmData.transactionId) {
      sendOrderDataGTM(gtmData);
    }
  }, [gtmData.transactionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return isMobile ? <MobileCheckoutConfirmation {...rest} /> : <DesktopCheckoutConfirmation {...rest} />;
}

export interface GTMData {
  transactionId: string | undefined;
  value: DiscountedPartPrice;
  currency: string | undefined;
  isOrderPOR: boolean;
  items: ShoppingCartItem[];
}

export function sendOrderDataGTM(data: GTMData) {
  const { transactionId, value, currency, items } = data;
  const orderData = {
    event: 'purchase',
    ecommerce: {
      transactionId,
      value,
      currency,
      items,
    },
  };

  // Clear the dataLayer
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      ecommerce: null,
    },
    dataLayerName,
  });

  TagManager.dataLayer({
    dataLayer: orderData,
    dataLayerName,
  });
}
