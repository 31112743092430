import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import samsonLogoLarge from 'src/assets/logo-default.svg';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons';
import { StyledLogo } from 'src/templates/UnauthenticatedTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CreateAccountSuccess({ logoUrl }: { logoUrl?: string }) {
  return (
    <CreateAccountSuccessContainer>
      <StyledLogo src={logoUrl || samsonLogoLarge} />
      <CreateAccountSuccessTitle>Thank you, your account has been created successfully.</CreateAccountSuccessTitle>
      <CreateAccountSuccessIcon icon={faUserCheck} />
      <CreateAccountSuccessBodyBold>Please, check your inbox</CreateAccountSuccessBodyBold>
      <CreateAccountSuccessBody>
        We have sent an email with a link to activate your account. This can take a few minutes, if nothing is received
        please check your Spam folder.
      </CreateAccountSuccessBody>
    </CreateAccountSuccessContainer>
  );
}

const CreateAccountSuccessContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;

const CreateAccountSuccessTitle = styled(MUITypography)`
  font-size: 2.125rem;
  margin: 1.25rem 0;
`;

const CreateAccountSuccessIcon = styled(FontAwesomeIcon)`
  height: 90px;
  margin: 1.25rem 0;
`;

const CreateAccountSuccessBodyBold = styled(MUITypography)`
  font-size: 1.25rem;
  width: 70%;
  margin: 1.25rem 0;
  font-weight: bold;
`;

const CreateAccountSuccessBody = styled(MUITypography)`
  font-size: 1rem;
  width: 70%;
  margin: 1.25rem 0;
`;
