// Fades meshes in and out. Capable of mutating colours (currently not used) and other properties of materials.
// Main design features:
// 1. Supports material deduplication so that we only transition minimal number of materials if a group of meshes shares material and has simmilar transitions.
// 2. Maintains original states of materials before transition, using .userData property on transitioned materials.
// 3. Reverts transitions using react's useEffect cleanup funciton.
// 4. Extracts shared materials when some meshes share material but have to go through different transitions (one black box fades out another does not).
// 5. Is able to manage start and end of transitions to update special properties ( mesh.visible and material.transparent )
// 6. Keeps styles separate for each kind of transition, so that we can adjust there speed and potentially color
// 7. Supports declaration of styles in any level of tree, so that leaf nodes inherit styles set on their parents.
// 8. Maintains logic of [show], [hide] and HIDDEN_TAG behaviour via precedence of style declaration merging

import { useRef, useEffect, useCallback } from 'react';
import { invalidate, useFrame } from '@react-three/fiber';
import exposeAsGlobal from 'src/services/exposeAsGlobal';
import type { Mesh, Object3D, MeshStandardMaterial } from 'three';
// Style definitions for diferent transitions ↓
import { styles, StyleDeclaration, MutableProperty } from './threeStyles';
// Functions for individual properties that we mutate in frame-time. Atm it's opacity and colour ↓
import mutators from './mutators';
import {
  materialWithinMesh,
  cloneObjectWithColor,
  prepareMaterial,
  PreparedMaterial,
  updateAssociatedMeshesVisibility,
  updateMeshVisibility,
  isMesh,
} from './helpers';

type Props = {
  scene: Object3D;
  show?: string[];
  hide?: string[];
  hiddenVariantNames?: string[];
};

// Managed in Blender plugin, the HIDDEN tag is used for tools that are ment to be invisible unless explicitly showed.
const HIDDEN_TAG = 'HIDDEN';

// Fixing the dreaded React pitfall with default reference type parameters as dependencies. see https://github.com/facebook/react/issues/18123
const emptyArray: string[] = [];

export function FadeModel({ scene, show = emptyArray, hide = emptyArray, hiddenVariantNames = emptyArray }: Props) {
  // Collection of all materials that are still animated and need to be mutated every frame. Dereferenced in useFrame.
  const ongoing = useRef<Set<PreparedMaterial>>(new Set());
  // Caching meshes by id's as .getObjectById() is slow and we can't store references on material.
  const meshCache = useRef<{ [key: string]: Mesh }>({});
  // Debug & storybook helper. Open the F12 developer tools and type fadeModel in the console to debug real time.
  exposeAsGlobal('fadeModel', { show, hide, ongoing, hiddenVariantNames });
  // ↓ Will be called later when constructing styles for meshes that we want to animate. It manages connection between materials ( we animate materials ) and meshes they belong to. ↓
  const prepareTransitions = useCallback((mesh: Mesh, style: StyleDeclaration, prepared: MeshStandardMaterial[]) => {
    // Make mesh visible - if it's transitioning it must be on visible layer, otherwise we won't see it fading in.
    updateMeshVisibility(mesh, true);
    // Prepares material and meshId's,
    const preparedMaterial = prepareMaterial(materialWithinMesh(mesh), prepared, style);
    // Cache the mesh for further reuse when updating visibilit via layers in useFrame
    const { id } = mesh;
    if (!meshCache.current[id]) {
      meshCache.current[id] = mesh;
    }
    // Reference meshes by id on material (for material.visible mutation)
    preparedMaterial.userData.meshIds.push(id);
    mesh.material = preparedMaterial;
  }, []);
  // ↓ Main effect managing logic of [show], [hide], HIDDEN_TAG and [hiddenVariantNames] logic and styles.
  // ↓ It iterates over all meshes, decides what style should be applied on each, and schedules transitions that happen in useFrame hook below.
  useEffect(() => {
    // Temporary array storing materials for material reuse and for cleaning in useEffect returned function
    const prepared: PreparedMaterial[] = [];
    // HYDRADIG hotfix. Assuming that if all we have in show array are HIDDEN meshes we should not fade out everything not shown.
    const shouldHideByDefault = show.filter((name) => !name.includes(HIDDEN_TAG)).length > 0;

    scene.traverse((child) => {
      // Only build styles for meshes, not other Object3d's like groups.
      if (isMesh(child)) {
        // Start with undefined style. If no style will be set we will not create transition
        let style: StyleDeclaration | undefined;
        // Accumulator for show array behaviour
        let hasSolidParent = false;
        // Main function that closes over helper vars ^^ and will be run for mesh and it's ancestors.
        const buildStyle = (object: Object3D) => {
          // First deal with hidden tags. They can be overriden by show.
          if (object.name.includes(HIDDEN_TAG)) {
            style = { ...style, ...styles.hiddenTag };
          }
          // Everything not being a child of a show'ed object3D is supposed to be hidden when show array present.
          // This is why show is handled differently. We save this information and later assign a style to all meshes that are not shown.
          if (show.includes(object.name)) {
            hasSolidParent = true;
            // Show doesn't have it's own style, except when overriding HIDDEN_TAG
            if (object.name.includes(HIDDEN_TAG)) {
              style = { ...style, ...styles.shownBecauseOverridingHiddenTag };
            }
          }
          // Hiding is also sometimes set on parent objects, not meshes, so we also check ancestors.  See Lotus Evora 410
          if (hide.includes(object.name)) {
            style = { ...style, ...styles.hide };
          }
          // When a variant has multiple materials, it will be represented in three as multiple meshes.
          // Therefore we also need to check parent. See generic-car-engine-option / Red Cam Cover for example.
          if (hiddenVariantNames.includes(object.name)) {
            style = { ...style, ...styles.hiddenVariantNames };
          }
        };
        // First check for styles inherited from ancestors
        child.traverseAncestors(buildStyle);
        // Then override them with own styles - direct styles are considered more important
        buildStyle(child);
        // Here we are dealing with special behaviour of show array. It hides other meshes.
        if (shouldHideByDefault && !hasSolidParent) {
          style = { ...styles.hiddenBecauseNotShown, ...style };
        }
        // If any of the rules set style prepare transition ( extract material, save old style etc. )
        if (style) {
          prepareTransitions(child, style, prepared);
        }
      }
    });
    // After deduping prepared transitions we schedule them for execution
    prepared.forEach((transition) => ongoing.current.add(transition));
    // Invalidate every time we add new transitions as we are in on demand loop and transitions are a ref. ( Need to trigger useFrame )
    invalidate();

    return () => {
      // Reverse all transitions on cleanup
      prepared.forEach((material) => {
        // Assuming instant reversal here. We could potentially copy speed from existing transition.
        material.userData.transitions = cloneObjectWithColor(material.userData.originalProperties);
        // Update visibility before reversal so that fade in will work
        updateAssociatedMeshesVisibility(material, meshCache, true);
        // Jumping out of react to mutate the set holding all transitions. Eslint gives false positive.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ongoing.current.add(material);
      });
    };
  }, [hide, hiddenVariantNames, show, prepareTransitions, scene]);

  useFrame(() => {
    // Check size to avoid invalidate() call when transitions are done
    if (ongoing.current.size) {
      // Iterate over currently happening transitions and run mutations.
      ongoing.current.forEach((material) => {
        const {
          transitions: {
            speed = 1,
            // Dissociating visible property. It is being handled separately, because it's mesh-level and needs to be handled on transition end and start.
            visible,
            // Dissociating transparent property. It is being handled separately.
            // It is simmilar to visible property in that we need to set it on the beginning and the end of fading transitions,
            // But it is different as it is material-level, not mesh level, and that its original state needs to be saved to manage semitransparent meshes fading.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            transparent,
            // Now we are left with the styles we want to mutate.
            ...styles
          },
        } = material.userData;
        // ↓ Run actual mutations and check if done ↓
        const finished = !Object.entries(styles)
          // Mutator changes property values on material
          .map(([property, target]) => mutators[property as MutableProperty](material, target, speed))
          // and returns true when mutation is finished
          .includes(false);
        // ↓ Now cancel transition and handle special properties ( visible and transparent ) ↓
        if (finished) {
          // First remove from transitions once finished so that frame won't iterate over it anymore
          ongoing.current.delete(material);
          // Then we want to update associated meshes visibility, but only if this was a fade out transition.
          // When fading in visibility was updated when preparing transition, otherwise we won't be able to see it.
          if (visible === false) {
            // Re-setting original transparency here, to avoid empty mutator.
            material.transparent = !!material.userData.originalProperties.transparent;
            updateAssociatedMeshesVisibility(material, meshCache, false);
          }
        }
      });
      // Only invalidate if still transitioning
      invalidate();
    }
  });

  return null;
}
