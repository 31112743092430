import { styled } from '@mui/material';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import MUITypography from '@mui/material/Typography';
import { TableDataRow, TableHeader } from 'src/components/Table/MUITableStyles';
import { DiscountManagementTableStyles } from './DiscountManagementTable';

export default function EmptyDiscountTable() {
  return (
    <DiscountManagementTableStyles>
      <MUITable>
        <MUITableHead>
          <MUITableRow>
            <TableHeader />
          </MUITableRow>
        </MUITableHead>
        <MUITableBody>
          <TableDataRow>
            <EmptyTableContainer data-testid="empty-discount-table">
              <EmptyTableTitle>There are currently no discounts created</EmptyTableTitle>
              <EmptyTableSubTitle>Please, use the “Add new discount” button above to add them</EmptyTableSubTitle>
            </EmptyTableContainer>
          </TableDataRow>
        </MUITableBody>
      </MUITable>
    </DiscountManagementTableStyles>
  );
}

const EmptyTableContainer = styled('td')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
`;

const EmptyTableTitle = styled(MUITypography)`
  font-weight: bold;
  margin: 1rem;
`;

const EmptyTableSubTitle = styled(MUITypography)`
  margin-bottom: 1.5rem;
`;
