import type { DiscountedPartPrice, PartPrice } from '@samsonvt/shared-types/partsTable';

interface PORCheckable {
  unitPrice: PartPrice;
}

export interface PORCalculable {
  unitPrice: DiscountedPartPrice;
  quantity: number;
}

export default function isPOR(items: PORCheckable[]) {
  return items.some((item: PORCheckable) => item.unitPrice === 'por');
}

export function calculateTotalWithPOR(items: PORCalculable[]): DiscountedPartPrice {
  return isPOR(items)
    ? 'por'
    : items.reduce((acc, item) => acc + (item.quantity as number) * (item.unitPrice as number), 0);
}
