import { styled } from '@mui/material';
import { StyledMUIPrimaryButton } from 'src/components/Button';

export const MobileContentContainer = styled('div')`
  width: 80%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MobileAccountDetailsFormButton = styled(StyledMUIPrimaryButton)`
  width: fit-content;
  margin-top: 1rem;
  padding: 1.25rem 1.5rem;
`;
