import { useQueryClient } from '@tanstack/react-query';
import { useProduct } from 'src/providers/Product';

export const useInvalidateProductQuery = () => {
  const queryClient = useQueryClient();
  const { productId } = useProduct();

  const invalidateProductQuery = () => {
    queryClient.invalidateQueries(['product', productId]);
  };

  return {
    invalidateProductQuery,
  };
};
