import { Link } from 'react-router-dom';
import { alpha, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';

import { Icon } from 'src/components/Icon';
import { getFormattedRepairTime } from 'src/services/utils';
import { spacing } from 'src/constants/spacing';
import { device } from 'src/constants/mediaQueries';
import { font } from 'src/constants/fontSizes';
import type { IRepairSectionView, IRepairStepView } from 'src/providers/repairs/types';

import { RepairStep } from 'src/components/Product/Desktop/Panels/WorkInstructions/RepairStep';

const sectionNumberLineHeight = '3.2rem';

const StepList = styled('ol')`
  padding: 0;
  list-style: none;
  margin-bottom: 0;
`;

const SectionNumber = styled('h1')`
  font-weight: normal;
  font-size: ${font.xxLarge};
  line-height: ${sectionNumberLineHeight};
  margin: 0;
  user-select: none;
`;

const SectionDetails = styled('div')`
  flex-grow: 1;
  padding: 0 ${spacing.smallSpacing};
`;

const SectionName = styled('h3')`
  font-size: ${font.medium};
  font-weight: normal;
  margin: 0;
  text-transform: uppercase;
`;

const SectionDuration = styled('h4')`
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey['500']};
  margin: 0;

  svg {
    vertical-align: middle;
    margin-right: ${spacing.xSmallSpacing};
  }
`;

const StepCount = styled('h3')`
  font-weight: normal;
  color: ${({ theme }) => theme.palette.grey['500']};
  margin: 0;
  font-size: ${spacing.mediumSpacing};
  user-select: none;
`;

const SectionContentLink = styled(Link)`
  display: flex;
  padding: ${spacing.mediumSpacing};
  background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.6)};

  :not(.current) {
    :hover {
      background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.7)};
      transition: all ease 0.3s;
    }
    cursor: pointer;
  }

  .section-name,
  .section-number,
  .section-duration,
  .step-count {
    color: ${({ theme }) => alpha(theme.palette.primary.contrastText, 0.5)};
  }

  &.current {
    cursor: default;
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.4)};

    .section-name,
    .section-number {
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    .section-duration,
    .step-count {
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    & + ol {
      background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.4)};
    }
  }
`;

const RepairSectionContainer = styled('li')`
  border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.primary.main, 0.2)};

  @media ${device.smallLaptop} {
    font-size: ${font.small};
  }
`;

const CompletedIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: ${spacing.largeSpacing};
  height: ${spacing.largeSpacing};
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: ${spacing.xSmallSpacing};
`;

interface IProps {
  section: IRepairSectionView;
  index: number;
  currentSectionNumber: number;
  currentStepNumber: number;
  isOneWorkingInstruction: boolean;
}

export function RepairSection({
  section,
  index,
  currentSectionNumber,
  currentStepNumber,
  isOneWorkingInstruction,
}: IProps) {
  const isCurrentSection = currentSectionNumber === index;
  const isPreviousSection = currentSectionNumber > index;
  // eslint-disable-next-line no-nested-ternary
  const currentStepCount = isCurrentSection
    ? currentStepNumber + 1
    : currentStepNumber > index
    ? section.steps.length
    : 0;

  return (
    <RepairSectionContainer>
      <SectionContentLink
        to={section.url}
        replace
        className={isCurrentSection ? 'current' : ''}
        data-testid="repair-section"
      >
        {isOneWorkingInstruction ? null : (
          <SectionNumber className="section-number" data-testid="repair-section-number">
            {`${index + 1}`.padStart(2, '0')}
          </SectionNumber>
        )}
        <SectionDetails>
          <SectionName className="section-name" data-testid="repair-section-name">
            {section.name}
          </SectionName>
          {!!section.duration && (
            <SectionDuration className="section-duration" data-testid="repair-section-duration">
              <FontAwesomeIcon icon={faClock} />
              {getFormattedRepairTime(section.duration)}
            </SectionDuration>
          )}
        </SectionDetails>
        <StepCount className="step-count" data-testid="repair-step-count">
          {isPreviousSection ? (
            <CompletedIcon>
              <Icon name="COMPLETED" label="Completed" />
            </CompletedIcon>
          ) : (
            `${currentStepCount}/${section.steps.length}`
          )}
        </StepCount>
      </SectionContentLink>
      {isCurrentSection && (
        <StepList data-testid="repair-steps">
          {section.steps.map((step: IRepairStepView, stepIndex) => (
            <RepairStep key={step.url} step={step} index={stepIndex} currentStepNumber={currentStepNumber} />
          ))}
        </StepList>
      )}
    </RepairSectionContainer>
  );
}
