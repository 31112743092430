import { alpha, styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import type { Supersession as SupersessionsType, Supersessions } from '@samsonvt/shared-types/partsTable';
import { PaddedBlock } from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import { dateLabel } from 'src/services/formatDate';

interface SuperSessionsProps {
  supersessions: Supersessions;
  supersessionParameterInQueryString?: string;
}

const Column = styled(MUIBox)<{ highlightedAsASearchResult: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1.2rem 1rem 0.5rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: ${({ highlightedAsASearchResult, theme }) => highlightedAsASearchResult && theme.palette.secondary.dark};
`;

const ReverseSpacer = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const Notes = styled('div')`
  font-size: 0.875rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PartNumber = styled('div')`
  font-weight: bold;
`;

const Dates = styled('div')`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.7)};
`;

export default function SupersessionsTab({ supersessions, supersessionParameterInQueryString }: SuperSessionsProps) {
  if (!supersessions.length) {
    return <PaddedBlock>This part does not have any supersessions</PaddedBlock>;
  }

  return (
    <>
      {supersessions.map((s) => (
        <Supersession
          key={s.partNumber}
          {...s}
          highlightedAsASearchResult={supersessionParameterInQueryString === s.partNumber}
        />
      ))}
    </>
  );
}

function Supersession({
  partNumber,
  dateFrom,
  dateTo,
  notes,
  highlightedAsASearchResult,
}: SupersessionsType & { highlightedAsASearchResult: boolean }) {
  return (
    <Column highlightedAsASearchResult={highlightedAsASearchResult}>
      <PartNumber>{partNumber}</PartNumber>
      <Notes>{notes}</Notes>
      <ReverseSpacer>
        <Dates data-testid={`supersession-${partNumber}-date`}>{dateLabel(dateFrom, dateTo)}</Dates>
      </ReverseSpacer>
    </Column>
  );
}
