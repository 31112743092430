import type { PartNode, ProductAPIResponse } from '@samsonvt/shared-types/productLambda';
import { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { getProduct } from 'src/services/api';
import { PartPathWithHash } from 'src/services/partPath';
import { flattenAndHash } from 'src/services/partUtils';
import flattenParts from './flattenParts';

interface IProductParams {
  productId: string;
}

export class Product {
  public partPaths: PartPathWithHash[];

  public currentProduct: ProductAPIResponse;

  public toggleVariantNames: (previouslySelected: string[], selected: string) => void;

  public hiddenVariantNames?: string[];

  get productId(): string {
    return this.currentProduct.productId;
  }

  get name(): string | undefined {
    // TODO: Can there be a product without a name? (eliminate | undefined and throw maybe?)
    return this.currentProduct.name;
  }

  get bom(): PartNode | undefined {
    return this.currentProduct.bom;
  }

  get enabledFeatures(): ProductFeature[] | undefined {
    return this.currentProduct.enabledFeatures;
  }

  constructor(
    product: ProductAPIResponse,
    partPaths: PartPathWithHash[],
    toggleVariantNames: (previouslySelected: string[], selected: string) => void,
    hiddenVariantNames?: string[]
  ) {
    this.currentProduct = product;
    this.partPaths = partPaths || [];
    this.toggleVariantNames = toggleVariantNames;
    this.hiddenVariantNames = hiddenVariantNames;
  }

  getPartsById: (id: string) => PartPathWithHash[] = (id) => this.partPaths.filter((path) => path.part.id === id);
  getPartsByPartNumber: (partNumber: string) => PartPathWithHash[] = (partNumber) =>
    this.partPaths.filter((path) => path.part.partNumber === partNumber);
}

export const ProductContext = createContext({} as Product);

export function ProductProvider({ children }: any) {
  const [chosenVariantNames, setChosenVariantNames] = useState<string[]>([]);

  const toggleVariantNames = (previouslySelected: string[], selected: string) =>
    setChosenVariantNames((oldNames) => {
      const withoutPreviouslySelected = arrayWithoutItems(oldNames, previouslySelected);
      return [...withoutPreviouslySelected, selected];
    });

  const { productId } = useParams<IProductParams>();

  const productQuery = () => getProduct(productId);

  const { data: product, error } = useQuery<ProductAPIResponse>(['product', productId], productQuery);

  const parts = product && product.bom ? flattenAndHash(product.bom) : [];

  if (error) return <Redirect to="/product-library" />;
  if (product && product.productId === productId) {
    const hiddenVariantNames = product.bom
      ? flattenParts(product.bom)
          .map((optionGroup) => {
            const optionsNotChosenByTheUser = optionGroup.children.filter(
              (variant) => !chosenVariantNames.includes(variant.id)
            );
            if (optionsNotChosenByTheUser.length === optionGroup.children.length) {
              return filterOutFirst(optionGroup.children);
            }
            return optionsNotChosenByTheUser;
          })
          .flat()
          .map((node) => node.object3DName)
          .filter((item): item is string => !!item)
      : [];

    // useMemo can't be called conditionally, assuming it's better to only build product after it's bein fetched then to memoize it.
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const values = new Product(product as ProductAPIResponse, parts, toggleVariantNames, hiddenVariantNames);
    return <ProductContext.Provider value={values}> {children} </ProductContext.Provider>;
  }
  return <FullscreenLoadingSpinner />;
}

export const useProduct = (): Product => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('`useProduct` hook must be used within a `ProductProvider` component');
  }
  return context;
};

function filterOutFirst<Type>(array: Type[]) {
  return array.filter((_item, index) => index !== 0);
}

function arrayWithoutItems<Type>(arr: Type[], items: Type[]) {
  return arr.filter((item) => !items.includes(item));
}
