import { faCartCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled } from '@mui/material';

import MUITypograhy from '@mui/material/Typography';

const MyOrdersHeader = styled(MUITypograhy)`
  color: ${({ theme }) => theme.palette.grey[900]};
  margin: 2rem 0 2rem 0;
`;

MyOrdersHeader.defaultProps = { variant: 'h5' };

export default function MyOrdersTableDescription() {
  return (
    <MyOrdersHeader>
      <FontAwesomeIcon icon={faCartCircleCheck} size="lg" /> My Orders
    </MyOrdersHeader>
  );
}
