import { Box, styled } from '@mui/material';
import { useContext } from 'react';
import { ShoppingCartPanelContext } from 'src/providers/ShoppingCart';

type Px = number & {}; // & {} prevents resolving type to number, see https://github.com/microsoft/TypeScript/issues/31940
const drawerBleeding: Px = 10;

export default function Puller() {
  const { panelOpen, setPanelOpen } = useContext(ShoppingCartPanelContext);

  return (
    <VerticalBar onClick={() => setPanelOpen((open: boolean) => !open)}>
      <Handle data-testid="shopping-cart-panel-puller-button">
        {panelOpen ? (
          <svg width="21" height="45" viewBox="0 0 21 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 15.4203C0 13.9324 0.825908 12.5674 2.14397 11.877L15.144 5.06744C17.8073 3.67236 21 5.60418 21 8.61077V38.3892C21 41.3958 17.8073 43.3276 15.144 41.9326L2.14397 35.123C0.825908 34.4326 0 33.0676 0 31.5797V15.4203Z"
              fill="black"
            />
            <path
              d="M11.1016 22.1484L5.85156 16.8984C5.52344 16.543 4.94922 16.543 4.62109 16.8984C4.26562 17.2266 4.26562 17.8008 4.62109 18.1289L9.24219 22.75L4.62109 27.3984C4.26562 27.7266 4.26562 28.3008 4.62109 28.6289C4.78516 28.793 5.00391 28.875 5.25 28.875C5.46875 28.875 5.6875 28.793 5.85156 28.6289L11.1016 23.3789C11.457 23.0508 11.457 22.4766 11.1016 22.1484ZM16.3516 22.1484L11.1016 16.8984C10.7734 16.543 10.1992 16.543 9.87109 16.8984C9.51562 17.2266 9.51562 17.8008 9.87109 18.1289L14.4922 22.75L9.87109 27.3984C9.51562 27.7266 9.51562 28.3008 9.87109 28.6289C10.0352 28.793 10.2539 28.875 10.5 28.875C10.7188 28.875 10.9375 28.793 11.1016 28.6289L16.3516 23.3789C16.707 23.0508 16.707 22.4766 16.3516 22.1484Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg width="21" height="45" viewBox="0 0 21 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 15.4203C0 13.9324 0.825908 12.5674 2.14397 11.877L15.144 5.06744C17.8073 3.67236 21 5.60418 21 8.61077V38.3892C21 41.3958 17.8073 43.3276 15.144 41.9326L2.14397 35.123C0.825908 34.4326 0 33.0676 0 31.5797V15.4203Z"
              fill="black"
            />
            <path
              d="M6.48047 22.75L11.1016 18.1289C11.457 17.8008 11.457 17.2266 11.1016 16.8984C10.7734 16.543 10.1992 16.543 9.87109 16.8984L4.62109 22.1484C4.26562 22.4766 4.26562 23.0508 4.62109 23.3789L9.87109 28.6289C10.0352 28.793 10.2539 28.875 10.5 28.875C10.7188 28.875 10.9375 28.793 11.1016 28.6289C11.457 28.3008 11.457 27.7266 11.1016 27.3984L6.48047 22.75ZM11.7305 22.75L16.3516 18.1289C16.707 17.8008 16.707 17.2266 16.3516 16.8984C16.0234 16.543 15.4492 16.543 15.1211 16.8984L9.87109 22.1484C9.51562 22.4766 9.51562 23.0508 9.87109 23.3789L15.1211 28.6289C15.2852 28.793 15.5039 28.875 15.75 28.875C15.9688 28.875 16.1875 28.793 16.3516 28.6289C16.707 28.3008 16.707 27.7266 16.3516 27.3984L11.7305 22.75Z"
              fill="white"
            />
          </svg>
        )}
      </Handle>
    </VerticalBar>
  );
}

const Handle = styled(Box)`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 10px 5px;
  cursor: pointer;
`;

const VerticalBar = styled(Box)`
  width: ${drawerBleeding}px;
  background-color: black;
  position: absolute;
  left: -${drawerBleeding}px;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;
