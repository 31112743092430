import { Uploader } from 'src/components/Uploader';
import { MainTemplate } from 'src/templates/MainTemplate';
import { ContentPadding } from 'src/components/ContentPane';
import { Header } from './Header';

export function UploadPage() {
  return (
    <MainTemplate data-testid="upload-page">
      <ContentPadding>
        <Header />
        <Uploader />
      </ContentPadding>
    </MainTemplate>
  );
}
