import { styled } from '@mui/material';

import MUISelect from '@mui/material/Select';

export const StyledSelectField = styled(MUISelect)`
  &.MuiFilledInput-root {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  &.MuiFilledInput-root::before {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[900]};
  }

  &.MuiFilledInput-root::after:not(error) {
    border-bottom: 2px solid ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiSvgIcon-root {
    background-color: ${({ theme }) => theme.palette.grey[900]};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.grey[900]};
  }
`;
StyledSelectField.defaultProps = {
  size: 'small',
  variant: 'filled',
};
