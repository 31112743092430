/* eslint-disable jsx-a11y/label-has-associated-control */
/* Rule disabled to clean console output and improve developer experience when writing new code. Should be reviewed */
import { useState, useEffect, FormEvent } from 'react';
import { styled } from '@mui/material';

import { H3 } from 'src/components/Type';
import { ButtonWrapper, ConfirmContainer, ModalButton, CancelContainer } from 'src/components/Modal/ModalElements';
import { spacing } from 'src/constants/spacing';
import { font } from 'src/constants/fontSizes';
import { border, radius } from 'src/constants/borderSizes';
import { Modal } from 'src/components/Modal';

import { IProductFile } from '.';

interface IProps {
  detailFiles: IProductFile[];
  cancel: (index: number) => void;
  close: () => void;
}

const ModalInput = styled('input')`
  padding: ${spacing.xSmallSpacing};
  border: solid ${border.xSmall} ${({ theme }) => theme.palette.grey['500']};
  border-radius: ${radius.medium};
  font-size: ${font.small};
  color: ${({ theme }) => theme.palette.common.black};
  font-family: Arial, sans-serif;
  display: block;
  max-width: 100%;
  min-width: 100%;

  :focus {
    border-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export function UploaderDetailsModal({ detailFiles, cancel, close }: IProps) {
  const [currentFile, setCurrentFile] = useState(0);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const submitDetails = (event: FormEvent) => {
    detailFiles[currentFile].productName = name;
    detailFiles[currentFile].productId = id;
    detailFiles[currentFile].description = description;
    setId('');
    setName('');
    setDescription('');
    if (currentFile + 1 < detailFiles.length) {
      setCurrentFile(currentFile + 1);
    } else {
      close();
    }
    event.preventDefault();
  };

  useEffect(() => {
    setCurrentFile(0);
  }, [detailFiles]);
  return (
    <Modal isOpen={detailFiles.length !== 0}>
      <form data-testid="uploader-details-modal" onSubmit={submitDetails}>
        <H3>Upload Details</H3>
        <p>File: {currentFile < detailFiles.length && detailFiles[currentFile].name}</p>
        <p>Please enter the name and ID of the product.</p>
        <label>Name:</label>
        <ModalInput data-testid="product-name-input" value={name} onChange={(e) => setName(e.target.value)} required />
        <label>ID:</label>
        <ModalInput data-testid="product-id-input" value={id} onChange={(e) => setId(e.target.value)} required />
        <label>Description:</label>
        <ModalInput
          data-testid="product-description-input"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required={false}
          as="textarea"
        />

        <ButtonWrapper>
          <ConfirmContainer>
            <ModalButton type="submit" data-testid="submit-details-button">
              Add Details
            </ModalButton>
          </ConfirmContainer>
          <CancelContainer>
            <ModalButton
              data-testid="cancel-details-button"
              color="warning"
              onClick={(event) => {
                event.preventDefault();
                cancel(detailFiles.length - currentFile);
              }}
              formNoValidate
            >
              Cancel
            </ModalButton>
          </CancelContainer>
        </ButtonWrapper>
      </form>
    </Modal>
  );
}
