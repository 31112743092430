import { faUserPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyProfileProps } from '../../AccountDetailsPage';
import MyProfileForm from './MyProfileForm';
import { MyProfileContainer, MyProfileTitle, MyProfileTitleContainer } from './styles';

export default function MyProfile({
  userDetailsFormState,
  handleMyProfileFormChange,
  submitMyProfileForm,
  userEmail,
  isApplyUserLoading,
  countryList,
}: MyProfileProps) {
  return (
    <MyProfileContainer>
      <MyProfileTitleContainer>
        <FontAwesomeIcon icon={faUserPen} size="2x" />
        <MyProfileTitle>My profile</MyProfileTitle>
      </MyProfileTitleContainer>
      <MyProfileForm
        userDetailsFormState={userDetailsFormState}
        handleMyProfileFormChange={handleMyProfileFormChange}
        submitMyProfileForm={submitMyProfileForm}
        userEmail={userEmail}
        isApplyUserLoading={isApplyUserLoading}
        countryList={countryList}
      />
    </MyProfileContainer>
  );
}
