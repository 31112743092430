import { PORComponent, PORProps } from 'src/components/POR';
import type { DiscountedPartPrice, PartPrice } from '@samsonvt/shared-types/partsTable';
import { useUser } from 'src/providers/User';
import { isFeatureSupported } from 'src/Feature';
import { AvailableISO639ForTranslation } from '@samsonvt/shared-types/accountsTable';

export type ISO4217 = string;
const currencyToLocaleMap: Record<ISO4217, AvailableISO639ForTranslation> = {
  EUR: 'de-de',
  GBP: 'en-gb',
  USD: 'en-us',
};
const getLocaleFromCurrency = (currency: ISO4217) => currencyToLocaleMap[currency];

export function getCurrencySymbol(currencyCode: ISO4217 | undefined) {
  if (currencyCode !== undefined) {
    const currencySymbol = Intl.NumberFormat(getLocaleFromCurrency(currencyCode), {
      style: 'currency',
      currency: currencyCode,
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency');

    return currencySymbol?.value;
  }
  return '£';
}

export const formatCurrency = ({ price, currency: currencyToUse, ...porProps }: FormattedPriceParams) => {
  const currency = currencyToUse ?? 'GBP';

  if (price !== undefined) {
    if (price === 'por') {
      return <PORComponent {...porProps} />;
    }

    if (Number.isNaN(price)) {
      return `${getCurrencySymbol(currencyToUse)}--.--`;
    }

    const formatter = Intl.NumberFormat(getLocaleFromCurrency(currency), {
      style: 'currency',
      currency,
    });

    return formatter.format(price);
  }
  return `${getCurrencySymbol(currencyToUse)}--.--`;
};

type FormattedPriceParams = {
  price: PartPrice;
  currency?: ISO4217;
} & PORProps;

export default function useFormattedPrice({ price, currency, ...porProps }: FormattedPriceParams) {
  const { enabledFeatures, preferredCurrency } = useUser();
  const currencyToUse = currency || preferredCurrency || 'GBP';
  return !isFeatureSupported('shopping-cart', enabledFeatures)
    ? ''
    : formatCurrency({ price, currency: currencyToUse, ...porProps });
}

export const calculateDiscountedPrice = (
  price: NonNullable<PartPrice>,
  discountPercentage?: number
): DiscountedPartPrice => {
  const multiplier = discountPercentage ? (100 - discountPercentage) / 100 : 1;
  if (typeof price === 'number') return price * multiplier;
  return price;
};
