import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useFrame, invalidate, Camera } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import { Vector3Tuple } from 'three';

export function useCameraControls(
  transformControlsEnabled: boolean,
  orbitCenter: Vector3Tuple,
  position: Vector3Tuple,
  camera: Camera,
  setCameraCoords: (a: Vector3Tuple) => void,
  setCameraHasMovedToNewPositionByProps: Dispatch<SetStateAction<boolean>>
) {
  const ref = useRef<CameraControls>(null!);

  useEffect(() => {
    // Attach transform controls
    const onCameraPositionChange = () => {
      if (transformControlsEnabled) {
        setCameraCoords(camera.position.toArray());
      }
    };
    ref.current.addEventListener('update', onCameraPositionChange);
  }, [camera, setCameraCoords, transformControlsEnabled]);

  useEffect(() => {
    ref.current.moveTo(...orbitCenter, false);
    ref.current.setPosition(...position, false);
    // Invalidate on control events; for some reason controls.update give false on wheel scroll... https://github.com/yomotsu/camera-controls/issues/234
    ref.current.addEventListener('control', () => invalidate());
    // Set position without animation on start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref.current.moveTo(...orbitCenter, true);
    ref.current.setPosition(...position, true).then(() => setCameraHasMovedToNewPositionByProps(true));
    // When position changes unset state
    return () => setCameraHasMovedToNewPositionByProps(false);
    // Animate on subsequent updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, orbitCenter]);

  useFrame(
    (_state, delta) => ref.current.update(Math.min(delta, 0.2)) && invalidate()
    // Very high delta that we have often because of in demand loop breaks animation
  );

  const resetCamera = () => {
    ref.current.setLookAt(...position, ...orbitCenter, false);
  };

  return { cameraRef: ref, resetCamera };
}
