import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUIBox from '@mui/material/Box';
import MUITypography from '@mui/material/Typography';

export const OrderDetailsHeader = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.grey[900]};
  min-width: 820px;
`;

export const OrderDetailsTitleContainer = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const OrderDetailsButtonsContainer = styled(MUIBox)`
  display: flex;
`;

export const OrderDetailsMainContent = styled(MUIBox)`
  display: flex;
  justify-content: space-around;
  min-width: 820px;
  flex-grow: 1;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-bottom: 5rem;
`;

export const OrderDetailsLeftPanel = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-right: 1rem;
  min-width: 400px;
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  overflow: auto;
  border-radius: 4px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome Safari Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const OrderDetailsPanel = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  flex-grow: 1;
  min-width: 400px;
  max-height: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
`;
export const OrderDetailsSection = styled(MUIBox)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const OrderDetailsPanelTitle = styled(MUITypography)`
  margin: 2rem 2rem 0 2rem;
`;
OrderDetailsPanelTitle.defaultProps = { variant: 'h6', fontWeight: 'bold' };

export const OrderDetailsData = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem 1rem 2rem;
`;

export const OrderDetailsDataRow = styled(MUIBox)`
  display: flex;
`;

export const OrderDetailsDataHeader = styled(MUITypography)`
  margin: 0.15rem 0;
  width: 50%;
`;

export const OrderDetailsDataCell = styled(MUITypography)`
  width: 50%;
  margin: 0.15rem 0;
  overflow-wrap: break-word;
`;
OrderDetailsDataCell.defaultProps = { fontWeight: 'bold' };

export const OrderDetailsDivider = styled(MUIDivider)`
  width: 90%;
  height: 2px;
  align-self: center;
  border-color: ${({ theme }) => theme.palette.grey[300]};
`;

export const TotalDivider = styled(MUIDivider)`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[900]};
  align-self: flex-end;
`;
