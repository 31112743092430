import axios from 'axios';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { getOrders } from 'src/services/api';
import { useQuery } from '@tanstack/react-query';

import { FilterTypes, getFilterFunc, getSortingFunc } from '../../filters';
import ReceivedOrdersTable from './ReceivedOrdersTable';

export default function ReceivedOrdersTableLogic() {
  const [completedFilterStatus, setCompletedFilterStatus] = useState(FilterTypes.ALL_ORDERS);
  const [timeFilterStatus, setTimeFilterStatus] = useState(FilterTypes.MOST_RECENT);
  const [orderIdSearchFilter, setOrderIdSearchFilter] = useState('');

  const receivedOrdersQuery = () => {
    const { token } = axios.CancelToken.source();
    return getOrders(token);
  };

  const {
    isLoading: isLoadingOrders,
    data: unFilteredOrders,
    isError: isErrorLoadingOrders,
  } = useQuery(['receivedOrdersList'], receivedOrdersQuery);

  const sortFunc = getSortingFunc(timeFilterStatus);
  const filterFunc = getFilterFunc(completedFilterStatus);
  const filteredOrders = [...(unFilteredOrders || [])]
    .sort(sortFunc)
    .filter(filterFunc)
    .filter((order) => order.orderId.includes(orderIdSearchFilter));

  const areThereOrdersWithSearchCriteria = filteredOrders.length !== 0;
  const isTheTableEmpty = !isLoadingOrders && !areThereOrdersWithSearchCriteria;
  const areTheOrdersAllLoaded = areThereOrdersWithSearchCriteria;

  const handleCompletedFilter = (e: SelectChangeEvent<unknown>) => {
    setCompletedFilterStatus(e.target.value as string);
  };

  const handleTimeFilter = (e: SelectChangeEvent<unknown>) => {
    setTimeFilterStatus(e.target.value as string);
  };

  const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderIdSearchFilter(e.target.value.toUpperCase());
  };

  return (
    <ReceivedOrdersTable
      filteredOrders={filteredOrders}
      areTheOrdersAllLoaded={areTheOrdersAllLoaded}
      isErrorLoadingOrders={isErrorLoadingOrders}
      isLoadingOrders={isLoadingOrders}
      isTheTableEmpty={isTheTableEmpty}
      completedFilterStatus={completedFilterStatus}
      timeFilterStatus={timeFilterStatus}
      orderIdSearchFilter={orderIdSearchFilter}
      handleCompletedFilter={handleCompletedFilter}
      handleTimeFilter={handleTimeFilter}
      handleSearchFilter={handleSearchFilter}
    />
  );
}
