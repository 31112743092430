import { useState } from 'react';

import { error as logError } from 'src/services/log';

import { StyledMUIButton } from '../Button';
import { LoginTextInput } from '../TextInput/LoginTextInput';
import { Toast, useTrigger } from '../Toast';
import { SendEmailResetTitle, StyledButtonContainer } from './styles';
import { SendPasswordResetFormData, SendPasswordResetFormProps } from './types';

export function SendPasswordResetForm({ setLoading, forgotPassword, setResetEmailSent }: SendPasswordResetFormProps) {
  const [data, setData] = useState<SendPasswordResetFormData>({
    email: '',
  });

  const [successToast, setSuccessToast] = useTrigger();
  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setToastErrorMessage] = useState<string | undefined>();

  const validateBeforeSending = () => {
    const error = new Error();
    if (!data.email) {
      error.name = 'FieldsEmpty';
      throw error;
    }
  };

  const showErrorToast = (message: string) => {
    setLoading(false);
    setToastErrorMessage(message);
    setErrorToast();
  };

  const showSuccessToast = () => {
    setLoading(false);
    setSuccessToast();
  };

  const onButtonClick = async (e: any, data: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      validateBeforeSending();
      await forgotPassword(data.email);
      showSuccessToast();
      setResetEmailSent(true);
    } catch (e: any) {
      logError(e);

      switch (e.name) {
        case 'FieldsEmpty':
          showErrorToast('Fields cannot be empty');
          break;
        case 'InvalidEmailFormat':
          showErrorToast('The email provided is not valid');
          break;
        default:
          showErrorToast(e.toString());
          break;
      }
    }
  };

  return (
    <>
      <form onSubmit={(e) => onButtonClick(e, data)}>
        <SendEmailResetTitle>Type your email to reset your password:</SendEmailResetTitle>
        <div>
          <LoginTextInput
            label="Email"
            type="email"
            value={data.email}
            onChange={(e: any) => setData((prevData) => ({ ...prevData, email: e.target.value }))}
            error={!!errorToast}
          />
        </div>

        <StyledButtonContainer>
          <StyledMUIButton type="submit" data-testid="login-submit" fullWidth>
            Reset password
          </StyledMUIButton>
        </StyledButtonContainer>
      </form>

      <Toast
        dependency={successToast}
        severity="success"
        title="Success"
        message={`We have emailed you a link to reset your password to ${data.email}.`}
      />
      <Toast dependency={errorToast} severity="error" title="Error" message={errorToastMessage || ''} />
    </>
  );
}
