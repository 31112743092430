import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, styled } from '@mui/material';

import { useState } from 'react';
import { WhiteTextField } from './WhiteTextField';

interface PasswordTextInputProps {
  label: string;
  value: string;
  isRequired?: boolean;
  onChange: any;
  error?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  match?: boolean;
  testId?: string;
}

const WhitePasswordInput = styled(WhiteTextField, { shouldForwardProp: (prop) => prop !== 'match' })<{
  match?: boolean;
}>`
  height: 56px;
  margin: 1rem 0;

  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiInputAdornment-root > button {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  & .MuiFormHelperText-root {
    background-color: ${({ theme, match }) => (match ? theme.palette.success.main : theme.palette.error.main)};
    padding-left: 1rem;
    margin: 4px 0 0 0;
    color: ${({ theme }) => theme.palette.common.black};
  }

  & .MuiFormHelperText-root {
    color: ${({ theme }) =>
      theme.palette.common
        .black} !important; // done to avoid MUI error text colour on red background in the match indicator;
  }
`;

export function WhitePasswordInputWithMatchIndicator({
  label,
  value,
  isRequired = false,
  onChange,
  error,
  match,
  testId,
}: PasswordTextInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const determineMatchText = (value: string, match: boolean | undefined) => {
    if (match !== undefined) {
      return match ? 'Passwords match' : "Passwords don't match";
    }
    return undefined;
  };

  return (
    <WhitePasswordInput
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      required={isRequired}
      helperText={determineMatchText(value, match)}
      match={match}
      InputProps={{
        autoComplete: 'current-password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              aria-label="toggle password visibility"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        'data-testid': testId,
      }}
      error={!!error}
    />
  );
}

export function WhitePasswordInputWithShowHide({
  label,
  value,
  isRequired = false,
  onChange,
  error,
  testId,
}: PasswordTextInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <WhitePasswordInput
      label={label}
      value={value}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      required={isRequired}
      InputProps={{
        autoComplete: 'current-password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              aria-label="toggle password visibility"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        'data-testid': testId,
      }}
      error={!!error}
    />
  );
}
