import { styled } from '@mui/material';
import { WhiteTextField } from './WhiteTextField';

// this removes all of the padding to accommodate the label

export const WhiteTextFieldWithoutLabel = styled(WhiteTextField)`
  input {
    height: ${({ size }) => (size === 'small' ? '48px' : '56px')}; // standard MUI height for inputs

    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
