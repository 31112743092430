import { styled } from '@mui/material';
import { IProductFile } from 'src/components/Uploader';
import { FileToUpload } from './FileToUpload';

const FilesToUploadWrapper = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;

  :after {
    content: '';
    display: block;
    clear: both;
  }
`;

interface IProps {
  files: IProductFile[];
  removeFile?: (index: number) => void;
}

export function FilesToUpload({ files, removeFile }: IProps): JSX.Element {
  return (
    <FilesToUploadWrapper>
      {files.map((file, index) => (
        <FileToUpload
          key={file.name}
          index={index}
          name={file.name}
          removeFile={removeFile}
          percentUploaded={file.percentUploaded}
          uploadState={file.uploadState}
          productName={file.productName}
          productId={file.productId}
        />
      ))}
    </FilesToUploadWrapper>
  );
}
