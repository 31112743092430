import { createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, FetchStatus } from 'src/hooks/useFetch';
import { getRepairs } from 'src/services/api';
import type { RepairListAPIResponse } from '@samsonvt/shared-types/repairLambda';

interface ProductRepairs extends FetchStatus {
  repairs: RepairListAPIResponse | undefined;
}

interface IProductParams {
  productId: string;
}

export const RepairListContext = createContext<ProductRepairs>(undefined! as ProductRepairs);

export function RepairListProvider({ children }: any) {
  const { productId } = useParams<IProductParams>();

  const {
    loading,
    data: repairs,
    error,
  } = useFetch<RepairListAPIResponse>(
    (token) => getRepairs(productId, token),
    [productId],
    `Failed to load work instructions for product: ${productId}`
  );

  const value = useMemo(
    () => ({
      loading,
      repairs,
      error,
    }),
    [loading, repairs, error]
  );

  return <RepairListContext.Provider value={value}>{children}</RepairListContext.Provider>;
}
