import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import React, { createContext, useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useProduct } from 'src/providers/Product';

import type { PartPathWithHash } from './partPath';
import { groupKey, meshKey, useQueryStringValue } from './partUrl';

export type PartsCatalogueParams = {
  productId?: string;
  partId?: string;
};
export class Part {
  partPath?: PartPathWithHash;
  productId?: string;

  get current() {
    return this.partPath?.part;
  }

  get group() {
    return this.current?.focal ? this.current : this.partPath?.group;
  }

  get focalPoint() {
    return this.current?.focal ?? this.group?.focal;
  }

  constructor(partPath: PartPathWithHash | undefined, productId?: string) {
    this.partPath = partPath;
    this.productId = productId;
  }
}

const partsTab: ProductFeature = 'parts-catalogue';

export const PartContext = createContext(new Part(undefined));

export function PartProvider({ children }: { children: React.ReactNode }) {
  const match = useRouteMatch<PartsCatalogueParams>(`/product-library/:productId/${partsTab}/:partId?`);

  const pathHash = useQueryStringValue(groupKey);
  const meshName = useQueryStringValue(meshKey);
  const partId = match?.params.partId && decodeURIComponent(match?.params.partId);
  const { getPartsById, productId } = useProduct();

  const partPaths = partId ? getPartsById(partId) : [];

  const inContext = partPaths.filter((path) => path.pathHash === pathHash);

  const exactMatch = inContext.find((path) => path.part.object3DName === meshName);
  const matchByName = partPaths.find((path) => path.part.object3DName === meshName);

  const partPath = exactMatch ?? matchByName ?? inContext[0] ?? partPaths[0];

  const value = useMemo(() => new Part(partPath, productId), [partPath, productId]);

  return <PartContext.Provider value={value}> {children} </PartContext.Provider>;
}

export const usePart = (): Part => {
  const context = useContext(PartContext);

  if (context === undefined) {
    throw new Error('`usePart` hook must be used within a `PartProvider` component');
  }

  return context;
};
