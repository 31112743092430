import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import { FlexContainer } from 'src/components/FlexContainer';
import { ReactComponent as SamsonLogo } from 'src/assets/logo-default.svg';
import { spacing } from 'src/constants/spacing';

interface ErrorState {
  state: {
    error?: string;
  };
}

const DefaultPage = styled(FlexContainer)`
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${spacing.largeSpacing};
`;

const StyledErrorMessage = styled('span')`
  color: ${({ theme }) => theme.palette.grey['500']};
  margin-bottom: ${spacing.largeSpacing};
  max-width: 800px;
`;

export default function InitialisationFailure() {
  const { state }: ErrorState = useLocation();
  const errorMessage = state?.error || 'There was an error retreiving data. Please contact your administrator.';

  return (
    <DefaultPage data-testid="initialisation-failed-background">
      <h1>{errorMessage}</h1>
      <StyledErrorMessage>
        There was an issue loading data from the above URL. A likely cause for this could be your internal firewall.
        Please contact your administrator for further assistance.
      </StyledErrorMessage>
      <Link to="/product-library" data-testid="not-found-page-link">
        <SamsonLogo />
      </Link>
    </DefaultPage>
  );
}
