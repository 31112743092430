import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from 'src/components/Loading';
import { AllOrdersLoaded, LoadingSpinnerStyles } from './styles';

interface TableFooterProps {
  areTheOrdersAllLoaded: boolean;
  isLoading: boolean;
  filteredOrdersLength: number;
}

export function TableFooter({ isLoading, filteredOrdersLength, areTheOrdersAllLoaded }: TableFooterProps) {
  return (
    <>
      {areTheOrdersAllLoaded && (
        <AllOrdersLoaded listLength={filteredOrdersLength} data-testid="all-loaded">
          <FontAwesomeIcon icon={faCircleCheck} /> All orders loaded ({filteredOrdersLength})
        </AllOrdersLoaded>
      )}
      {isLoading && (
        <LoadingSpinnerStyles
          data-testid="table-loading-spinner"
          isFirstTimeLoading={filteredOrdersLength === 0}
          listLength={filteredOrdersLength}
        >
          <LoadingSpinner height="60px" viewBox="0 35 100 30" />
        </LoadingSpinnerStyles>
      )}
    </>
  );
}
