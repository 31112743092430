import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUITypography from '@mui/material/Typography';
import { styled } from '@mui/material';

import { faHouse } from '@fortawesome/pro-light-svg-icons';

import { MobileTenantSwitcher } from 'src/components/Header/TenantSwitcher';
import { Logo } from '../Logo';
import { XButton } from '../Button/XButton';

interface MobileProductMenuHeaderProps {
  triggerMobileProductMenu: () => void;
  goToLibrary: () => void;
}

export default function MobileProductMenuHeader({
  triggerMobileProductMenu,
  goToLibrary,
}: MobileProductMenuHeaderProps) {
  return (
    <>
      <Header>
        <HeaderToolbar>
          <XButtonStyles>
            <XButton onClick={triggerMobileProductMenu} />
          </XButtonStyles>
          <Logo />
          <HomeStyle>
            <FontAwesomeIcon icon={faHouse} onClick={goToLibrary} />
          </HomeStyle>
        </HeaderToolbar>
      </Header>
      <MobileTenantSwitcher />
    </>
  );
}

const Header = styled(MUITypography)`
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.common.white};
  min-height: 5.875rem;
`;
Header.defaultProps = { variant: 'h5' };

const HeaderToolbar = styled('div')`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: space-around;
  padding-bottom: 0.5rem;
`;

const XButtonStyles = styled('div')`
  line-height: 35px;
  font-size: 2rem;
`;

const HomeStyle = styled('div')`
  line-height: 35px;
`;
