import { NamedLink } from 'src/services/partNavigation';
import { appendDefaultBuyableQuantityToPartUrl } from 'src/services/partUrl';
import { BomItemLink } from './BomItemLink';

interface PartsTabListProps {
  partChildren: NamedLink[];
}

interface NamedLinkWithCountOfDuplicates extends NamedLink {
  quantity: number;
}

export function PartsTabList({ partChildren }: PartsTabListProps) {
  const uniquePartChildrenWithCount = extendNamedLinkWithQuantityOfDuplicates(partChildren);
  return (
    <>
      {uniquePartChildrenWithCount.map((child) => (
        <BomItemLink
          name={child.name}
          url={appendDefaultBuyableQuantityToPartUrl(child.url, child.quantity)} // this is always a string because we check that with uniqBy
          id={child.id}
          data={child.data}
          key={child.url}
          quantity={child.quantity} // this is always a number because we check it above
        />
      ))}
    </>
  );
}

const extendNamedLinkWithQuantityOfDuplicates = (partChildren: NamedLink[]) =>
  partChildren.reduce((accum: NamedLinkWithCountOfDuplicates[], val) => {
    const dupeIndex = accum.findIndex((arrayItem: NamedLink) => {
      if (arrayItem.id && val.id) {
        // use NamedLink.id when it is present (this means part is a variant, otherwise use NamedLink.data.partNumber)
        return arrayItem.id && arrayItem.id === val.id;
      }
      return arrayItem.data && val.data ? arrayItem.data.partNumber === val.data.partNumber : false;
    });

    if (dupeIndex === -1) {
      // Not found, so initialize.
      accum.push({
        quantity: 1,
        ...val,
      });
    } else {
      // Found, so increment counter.
      accum[dupeIndex].quantity += 1;
    }
    return accum;
  }, []);
