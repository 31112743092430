import { EmptyTab } from 'src/components/EmptyTab';
import { Autocomplete } from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete';
import { SidebarNavigation } from 'src/components/Product/Desktop/Panels/PartsCatalogue/SidebarNavigation';
import TabHeader from 'src/components/Product/Desktop/Panels/PartsCatalogue/TabHeader';
import { SearchStyles, ThinDivider } from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import { TabPanelElement } from 'src/components/Product/Shared/TabPanelElement';
import { useQueryString } from 'src/hooks/useQueryString';
import { usePart } from 'src/services/part';
import { usePartNavigation } from 'src/services/partNavigation';
import { usePartsWithPriceOrWithChildren } from 'src/services/usePartsWithPriceOrWithChildren';
import { BuyPartsSection } from './BuyPartSection/BuyPartSection';
import { PartChildren } from './PartChildren';
import { PartDetails } from './PartDetails';
import { PartHistory } from './PartHistory';
import { PartLocations } from './PartLocations';
import { PartOptions } from './PartOptions';

export default function PartsCatalogue() {
  const part = usePart();
  const partNavigation = usePartNavigation();
  const defaultBuyableQuantity = useQueryString('defaultBuyableQuantity');

  const currentPart = part.current || part.partPath?.part;
  const partParents = partNavigation.parents;
  const alphabeticallySortedChildren = partNavigation.children.sort((childA, childB) =>
    childA.name.localeCompare(childB.name)
  );

  const childrenToDisplay = usePartsWithPriceOrWithChildren(alphabeticallySortedChildren);

  if (!currentPart) {
    return <EmptyTab />;
  }

  const hasAnyChildren = Boolean(alphabeticallySortedChildren.length);

  return (
    <TabPanelElement>
      <SearchStyles>
        <Autocomplete />
      </SearchStyles>
      <ThinDivider />
      {partParents?.length > 0 && <SidebarNavigation partParents={partParents} />}
      <TabHeader currentPart={currentPart} />
      <PartOptions currentPart={currentPart} parents={partParents} key={currentPart.id} />
      <BuyPartsSection currentPart={currentPart} defaultBuyableQuantity={defaultBuyableQuantity} />
      <PartDetails currentPart={currentPart} />
      {hasAnyChildren ? (
        <PartChildren childrenToDisplay={childrenToDisplay} />
      ) : (
        <>
          <PartHistory currentPart={currentPart} />
          <PartLocations />
        </>
      )}
    </TabPanelElement>
  );
}
