import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import samsonLogoLarge from 'src/assets/logo-default.svg';
import { faLockKeyholeOpen } from '@fortawesome/pro-light-svg-icons';
import { StyledLogo } from 'src/templates/UnauthenticatedTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SendPasswordResetSent({ logoUrl }: { logoUrl?: string }) {
  return (
    <SendPasswordResetSentContainer>
      <StyledLogo src={logoUrl || samsonLogoLarge} />
      <SendPasswordResetSentTitle>
        Thank you, we have emailed you a link to reset your password.
      </SendPasswordResetSentTitle>
      <SendPasswordResetSentIcon icon={faLockKeyholeOpen} />
      <SendPasswordResetSentBodyBold>Please, check your inbox</SendPasswordResetSentBodyBold>
      <SendPasswordResetSentBody>
        This can take a few minutes, if nothing is received please check your Spam folder.
      </SendPasswordResetSentBody>
    </SendPasswordResetSentContainer>
  );
}

const SendPasswordResetSentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.brand.contrastText};
`;

const SendPasswordResetSentTitle = styled(MUITypography)`
  font-size: 2.125rem;
  margin: 1.25rem 0;
`;

const SendPasswordResetSentIcon = styled(FontAwesomeIcon)`
  height: 90px;
  margin: 1.25rem 0;
`;

const SendPasswordResetSentBodyBold = styled(MUITypography)`
  font-size: 1.25rem;
  width: 70%;
  margin: 1.25rem 0;
  font-weight: bold;
`;

const SendPasswordResetSentBody = styled(MUITypography)`
  font-size: 1rem;
  width: 70%;
  margin: 1.25rem 0;
`;
