import { SetStateAction, Dispatch, useState, useEffect } from 'react';
import axios, { AxiosError, CancelToken } from 'axios';
import { error as logError } from 'src/services/log';

export function usePatch<T>(
  patchFunction: (urlParams: string, body: T, cancelToken?: CancelToken) => Promise<any>,
  onSuccessCallback?: (res: T, setData?: Dispatch<SetStateAction<T | undefined>>, body?: T) => any,
  onFailureCallback?: (err: Error, setData?: Dispatch<SetStateAction<boolean>>, body?: T) => any,
  onFinallyCallback?: () => any,
  errorMessage?: string
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<T | undefined>();
  const source = axios.CancelToken.source();

  const triggerPatchRequest = (urlParams: string, body: T) => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    if (patchFunction) {
      patchFunction(urlParams, body, source.token)
        .then((res) => {
          setError(false);
          setSuccess(true);
          setData(res.data);
          if (onSuccessCallback) onSuccessCallback(res.data, setData, body);
        })
        .catch((err: AxiosError) => {
          setError(true);
          setSuccess(false);
          logError(err as Error, errorMessage || 'Failed to fetch from API');
          if (onFailureCallback) onFailureCallback(err, setError, body);
        })
        .finally(() => {
          setLoading(false);
          if (onFinallyCallback) onFinallyCallback();
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => source.cancel('Cancelling API request'), []);

  return {
    data,
    loading,
    error,
    success,
    triggerPatchRequest,
    setError,
    setLoading,
    setData,
    setSuccess,
  };
}
