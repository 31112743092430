import { styled } from '@mui/material';
import MUIDivider from '@mui/material/Divider';
import MUITypography from '@mui/material/Typography';

import { StyledMUIButtonLink } from '../Button';

export const StyledButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled('a')`
  display: block;
  margin-bottom: 1rem;
  text-align: left;

  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
    text-decoration: none;
  }
`;

export const StyledFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 320px;
`;

export const StyledDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 2px;
  margin-top: 3.8rem;
`;

export const RegisterTitle = styled(MUITypography)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.brand.contrastText};
  text-align: left;
  margin: 1rem 0;
`;

export const CreateAccountButton = styled(StyledMUIButtonLink)`
  background-color: transparent;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.palette.brand.contrastText};
  color: ${({ theme }) => theme.palette.brand.contrastText};
  margin: 0;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.palette.whiteGlass};
  }
`;

export const SendEmailResetTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.brand.contrastText};
  text-align: left;
  margin-bottom: 1rem;
`;
