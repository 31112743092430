import { faCircleInfo, faListTree } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import {
  EmptyPartsTab,
  EmptyPartsText,
  StyledTabContainer,
  StyledTabContentPanel,
} from 'src/components/Product/Mobile/Panels/PartsCatalogue/styles';
import { PartsTab } from 'src/components/Product/Shared/PartsTab/PartsTab';
import { NamedLink } from 'src/services/partNavigation';
import { Tab, Tabs } from './InnerTabs/Tab';

const IconTab = styled(Tab)`
  min-height: 48px;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

export default function SingleTabPartsPanel({ partChildren }: { partChildren: NamedLink[] }) {
  return (
    <StyledTabContainer>
      <Tabs value="parts" data-testid="parts-tab-panel">
        <IconTab label="Parts" value="parts" icon={<FontAwesomeIcon icon={faListTree} />} iconPosition="start" inner />
      </Tabs>
      <StyledTabContentPanel>
        {partChildren.length ? (
          <PartsTab partChildren={partChildren} />
        ) : (
          <EmptyPartsTab>
            <FontAwesomeIcon icon={faCircleInfo} size="2x" />
            <EmptyPartsText>No parts to buy</EmptyPartsText>
          </EmptyPartsTab>
        )}
      </StyledTabContentPanel>
    </StyledTabContainer>
  );
}
