import { useContext } from 'react';
import { ShoppingCartPanelContext } from 'src/providers/ShoppingCart';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { calculateTotalWithPOR } from 'src/services/isPOR';

import MobileShoppingCartPanel from './Mobile';
import { DesktopShoppingCartPanel } from './Desktop';

export default function ShoppingCartPanel({ isMobile }: { isMobile?: boolean }) {
  const { panelOpen, setPanelOpen } = useContext(ShoppingCartPanelContext);
  const { data: cart } = useShoppingCartQuery();
  const cartTotal = calculateTotalWithPOR(cart);

  const triggerPanel = () => setPanelOpen((prevState) => !prevState);

  return isMobile ? (
    <MobileShoppingCartPanel panelOpen={panelOpen} triggerPanel={triggerPanel} cart={cart} cartTotal={cartTotal} />
  ) : (
    <DesktopShoppingCartPanel panelOpen={panelOpen} triggerPanel={triggerPanel} cart={cart} cartTotal={cartTotal} />
  );
}
