import { styled } from '@mui/material';
import MUITab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';

export const Tabs = styled(MUITabs)`
  padding-top: 1.5em;
  .MuiTabs-indicator {
    display: none;
  }
`;

const shouldForwardProp = (prop: string) => prop !== 'inner';

export const Tab = styled(MUITab, { shouldForwardProp })<{ inner?: boolean }>`
  border-top-left-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  border-top-right-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  min-width: auto;
  text-transform: none;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  opacity: 0.5;
  padding: 12px 14px; // MUI uses px
  font-weight: bold;

  &.Mui-selected {
    background: ${({ theme, inner }) => (inner ? theme.palette.whiteGlass : theme.palette.sidebarBackground.main)};
    color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
    opacity: 1;
  }
`;
