import { Object3D, Vector3 } from 'three';

export const translateCoordinates = (transformObject: Object3D, translateTo: number[]) => {
  const currentPosition = transformObject.position;
  const inputVector = new Vector3().fromArray(translateTo);
  const translateBy = new Vector3();
  translateBy.subVectors(inputVector, currentPosition);

  transformObject.translateX(translateBy.x);
  transformObject.translateY(translateBy.y);
  transformObject.translateZ(translateBy.z);

  return transformObject.position.toArray();
};
