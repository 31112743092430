import { styled } from '@mui/material';

import MUITypography from '@mui/material/Typography';
import { EmptyStyledButton } from 'src/components/Button';

export const DiscountManagementContentPadding = styled('div')`
  margin: 2rem 7rem;
  display: flex;
  flex-direction: column;
`;

export const DiscountManagementTitleContainer = styled('div')`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const DiscountManagementTitle = styled(MUITypography)`
  font-size: 1.5rem;
  margin: 1rem;
`;

export const DiscountManagementAddButton = styled(EmptyStyledButton)`
  margin: 0;
`;
