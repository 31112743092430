import { useContext } from 'react';
import { RepairDetailContext } from 'src/providers/repairs/RepairDetails';
import { TabPanelElement } from 'src/components/Product/Shared/TabPanelElement';
import { TrackedRedirect } from 'src/components/TrackedRedirect';
import { RepairText } from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairText';
import { workInstructionsLink } from 'src/utils/urls';
import { useProduct } from 'src/providers/Product';

export function RepairSections() {
  const repairDetails = useContext(RepairDetailContext);

  const currentSectionNumber = repairDetails?.sectionNumber!;
  const currentStepNumber = repairDetails?.stepNumber!;
  const { productId } = useProduct();
  const repairsListUrl = workInstructionsLink(productId);

  if (!repairDetails) return null;

  const {
    repair: { sections, repairId },
  } = repairDetails;

  if (Number.isNaN(currentSectionNumber) || currentSectionNumber > sections.length - 1) {
    return <TrackedRedirect to={repairsListUrl} />;
  }
  if (Number.isNaN(currentStepNumber) || currentStepNumber > sections[currentSectionNumber].steps.length - 1) {
    return <TrackedRedirect to={`${repairsListUrl}/${repairId}/${currentSectionNumber}/0`} />;
  }

  return (
    <TabPanelElement>
      <RepairText />
    </TabPanelElement>
  );
}
