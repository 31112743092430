import { useFetch } from 'src/hooks/useFetch';
import { getBufferFromIndexedDB } from 'src/hooks/useIndexedDBState';
import { MetaObject } from 'src/providers/ShoppingCart';
import * as THREE from 'three';
import Thumbnail3D from '../Thumbnail3D';

export default function PartThumbnail({
  partNumber,
  defaultToSphere = false,
  ...props
}: { partNumber: string; defaultToSphere?: boolean } & React.ComponentProps<typeof Thumbnail3D>) {
  const { data } = useFetch(() => getBufferFromIndexedDB(`svt:part:${partNumber}`));

  if (data) {
    return <Thumbnail3D {...props} metaObject={data} />;
  }

  if (defaultToSphere) {
    return <Thumbnail3D {...props} metaObject={sphere} />;
  }

  return null;
}

const sphere = new THREE.Mesh(
  new THREE.SphereBufferGeometry(1),
  new THREE.MeshLambertMaterial()
).toJSON() as MetaObject;
