import { ShoppingCartItem } from '@samsonvt/shared-types/shoppingCart';
import React from 'react';
// import PartThumbnail from 'src/components/PartThumbnail';
import useFormattedPrice from 'src/services/formatCurrency';
import { calculateTotalWithPOR } from 'src/services/isPOR';
import { QuantityCounter } from './QuantityCounter';
import {
  ContentContainer,
  DeleteButton,
  OneLineEllipsis,
  PaddingTop,
  PositionedCard,
  PriceTag,
  ProductInfo,
  StyledCardHeader,
  ThumbnailStyles,
  TwoLineEllipsis,
} from './styles';

interface CartItemProps extends ShoppingCartItem {
  removeItem: () => void;
  updateItemQuantity: (quantity: number) => void;
}

export default function MobilePanelCartItem({
  partName,
  quantity,
  partNumber,
  unitPrice,
  removeItem,
  updateItemQuantity,
}: CartItemProps) {
  const price = calculateTotalWithPOR([{ quantity, unitPrice }]);

  const addOneMore = () => updateItemQuantity(quantity + 1);
  const removeOneLess = () => updateItemQuantity(quantity ? quantity - 1 : 1);
  const updateWithInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '0' ? '1' : e.target.value;
    updateItemQuantity(parseInt(value));
  };
  const validateValue = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      return;
    }
    updateItemQuantity(1);
  };

  return (
    <PositionedCard data-testid="shopping-cart-panel-item">
      <StyledCardHeader>
        <ThumbnailStyles>
          {/* <PartThumbnail partNumber={partNumber} quantity={quantity} height={100} width={70} transparent />remove for now SVT-2407 */}
        </ThumbnailStyles>
        <ProductInfo>
          <TwoLineEllipsis>
            <strong>{partName}</strong>
          </TwoLineEllipsis>
          <OneLineEllipsis>{partNumber}</OneLineEllipsis>
        </ProductInfo>
      </StyledCardHeader>
      <ContentContainer>
        <PaddingTop>
          <DeleteButton onClick={removeItem}>Delete</DeleteButton>
          <QuantityCounter
            quantity={quantity}
            addOneMore={addOneMore}
            removeOneLess={removeOneLess}
            removeItem={removeItem}
            updateWithInputField={updateWithInputField}
            validateValue={validateValue}
          />
          <PriceTag data-testid="cart-item-panel-price">
            {useFormattedPrice({ price, shortLabel: true, smallFont: true })}
          </PriceTag>
        </PaddingTop>
      </ContentContainer>
    </PositionedCard>
  );
}
