import { Divider } from '@mui/material';
import { PartDetails } from '@samsonvt/shared-types/partService';
import type { PartDetailsForm } from './EditModal';
import { EditPartDetailsLoadingSpinner, StyledMUIDialogContentEditDetails } from './styles';
import Tabs from './Tabs';

interface EditPartsDetailsProps {
  partForm: PartDetailsForm;
  originalPartDetails: PartDetails | undefined;
  isFetchingPartDetails: boolean;
  setPartDetailsForm: React.Dispatch<React.SetStateAction<PartDetailsForm | undefined>>;
}

export function EditPartsDetails({
  partForm,
  originalPartDetails,
  isFetchingPartDetails,
  setPartDetailsForm,
}: EditPartsDetailsProps) {
  return isFetchingPartDetails ? (
    <EditPartDetailsLoadingSpinner />
  ) : (
    <StyledMUIDialogContentEditDetails
      onClick={(e) =>
        // SVT-1927 stops unintended selections on underlying <Canvas/>
        e.stopPropagation()
      }
    >
      <Divider />
      <Tabs {...partForm} originalPartDetails={originalPartDetails} setPartForm={setPartDetailsForm} />
    </StyledMUIDialogContentEditDetails>
  );
}
