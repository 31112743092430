import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import samsonLogoLarge from 'src/assets/logo-default.svg';
import { LocationType, UnauthenticatedContainer, StyledLogo } from 'src/templates/UnauthenticatedTemplate';
import { TenantContext } from 'src/providers/Tenant';
import { ProcessingOverlay } from 'src/components/Loading/ProcessingOverlay';
import { useUser } from 'src/providers/User';
import { StyledHeadingContainer, StyledResetPasswordFormContainer } from 'src/pages/ResetPasswordPage/styles';
import { LoadingSpinner } from 'src/components/Loading';
import { CreatePasswordWithTempCredentialsFormContainer } from './CreatePasswordWithTempCredentialsFormContainer';
import { CreatePasswordWithTempCredentialsQueryParams } from './types';

function CreatePasswordWithTempCredentialsFormPage({
  triggerSuccessToast,
}: {
  triggerSuccessToast: (title: string, message: string) => void;
}) {
  const [createPasswordWithTempCredentialsQueryParams, setCreatePasswordWithTempCredentialsQueryParams] =
    useState<CreatePasswordWithTempCredentialsQueryParams>({
      email: undefined,
    });
  const [loading, setLoading] = useState(false);

  const { createPasswordWithTempCredentials } = useUser();
  const { logoUrl, name: tenantName } = useContext(TenantContext);
  const location = useLocation<LocationType>();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);

    const queryParams: CreatePasswordWithTempCredentialsQueryParams = {
      email: searchParams.get('email') || undefined,
    };

    setCreatePasswordWithTempCredentialsQueryParams(queryParams);
  }, [location]);

  if (!tenantName) {
    return <ProcessingOverlay />;
  }

  return (
    <UnauthenticatedContainer>
      <StyledResetPasswordFormContainer>
        {loading ? <LoadingSpinner width="360px" /> : <StyledLogo src={logoUrl || samsonLogoLarge} />}
        <StyledHeadingContainer>Enter a new password to validate your account:</StyledHeadingContainer>

        <CreatePasswordWithTempCredentialsFormContainer
          setLoading={setLoading}
          createPasswordWithTempCredentialsQueryParams={createPasswordWithTempCredentialsQueryParams}
          createPasswordWithTempCredentials={createPasswordWithTempCredentials}
          history={history}
          triggerSuccessToast={triggerSuccessToast}
        />
      </StyledResetPasswordFormContainer>
    </UnauthenticatedContainer>
  );
}

export default CreatePasswordWithTempCredentialsFormPage;
