import { RowSecondaryButton } from './styles';

export function ResetPasswordButton({
  sendResetPasswordEmail,
  emailToSendTheResetPassword,
}: {
  sendResetPasswordEmail: (email: string) => Promise<void>;
  emailToSendTheResetPassword: string;
}) {
  return (
    <RowSecondaryButton onClick={() => sendResetPasswordEmail(emailToSendTheResetPassword)} color="success">
      Reset password
    </RowSecondaryButton>
  );
}
