import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';

import MUIBox from '@mui/material/Box';
import MUIDivider from '@mui/material/Divider';
import MUIInputLabel from '@mui/material/InputLabel';
import MUIMenuItem from '@mui/material/MenuItem';

import { StyledSelectField } from 'src/components/Select';
import { WhiteTextField } from 'src/components/TextInput/WhiteTextField';

export const MyProfileTitleContainer = styled('div')`
  display: flex;
  margin-bottom: 1rem;
`;

export const MyProfileTitle = styled(MUITypography)`
  margin-left: 1rem;
  font-size: 1.5rem;
`;

export const MyProfileContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

// STUFF FROM CHECKOUT

export const Background = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  flex-grow: 1;
  overflow-y: auto;

  ${({ theme }) => theme.mixins.hideScrollbar}
`;

export const MainContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  flex-grow: 1;
`;

export const MyProfileFormWrapper = styled('form')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  height: 50%;
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  border-radius: 4px;
  padding: 2rem;
`;

export const MyProfileSubTitle = styled(MUITypography)`
  width: 100%;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
export const MyProfileSelectOption = styled(MUIMenuItem)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const MyProfileDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  width: 2px;
  height: 100%;
`;
MyProfileDivider.defaultProps = { orientation: 'vertical' };

export const MyProfileInputField = styled(WhiteTextField)`
  width: 100%;
`;
MyProfileInputField.defaultProps = {
  size: 'small',
  variant: 'filled',
  InputLabelProps: { required: false },
};

export const MyProfileSelectLabel = styled(MUIInputLabel)`
  color: ${({ theme }) => theme.palette.grey[600]};
`;
MyProfileSelectLabel.defaultProps = { required: false };

export const MyProfileSelectField = styled(StyledSelectField)`
  width: 100%;
  height: 100%;

  &.Mui-error::after {
    border-color: ${({ theme }) => theme.palette.error.main} !important;
  }
`;

export function OptionalMyProfileLabel({ label }: { label: string }) {
  return (
    <MUITypography>
      {label} <sup>(optional)</sup>
    </MUITypography>
  );
}

export const Row = styled(MUIBox)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Column = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const SectionsTitlesContainer = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2.5rem;
`;

export const SectionsContainer = styled(Row)`
  justify-content: space-around;
  align-items: center;
  height: 242px;
`;

export const LeftSection = styled(Column)`
  width: 50%;
  height: 100%;
`;

export const RightSection = styled(Column)`
  width: 50%;
  height: 100%;
`;

export const UnEditableInputField = styled('div')`
  width: 100%;
  height: 48px;
  padding-left: 12px;
`;

export const UnEditableInputFieldLabel = styled(MUITypography)`
  width: 100%;
  color: ${({ theme }) => theme.palette.grey['600']};
  font-size: 12px;
`;

export const UnEditableInputFieldValue = styled(MUITypography)`
  width: 100%;
  color: ${({ theme }) => theme.palette.grey['900']};
`;
