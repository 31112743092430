import { useState } from 'react';

import { Toast, useTrigger } from 'src/components/Toast';
import { error as logError } from 'src/services/log';
import { useMediaQuery } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import { ResetPasswordContainerProps, ResetPasswordFormData, ResetPasswordFormProps } from './types';

import { MobileResetPasswordForm } from './Mobile/MobileResetPasswordForm';
import { DesktopResetPasswordForm } from './Desktop/DesktopResetPasswordForm';

export function ResetPasswordFormContainer({
  setLoading,
  userParams,
  login,
  forgotPasswordSubmit,
  history,
  triggerSuccessToast,
}: ResetPasswordContainerProps) {
  const [resetPasswordFormData, setResetPasswordFormData] = useState<ResetPasswordFormData>({
    password: '',
    confirmPassword: '',
  });

  const [errorToast, setErrorToast] = useTrigger();
  const [errorToastMessage, setToastErrorMessage] = useState<string | undefined>();

  const isMobile = useMediaQuery(`${device.smallLaptop} , ${device.mobileOrTablet}`); // using small laptop because the requirements text is occluded from small laptops down

  const { password, confirmPassword } = resetPasswordFormData;

  const checkPasswordsMatch = () => {
    if (password && confirmPassword) {
      return password === confirmPassword;
    }
    return undefined;
  };

  const showErrorToast = (message: string) => {
    setLoading(false);
    setToastErrorMessage(message);
    setErrorToast();
  };

  const validateBeforeSending = () => {
    const error = new Error();

    if (!userParams.emailAddress || !userParams.resetCode) {
      error.name = 'LinkError';
      throw error;
    }
  };

  async function resetForgottenPassword(data: ResetPasswordFormData) {
    await forgotPasswordSubmit(userParams.emailAddress as string, userParams.resetCode as string, data.password);
    await login(userParams.emailAddress as string, data.password);
  }

  const onButtonClick = async (e: any, data: ResetPasswordFormData) => {
    e.preventDefault();
    setLoading(true);

    try {
      validateBeforeSending();
      await resetForgottenPassword(data);
      setLoading(false);
      triggerSuccessToast('Password changed', 'Your new password has been succesfully changed.');
      history.push('/product-library');
    } catch (e: any) {
      logError(e);
      // all possible errors are listed here

      switch (e.name) {
        case 'InvalidPasswordException':
          showErrorToast('The password does not meet the given requirements');
          break;

        case 'InvalidParameterException':
          showErrorToast('The password does not meet the given requirements');
          break;

        case 'LimitExceededException':
          showErrorToast('You have excedeeded the retry limit, try again in a few minutes');
          break;

        case 'LinkError':
          showErrorToast(
            'It looks like the link you are on is wrong. Please try clicking the link from your email again'
          );
          break;

        default:
          showErrorToast(e.toString());
          break;
      }
    }
  };

  const passwordFormProps: ResetPasswordFormProps = {
    onButtonClick,
    resetPasswordFormData,
    checkPasswordsMatch,
    setResetPasswordFormData,
    error: !!errorToast,
  };

  return (
    <>
      {isMobile ? (
        <MobileResetPasswordForm {...passwordFormProps} />
      ) : (
        <DesktopResetPasswordForm {...passwordFormProps} />
      )}
      <Toast
        dependency={errorToast}
        severity="error"
        title="Error resetting password"
        message={errorToastMessage || ''}
      />
    </>
  );
}
