import { faSquareRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from '@samsonvt/shared-types/orderLambda';
import { TableDataRow, TableData } from 'src/components/Table/MUITableStyles';
import { formatDateWithTime } from 'src/services/formatDate';
import { ConfirmationNumber } from '../../../styles';

interface MyOrdersTableRowProps {
  order: Order;
}

export default function MobileMyOrdersTableRow({ order }: MyOrdersTableRowProps) {
  const date = formatDateWithTime(order.timestamp);

  return (
    <TableDataRow data-testid="received-orders-row">
      <TableData>
        <ConfirmationNumber to={`/my-orders/${order.orderId}`} data-testid="confirmationNo-link">
          <FontAwesomeIcon icon={faSquareRight} style={{ marginRight: '.5rem' }} />
          {order.orderId}
        </ConfirmationNumber>
      </TableData>
      <TableData>{date}</TableData>
    </TableDataRow>
  );
}
