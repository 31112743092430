import type { MessageType } from 'src/pages/ProductListPage/ProductListPage';
import type { ModelStatus } from '@samsonvt/shared-types/productsTable';
import {
  ExpandMore,
  ErrorOutline,
  Check,
  MoreHoriz,
  PlayArrow,
  Pause,
  AccessTime,
  DescriptionOutlined,
  Delete,
} from '@mui/icons-material';
import FileIcon3DS from './Icon_File3DS.svg';
import FileIconDWG from './Icon_FileDWG.svg';
import FileIconFBX from './Icon_FileFBX.svg';
import FileIconNWD from './Icon_FileNWD.svg';
import FileIconOBJ from './Icon_FileOBJ.svg';
import FileIconSTEP from './Icon_FileSTEP.svg';

type FileName = '3DS' | 'DWG' | 'FBX' | 'NWD' | 'OBJ' | 'STEP' | 'STP' | 'STE' | 'P21';
type IconName = MessageType | ModelStatus | 'arrow-right' | 'arrow-left' | 'more' | 'tick' | 'play' | 'pause';
interface IProps {
  name: IconName;
  label?: string;
}

const IconStyle = {
  display: 'inline-block',
  width: 'auto',
};

export function Icon({ name, label }: IProps): JSX.Element | null {
  switch (name) {
    case 'arrow-left':
      return <ExpandMore style={{ ...IconStyle, transform: 'rotate(90deg)' }} aria-label={label || 'Arrow left'} />;
    case 'arrow-right':
      return <ExpandMore style={{ ...IconStyle, transform: 'rotate(-90deg)' }} aria-label={label || 'Arrow right'} />;
    case 'error':
      return <ErrorOutline style={IconStyle} aria-label={label || 'Error'} />;
    case 'more':
      return <MoreHoriz style={IconStyle} aria-label={label || 'More'} />;
    case 'COMPLETED':
    case 'success':
    case 'tick':
      return <Check style={IconStyle} aria-label={label || 'Check'} />;
    case 'AWAITING_OPTIMISATION':
      return <MoreHoriz style={IconStyle} aria-label={label || 'More'} />;
    case 'DRAFT':
      return <AccessTime style={IconStyle} aria-label={label || 'Time'} />;
    case 'DELETED':
      return <Delete style={IconStyle} aria-label={label || 'Deleted'} />;
    case 'play':
      return <PlayArrow style={IconStyle} aria-label={label || 'Play'} />;
    case 'pause':
      return <Pause style={IconStyle} aria-label={label || 'Pause'} />;
    default:
      return null;
  }
}

export function FileIcon({ name }: { name: FileName | string }): JSX.Element {
  switch (name) {
    case '3DS':
      return <img src={FileIcon3DS} style={IconStyle} alt="3DS" />;
    case 'DWG':
      return <img src={FileIconDWG} style={IconStyle} alt="DWG" />;
    case 'FBX':
      return <img src={FileIconFBX} style={IconStyle} alt="FBX" />;
    case 'NWD':
      return <img src={FileIconNWD} style={IconStyle} alt="NWD" />;
    case 'OBJ':
      return <img src={FileIconOBJ} style={IconStyle} alt="OBJ" />;
    case 'STEP':
    case 'STP':
    case 'STE':
    case 'P21':
      return <img src={FileIconSTEP} style={IconStyle} alt="STEP" />;
    default:
      return <DescriptionOutlined style={IconStyle} />;
  }
}
