import { Box, styled } from '@mui/material';

const drawerBleeding = 56;
export const headerZ = 0;

export const StyledHeader = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${headerZ};

  top: -${drawerBleeding}px;
  left: 0;
  right: 0;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 0 20px;

  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-align: center;
  visibility: visible;
  min-height: ${drawerBleeding}px;
  height: fit-content;

  svg {
    width: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;
StyledHeader.defaultProps = {
  // @ts-ignore
  'data-testid': 'drawer-header',
};
