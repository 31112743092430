import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';

import MUIDivider from '@mui/material/Divider';

import { Column } from '../../Desktop/MyProfile/styles';

export const MobileMyProfileFormWrapper = styled('form')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  height: 50%;
  border-radius: 4px;
`;

export const MobileMyProfileSubTitle = styled(MUITypography)`
  width: 100%;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-weight: bold;
  font-size: 1.25rem;
`;
export const MobileMyProfileDivider = styled(MUIDivider)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  height: 2px;
  width: 100%;
`;
MobileMyProfileDivider.defaultProps = { orientation: 'horizontal' };

export const MobileSectionsContainer = styled(Column)`
  justify-content: space-around;
  align-items: center;
`;

export const TopSection = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const BottomSection = styled(Column)`
  width: 100%;
  height: 100%;
`;
