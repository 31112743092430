import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { useMediaQuery } from '@mui/material';
import { FormEvent, useContext, useState } from 'react';
import samsonLogoLarge from 'src/assets/logo-default.svg';
import { LoadingSpinner } from 'src/components/Loading';
import { Toast, useTrigger } from 'src/components/Toast';
import { device } from 'src/constants/mediaQueries';
import { usePost } from 'src/hooks/usePost';
import { TenantContext } from 'src/providers/Tenant';
import { useUser } from 'src/providers/User';
import { StyledLogo } from 'src/templates/UnauthenticatedTemplate';
import CreateAccountSuccess from './CreateAccountSuccess';
import { DesktopCreateAccountForm } from './Desktop/DesktopCreateAccountForm';
import { MobileCreateAccountForm } from './Mobile/MobileCreateAccountForm';
import { BackToLoginButton, ChevronBack, StyledCreateAccountFormContainer, StyledDivider } from './styles';
import { CreateAccountFormData } from './types';

export function CreateAccoutFormContainer() {
  const [formSentSuccessfully, setFormSentSuccessfully] = useState(false);
  const { logoUrl } = useContext(TenantContext);

  const [passwordErrorToast, setPasswordErrorToast] = useTrigger();
  const [passwordErrorToastMessage, setPasswordToastErrorMessage] = useState<string | undefined>();

  const [emailErrorToast, setEmailErrorToast] = useTrigger();
  const [emailErrorToastMessage, setEmailToastErrorMessage] = useState<string | undefined>();

  const [errorToastMessageTitle, setToastErrorMessageTitle] = useState<string | undefined>();

  const { signup } = useUser();
  const isMobile = useMediaQuery(`${device.smallLaptop} , ${device.mobileOrTablet}`); // using small laptop because the requirements text is occluded from small laptops down

  const {
    triggerPostRequest: triggerSignup,
    loading,
    setLoading,
  } = usePost({
    handler: (body: CreateAccountFormData) => {
      const { email, password, name, surname } = body;
      return signup(email, password, name, surname);
    },
    onSuccess: (res, setData, body) => {
      setFormSentSuccessfully(true);
      localStorage.setItem('password', body?.password || ''); // save password for automatic login once user is confirmed
    },
    onError: (error) => {
      switch (error.name) {
        case 'PasswordDoesNotMeetRequirements':
          showPasswordErrorToast(
            'Passwords are not in the right format. Please check them again',
            'Incorrect Passwords'
          );
          return;
        case 'UsernameExistsException':
          showEmailErrorToast('An account with the given email already exists', 'Account already exists');
          return;
        case 'InvalidEmailFormat':
          showEmailErrorToast('The email provided is not valid', 'Invalid Email Format');
          return;
        default:
          showPasswordErrorToast(error.message);
      }
    },
  });

  const createNewUser = (createNewUserFormData: CreateAccountFormData) => async (event: FormEvent) => {
    event.preventDefault();
    triggerSignup(createNewUserFormData);
  };

  const showPasswordErrorToast = (message: string, title?: string) => {
    setLoading(false);
    setPasswordToastErrorMessage(message);
    setToastErrorMessageTitle(title);
    setPasswordErrorToast();
  };

  const showEmailErrorToast = (message: string, title?: string) => {
    setLoading(false);
    setEmailToastErrorMessage(message);
    setToastErrorMessageTitle(title);
    setEmailErrorToast();
  };
  return (
    <>
      {formSentSuccessfully ? (
        <CreateAccountSuccess logoUrl={logoUrl} />
      ) : (
        <StyledCreateAccountFormContainer>
          {loading ? <LoadingSpinner width="360" /> : <StyledLogo src={logoUrl || samsonLogoLarge} />}
          {isMobile ? (
            <MobileCreateAccountForm
              createNewUser={createNewUser}
              passwordError={!!passwordErrorToast}
              emailError={!!emailErrorToast}
            />
          ) : (
            <DesktopCreateAccountForm
              createNewUser={createNewUser}
              passwordError={!!passwordErrorToast}
              emailError={!!emailErrorToast}
            />
          )}
          <StyledDivider />
          <BackToLoginButton to="/" startIcon={<ChevronBack icon={faChevronLeft} />}>
            Back to login
          </BackToLoginButton>
        </StyledCreateAccountFormContainer>
      )}

      <Toast
        dependency={passwordErrorToast}
        severity="error"
        title={errorToastMessageTitle || 'Error creating a new user'}
        message={passwordErrorToastMessage || ''}
      />

      <Toast
        dependency={emailErrorToast}
        severity="error"
        title={errorToastMessageTitle || 'Error creating a new user'}
        message={emailErrorToastMessage || ''}
      />
    </>
  );
}
