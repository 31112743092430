import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { useState } from 'react';
import Chip from 'src/components/Chip';
import { FormsSupersession } from '../../EditModal';
import { Button, DeleteButton } from './Buttons';
import SupersessionForm from './SupersessionForm';

type Props = {
  supersession: FormsSupersession;
  isFromPrevPart: boolean | undefined;
  setSupersession: (s: Partial<FormsSupersession>) => void;
  deleteSupersession: (supersession: FormsSupersession) => void;
};

export default function SupersessionRow({ supersession, setSupersession, deleteSupersession, isFromPrevPart }: Props) {
  const {
    part: { number, notes },
  } = supersession;
  const [notesOpen, setNotesOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(!('svtPartID' in supersession));

  const closeForm = () => setFormOpen(false);

  return (
    <>
      {formOpen ? (
        <Zebra>
          <td colSpan={5}>
            <DeleteWrapper>
              <DeleteButton
                onClick={() => {
                  deleteSupersession(supersession);
                  closeForm();
                }}
                data-testid="delete-supersession-button"
              />
              <SupersessionForm supersession={supersession} setSupersession={setSupersession} closeForm={closeForm} />
            </DeleteWrapper>
          </td>
        </Zebra>
      ) : (
        <Zebra>
          <td>
            <Arrow
              style={{ transform: notesOpen ? 'rotate(90deg)' : 'rotate(0)' }}
              icon={faRight}
              onClick={() => setNotesOpen((v) => !v)}
            />
            <Small>Notes</Small>
          </td>
          <td>
            <ChipWrapper>
              <strong>{number}</strong>
              {isFromPrevPart ? <Chip size="small" label="New!" /> : null}
            </ChipWrapper>
          </td>
          <td>
            <EditButton
              size="small"
              startIcon={<FontAwesomeIcon size="xs" icon={faPenToSquare} />}
              onClick={() => {
                setFormOpen((v) => !v);
                setNotesOpen(false);
              }}
              data-testid="edit-supersession-button"
            >
              Edit
            </EditButton>
          </td>
        </Zebra>
      )}
      {notesOpen ? ( // Additional <tr> maintains zebra stripes odd/even calc
        <>
          <tr />
          <Zebra>
            <td colSpan={5}>
              {notes || (
                <small>
                  <i>No notes yet</i>
                </small>
              )}
            </td>
          </Zebra>
        </>
      ) : null}
    </>
  );
}

const EditButton = styled(Button)`
  width: 100%;
`;

const Zebra = styled('tr')`
  & td {
    padding: 0.9rem;
  }
  &:nth-of-type(even) {
    background-color: #eeeeee;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-right: 0.5rem;
  cursor: pointer;
`;

const Small = styled('span')`
  font-size: 14px;
`;

const ChipWrapper = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const DeleteWrapper = styled('div')`
  display: flex;
  gap: 1.5rem;
  padding-left: 0.5rem;
  justify-content: center;
  align-items: center;
`;
