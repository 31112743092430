import { faCartPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { useContext } from 'react';
import { StyledAddToCartButton } from 'src/components/Button';
import { ModelContext } from 'src/providers/Model';
import { extractAndSaveMetaObject, ShoppingCartPanelContext } from 'src/providers/ShoppingCart';
import useShoppingCartMutation, { addQuantity } from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { PartWithPrice } from 'src/services/isPartBuyable';
import { calculateDiscountedPrice } from 'src/services/formatCurrency';
import { useUser } from 'src/providers/User';
import { useProduct } from 'src/providers/Product';

interface AddToCartButtonProps {
  part: PartWithPrice;
  quantity: number;
  disabled?: boolean;
}

export function AddToCartButton({ part, quantity, disabled }: AddToCartButtonProps) {
  const { discountPercentage } = useUser();
  const { productId } = useProduct();

  const { partNumber, price, name, eCommerceId } = part;
  const { data: cart } = useShoppingCartQuery();
  const { mutate } = useShoppingCartMutation();

  const { setPanelOpen } = useContext(ShoppingCartPanelContext);

  const { model } = useContext(ModelContext);

  const currentQuantity = cart.find(({ partNumber }) => partNumber === part.partNumber)?.quantity;

  const clickHandler = () => {
    setPanelOpen(true);
    mutate(
      addQuantity({
        cart,
        quantityToAdd: quantity,
        item: {
          partName: name,
          partNumber,
          unitPrice: calculateDiscountedPrice(price, discountPercentage), // While the price on product's part is an original price, the price we use in shopping cart and orders is a discounted one.
          productId,
          eCommerceId,
        },
      })
    );
    extractAndSaveMetaObject(part, model);
  };

  const buttonText = 'Add to cart';

  return (
    <AddToCartButtonContainer>
      <StyledAddToCartButton
        data-testid="add-to-cart-button"
        size="small"
        startIcon={<FontAwesomeIcon icon={faCartPlus} />}
        onClick={clickHandler}
        disabled={disabled}
      >
        {buttonText}
      </StyledAddToCartButton>
      <span>{currentQuantity && ` (${currentQuantity} in the cart)`}</span>
    </AddToCartButtonContainer>
  );
}

const AddToCartButtonContainer = styled('div')`
  margin-bottom: 2.5rem;
`;
