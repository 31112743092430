import { styled } from '@mui/material';
import MUISwitch, { SwitchProps } from '@mui/material/Switch';

export default function AttributeSwitch(props: SwitchProps & { label: string }) {
  const { label, checked, ...rest } = props;
  return (
    <Label>
      {label}
      <SwitchContainer>
        <MUISwitch {...rest} checked={checked} />
        <YesContainer>{checked ? 'Yes' : 'No'}</YesContainer>
      </SwitchContainer>
    </Label>
  );
}

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.25 : 1)};
`;

const SwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const YesContainer = styled('div')`
  width: 2rem;
  text-align: center;
`;
