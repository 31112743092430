import { alpha, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Highlight } from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete/Highlight';
import MUITypography from '@mui/material/Typography';
import { ProductListResult } from '@samsonvt/shared-types/productLambda';
import { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { productCategoryPathEnumerationSeparator } from 'src/utils/urls';

interface ProductSearchOptionProps {
  option: ProductListResult;
  searchFieldState: string;
  availableProductFeatures: ProductFeature[];
}

const productCategoryPathEnumerationSeparatorRegex = new RegExp(`\\${productCategoryPathEnumerationSeparator}`, 'g');

export default function ProductSearchOption({
  option,
  searchFieldState,
  availableProductFeatures,
}: ProductSearchOptionProps) {
  const highlightRegex = new RegExp(`${searchFieldState}`, 'i');
  const { productCategories, productId } = option;
  const categoriesDisplayName = productCategories
    ?.map((pathEnumeration) => pathEnumeration.replace(productCategoryPathEnumerationSeparatorRegex, ' > '))
    .join(' and ');

  return (
    <StyledProductSearchOption
      data-testid={`link-to-${productId}`}
      to={availableProductFeatures.length ? `/product-library/${productId}/${availableProductFeatures[0]}` : '/404'}
    >
      <ProductSearchOptionTitle>
        <Highlight text={option.name} regex={highlightRegex} />
      </ProductSearchOptionTitle>
      {categoriesDisplayName && (
        <ProductSearchOptionSubTitle data-testid="categories-breadcrumbs">{`in ${categoriesDisplayName}`}</ProductSearchOptionSubTitle>
      )}
    </StyledProductSearchOption>
  );
}

const StyledProductSearchOption = styled(Link)`
  background-color: inherit; // specified in the styled popper
  display: block;
  height: 100%;
  padding: 1rem;

  &:hover {
    background-color: ${({ theme }) =>
      alpha(
        theme.palette.common.white,
        0.08
      )} !important; // override all of the inherited colours from the listbox definition in the styled popper
  }
`;

const ProductSearchOptionTitle = styled(MUITypography)`
  font-size: 1.25rem;
  font-weight: bold;
`;

const ProductSearchOptionSubTitle = styled(MUITypography)`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
`;
