/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* Rules disabled to clean console output and improve developer experience when writing new code. Should be reviewed */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/components/Icon';
import type { ProductListResult, UpdateProductBodyShape } from '@samsonvt/shared-types/productLambda';
import { format, parseISO } from 'date-fns';
import { updateProduct } from 'src/services/api';
import { usePatch } from 'src/hooks/usePatch';
import type { ModelStatus } from '@samsonvt/shared-types/productsTable';
import {
  DropdownContainerStyles,
  DropdownHiddenContainer,
  DropdownNavItem,
  StatusDropdownList,
  StatusDropdownListItem,
  UpdateStatusButton,
} from './styles';
import { transformStatusString } from './ProductCard';

const defaultUploader = 'SamsonVT';

interface ProductDropdownProps {
  product: ProductListResult;
  productStatusDisplayed: ModelStatus;
  setProductStatusDisplayed: Dispatch<SetStateAction<ModelStatus>>;
  canDeleteProduct: boolean;
  canUpdateProduct: boolean;
}

export function ProductCardEllipsesDropdown({
  product,
  productStatusDisplayed,
  setProductStatusDisplayed,
  canDeleteProduct,
  canUpdateProduct,
}: ProductDropdownProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const deleteModalRef = useRef<HTMLDivElement>(null);
  const modelStatusOptions = canUpdateProduct
    ? ['DRAFT', 'AWAITING_OPTIMISATION', 'COMPLETED', canDeleteProduct && 'DELETED'].filter((item) => item)
    : [];

  const [modelStatusMenuAnchorEl, setModelStatusAnchorEl] = useState<null | HTMLElement>(null);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);

  const handleOpenModelStatusMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setModelStatusAnchorEl(e.currentTarget);
  };
  const handleCloseModelStatusMenu = () => {
    setModelStatusAnchorEl(null);
  };

  const { updatedBy, createdAt, productId } = product;
  const uploadedBy = updatedBy || defaultUploader;
  const uploadedOn = format(parseISO(createdAt), 'dd-MM-yyyy');

  const statusString = transformStatusString(productStatusDisplayed);

  const { triggerPatchRequest, loading } = usePatch<UpdateProductBodyShape>(
    updateProduct,
    (fieldsChanged: UpdateProductBodyShape) => {
      if (fieldsChanged.modelStatus) {
        setProductStatusDisplayed(fieldsChanged.modelStatus);
      }
    }
  );

  const updateProductStatus = (newModelStatus: ModelStatus) => {
    triggerPatchRequest(productId, { modelStatus: newModelStatus });
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const path = event.composedPath && event.composedPath();

      if (!path) {
        return;
      }

      const deleteContainer = path.includes(deleteModalRef.current as Node);
      const wrapperRefContainer = path.includes(wrapperRef.current as Node);

      if (!wrapperRefContainer && !deleteContainer && dropdownActive) {
        setDropdownActive(false);
      }
    };

    if (dropdownActive && !modelStatusMenuAnchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownActive, deleteModalRef, modelStatusMenuAnchorEl]);

  return (
    <DropdownContainerStyles data-testid="product-actions" ref={wrapperRef} isActive={dropdownActive}>
      <div className="product-actions__view-more" onClick={() => setDropdownActive(!dropdownActive)}>
        <Icon name="more" />
      </div>
      <DropdownHiddenContainer isActive={dropdownActive}>
        <DropdownNavItem>
          <span>Uploaded by: {uploadedBy}</span>
        </DropdownNavItem>
        <DropdownNavItem>
          <span>Uploaded on: {uploadedOn}</span>
        </DropdownNavItem>
        <DropdownNavItem status={productStatusDisplayed}>
          <span data-testid={`icon-status-${productStatusDisplayed}`}>
            Status: <Icon name={productStatusDisplayed} />
            {statusString}
          </span>
        </DropdownNavItem>
        {canUpdateProduct && (
          <DropdownNavItem>
            <UpdateStatusButton onClick={handleOpenModelStatusMenu}>
              {loading ? 'Switching status...' : 'Switch status'}
            </UpdateStatusButton>
            <StatusDropdownList
              id="id"
              open={!!modelStatusMenuAnchorEl}
              anchorEl={modelStatusMenuAnchorEl}
              onClose={handleCloseModelStatusMenu}
            >
              {(modelStatusOptions as ModelStatus[]).map((modelStatusOptions) => (
                <StatusDropdownListItem
                  key={modelStatusOptions}
                  onClick={() => {
                    updateProductStatus(modelStatusOptions);
                    handleCloseModelStatusMenu();
                  }}
                >
                  {modelStatusOptions}
                </StatusDropdownListItem>
              ))}
            </StatusDropdownList>
          </DropdownNavItem>
        )}
      </DropdownHiddenContainer>
    </DropdownContainerStyles>
  );
}
