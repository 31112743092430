import { alpha, styled } from '@mui/material';
import { PartNode } from '@samsonvt/shared-types/productLambda';
import { filterCustomAttributesToShow } from 'src/components/Product/Shared/Helpers/filterCustomAttributesToShow';

interface PartDetailsProps {
  currentPart: PartNode;
}

/**
 * Part details - lists part attributes
 */
export function PartDetails({ currentPart }: PartDetailsProps) {
  const { attributes } = currentPart;
  const attributesWithoutEmptyFields = filterCustomAttributesToShow(attributes);

  if (!attributesWithoutEmptyFields || !Object.keys(attributesWithoutEmptyFields).length) {
    return null;
  }

  const shouldDisplayInRows = (content: string | string[]) => emptyArray.concat(content).join('').length > 40;

  return (
    <PartDetailsContainer>
      {Object.entries(attributesWithoutEmptyFields).map(([title, content]) =>
        shouldDisplayInRows(content) ? (
          <RowDataField key={title} title={title} content={content} />
        ) : (
          <ColumnDataField key={title} title={title} content={content} />
        )
      )}
    </PartDetailsContainer>
  );
}

interface DataFieldProps {
  title: string;
  content: string | string[];
}
function ColumnDataField({ title, content }: DataFieldProps) {
  return (
    <ColumnWrapper>
      <Label>{title}</Label>
      <Value>{insertLinebreaksIfArray(content)}</Value>
    </ColumnWrapper>
  );
}

function RowDataField({ title, content }: DataFieldProps) {
  return (
    <RowWrapper>
      <RowLabel>{title}</RowLabel>
      <RowValue>{insertLinebreaksIfArray(content)}</RowValue>
    </RowWrapper>
  );
}

const emptyArray: string[] = [];
export const insertLinebreaksIfArray = (content: string | string[]) => emptyArray.concat(content).join('\n');

const PartDetailsContainer = styled('div')`
  margin-bottom: 1.5em;
  padding-top: 0.8em;
  border-top: 1px solid ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.12)};
`;

const ColumnWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

const Label = styled('div')`
  max-width: 50%;
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.7)};
`;

const Value = styled('div')`
  max-width: 50%;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;

const RowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const RowLabel = styled('div')`
  color: ${({ theme }) => alpha(theme.palette.sidebarBackground.contrastText, 0.7)};
  margin-bottom: 0.5em;
`;

const RowValue = styled('div')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
`;
