import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { RepairSections } from 'src/components/Product/Desktop/Panels/WorkInstructions/RepairSections';

export function RepairTabPanel() {
  const { search: tagUrlSection } = useLocation();
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:repairSection/:repairStep`}>
        <RepairSections />
      </Route>
      <Route path={`${path}/:repairSection`}>
        <Redirect to={`${url}/0/0${tagUrlSection}`} />
      </Route>
      <Route path={path} exact>
        <Redirect to={`${url}/0/0${tagUrlSection}`} />
      </Route>
    </Switch>
  );
}
