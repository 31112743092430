import { styled } from '@mui/material';
import { EmptyTab } from 'src/components/EmptyTab';
import { ThinDivider } from 'src/components/Product/Desktop/Panels/PartsCatalogue/styles';
import AddToCartButton from 'src/components/Product/Mobile/Panels/PartsCatalogue/AddToCartButton';
import InnerTabs from 'src/components/Product/Mobile/Panels/PartsCatalogue/InnerTabs';
import SingleTabPartsPanel from 'src/components/Product/Mobile/Panels/PartsCatalogue/SingleTabPartsPanel';
import TabHeader from 'src/components/Product/Mobile/Panels/PartsCatalogue/TabHeader';
import { TabPanelElement } from 'src/components/Product/Shared/TabPanelElement';
import { Feature } from 'src/Feature';
import { hasPrice } from 'src/services/isPartBuyable';
import { usePart } from 'src/services/part';
import { usePartNavigation } from 'src/services/partNavigation';
import { usePartsWithPriceOrWithChildren } from 'src/services/usePartsWithPriceOrWithChildren';
import { PriceSection } from './PriceSection/PriceSection';

const Summary = styled('div')`
  padding: 1rem 1rem 0 1rem;
  background-color: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

export default function PartsCatalogue() {
  const part = usePart();
  const currentPart = part.current || part.partPath?.part;

  const { children: partChildren, parents } = usePartNavigation();
  const alphabeticallySortedChildren = partChildren.sort((childA, childB) => childA.name.localeCompare(childB.name));
  const hasChildren = !!alphabeticallySortedChildren.length;
  const isBuyable = hasPrice(currentPart); // undefined price means part is not buyable

  const childrenWithoutPriceUndefinedParts = usePartsWithPriceOrWithChildren(alphabeticallySortedChildren);

  if (!currentPart) {
    return <EmptyTab />;
  }

  return (
    <TabPanelElement>
      <Summary>
        <TabHeader bomPart={currentPart} />
        <ThinDivider />

        {isBuyable ? (
          <>
            <PriceSection price={currentPart.price} />
            <Feature flag="shopping-cart">
              <AddToCartButton part={currentPart} />
            </Feature>
          </>
        ) : null}

        <ThinDivider />
      </Summary>

      {!hasChildren && <InnerTabs current={currentPart} parents={parents} />}
      {hasChildren && <SingleTabPartsPanel partChildren={childrenWithoutPriceUndefinedParts} />}
    </TabPanelElement>
  );
}
