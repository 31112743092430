import { RowSecondaryButton } from './styles';

export function ResendInviteButton({
  resendInvite,
  disableResendButton,
}: {
  resendInvite: () => Promise<void>;
  disableResendButton: boolean;
}) {
  return (
    <RowSecondaryButton onClick={resendInvite} disabled={disableResendButton} color="success">
      Resend invite
    </RowSecondaryButton>
  );
}
