import type { ProductFeature } from '@samsonvt/shared-types/productsTable';
import { useRouteMatch } from 'react-router-dom';
import { useProductFeatures } from 'src/hooks/useProductFeatures';

export const useCurrentTabName = () => {
  const visibleTabs = useProductFeatures(); // Only returns tab that can be accessed. Otherwise undefined

  const match = useRouteMatch<{ productId: string; tab: ProductFeature }>(
    `/product-library/:productId/:tab(${visibleTabs.join('|')})`
  );
  return match?.params.tab;
};
