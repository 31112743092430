import MUIDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material';
import type { History } from 'history';

import type { Dispatch, SetStateAction } from 'react';
import type { ProductListAPIResponse } from '@samsonvt/shared-types/productLambda';

import MobileProductMenuList from './MobileProductMenuList';
import MobileProductMenuHeader from './MobileProductMenuHeader';
import MobileProductMenuFooter from './MobileProductMenuFooter';

interface MobileProductMenuProps {
  openMobileProductMenu: boolean;
  setOpenMobileProductMenu: Dispatch<SetStateAction<boolean>>;
  history: History<unknown>;
  products: ProductListAPIResponse;
  currentProductName: string | undefined;
  logout: () => Promise<void>;
}

export default function MobileProductMenu({
  openMobileProductMenu,
  setOpenMobileProductMenu,
  history,
  products,
  currentProductName,
  logout,
}: MobileProductMenuProps) {
  const triggerMobileProductMenu = () => setOpenMobileProductMenu((prev) => !prev);

  const goToLibrary = () => {
    history.push('/product-library');
    triggerMobileProductMenu();
  };

  return (
    <StyledDrawer open={openMobileProductMenu} onClose={triggerMobileProductMenu} onOpen={triggerMobileProductMenu}>
      <MenuWrapper data-testid="mobile-product-menu">
        <MobileProductMenuHeader triggerMobileProductMenu={triggerMobileProductMenu} goToLibrary={goToLibrary} />
        <MobileProductMenuList
          products={products}
          currentProductName={currentProductName}
          history={history}
          triggerMobileProductMenu={triggerMobileProductMenu}
        />
        <MobileProductMenuFooter logout={logout} />
      </MenuWrapper>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(MUIDrawer)`
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

StyledDrawer.defaultProps = {
  anchor: 'left',
  color: 'secondary',
  PaperProps: {
    sx: {
      background: ({ palette }) => palette.primary.dark,
      boxSizing: 'border-box',
      overflow: 'visible',
      width: 320,
    },
  },
  swipeAreaWidth: 0,
};
const MenuWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
