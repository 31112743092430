import { faCartCircleCheck, faListTree } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { StyledMUIButton } from 'src/components/Button';
import { CheckoutConfirmationProps } from '../Confirmation';

type DesktopCheckoutConfirmationProps = Omit<CheckoutConfirmationProps, 'gtmData'>;

export function DesktopCheckoutConfirmation({ confirmationNo, isOrderPOR }: DesktopCheckoutConfirmationProps) {
  const { push } = useHistory();
  return (
    <ConfirmationContainer>
      <ConfirmationTitle>
        Thank you, your {isOrderPOR ? 'quote' : 'order'} has been submitted successfully
      </ConfirmationTitle>
      <FontAwesomeIcon icon={faCartCircleCheck} size="6x" />
      <ConfirmationBody>
        The confirmation number for your {isOrderPOR ? 'quote' : 'order'} is <strong>{confirmationNo}</strong>.{' '}
        {isOrderPOR ? 'A' : 'An order'} summary has been sent to your email.
        <br />
        This can take a few minutes, if nothing is received please check your Spam folder.
      </ConfirmationBody>
      <ConfirmationBody>Please use the button below to continue ordering from the Parts Catalogue</ConfirmationBody>
      <PartsCatalogueButton
        data-testid="empty-cart-button"
        startIcon={<FontAwesomeIcon icon={faListTree} size="sm" />}
        onClick={() => push('/product-library')}
      >
        Browse the Parts Catalogue
      </PartsCatalogueButton>
    </ConfirmationContainer>
  );
}

const PartsCatalogueButton = styled(StyledMUIButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const ConfirmationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-top: 6rem;
`;

const ConfirmationTitle = styled(MUITypography)`
  margin-bottom: 7.25rem;
  text-align: center;
`;
ConfirmationTitle.defaultProps = { variant: 'h4' };

const ConfirmationBody = styled(MUITypography)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`;
ConfirmationBody.defaultProps = { variant: 'body1' };
