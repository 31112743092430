import { BackButton } from 'src/components/Button';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import ChangeYourPassword from './ChangeYourPassword/ChangeYourPassword';
import MyProfile from './MyProfile/MyProfile';
import { BreadCrumbsMargin, ContentContainer, InnerContentContainer } from './styles';
import { AccountDetailsProps } from '../AccountDetailsPage';

export default function DesktopAccountDetails({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  verifyNewPassword,
  setVerifyNewPassword,
  submit,
  checkPasswordsMatch,
  error,
  userDetailsFormState,
  handleMyProfileFormChange,
  submitMyProfileForm,
  userEmail,
  isApplyUserLoading,
  countryList,
  onClickBackButton,
}: AccountDetailsProps) {
  return (
    <ContentContainer>
      <BackButton onClick={onClickBackButton} />
      <BreadCrumbsMargin>
        <StandardBreadcrumbs
          current={{ name: 'Account details' }}
          parents={[{ name: 'My account', url: '/my-account' }]}
        />
        <InnerContentContainer>
          <MyProfile
            userDetailsFormState={userDetailsFormState}
            handleMyProfileFormChange={handleMyProfileFormChange}
            submitMyProfileForm={submitMyProfileForm}
            userEmail={userEmail}
            isApplyUserLoading={isApplyUserLoading}
            countryList={countryList}
          />
          <ChangeYourPassword
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            verifyNewPassword={verifyNewPassword}
            setVerifyNewPassword={setVerifyNewPassword}
            submit={submit}
            checkPasswordsMatch={checkPasswordsMatch}
            error={error}
          />
        </InnerContentContainer>
      </BreadCrumbsMargin>
    </ContentContainer>
  );
}
