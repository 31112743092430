import { useLayoutEffect } from 'react';
import { Environment as DreiEnvironment } from '@react-three/drei';
import hdri from "src/assets/hdri/studio_small_08_1k.hdr";

type Props = {
  background?: boolean;
  hdriSrc?: string;
  onCreated?: () => void;
};


export function Environment({ background = false, hdriSrc = hdri, onCreated }: Props) {

  useLayoutEffect(() => {
    if (onCreated) {
      onCreated();
    }
  }, [onCreated]);

  return (
    <DreiEnvironment background={background} files={hdriSrc}/>
  );
}
