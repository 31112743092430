import { createContext, useContext } from 'react';
import { error as logError } from 'src/services/log';
import { TenantDetailsAPIResponse } from '@samsonvt/shared-types/userLambda';
import { getTenantDetails } from 'src/services/api';
import { useFetch } from 'src/hooks/useFetch';
import { AccountDBSource } from '@samsonvt/shared-types/accountsTable';

const defaultTenantValue = {
  accountId: '',
  name: '',
  enabledFeatures: [],
};

export const TenantContext = createContext<TenantDetailsAPIResponse>(defaultTenantValue);

const staticUrl = process.env.REACT_APP_BASE_URL?.replace('api.', 'static.');

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const { error: apiError, data: tenant } = useFetch<TenantDetailsAPIResponse>(
    (token) => getTenantDetails(token),
    [],
    'Failed to get tenant details'
  );

  const { error: initialisationError, data: initialisationFile } = useFetch(
    () => fetch(`${staticUrl}/_assets/samsonvt/samson-logo.svg`),
    [],
    `Error retrieving file from ${staticUrl}`
  );

  if (apiError || initialisationError || (initialisationFile && !initialisationFile.ok)) {
    // Initialisation check. https://samsonvt.atlassian.net/browse/SVT-1019
    logError((apiError || initialisationError || new Error('Initialisation error')) as Error);
    window.history.pushState({}, '', '/initialisation-failed'); // Manual push as we are outside router

    return (
      <TenantContext.Provider
        value={
          tenant ||
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          (defaultTenantValue as AccountDBSource)
        }
      >
        {children}
      </TenantContext.Provider>
    );
  }

  if (tenant) {
    return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>;
  }

  return null;
}

export const useTenant = (): AccountDBSource => {
  const context = useContext(TenantContext);

  if (context === undefined) {
    throw new Error('`useTenant` hook must be used within a `TenantProvider` component');
  }

  return context;
};
