import { MainTemplate } from 'src/templates/MainTemplate';
import { ContentPadding } from 'src/components/ContentPane';
import { ProductList } from 'src/components/ProductsList';
import { FullscreenLoadingSpinner } from 'src/components/Loading/FullscreenLoadingSpinner';
import { useProductsListQuery } from 'src/providers/useProductsListQuery';
import { useMediaQuery } from '@mui/material';
import { device } from 'src/constants/mediaQueries';
import { ProductLibraryBackground } from 'src/components/ProductLibraryBackground';
import { Toast } from 'src/components/Toast';
import { Footer } from '../../components/Footer';
import { DesktopProductCategoryHeader } from './Desktop/DesktopProductCategoryHeader';
import { MobileProductCategoryHeader } from './Mobile/MobileProductCategoryHeader';
import EmptyCategoryPage from './EmptyCategoryPage';

export type MessageType = 'error' | 'success';
export type DisplayMessageHandler = (title: string, message: string, messageType: MessageType) => void;

interface ProductCategoryPageProps {
  showAddProductTile: boolean;
  decodedPathEnumeration: string;
}

export function ProductCategoryPage({ showAddProductTile, decodedPathEnumeration }: ProductCategoryPageProps) {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  const { data: fetchedProducts = [], isLoading, isError } = useProductsListQuery();
  const filteredProducts = fetchedProducts.filter(({ productCategories }) =>
    productCategories?.includes(decodedPathEnumeration)
  );

  if (isLoading) {
    return <FullscreenLoadingSpinner />;
  }

  return (
    <>
      <MainTemplate>
        <ProductLibraryBackground>
          <ContentPadding>
            {isMobile ? (
              <MobileProductCategoryHeader />
            ) : (
              <DesktopProductCategoryHeader decodedPathEnumeration={decodedPathEnumeration} />
            )}
            {!filteredProducts.length ? (
              <EmptyCategoryPage />
            ) : (
              <ProductList products={filteredProducts} showAddProductTile={showAddProductTile} />
            )}
          </ContentPadding>
        </ProductLibraryBackground>
        <Footer />
      </MainTemplate>
      <Toast
        dependency={isError}
        severity="error"
        title="Sorry there has been a problem"
        message="Please try again later."
      />
    </>
  );
}
