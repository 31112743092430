import { useEffect, useState } from 'react';
import { useTrigger } from 'src/components/Toast';
import { inviteUser } from 'src/services/api';
import { error as logError } from 'src/services/log';
import { UserItem } from './UserManagementTable';


const emailResetTime = 300000;

export const useInvitationEmail = (user: UserItem) => {
  const [disableResendButton, setDisableResendButton] = useState(false);

  const [emailSentToast, openEmailSentToast] = useTrigger();
  const [emailErrorToast, openEmailErrorToast] = useTrigger();

  const setEmailSent = (emailAddress: string) => {
    if (typeof window?.localStorage !== 'undefined') {
      const currentTime = Date.now().toString();
      window.localStorage.setItem(`emailLastSent_${emailAddress}`, currentTime);
      setDisableResendButton(true);

      setTimeout(() => {
        setDisableResendButton(false);
      }, emailResetTime);
    }
  };

  const resendInvite = async () => {
    if (!user.email) {
      openEmailErrorToast();
      return;
    }

    try {
      await inviteUser(user);
      openEmailSentToast();
      setEmailSent(user.email);
    } catch (err: any) {
      logError(err, 'failed to resend invite.');
      openEmailErrorToast();
    }
  };

  useEffect(() => {
    if (typeof window?.localStorage !== 'undefined') {
      const emailLastSent = window.localStorage.getItem(`emailLastSent_${user.email}`);

      if (!emailLastSent) {
        return;
      }

      const timeRemainingUntilResend = Date.now() - Number(emailLastSent);

      if (timeRemainingUntilResend > emailResetTime) {
        return setDisableResendButton(false);
      }

      setDisableResendButton(true);

      const resendInviteInterval = setTimeout(
        () => setDisableResendButton(false),
        emailResetTime - timeRemainingUntilResend
      );

      return () => clearInterval(resendInviteInterval);
    }
  }, [user.email]);

  return {
    setEmailSent,
    resendInvite,
    disableResendButton,
    emailSentToast,
    emailErrorToast,
  };
};
