import { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { RepairListContext } from 'src/providers/repairs/RepairList';

import RepairListHeader from 'src/components/Product/Mobile/Panels/WorkInstructions/RepairListHeader';
import { useUser } from 'src/providers/User';
import { EmptyTab } from 'src/components/EmptyTab';
import { useProduct } from 'src/providers/Product';
import { useQueryString } from 'src/hooks/useQueryString';

import { LoadingSpinner } from 'src/components/Loading';
import { TagList } from './TagList';

import { RepairListItems } from './RepairListItems';
import { StyledLoadingSpinner, StyledRepairList } from './styles';

export function SidebarRepairList() {
  const { repairs, loading } = useContext(RepairListContext);
  const currentlySelectedRepairTagId = useQueryString('tag');
  const { tags } = useUser();
  const { url } = useRouteMatch();
  const { search: tagUrlSection } = useLocation();
  const { name: productName } = useProduct();

  const tagListActive = tags && !currentlySelectedRepairTagId;

  if (loading) {
    return (
      <StyledLoadingSpinner>
        <LoadingSpinner />
      </StyledLoadingSpinner>
    );
  }

  if (!repairs || repairs.length === 0) {
    return <EmptyTab />;
  }

  return (
    <StyledRepairList data-testid="repair-list-screen">
      <RepairListHeader productName={productName} tagListActive={tagListActive} />
      {!currentlySelectedRepairTagId && tags?.length ? (
        <TagList tags={tags} />
      ) : (
        <RepairListItems
          repairs={repairs}
          url={url}
          tagUrlSection={tagUrlSection}
          repairTag={currentlySelectedRepairTagId}
        />
      )}
    </StyledRepairList>
  );
}
