import { useState } from 'react';
import MUIFormControl from '@mui/material/FormControl';
import MUITypography from '@mui/material/Typography';

import { ISO4217 } from 'src/services/formatCurrency';
import { CartEntry } from 'src/providers/ShoppingCart';

import { styled } from '@mui/material';
import useTranslation from 'src/hooks/useTranslation';
import { AccountDetails, MutateAccountDetails } from 'src/hooks/useAccountDetails';
import { TelephoneInput } from 'src/components/TextInput/TelephoneInput';
import { RawFormState, serialiseOrderData } from '../types';
import {
  FormContainer,
  CheckOutCheckBox,
  CheckOutInputField,
  CheckOutSelectField,
  CheckoutSelectLabel,
  CheckOutSelectOption,
  CheckOutSubTitle,
  LongCheckOutInputField,
  OptionalCheckoutLabel,
  Column,
  StyledMUICheckbox,
} from '../styles';
import MobileCheckoutActionBar from './MobileCheckoutActionBar';

interface CheckoutFormProps {
  cart: CartEntry[];
  discountPercentage?: number;
  preferredCurrency?: ISO4217;
  sendOrder: (body: any) => void;
  isOrderPOR: boolean;
  isFetching: boolean;
  goBack: () => void;
  countryList: string[];
  defaultAccountDetails: MutateAccountDetails & AccountDetails;
}

export function MobileCheckoutForm({
  cart,
  discountPercentage,
  preferredCurrency,
  sendOrder: sendForm,
  isOrderPOR,
  isFetching,
  goBack,
  countryList,
  defaultAccountDetails,
}: CheckoutFormProps) {
  const { name, companyName, telephone, buildingName, city, postalCode, country, streetName, applyUserDetails } =
    defaultAccountDetails;

  const [formState, setFormState] = useState<RawFormState>({
    firstName: name,
    lastName: name,
    companyName,
    telephone,
    buildingName,
    streetName,
    postalCode,
    country,
    city,
    saveDetails: false,
    customerReference: undefined,
    messageToSeller: undefined,
    'vtaas-ui:checkout:customerAccountField': undefined,
  });

  const [validate, setValidate] = useState(false);

  const handleChange = (e: any) => {
    const { name } = e.target;
    if (e.target.type === 'checkbox') {
      setFormState((formState) => ({ ...formState, [name]: e.target.checked }));
    } else {
      setFormState((formState) => ({ ...formState, [name]: e.target.value }));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!formState.telephone) {
      return;
    }

    if (formState.saveDetails) {
      const { firstName, companyName, telephone, buildingName, streetName, city, postalCode, country } = formState;
      applyUserDetails({
        name: firstName,
        companyName,
        telephone,
        buildingName: buildingName || '',
        streetName,
        city,
        postalCode,
        country,
      });
    }

    const newOrder = serialiseOrderData(formState, cart, preferredCurrency, discountPercentage);
    sendForm(newOrder);
  };

  const validateOnSubmission = (condition: boolean) => validate && condition;

  return (
    <MobileCheckoutFormWrapper onSubmit={handleSubmit}>
      <FormContainer>
        <MobileSectionContainer>
          <MobileSection>
            <MobileCheckOutTitle>Your order details</MobileCheckOutTitle>
            <CheckOutInputField
              label="First name"
              name="firstName"
              onChange={handleChange}
              required
              value={formState.firstName}
              error={validateOnSubmission(!formState.firstName)}
            />
            <CheckOutInputField
              label="Last name"
              name="lastName"
              onChange={handleChange}
              required
              value={formState.lastName}
              error={validateOnSubmission(!formState.lastName)}
            />

            <CheckOutInputField
              label="Company name"
              name="companyName"
              onChange={handleChange}
              required
              value={formState.companyName}
              error={validateOnSubmission(!formState.companyName)}
            />
            <TelephoneInput
              Component={CheckOutInputField}
              onChange={handleChange}
              required
              value={formState.telephone}
              error={validateOnSubmission(!formState.telephone)}
            />
            <CheckOutSubTitle>Delivery address</CheckOutSubTitle>
            <CheckOutInputField
              label={<OptionalCheckoutLabel label="Building name / Number" />}
              name="buildingName"
              value={formState.buildingName}
              onChange={handleChange}
            />
            <CheckOutInputField
              label="Street address"
              name="streetName"
              onChange={handleChange}
              required
              value={formState.streetName}
              error={validateOnSubmission(!formState.streetName)}
            />

            <CheckOutInputField
              label="Town / City"
              name="city"
              onChange={handleChange}
              required
              value={formState.city}
              error={validateOnSubmission(!formState.city)}
            />
            <CheckOutInputField
              label="Postcode"
              name="postalCode"
              onChange={handleChange}
              required
              value={formState.postalCode}
              error={validateOnSubmission(!formState.postalCode)}
            />

            <MUIFormControl
              fullWidth
              variant="filled"
              size="small"
              required
              error={validateOnSubmission(!formState.country)}
              data-testid="country-select"
            >
              <CheckoutSelectLabel>Country</CheckoutSelectLabel>
              <CheckOutSelectField value={formState.country} name="country" onChange={handleChange}>
                {countryList.map((countryName) => (
                  <CheckOutSelectOption value={countryName} key={countryName}>
                    {countryName}
                  </CheckOutSelectOption>
                ))}
              </CheckOutSelectField>
            </MUIFormControl>
            <StyledMUICheckbox
              control={<CheckOutCheckBox name="saveDetails" onChange={handleChange} />}
              label="Save details for future orders"
            />
            <CheckOutInputField
              label={<OptionalCheckoutLabel label="Customer reference" />}
              name="customerReference"
              value={formState.customerReference}
              onChange={handleChange}
            />
            <CheckOutInputField
              label={
                <OptionalCheckoutLabel
                  label={useTranslation('vtaas-ui:checkout:customerAccountField', 'Customer account')}
                />
              }
              name="vtaas-ui:checkout:customerAccountField"
              onChange={handleChange}
              value={formState['vtaas-ui:checkout:customerAccountField']}
            />

            <LongCheckOutInputField
              label={<OptionalCheckoutLabel label="Message to the seller" />}
              name="messageToSeller"
              value={formState.messageToSeller}
              onChange={handleChange}
            />
          </MobileSection>
        </MobileSectionContainer>
        <MobileCheckoutActionBar
          setValidate={setValidate}
          isOrderPOR={isOrderPOR}
          isFetching={isFetching}
          goBack={goBack}
        />
      </FormContainer>
    </MobileCheckoutFormWrapper>
  );
}

const MobileSectionContainer = styled('div')`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  padding: 0 2rem 0 2rem;
`;

const MobileSection = styled(Column)`
  width: 100%;
  height: 100%;
`;

const MobileCheckoutFormWrapper = styled('form')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  min-height: 100%;
  min-width: 100%;

  border-radius: 0px 0px 4px 4px;
`;

const MobileCheckOutTitle = styled(MUITypography)`
  width: 100%;
  margin: 2rem 0 0rem 0;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
