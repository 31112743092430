import { faChevronLeft, faFileExport, faUserPlus, faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { StandardBreadcrumbs } from 'src/components/Breadcrumbs/StandardBreadcrumbs';
import { EmptyStyledButton } from 'src/components/Button';
import { spacing } from 'src/constants/spacing';
import { useUser } from 'src/providers/User';
import { can } from 'src/services/Can';
import { SearchField } from 'src/components/SearchField';
import { exportUserList } from './exportUserList';
import { PrimaryButton } from './styles';
import type { FullUser } from './UserManagement';

interface UserManagementHeaderProps {
  onAddNewUser: () => void;
  sortedUserList: FullUser[];
  setSearchValue: any;
  searchValue: any;
}
export function UserManagementHeader({
  onAddNewUser,
  sortedUserList,
  setSearchValue,
  searchValue,
}: UserManagementHeaderProps) {
  const { goBack } = useHistory();
  const user = useUser();
  const canAddUser = can(user, 'add', 'user');

  return (
    <FlexColumnContainer>
      <FlexBox>
        <PrimaryButton onClick={goBack} startIcon={<FontAwesomeIcon icon={faChevronLeft} />} variant="contained">
          Back
        </PrimaryButton>
      </FlexBox>
      <StandardBreadcrumbs
        current={{ name: 'User and discount management' }}
        parents={[{ name: 'My account', url: '/my-account' }]}
      />
      <FlexBox>
        <UserManagementTitle>
          <FontAwesomeIcon icon={faUsers} size="lg" /> User Management
        </UserManagementTitle>
        {canAddUser && (
          <FlexColumnContainer>
            <AddUserButton onClick={onAddNewUser} startIcon={<FontAwesomeIcon icon={faUserPlus} />}>
              Add new user
            </AddUserButton>
          </FlexColumnContainer>
        )}
        <UserManagementSearchField
          placeholder="Search for a user..."
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          data-testid="user-management-search"
        />
        <ExportUserListButton
          onClick={() => exportUserList(sortedUserList)}
          startIcon={<FontAwesomeIcon icon={faFileExport} />}
        >
          Export user list
        </ExportUserListButton>
      </FlexBox>
    </FlexColumnContainer>
  );
}

const UserManagementTitle = styled(MUITypography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  padding: 1rem;
`;

UserManagementTitle.defaultProps = { variant: 'h5' };

const FlexBox = styled(Box)`
  display: flex;
`;

const FlexColumnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ExportUserListButton = styled(EmptyStyledButton)`
  margin: auto 0 auto 1.5rem;
`;

const AddUserButton = styled(EmptyStyledButton)`
  margin: auto;
  margin-left: ${spacing.mediumSpacing};
`;

const UserManagementSearchField = styled(SearchField)`
  width: 200px;
  height: 2.6rem;
  margin: auto;
  margin-right: 0;
`;
