import { styled, alpha } from '@mui/material';
import MUITextField from '@mui/material/TextField';

export const PasswordTextField = styled(MUITextField, { shouldForwardProp: (prop) => prop !== 'match' })<{
  match?: boolean;
}>`
  box-shadow: 0 0 2px ${({ theme }) => theme.palette.grey[700]};

  border-radius: 5px 4px 0px 0px;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.palette.brand.contrastText};

  & .MuiFilledInput-root {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
    height: 56px;
  }

  & .MuiFilledInput-root:hover {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.2)};
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.7)};
  }

  & .MuiFilledInput-root.Mui-focused {
    background-color: ${({ theme }) => alpha(theme.palette.brand.contrastText, 0.1)};
  }

  & .MuiFilledInput-input {
    color: ${({ theme }) =>
      theme.palette.brand.contrastText}!important; // override autofill background color and text color
    background-color: transparent !important; // override autofill background color and text color
  }

  & .MuiFilledInput-root::before {
    border-bottom: none;
  }

  & .MuiFilledInput-root::after {
    border-bottom: 1px solid ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiFilledInput-root.Mui-error {
    border-bottom: none;
  }

  & .MuiFilledInput-root.Mui-error::after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.error.main};
  }

  & .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiInputLabel-root.Mui-error {
    color: ${({ theme }) => theme.palette.error.main};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiInputAdornment-root > button {
    color: ${({ theme }) => theme.palette.brand.contrastText};
  }

  & .MuiFormHelperText-root {
    background-color: ${({ theme, match }) => (match ? theme.palette.success.main : theme.palette.error.main)};
    padding-left: 1rem;
    margin: 4px 0 0 0;
    color: ${({ theme }) => theme.palette.common.black};
  }

  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.palette.common.black};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.brand.contrastText};
    caret-color: ${({ theme }) => theme.palette.brand.contrastText};
  }
`;

PasswordTextField.defaultProps = {
  variant: 'filled',
  fullWidth: true,
  InputLabelProps: { required: false },
};
