import md5 from 'md5';

export const createHash = (file: File): Promise<string> =>
  readFileAsArrayBuffer(file).then((buffer: Buffer) => {
    const hash = md5(buffer, { encoding: 'binary' });
    return hash;
  });

const readFileAsArrayBuffer = (file: File): Promise<Buffer> =>
  new Promise((resolve) => {
    const reader: FileReader = new FileReader();
    reader.onload = (): void => {
      if (!reader.result) {
        throw Error('Unable to hash file');
      } else {
        return resolve(reader.result as Buffer);
      }
    };
    reader.readAsArrayBuffer(file);
  });
