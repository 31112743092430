import { PartNode } from '@samsonvt/shared-types/productLambda';
import { NamedLink } from 'src/services/partNavigation';
import { BreadcrumbsContainer, BreadcrumbsLink, BreadcrumbsSeparator, CurrentBreadcrumb } from './styles';

interface IProps {
  current: Pick<PartNode, 'name'> | { name: string };
  parents: NamedLink[];
  sx?: {}; // used to style the breadcrumbs
}

export function StandardBreadcrumbs({ current, parents, sx }: IProps) {
  return (
    <BreadcrumbsContainer separator={<BreadcrumbsSeparator />} sx={sx}>
      {parents.map(({ url, name }) => (
        <BreadcrumbsLink
          key={url}
          // @ts-ignore For some reason `component` prop is not recognized when using styled()
          to={url}
        >
          {name}
        </BreadcrumbsLink>
      ))}
      <CurrentBreadcrumb>{current.name}</CurrentBreadcrumb>
    </BreadcrumbsContainer>
  );
}
