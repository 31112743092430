import { CartEntry } from 'src/providers/ShoppingCart';

import useShoppingCartMutation, { without, updateQuantity } from 'src/providers/ShoppingCart/useShoppingCartMutation';

import MobilePanelCartItem from './MobilePanelCartItem';
import { ListHeightContainer, ListBackground, StyledList } from './styles';

interface CartItemListProps {
  cart: CartEntry[];
}

export default function MobilePanelCartItemsList({ cart }: CartItemListProps) {
  const { mutate } = useShoppingCartMutation();

  return (
    <ListHeightContainer>
      <ListBackground>
        <StyledList>
          {cart.map((item) => (
            <MobilePanelCartItem
              key={item.partNumber}
              {...item}
              removeItem={() => mutate(without(cart, item.partNumber))}
              updateItemQuantity={(quantity: number) => mutate(updateQuantity(cart, quantity, item.partNumber))}
            />
          ))}
        </StyledList>
      </ListBackground>
    </ListHeightContainer>
  );
}
