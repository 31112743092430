import { ProductCategoriesPathEnumeration } from '@samsonvt/shared-types/productsTable';
import { encodeProductCategoryPathEnumeration, productCategoryPathEnumerationSeparator } from 'src/utils/urls';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { styled } from '@mui/material';
import MUITypography from '@mui/material/Typography';

interface ProductCategoriesListHeaderProps {
  decodedPathEnumeration: ProductCategoriesPathEnumeration;
}

export function ProductCategoriesListHeader({ decodedPathEnumeration }: ProductCategoriesListHeaderProps) {
  const currentCategories = decodedPathEnumeration.split(productCategoryPathEnumerationSeparator);

  const breadcrumbs = currentCategories.map((cat, index) => {
    const isLast = index === currentCategories.length - 1;
    return isLast ? (
      <CurrentProductCategoriesBreadcrumbs key={cat}>{cat}</CurrentProductCategoriesBreadcrumbs>
    ) : (
      <ProductCategoriesBreadcrumbsParentLink
        key={cat}
        to={`/product-library?productCategoryPath=${`${generateBreadcrumbLink(currentCategories, index)}`}`}
      >
        {cat}
      </ProductCategoriesBreadcrumbsParentLink>
    );
  });

  return (
    <ProductCategoriesBreadcrumbs separator={<ProductCategoriesBreadcrumbsSeparator icon={faChevronRight} />}>
      <ProductCategoriesBreadcrumbsParentLink to="/">All categories</ProductCategoriesBreadcrumbsParentLink>
      {breadcrumbs}
    </ProductCategoriesBreadcrumbs>
  );
}

const generateBreadcrumbLink = (currentCategories: string[], currentCategoryIndex: number) =>
  encodeProductCategoryPathEnumeration(
    currentCategories.slice(0, currentCategoryIndex + 1).join(productCategoryPathEnumerationSeparator)
  );

const ProductCategoriesBreadcrumbs = styled(MUIBreadcrumbs)`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ProductCategoriesBreadcrumbsParentLink = styled(Link)`
  color: ${({ theme }) => theme.palette.secondaryDarkest};
  text-decoration: underline;
  font-size: 1.25rem;
`;

const CurrentProductCategoriesBreadcrumbs = styled(MUITypography)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.common.black};
  margin-right: 1rem;
`;

const ProductCategoriesBreadcrumbsSeparator = styled(FontAwesomeIcon)`
  margin: 0 1rem;
`;
