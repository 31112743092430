import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { alpha, Box, styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import MUITableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableRow from '@mui/material/TableRow';
import MUITypography from '@mui/material/Typography';
import { StyledSelectField } from 'src/components/Select';
import { WhiteTextField } from 'src/components/TextInput/WhiteTextField';
import { spacing } from 'src/constants/spacing';

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1500px;
  min-width: 1050px; // SVT-1984 Prevent content jump before table loaded
  margin: auto;
  margin-top: 1rem;
`;

export const TableContainer = styled(MUITableContainer)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  margin-bottom: 5rem;
`;

export const TableHeader = styled(MUITableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
`;

TableHeader.defaultProps = { align: 'left' };

export const TableDataRow = styled(MUITableRow)`
  background-color: ${({ theme }) => theme.palette.common.white};
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  &:last-child td,
  &:last-child th {
    margin: 10px;
  }
`;

export const TableData = styled(MUITableCell)`
  color: ${({ theme }) => theme.palette.grey[900]};
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const EmptyTable = styled(MUITypography)`
  height: 330px;
  width: 100%;
  margin: auto;
  background-color: ${({ theme }) => theme.palette.whiteGlass};
  text-align: center;
  padding-top: 155px;
`;

export const StyledFilterIcon = styled(UnfoldMoreIcon)`
  vertical-align: middle;
  width: 20px;
  height: 20px;
`;

export const PrimaryButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SecondaryButton = styled(MUIButton)`
  text-transform: none;
  font-weight: bold;
  :disabled {
    background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.16)};
    color: ${({ theme }) => alpha(theme.palette.secondary.contrastText, 0.87)};
  }
`;

export const TableRowButton = styled(PrimaryButton)`
  width: fit-content;
`;

export const RowSecondaryButton = styled(TableRowButton)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SmallerText = styled('span')`
  font-size: smaller;
`;

export const CancelButton = styled(MUIButton)`
  margin-left: ${spacing.smallSpacing};
  color: ${({ theme }) => theme.palette.grey[900]};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: none;
  font-weight: 600;
  text-decoration-thickness: 2px;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    background-color: ${({ theme }) => alpha(theme.palette.secondary.light, 0.25)};
  }
`;

export const RowFilledInput = styled(WhiteTextField)`
  input {
    padding: 0.6rem;
  }
  input::placeholder {
    font-style: italic;
  }
`;

export const RowSelectField = styled(StyledSelectField)`
  width: 100%;
  .MuiFilledInput-input {
    padding: 0.6rem;
  }
`;
