import RequirementsBoxText from 'src/components/PasswordRequirementsBox.tsx';
import {
  WhitePasswordInputWithMatchIndicator,
  WhitePasswordInputWithShowHide,
} from 'src/components/TextInput/WhitePasswordInput';
import {
  ChangeYourPasswordContainer,
  ChangeYourPasswordContentContainer,
  ChangeYourPasswordDivider,
  ChangeYourPasswordLeftSection,
  ChangeYourPasswordRightSection,
  ChangeYourPasswordSectionsContainer,
  ChangeYourPasswordSubTitle,
  ChangeYourPasswordTitle,
  RequirementBoxContainer,
} from './styles';
import { AccountDetailsFormButton } from '../styles';
import { ChangePasswordFormProps } from '../../AccountDetailsPage';

export default function ChangeYourPassword({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  verifyNewPassword,
  setVerifyNewPassword,
  submit,
  checkPasswordsMatch,
  error,
}: ChangePasswordFormProps) {
  const areNewPasswordsMatching = checkPasswordsMatch();
  return (
    <ChangeYourPasswordContainer onSubmit={submit}>
      <ChangeYourPasswordTitle>Change your password</ChangeYourPasswordTitle>
      <ChangeYourPasswordContentContainer>
        <ChangeYourPasswordSubTitle>
          Please, type your old password first before changing to a new one:
        </ChangeYourPasswordSubTitle>
        <ChangeYourPasswordSectionsContainer>
          <input hidden autoComplete="username" />
          {/* done to placate chrome warn https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */}
          <ChangeYourPasswordLeftSection>
            <WhitePasswordInputWithShowHide
              label="Type your old password"
              value={oldPassword}
              onChange={(e: any) => setOldPassword(e.target.value)}
              testId="current-password-change-your-password-desktop"
              error={error}
            />
            <ChangeYourPasswordDivider />
            <WhitePasswordInputWithMatchIndicator
              label="Type your new password"
              value={newPassword}
              error={error}
              testId="new-password-desktop"
              onChange={(e: any) => setNewPassword(e.target.value)}
              match={areNewPasswordsMatching}
            />
            <WhitePasswordInputWithMatchIndicator
              label="Type your new password"
              value={verifyNewPassword}
              error={error}
              onChange={(e: any) => setVerifyNewPassword(e.target.value)}
              match={areNewPasswordsMatching}
              testId="new-password-verify-desktop"
            />
          </ChangeYourPasswordLeftSection>
          <ChangeYourPasswordRightSection>
            <RequirementBoxContainer>
              <RequirementsBoxText newPassword />
            </RequirementBoxContainer>
          </ChangeYourPasswordRightSection>
        </ChangeYourPasswordSectionsContainer>
        <AccountDetailsFormButton
          type="submit"
          data-testid="submit-new-pass-desktop"
          disabled={areNewPasswordsMatching !== undefined ? !areNewPasswordsMatching : false}
        >
          Save new password
        </AccountDetailsFormButton>
      </ChangeYourPasswordContentContainer>
    </ChangeYourPasswordContainer>
  );
}
