import { css, styled, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Html } from '@react-three/drei';
import { MouseEvent } from 'react';
import { border } from 'src/constants/borderSizes';
import { device } from 'src/constants/mediaQueries';
import { spacing } from 'src/constants/spacing';
import { ThemeProvider } from 'src/providers/Theme';
import { Vector3Tuple } from 'three';

interface FocalPointProps {
  onClick: (e: MouseEvent) => void;
  label: string;
  attenuateSize: boolean;
  scale: number;
  position: Vector3Tuple;
  theme: Theme;
}

interface HotspotParams {
  innerCircle: number;
  outerCircle: number;
  innerCircleOffset: number;
  outerCircleOffset: number;
  labelFontSize: number;
}
interface HotspotProps {
  hotspot: HotspotParams;
}

const shouldForwardProp = (prop: string) => prop !== 'labelFontSize';

const Label = styled('div', { shouldForwardProp })<{ labelFontSize: HotspotParams['labelFontSize'] }>`
  font-size: ${({ labelFontSize }) => `${labelFontSize}rem`};
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  border-radius: ${border.xLarge};
  padding: ${spacing.xSmallSpacing} ${spacing.smallSpacing};
  z-index: 1;
  white-space: nowrap;
`;

const Hotspot = styled('div')<HotspotProps>`
  ${({ theme, hotspot }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 0.8;
    transition: opacity 150ms;
    color: ${theme.palette.secondary.dark};

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 100%;
      display: block;
    }

    &:before {
      width: ${hotspot.outerCircle}rem;
      height: ${hotspot.outerCircle}rem;
      left: ${hotspot.outerCircleOffset}rem;
      top: ${hotspot.outerCircleOffset}rem;
      border: 3px solid ${theme.palette.secondary.dark};
    }

    &:after {
      width: ${hotspot.innerCircle}rem;
      height: ${hotspot.innerCircle}rem;
      top: ${hotspot.innerCircleOffset}rem;
      left: ${hotspot.innerCircleOffset + 1 / 16}rem;
      background: white;
    }

    :hover {
      opacity: 1;
    }

    div {
      border: ${border.small} solid ${theme.palette.secondary.dark};
    }
  `}
`;

interface HotspotWrapperProps {
  label: string;
  onClick: (e: MouseEvent) => void;
}

function HotspotWrapper({ label, onClick }: HotspotWrapperProps) {
  const isMobile = useMediaQuery(device.mobileOrTablet);
  const { hotspotSize } = useTheme();

  const defaultHotspotSize: HotspotParams = {
    outerCircle: 65 / 16,
    innerCircle: 54 / 16,
    outerCircleOffset: -16 / 16,
    innerCircleOffset: -11 / 16,
    labelFontSize: 1,
  };

  const largeHotspotSize: HotspotParams = {
    outerCircle: 85 / 16,
    innerCircle: 74 / 16,
    outerCircleOffset: -22 / 16,
    innerCircleOffset: -17 / 16,
    labelFontSize: 1.5,
  };

  const largeHotspotSizeMobile: HotspotParams = {
    outerCircle: 70 / 16,
    innerCircle: 59 / 16,
    outerCircleOffset: -18 / 16,
    innerCircleOffset: -13 / 16,
    labelFontSize: 1.1,
  };

  let hotspot = defaultHotspotSize;
  if (hotspotSize === 'large') {
    hotspot = isMobile ? largeHotspotSizeMobile : largeHotspotSize;
  }

  return (
    <Hotspot onClick={onClick} data-testid={`hotspot${label}`} hotspot={hotspot}>
      <Label labelFontSize={hotspot.labelFontSize}>{label}</Label>
    </Hotspot>
  );
}

export function FocalPoint({ label, attenuateSize, scale, position, onClick, theme }: FocalPointProps) {
  return (
    <Html distanceFactor={attenuateSize ? scale : undefined} position={position} zIndexRange={[100, -100]}>
      <ThemeProvider theme={theme}>
        <HotspotWrapper onClick={onClick} label={label} />
      </ThemeProvider>
    </Html>
  );
}
