import type { PartNode } from '@samsonvt/shared-types/productLambda';

export default function flattenParts(bom: PartNode) {
  const productOptions: PartNode[] = [];

  function pushParts(bom: PartNode) {
    if (bom.objectType === 'product-option') {
      productOptions.push(bom);
    }
    bom.children.forEach(pushParts);
  }
  pushParts(bom);

  return productOptions;
}
