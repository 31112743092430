import { Portal } from '@mui/material';
import { DiscountScheme } from '@samsonvt/shared-types/discountLambda';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Toast } from 'src/components/Toast';
import { deleteUser, patchUser, postUser } from 'src/services/api';
import { EditUserRow } from './EditUserRow';
import { StaticUserRow } from './StaticUserRow';
import { useInvitationEmail } from './useInvitationEmail';
import { UserRole } from './UserManagement';
import { UserHeader, UserItem } from './UserManagementTable';

interface UserListRowProps {
  userItem: UserItem;
  roles: UserRole[];
  discounts: DiscountScheme[];
  onAddNewUserCancel: () => void;
  onSaveUserMessage: (message: string) => void;
  sendResetPasswordEmail: (email: string) => Promise<void>;
}

export function UserListRow({
  userItem,
  roles,
  discounts,
  onAddNewUserCancel,
  onSaveUserMessage,
  sendResetPasswordEmail,
}: UserListRowProps) {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(Boolean(userItem.isNewUser));
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { emailSentToast, emailErrorToast, resendInvite, disableResendButton } = useInvitationEmail(userItem);

  const onSaveUserSuccess = () => {
    queryClient.invalidateQueries(['users']);
    onAddNewUserCancel();
    setIsEditing(false);
  };

  const postUserHandler = async ({ user }: { user: UserHeader }) => postUser(user);

  const {
    isLoading: createUserLoading,
    mutate: createUserRequest,
    isError: createUserError,
  } = useMutation(postUserHandler, {
    onSuccess: () => {
      onSaveUserSuccess();
      onSaveUserMessage('User successfully created');
    },
    onError: (error: any) => {
      if (error?.message) setErrorMessage(error.message);
    },
    onMutate: () => {
      setErrorMessage(undefined);
    },
  });

  const patchUserHandler = async ({ user, userId }: { user: UserHeader; userId: string }) => patchUser(userId, user);

  const deleteUserHandler = async ({ userId }: { userId: string }) => deleteUser(userId);

  const {
    isLoading: updateUserLoading,
    mutate: updateUserRequest,
    isError: updateUserError,
  } = useMutation(patchUserHandler, {
    onSuccess: () => {
      onSaveUserSuccess();
      onSaveUserMessage('User successfully updated');
    },
  });

  const {
    isLoading: deleteUserLoading,
    mutate: deleteUserRequest,
    isError: deleteUserError,
  } = useMutation(deleteUserHandler, {
    onSuccess: () => {
      onSaveUserSuccess();
      onSaveUserMessage('User successfully deleted');
    },
    onError: (error: any) => {
      if (error?.message) setErrorMessage(error.message);
    },
  });

  const isLoading = createUserLoading || updateUserLoading || deleteUserLoading;
  const isError = createUserError || updateUserError || deleteUserError;

  const editUser = () => setIsEditing(true);

  const cancelEdit = () => {
    if (userItem.isNewUser) {
      onAddNewUserCancel?.();
    } else {
      setIsEditing(false);
    }
  };

  const userRoleName = roles.find((role) => role.id === userItem.role)?.name || '';
  const selectedDiscount = discounts.find((discount) => discount.id === userItem.discountId);

  const toastErrorMessage =
    errorMessage || `It looks like there was an error ${userItem.isNewUser ? 'adding' : 'saving'} this user.`;

  return (
    <>
      {isEditing ? (
        <EditUserRow
          cancelEdit={cancelEdit}
          createUserRequest={createUserRequest}
          updateUserRequest={updateUserRequest}
          deleteUserRequest={deleteUserRequest}
          isLoading={isLoading}
          roles={roles}
          discounts={discounts}
          userItem={userItem}
          sendResetPasswordEmail={sendResetPasswordEmail}
        />
      ) : (
        <StaticUserRow
          userItem={userItem}
          editUser={editUser}
          userRoleName={userRoleName}
          resendInvite={resendInvite}
          disableResendButton={disableResendButton}
          discount={selectedDiscount}
          sendResetPasswordEmail={sendResetPasswordEmail}
        />
      )}
      <Portal>
        <Toast
          dependency={emailSentToast}
          severity="success"
          title="Success"
          message="Another invite email has been sent to the user."
        />
        <Toast
          dependency={emailErrorToast}
          severity="error"
          title="Error"
          message="It looks like there was an error sending the invite email to this user."
        />
        <Toast dependency={isError} severity="error" title="Error" message={toastErrorMessage} />
      </Portal>
    </>
  );
}
