import { styled } from '@mui/material';
import MUIBox from '@mui/material/Box';
import MUIListItem from '@mui/material/ListItem';
import MUITypography from '@mui/material/Typography';
import type { DiscountedPartPrice } from '@samsonvt/shared-types/partsTable';
import useFormattedPrice from 'src/services/formatCurrency';

interface CheckoutCartItemProps {
  partName: string;
  partNumber: string;
  quantity: number;
  unitPrice: DiscountedPartPrice;
}

export function DesktopCheckoutCartItem({
  partName,
  partNumber,
  quantity,
  unitPrice,
}: CheckoutCartItemProps): JSX.Element {
  return (
    <CheckoutListItem>
      <CartItemTitle>
        <PartName>{partName}</PartName> <span>•</span> <PartNumber>{partNumber}</PartNumber>
      </CartItemTitle>
      <CartItemInformation>
        <MUITypography> Quantity: {quantity} </MUITypography>
        <MUITypography variant="body1" fontWeight="bold" data-testid="checkout-cart-item-price">
          {useFormattedPrice({ price: unitPrice, smallFont: true })}
        </MUITypography>
      </CartItemInformation>
    </CheckoutListItem>
  );
}

const CheckoutListItem = styled(MUIListItem)`
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
`;

const CartItemTitle = styled(MUITypography)`
  margin: 0 5% 5% 5%;
  width: 100%;
`;

const PartName = styled('span')`
  max-width: 50%;
  margin-right: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const PartNumber = styled('span')`
  max-width: 50%;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CartItemInformation = styled(MUIBox)`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 90%;
  margin-left: 5%;
`;
