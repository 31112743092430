import { css, Global } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { device } from './constants/mediaQueries';
import { useForceUpdate } from './hooks/useForceUpdate';

/**
 * Rescale elements based on device size.
 * This is meant to get the elements to scale correctly on laptops with system 150% zoom.
 * Issue was visible mainly on work instructions sidebar.
 * Doesn't scale desktop or mobile which look good without adjustments.
 */

export function resizeAppHelper() {
  const pixelRatio = window?.devicePixelRatio || 1;

  const shouldResize = pixelRatio > 1 && pixelRatio < 1.5;

  const newScale = shouldResize ? 0.75 : 1;

  const sizesStyle = css`
    html {
      @media not ${device.mobileOrTablet} {
        // As of SVT-2109 zooming out should be happening on all laptops and desktops with high device pixel ratio.
        // Assuming they all have auto-zooming applied
        font-size: ${newScale * 100}%;
      }
    }
  `;
  return { sizesStyle, newScale };
}

export function ResizeAppGlobalStyles() {
  const forceUpdate = useForceUpdate();
  const resizeCss = useCallback(() => {
    forceUpdate(); // force the css styles to refresh when resizing happens
  }, [forceUpdate]);

  useEffect(() => {
    window.addEventListener('resize', resizeCss);
    return () => {
      window.removeEventListener('resize', resizeCss);
    };
  }, [resizeCss]);

  const { sizesStyle } = resizeAppHelper();
  return <Global styles={sizesStyle} />;
}
