import { Dispatch, SetStateAction, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import type { ScreenName } from 'src/App';
import MobileProductViewHeader from 'src/components/Header/Mobile/MobileProductViewHeader';
import { useCurrentTabName } from 'src/hooks/useCurrentTabName';
import { ShoppingCartPanelContext } from 'src/providers/ShoppingCart';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import filterProductOptionsFromParents from 'src/services/filterProductOptionsFromParents';
import { usePart } from 'src/services/part';
import { usePartNavigation } from 'src/services/partNavigation';
import { MobileCheckoutHeader } from './MobileCheckoutHeader';
import { MobileToolbar } from './MobileToolbar';

export default function MobileHeader({
  setOpenMobileProductMenu,
}: {
  setOpenMobileProductMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const match = useRouteMatch<{ screenName: ScreenName }>(`/:screenName`);
  const currentTabName = useCurrentTabName();
  const { current } = usePart();
  const { parents } = usePartNavigation();
  const { setPanelOpen } = useContext(ShoppingCartPanelContext);
  const { data: cart } = useShoppingCartQuery();
  const breadcrumbsParents = filterProductOptionsFromParents(parents);
  const isPartsCatalogue = currentTabName === 'parts-catalogue';
  const isWorkInstructions = currentTabName === 'work-instructions';
  const isCheckout = match?.params.screenName === 'checkout';

  const openPanel = () => setPanelOpen(true);

  const triggerMobileProductMenu = () => setOpenMobileProductMenu(true);

  if (isPartsCatalogue || isWorkInstructions) {
    return (
      <MobileProductViewHeader
        current={current}
        parents={breadcrumbsParents}
        numberOfCartItems={cart.reduce((total, { quantity }) => total + quantity, 0)}
        setPanelOpen={setPanelOpen}
        enableShoppingCart={isPartsCatalogue}
        triggerMobileProductMenu={triggerMobileProductMenu}
      />
    );
  }
  if (isCheckout) {
    return <MobileCheckoutHeader openPanel={openPanel} triggerMobileProductMenu={triggerMobileProductMenu} />;
  }

  return <MobileToolbar openPanel={openPanel} triggerMobileProductMenu={triggerMobileProductMenu} />;
}
