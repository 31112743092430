import { styled } from '@mui/material';
import { font } from 'src/constants/fontSizes';
import { spacing } from 'src/constants/spacing';
import MUITypography from '@mui/material/Typography';

export const SearchStyles = styled('div')`
  padding: ${spacing.mediumSpacing};

  h3 {
    margin-bottom: 0;
  }

  span {
    font-size: ${font.small};
  }
`;

export const PaddedBlock = styled('div')`
  padding: ${spacing.mediumSpacing};
`;

export const StyledTabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledTabContentPanel = styled('div')`
  border-top-right-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  flex-grow: 1;
`;

export const EmptyPartsTab = styled('div')`
  display: flex;
  align-items: center;
  margin: 1.5rem 1rem;
`;
export const EmptyPartsText = styled(MUITypography)`
  margin-left: 0.5rem;
`;
