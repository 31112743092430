export default function IconRotateModel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="19.024" viewBox="0 0 20.759 19.024">
      <g id="Group_710" data-name="Group 710" transform="translate(-8.5 -6.985)">
        <path
          id="Path_5199"
          data-name="Path 5199"
          d="M335.334,206.7h0a.579.579,0,0,0-.571,1.007c1.329.753,2.06,1.662,2.06,2.559,0,2.052-3.341,3.318-5.363,3.727a.579.579,0,0,0,.23,1.135c2.7-.547,6.291-2.108,6.291-4.862C337.981,208.932,337.041,207.665,335.334,206.7Z"
          transform="translate(-309.223 -191.775)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          opacity="0.5"
        />
        <path
          id="Path_5200"
          data-name="Path 5200"
          d="M131.1,18.376l-4.631-2.315a.579.579,0,0,0-.518,0l-4.631,2.315a.581.581,0,0,0-.32.518v5.789a.579.579,0,0,0,.32.518l4.631,2.315a.589.589,0,0,0,.259.061h0a.584.584,0,0,0,.259-.061L131.1,25.2a.579.579,0,0,0,.32-.518V18.894A.58.58,0,0,0,131.1,18.376Zm-5.469,7.685-3.473-1.737V19.831l3.473,1.737Zm.579-5.5-3.337-1.668,3.337-1.668,3.337,1.668Zm4.052,3.763-3.473,1.737V21.567l3.473-1.737Z"
          transform="translate(-107.33 -8.508)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_5201"
          data-name="Path 5201"
          d="M9.97,214.519l-1.232-2.04a.579.579,0,0,0-.991.6l.746,1.235-1.534-.239c-2.164-.375-5.8-1.637-5.8-3.809,0-.9.732-1.807,2.061-2.56a.579.579,0,0,0-.571-1.007C.94,207.666,0,208.933,0,210.265c0,2.954,4.047,4.513,6.959,4.982l1.112.144-.913.9a.579.579,0,1,0,.813.824l1.911-1.886A.579.579,0,0,0,9.97,214.519Z"
          transform="translate(9 -191.775)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
          opacity="0.5"
        />
      </g>
    </svg>
  );
}
