import { styled, alpha } from '@mui/material';
import { StyledMUIButton } from 'src/components/Button';
import MUIInput from '@mui/material/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

interface QuantityCounterProps {
  quantity: number;
  addOneMore: () => void;
  removeOneLess: () => void;
  removeItem: () => void;
  updateWithInputField: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validateValue: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export function QuantityCounter({
  quantity,
  addOneMore,
  removeOneLess,
  removeItem,
  updateWithInputField,
  validateValue,
}: QuantityCounterProps) {
  const isTheMinumQuantityAllowed = quantity === 1;

  return (
    <QuantityContainer>
      <ModifyQuantityLeft onClick={isTheMinumQuantityAllowed ? removeItem : removeOneLess}>
        {isTheMinumQuantityAllowed ? <FontAwesomeIcon icon={faTrash} /> : '-'}
      </ModifyQuantityLeft>
      <Quantity value={quantity} disableUnderline onChange={updateWithInputField} onBlur={validateValue} />
      <ModifyQuantityRight onClick={addOneMore}>+</ModifyQuantityRight>
    </QuantityContainer>
  );
}

const QuantityContainer = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 1px;
  border-radius: 4px;
`;

const ModifyQuantityLeft = styled(StyledMUIButton)`
  min-width: 0px;
  width: 30px;
  height: 30px;
  border-radius: 4px 0px 0px 4px;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: ${({ theme }) => theme.shadows[2]};

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

const ModifyQuantityRight = styled(ModifyQuantityLeft)`
  border-radius: 0px 4px 4px 0px;
`;

const Quantity = styled(MUIInput)`
  ${({ theme }) => theme.mixins.hideArrowsInNumberTypeInput}
  .MuiInput-input {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.7)};
    text-align: center;
    padding: 0;
    margin: 0 1px;
    height: 30px;
    width: 32px;
  }
`;

Quantity.defaultProps = { type: 'number' };
