import exposeAsGlobal from 'src/services/exposeAsGlobal';

import { Dispatch, SetStateAction } from 'react';
import * as THREE from 'three';
import type { Vector3Tuple } from 'three';
import { useThree, extend, Object3DNode } from '@react-three/fiber';
import CameraControls from 'camera-controls';

import { Theme } from '@mui/material';
import { EditorTools } from '../EditorTools/EditorTools';
import { HUDWrapper } from './OnscreenControls/HUDWrapper';
import { FullControls } from './OnscreenControls/FullControls';
import { ResetControls } from './OnscreenControls/ResetControls';
import { CoordinatesEditorPanel } from '../EditorTools/CoordinatesEditorPanel';
import { useCameraControls } from './useCameraControls';
import { useTransformControls } from './useTransformControls';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      cameraControls: Object3DNode<CameraControls, typeof CameraControls>;
    }
  }
}

CameraControls.install({ THREE });
extend({ CameraControls });

interface IAnimCamera {
  defaultOrbitCenter: Vector3Tuple;
  defaultPosition: Vector3Tuple;
  onCameraHasMovedToNewPositionByProps: Dispatch<SetStateAction<boolean>>;
  theme: Theme;
  fullControls?: boolean;
  applySvtScaling?: boolean;
}

export function AnimCamera({
  defaultOrbitCenter,
  defaultPosition,
  onCameraHasMovedToNewPositionByProps,
  theme,
  fullControls = true,
  applySvtScaling = false, // This prop is maintaining legacy scaling. Will be removed at some point in the future. Hence the default
}: IAnimCamera) {
  const { gl, camera } = useThree();

  exposeAsGlobal('camera', camera);

  const dollySpeed = applySvtScaling ? 1.0 : 0.3;
  const minZoomLevel = 0.5;
  // const minZoomLevel = applySvtScaling ? 0.5 : 0.5;
  // TODO: figure out what should be a min zoom level in the context of new camera settings
  const maxZoomLevel = applySvtScaling ? 10 : 50;

  const {
    transformControlsEnabled,
    pointCoords,
    setPointCoords,
    cameraCoords,
    setCameraCoords,
    inputCoordinates,
    setInputCoordinates,
  } = useTransformControls();

  const { cameraRef, resetCamera } = useCameraControls(
    transformControlsEnabled,
    defaultOrbitCenter,
    defaultPosition,
    camera,
    setCameraCoords,
    onCameraHasMovedToNewPositionByProps
  );

  return (
    <>
      <cameraControls
        ref={cameraRef}
        args={[camera, gl.domElement]}
        minDistance={minZoomLevel}
        maxDistance={maxZoomLevel}
        restThreshold={0.1}
        dollySpeed={dollySpeed}
      />
      {cameraRef.current && (
        <HUDWrapper theme={theme}>
          {fullControls ? (
            <FullControls
              cameraControls={cameraRef.current}
              resetCamera={resetCamera}
              minZoomLevel={minZoomLevel}
              maxZoomLevel={maxZoomLevel}
            />
          ) : (
            <ResetControls cameraControls={cameraRef.current} resetCamera={resetCamera} />
          )}
        </HUDWrapper>
      )}

      {transformControlsEnabled && (
        <>
          <CoordinatesEditorPanel
            cameraControls={cameraRef.current}
            setInputCoordinates={setInputCoordinates}
            cameraCoords={cameraCoords}
            pointCoords={pointCoords}
          />

          <EditorTools
            cameraControls={cameraRef.current}
            setPointCoords={setPointCoords}
            inputCoordinates={inputCoordinates}
          />
        </>
      )}
    </>
  );
}
