import styled from '@emotion/styled';
import { TextFieldProps } from '@mui/material';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { WhiteTextFieldWithoutLabel } from 'src/components/TextInput/WhiteTextFieldWithoutLabel';

export default function AttributePriceField(props: TextFieldProps & { preferredCurrency: string | undefined }) {
  const { label, disabled, preferredCurrency, ...rest } = props;
  return (
    <Label disabled={disabled}>
      {label}
      <PriceInputContainer>
        {currencySymbol(preferredCurrency)}
        <TextField {...rest} />
      </PriceInputContainer>
    </Label>
  );
}

const currencySymbol = (currency: ISO4217 = 'GBP') =>
  new Intl.NumberFormat('EN-gb', { style: 'currency', currency }).formatToParts()[0].value;

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.25 : 1)};
`;

const TextField = styled(WhiteTextFieldWithoutLabel)`
  width: 10rem;
  input {
    font-weight: bold;
  }
`;

const PriceInputContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
`;
