import { Theme } from '@mui/material';
import { useMemo } from 'react';
import { AnimationClip, AnimationMixer, Object3D } from 'three';
import { AnimationAction } from './AnimationAction';
import exposeAsGlobal from '../../../services/exposeAsGlobal';

interface AnimationProps {
  scene: Object3D;
  clip: AnimationClip | undefined;
  showPauseButton: boolean;
  cameraHasMovedToNewPositionByProps: boolean;
  theme: Theme;
}

export function Animation({ scene, clip, showPauseButton, cameraHasMovedToNewPositionByProps, theme }: AnimationProps) {
  const mixer = useMemo(() => {
    const mixer = new AnimationMixer(scene);
    mixer.timeScale = 1;
    return mixer;
  }, [scene]);

  exposeAsGlobal('clip', clip);
  return clip ? (
    <AnimationAction
      cameraHasMovedToNewPositionByProps={cameraHasMovedToNewPositionByProps}
      mixer={mixer}
      clip={clip}
      showPauseButton={showPauseButton}
      theme={theme}
    />
  ) : null;
}
