import { styled } from '@mui/material';

export const Panel = styled('div')`
  display: block;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  height: 100%;
  background: ${({ theme }) => theme.palette.sidebarBackground.main};
`;

export const PanelInnerContainer = styled('div')`
  color: ${({ theme }) => theme.palette.sidebarBackground.contrastText};
  min-height: 100%;
  height: 1px;
  overflow-y: scroll;
`;
