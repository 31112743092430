import { RepairListAPIResponse, RepairHeader } from '@samsonvt/shared-types/repairLambda';
import { getFormattedRepairTime } from 'src/services/utils';
import {
  StyledRepairListItem,
  StyledRepairName,
  StyledRepairListDurationRow,
  StyledAccessTimeIcon,
  StyledRepairListDurationTitle,
  StyledRepairListDurationBadge,
  RepairsButton,
  SecondaryDivider,
} from './styles';

interface RepairListItemsProps {
  repairs: RepairListAPIResponse | undefined;
  url: string;
  tagUrlSection: string | undefined;
  repairTag: string | undefined;
}

export function RepairListItems({ repairs, url, tagUrlSection: search, repairTag }: RepairListItemsProps) {
  const tagFilter = (repair: RepairHeader) => (repairTag ? !!(repair.tags && repair.tags.includes(repairTag)) : true);

  return (
    <>
      {(repairs as RepairListAPIResponse) // We only display this tab when repair list is defined
        .filter(tagFilter)
        .map((repair) => (
          <div key={repair.repairId}>
            <StyledRepairListItem data-testid="repair-element">
              <div>
                <StyledRepairName>{repair.name}</StyledRepairName>
                {!!repair.duration && (
                  <StyledRepairListDurationRow data-testid="repair-list-duration">
                    <StyledAccessTimeIcon />
                    <StyledRepairListDurationTitle>Duration:</StyledRepairListDurationTitle>
                    <StyledRepairListDurationBadge>
                      {getFormattedRepairTime(repair.duration)}
                    </StyledRepairListDurationBadge>
                  </StyledRepairListDurationRow>
                )}
              </div>
              <RepairsButton data-testid="repair-element-button" to={`${url}/${repair.repairId}${search}`}>
                View
              </RepairsButton>
            </StyledRepairListItem>
            <SecondaryDivider />
          </div>
        ))}
    </>
  );
}
