// Temporarily commented out until we have back to library functionality
// import { faGridHorizontal } from '@fortawesome/pro-thin-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { StyledMUIButton } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { SimpleSpinner } from '../Loading/SimpleSpinner';
import { ProgressBar } from './ProgressBar';
import { FileUploadStatusMap } from './UploadInProgress';

interface Props {
  filesUploadStatus: FileUploadStatusMap;
  close: () => void;
  isOpen: boolean;
}

export function UploadProgressModal({ filesUploadStatus, isOpen, close }: Props) {
  const isUploading = [...filesUploadStatus.values()].some(
    (value) => value.isSuccess === undefined && value.isError === undefined
  );
  const successfullyUploaded = [...filesUploadStatus.values()].filter((value) => value.isSuccess).length;
  // const history = useHistory();

  // Temporarily commented out until we have back to library functionality
  // const goToLibrary = () => {
  //   // history.push('/product-library');
  //   // From the design, it looks like we should just close the modal
  //   close();
  // };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent data-testid="upload-in-progress">
        <EdgeBarTop>
          {isUploading ? (
            <EdgeBarText>Just a moment, we&apos;re currently uploading {filesUploadStatus.size} files</EdgeBarText>
          ) : (
            <EdgeBarText>{successfullyUploaded} files uploaded successfully</EdgeBarText>
          )}
        </EdgeBarTop>
        <FlexColumn>
          {[...filesUploadStatus.entries()].map(([fileName, value]) => (
            <ProgressBar key={fileName} name={fileName} fileUploadStatus={value} />
          ))}
        </FlexColumn>
        <EdgeBarBottom>
          {
            isUploading ? (
              <FlexRow style={{ alignItems: 'center' }}>
                <SimpleSpinner size="1.5em" />
                <BottomBarText>Uploading in progress please do not leave this page</BottomBarText>
              </FlexRow>
            ) : (
              <BackToLibraryButton onClick={close}>OK</BackToLibraryButton>
            )
            // Temporarily commented out
            // : (
            //   <BackToLibraryButton onClick={goToLibrary}>
            //     <FontAwesomeIcon icon={faGridHorizontal} size="lg" style={{ marginRight: '0.5em' }} /> Return to Library
            //   </BackToLibraryButton>
            // )
          }
        </EdgeBarBottom>
      </ModalContent>
    </Modal>
  );
}

const FlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled('div')`
  display: flex;
`;

const BackToLibraryButton = styled(StyledMUIButton)`
  margin-left: auto;
`;

const EdgeBarText = styled('span')``;

const BottomBarText = styled(EdgeBarText)`
  margin-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

const EdgeBarTop = styled('div')`
  width: 100%;
  background: ${({ theme }) => theme.palette.grey['900']};
  color: ${({ theme }) => theme.palette.grey['100']};
  position: absolute;
  height: 3rem;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1.875rem;
  display: flex;
  align-items: center;
`;

const EdgeBarBottom = styled(EdgeBarTop)`
  top: unset;
  bottom: 0;
`;

const ModalContent = styled('div')`
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
`;
