import { styled } from '@mui/material';
import AttributeTextField from './AttributeTextField';
import { PartDetailsForm } from '../EditModal';

export type AttributesTabProps = PartDetailsForm & {
  setPartForm: React.Dispatch<React.SetStateAction<PartDetailsForm | undefined>>;
};

export default function Attributes({ customAttributes, setPartForm }: AttributesTabProps) {
  const customAttributesNames = customAttributes ? Object.keys(customAttributes) : undefined;

  if (!customAttributesNames?.length || !customAttributes) {
    return <>No attributes to show</>;
  }

  return (
    <Column>
      {customAttributesNames.map((name) => (
        <AttributeTextField
          label={name}
          value={customAttributes[name]}
          key={name}
          onChange={(e) => {
            setPartForm((prevForm) => ({
              ...prevForm!, // we are sure we have the form at this point
              customAttributes: { ...prevForm!.customAttributes, [name]: e.target.value },
            }));
          }}
        />
      ))}
    </Column>
  );
}

const Column = styled('div')`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
`;
