import { styled } from '@mui/material';

import { validate as validateUuid } from 'uuid';

import { spacing } from 'src/constants/spacing';
import type { PartNode } from '@samsonvt/shared-types/productLambda';

const shouldShowSubtitle = (bomPart: PartNode) => {
  const { name, id } = bomPart;
  const hasRealPartNumber = bomPart && !validateUuid(id);

  if (!hasRealPartNumber) {
    return false;
  }
  if (!id) {
    return false;
  }
  return name !== id;
};

export default function TabHeader({ bomPart }: { bomPart: PartNode }) {
  const { name, id } = bomPart;

  if (!shouldShowSubtitle(bomPart)) {
    return null;
  }

  return <StyledProductSubTitle>{name !== id && id}</StyledProductSubTitle>;
}

const StyledProductSubTitle = styled('h5')`
  padding: ${spacing.mediumSpacing} 0;
  font-weight: 400;
  margin-bottom: 0;
  word-break: break-word;
  font-size: 16px;
`;
