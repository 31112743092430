import { styled } from '@mui/material';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { EditButton } from 'src/components/EditParts/EditButton';
import { spacing } from 'src/constants/spacing';
import { Feature } from 'src/Feature';
import { usePermissions } from 'src/hooks/usePermissions';
import exposeAsGlobal from 'src/services/exposeAsGlobal';
import { useInvalidateProductQuery } from 'src/services/useInvalidateProductQuery';
import { validate as validateUuid } from 'uuid';

export default function TabHeader({ currentPart }: { currentPart: PartNode }) {
  const { name, id, partNumber } = currentPart;
  const hasRealPartNumber = currentPart && !validateUuid(id);

  const canUpdateProduct = usePermissions(['update', 'product.partsCatalogue']);

  // ONLY FOR TESTING PURPOSES
  // TODO remove after testing
  const { invalidateProductQuery } = useInvalidateProductQuery();
  exposeAsGlobal('invalidateProductQuery', invalidateProductQuery);

  if (!hasRealPartNumber) {
    return null;
  }

  return (
    <>
      <StyledProductTitle data-testid="sidebar-active-title">
        {name}
        <Feature flag="edit-parts">{canUpdateProduct ? <EditButton part={currentPart} /> : null}</Feature>
      </StyledProductTitle>
      <StyledProductSubTitle>{partNumber}</StyledProductSubTitle>
    </>
  );
}

export const StyledProductTitle = styled('h3')`
  font-weight: bold;
  margin-bottom: 0;
  margin-top: ${spacing.mediumSpacing};
  word-break: break-word;
  font-size: 1.75rem;
  position: relative;
`;

const StyledProductSubTitle = styled('h5')`
  font-weight: bold;
  padding: ${spacing.smallSpacing} 0;
  margin-bottom: 0;
  word-break: break-word;
  font-size: 16px;
`;
