import { styled } from '@mui/material';
import MUIDialogActions from '@mui/material/DialogActions';
import MUITypography from '@mui/material/Typography';
import { StyledMUIButton } from '../Button';
import { FlexRow } from './styles';

type Props = {
  initialPartNumber: string;
  addPrevToSupersessions: boolean | undefined;
  setAddPrevToSupersessions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export function AddToSupersessionsDecision({
  initialPartNumber,
  setAddPrevToSupersessions,
  addPrevToSupersessions,
}: Props) {
  const yes = () => {
    setAddPrevToSupersessions(true);
  };
  const no = () => {
    setAddPrevToSupersessions(false);
  };

  const selectedYes = addPrevToSupersessions === true;
  const selectedNo = addPrevToSupersessions === false;
  const yesVariant = selectedYes ? 'contained' : 'outlined';
  const noVariant = selectedNo ? 'contained' : 'outlined';

  return (
    <div>
      <DialogActions>
        <FlexRow style={{ width: '100%', alignItems: 'center' }}>
          <PromptText>Add &ldquo;{initialPartNumber}&rdquo; to the Previous part numbers?</PromptText>
          <ChoiceButton selected={selectedNo} color="secondary" variant={noVariant} type="button" onClick={no}>
            No
          </ChoiceButton>
          <ChoiceButton selected={selectedYes} color="secondary" variant={yesVariant} type="button" onClick={yes}>
            Yes
          </ChoiceButton>
        </FlexRow>
      </DialogActions>
    </div>
  );
}

export const DialogActions = styled(MUIDialogActions)`
  padding: 1rem 2rem;
  gap: 2rem;
  background-color: ${({ theme }) => theme.palette.grey[900]};
`;

const PromptText = styled(MUITypography)`
  margin-right: auto;
  color: white;
`;

const ChoiceButton = styled(StyledMUIButton)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) => (selected ? theme.palette.secondary.main : theme.palette.grey[900])};
  color: ${({ theme, selected }) => (selected ? theme.palette.grey[900] : theme.palette.primary.contrastText)};
  font-size: 1.1rem;
`;
