import { faCartCircleExclamation, faCartPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import type { PartNode } from '@samsonvt/shared-types/productLambda';
import { useContext } from 'react';
import { StyledAddToCartButton } from 'src/components/Button';
import { ModelContext } from 'src/providers/Model';
import { useProduct } from 'src/providers/Product';
import { extractAndSaveMetaObject, ShoppingCartPanelContext } from 'src/providers/ShoppingCart';
import useShoppingCartMutation, { addOne } from 'src/providers/ShoppingCart/useShoppingCartMutation';
import useShoppingCartQuery from 'src/providers/ShoppingCart/useShoppingCartQuery';
import { useUser } from 'src/providers/User';
import { calculateDiscountedPrice } from 'src/services/formatCurrency';
import { isPartBuyable } from 'src/services/isPartBuyable';

const StyledDrawerCartButton = styled(StyledAddToCartButton)`
  width: 100%;
  height: 2.625rem;
`;

export default function AddToCartButton({ part }: { part: PartNode }) {
  const { discountPercentage } = useUser();
  const { productId } = useProduct();

  const { partNumber, price, name, eCommerceId } = part;
  const { data: cart } = useShoppingCartQuery();
  const { mutate } = useShoppingCartMutation();

  const { setPanelOpen } = useContext(ShoppingCartPanelContext);

  const { model } = useContext(ModelContext);

  const currentQuantity = cart.find(({ partNumber }) => partNumber === part.partNumber)?.quantity;

  const clickHandler = isPartBuyable(price)
    ? () => {
        setPanelOpen(true);
        mutate(
          addOne(cart, {
            partName: name,
            partNumber,
            unitPrice: calculateDiscountedPrice(price, discountPercentage), // While the price on product's part is an original price, the price we use in shopping cart and orders is a discounted one.
            productId,
            quantity: 1,
            eCommerceId,
          })
        );
        extractAndSaveMetaObject(part, model);
      }
    : undefined;

  let buttonText: string;
  if (isPartBuyable(price)) {
    buttonText = currentQuantity ? 'Add one more part' : 'Add to cart';
  } else {
    buttonText = 'Not available to order';
  }

  return (
    <div>
      <StyledDrawerCartButton
        data-testid="add-to-cart-button"
        size="small"
        disabled={!clickHandler}
        startIcon={<FontAwesomeIcon icon={isPartBuyable(price) ? faCartPlus : faCartCircleExclamation} />}
        onClick={clickHandler}
      >
        {buttonText}
      </StyledDrawerCartButton>
      <span>{currentQuantity && ` (${currentQuantity} in the cart)`}</span>
    </div>
  );
}
