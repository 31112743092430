import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Footer } from 'src/components/Footer';
import { styled } from '@mui/material';
import { RepairTabPanel } from './RepairTabPanel';
import { SidebarRepairList } from './SidebarRepairList';

const StyledBomTabPanel = styled('div')`
  min-height: calc(100% - 2.4em);
  height: 1px; // Fix a CSS bug where children won't inherit min-height from parents
`;

export function WorkInstructionsPanelMobile() {
  const { path } = useRouteMatch();

  return (
    <>
      <StyledBomTabPanel>
        <Switch>
          <Route exact path={path}>
            <SidebarRepairList />
          </Route>
          <Route path={`${path}/:detailId`}>
            <RepairTabPanel />
          </Route>
        </Switch>
      </StyledBomTabPanel>
      <Footer transparent />
    </>
  );
}
