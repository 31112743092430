import { createTheme, ThemeProvider as ThemeProviderImpl } from '@mui/material/styles';
import { AccountDBSource, ColourSettings } from '@samsonvt/shared-types/accountsTable';
import { useContext, useMemo } from 'react';
import { breakpoints } from 'src/constants/mediaQueries';
import { Color } from 'three';
import { TenantContext } from '../Tenant';

type TenantConfig = {
  [key in ColourSettings]: string;
} & {
  hotspotSize: AccountDBSource['hotspotSize'];
};

declare module '@mui/material/styles' {
  interface Palette {
    whiteGlass: React.CSSProperties['color'];
    white56: React.CSSProperties['color'];
    modalBackdrop: React.CSSProperties['color'];
    secondaryDarkest: React.CSSProperties['color'];
    sidebarBackground: Palette['primary'];
    brand: Palette['primary'];
    canvasBackground: { srgb: string; linear: string };
  }
  interface PaletteOptions {
    whiteGlass: string;
    white56: string;
    modalBackdrop: string;
    sidebarBackground: PaletteOptions['primary'];
    brand: PaletteOptions['primary'];
    secondaryDarkest: string;
    canvasBackground: { srgb: string; linear: string };
  }
  interface Theme {
    hotspotSize: AccountDBSource['hotspotSize'];
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    hotspotSize: AccountDBSource['hotspotSize'];
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    hideScrollbar: string;
    hideArrowsInNumberTypeInput: string;
  }

  interface MixinsOptions {
    hideScrollbar: string;
    hideArrowsInNumberTypeInput: string;
  }
}

export function ThemeProvider(props: any) {
  const { colours: tenantColours, hotspotSize } = useContext(TenantContext) || {}; // Defaulting to {} as a workaround for lack of Tenant context in HTMLPseudoportals
  const theme = useMemo(() => {
    const defaultsMergedWithTenantConfig = {
      ...defaultGlobalConfig.SamsonVT,
      ...tenantColours,
      hotspotSize: hotspotSize || defaultGlobalConfig.SamsonVT.hotspotSize,
    };
    return createThemeFromConfig(defaultsMergedWithTenantConfig);
  }, [hotspotSize, tenantColours]);

  return <ThemeProviderImpl theme={theme} {...props} />; // Overwriting theme with potential props.theme; Helpful for tests and workaround for context passing to
}

const {
  palette: { augmentColor },
} = createTheme(); // Augment color not importable directly, as it's not pure, depends on tonalOffset https://github.com/mui/material-ui/blob/99ca172ffd3c775240e710f647bb7880c328f7c7/packages/material-ui/src/styles/createPalette.js#L159

// exported for storybook
export const createThemeFromConfig = (config: TenantConfig) =>
  createTheme({
    palette: {
      primary: { main: config.primary },
      secondary: { main: config.secondary },
      brand: augmentColor({ color: { main: config.brand } }),
      sidebarBackground: augmentColor({ color: { main: config.sidebarBackground } }),
      whiteGlass: 'rgba(255,255,255, 0.12)',
      white56: 'rgba(255,255,255, 0.56)',
      modalBackdrop: 'rgba(0,0,0,0.3)',
      background: { default: '#E0E0E0' },
      secondaryDarkest: 'rgba(21, 109, 147, 1)',
      canvasBackground: {
        srgb: config.canvasBackground,
        // Due to the canvas being rendered in a non-linear color space, if we want to use the same color we need to manually map it.
        linear: `#${new Color(config.canvasBackground).convertLinearToSRGB().getHexString()}`,
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold',
      },
      fontFamily: 'Arial, sans-serif',
    },
    mixins: {
      hideScrollbar: `
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  
    /* Chrome Safari Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    `,

      hideArrowsInNumberTypeInput: `// hide type number arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }`,
    },
    breakpoints: { values: breakpoints },
    hotspotSize: config.hotspotSize,
  });

export const defaultGlobalConfig: { [key: string]: TenantConfig } = {
  SamsonVT: {
    // SamsonVT default dark
    brand: '#28253A', // Navbar and input, logo background. Can be dark or light.
    primary: '#28253A', // On dark themes === brand. Inactive top tabs, footer, HUD cube, shopping cart background. Always dark.
    sidebarBackground: '#365974', // Sidebar and checkout pages background. Always dark.
    secondary: '#54C8EE', // Buttons, highlights. Always light.
    canvasBackground: '#E5E5E5',
    hotspotSize: 'default',
  },
  Norton: {
    // light
    brand: '#FFFFFF',
    primary: '#191A1B',
    sidebarBackground: '#4A4C4F',
    secondary: '#AFCDD7',
    canvasBackground: '#E5E5E5',
    hotspotSize: 'default',
  },
};
