import { styled, Theme } from '@mui/material';
import HtmlStaticPseudoPortal from '../../HtmlStaticPseudoPortal';

const SPACING = 1.875;

const Positioning = styled('div')`
  right: ${SPACING}rem;
  bottom: ${SPACING}rem;
  position: absolute;
`;

interface IHUDWrapper {
  children: React.ReactNode;
  theme: Theme;
}

export function HUDWrapper({ children, theme }: IHUDWrapper) {
  return (
    <HtmlStaticPseudoPortal theme={theme}>
      <Positioning>{children}</Positioning>
    </HtmlStaticPseudoPortal>
  );
}
