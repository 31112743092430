import { uniqBy } from 'lodash-es';
import { FilterOptionsState } from '@mui/material';
import type { SearchablePart } from 'src/components/Product/Desktop/Panels/PartsCatalogue/Autocomplete';

export default function filterOptionsFunction(regex: RegExp, enabled: boolean) {
  return (options: SearchablePart[], _state?: FilterOptionsState<SearchablePart>) => {
    if (!enabled) return [];
    const filtered = options.filter(({ part: { name, id }, partNumber }) =>
      partNumber
        ? partNumber?.match(regex) // Special treatment for supersessions. Don't show them when searching by name.
        : name?.match(regex) || // TODO: Verify why '?' is needed. Apparently our typing is off and some parts are in fact not IBom
          id?.match(regex)
    );
    return uniqBy(filtered, uniqueKey);
  };
}

// Supersessions have partNumber and other parts have id. It's basically the same thing, maybe it should be merged in the EPC?
export const uniqueKey = ({ part: { id }, pathHash, partNumber }: SearchablePart) => (partNumber || id) + pathHash;
